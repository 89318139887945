import _ from 'lodash'
import {
  GET_ALL_PEDIGREE_HEADER,
  GET_ALL_PEDIGREE_HEADER_OPTIONS,
  GET_ALL_PEDIGREE_HEADER_SUCCESS,
  GET_PEDIGREE_HEADER,
  GET_PEDIGREE_HEADER_SUCCESS,
} from './actionTypes'

const form = {
  header_name: '',
  logo_1: '',
  logo1: '',
  logo_2: '',
  logo2: '',
  first_name: '',
  last_name: '',
  address_1: '',
  address_2: '',
  country_id: '',
  state_id: '',
  city: '',
  zipcode: '',
  phone: '',
  email: '',
  signature: '',
  font_type: '',
}
const initialState = {
  pedigreeHeader: {
    pedigreeHeaderList: [],
    loading: true,
  },

  form: form,
  formLoading: false,
}

const pedigreeHeaderReducer = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case GET_ALL_PEDIGREE_HEADER:
    case GET_ALL_PEDIGREE_HEADER_OPTIONS:
      return {
        ...state,
        pedigreeHeader: {
          ...state.pedigreeHeader,
          loading: true,
        },
        form: form,
      }
    case GET_ALL_PEDIGREE_HEADER_SUCCESS:
      return {
        ...state,
        pedigreeHeader: {
          ...state.pedigreeHeader,
          pedigreeHeaderList: payload,
          loading: false,
        },
      }
    case GET_PEDIGREE_HEADER:
      return {
        ...state,
        formLoading: true,
      }
    case GET_PEDIGREE_HEADER_SUCCESS:
      return {
        ...state,
        form: _.pick(payload, Object.keys(form)),
        formLoading: false,
      }
    default:
      return state
  }
}
export default pedigreeHeaderReducer
