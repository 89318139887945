import React, { useState, useEffect } from 'react'

// import { Elements } from '@stripe/react-stripe-js';
import { Col, Container, Modal, Row } from 'reactstrap'

import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'
// import CheckoutForm from './Component/checkoutForm'

// import { post } from '../../helpers/api_helper'
import { useHistory, useLocation } from 'react-router-dom'
import { post, put } from '../../../helpers/api_helper'
import CheckoutForm from './checkoutForm'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import checkImg from '../../../assets/images/users/check.png'
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE)

const Checkout = () => {
  const location = useLocation()
  const history = useHistory()
  const query = new URLSearchParams(location.search)
  const [showModal, setShowModal] = useState(false)
  const YourParticipation = useSelector((state) => state.YourParticipation)

  const { shoppingData } = YourParticipation
  // const payment_intent = query.get('payment_intent')

  const [clientSecret, setClientSecret] = useState(shoppingData.clientSecret)
  useEffect(() => {
    const clientSecret = new URLSearchParams(window.location.search).get(
      'payment_intent_client_secret'
    )

    const paymentIntent = new URLSearchParams(window.location.search).get(
      'payment_intent'
    )

    if (clientSecret && paymentIntent) {
      const fun = async () => {
        let data = {
          clientSecret: clientSecret,
          paymentIntentId: paymentIntent,
        }
        try {
          await put(`/api/v1/your-participation/payment-success-online`, data)
          setShowModal(true)
          setTimeout(() => {
            history.push('/your_participation')
          }, 1500)
        } catch (err) {
          history.push('/your_participation')
          // toast.error(err.response.data)
        }
      }
      fun()
    }
  }, [])
  // const [loading, setLoading] = useState(false)

  // useEffect(() => {
  //   post(`/api/v1/shows/user-sponsor/create-payment-intent`).then((res) => {
  //     setClientSecret(res.client_secret)
  //   })
  // }, [payment_intent])

  const appearance = {
    theme: 'stripe',
  }
  const options = {
    clientSecret,
    appearance,
    hidePostalCode: true,
    country: false,
  }

  return (
    <React.Fragment>
      <div className="page-content bg-gray-101 min-h-85">
        <section id="about" class="section1 bg-gray-101 pages event-list">
          <Container fluid={true} className="auto-fit">
            <Row>
              <Col lg={12} className="text-center">
                <div className="page-title-box justify-content-between">
                  <h2 className="top-title mb-4">
                    <span>PROCESS PAYMENT</span>
                  </h2>
                </div>
              </Col>
            </Row>
            {clientSecret && (
              <Elements options={options} stripe={stripePromise}>
                <CheckoutForm isDisabled={clientSecret} />
              </Elements>
            )}
          </Container>
        </section>
      </div>
      <Modal
        className="sucess_modal"
        isOpen={showModal}
        toggle={() => {
          // contact_center_modal();
        }}
        centered={true}
      >
        <div className="modal-header">
          <h4>&nbsp;</h4>
          <button
            type="button"
            onClick={() => {
              setShowModal(false)
            }}
            className="close close-btn"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <Row className="mb-2 text-center pay-modal">
            <Col lg={12}>
              <h4>PAYMENT SUCCESSFUL</h4>
              <p className="m-0 ">
                <img src={checkImg} alt="sucess" className="p-r-3" />
              </p>
              <p className="m-0 ">
                {/* Dear, {user.first_name} {user.last_name} */}
              </p>
              {/* <p className="m-0 ">
                    Thank you for participating in the event.
                  </p> */}
              <p>Participation has been updated successfully</p>
            </Col>
          </Row>
        </div>
      </Modal>
    </React.Fragment>
  )
}

export default Checkout
