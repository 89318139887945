import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Button, Col, Container, Input, Label, Row } from 'reactstrap'
import { get, patch } from '../../../helpers/api_helper'
import { setMsg, setShowAlert } from '../../../store/successpopup/actions'

const EditSubUser = (props) => {
  const history = useHistory()
  useEffect(() => {
    get(`/api/v1/profile/profile_details`)
      .then((data) => {
        let userData = data.data
        setInputs((values) => ({
          ...values,
          parent_user_name: `${userData.last_name} ${userData.first_name}`,
        }))
      })
      .catch((error) => {
        console.log(error)
      })
  }, [])
  const { first_name, last_name, active, arba_number, id } =
    (props.location && props.location.state) || {}
  console.log(props.location.state)
  const [inputs, setInputs] = useState({
    first_name: first_name || '',
    last_name: last_name || '',
    arba_no: arba_number ? arba_number : '',
    active: active,
  })
  const [errors, setErrors] = useState({
    first_name: null,
    last_name: null,
    arba_no: null,
  })
  let [backendErr, setBackendErr] = useState({})

  const dispatch = useDispatch()
  useEffect(() => {}, [])
  const handleChange = (event) => {
    const name = event.target.name
    let value = event.target.value
    if (name === 'active') {
      let Checked = event.target.checked
      if (Checked) {
        value = 1
      } else {
        value = 0
      }
    }
    setInputs((values) => ({ ...values, [name]: value }))
  }
  const handleSubmit = (e) => {
    e.preventDefault()
    let subUserData = {}
    let validation = true
    subUserData = {
      first_name: inputs.first_name.toString().trim(),
      last_name: inputs.last_name.toString().trim(),
      active: inputs.active,
      arba_number: inputs.arba_no.toString().trim(),
    }

    let err = {
      first_name: '',
      last_name: '',
      arba_number: '',
    }
    if (!inputs.first_name.toString().trim()) {
      validation = false
      err.first_name = 'First name field is required'
    }
    if (inputs.first_name.toString().trim()) {
      let expression = /^[a-zA-Z \-]+$/g
      let regex = new RegExp(expression)
      if (!inputs.first_name.toString().trim().match(regex)) {
        validation = false
        err.first_name = 'First name field allow only characters'
      }
      if (inputs.first_name.toString().trim().length > 50) {
        validation = false
        err.first_name =
          'First name must be of minimum 1 and maximum 50 character length'
      }
    }
    if (!inputs.last_name.toString().trim()) {
      validation = false
      err.last_name = 'Last name field is required'
    }
    if (inputs.last_name.toString().trim()) {
      let expression = /^[a-zA-Z \-]+$/g
      let regex = new RegExp(expression)
      if (!inputs.last_name.toString().trim().match(regex)) {
        validation = false
        err.last_name = 'Last name field allow only characters'
      }
      if (inputs.last_name.toString().trim().length > 50) {
        validation = false
        err.last_name =
          'Last name must be of minimum 1 and maximum 50 character length'
      }
    }
    // if (!inputs.arba_no) {
    //   validation = false
    //   err.arba_number = 'ARBA no. is required'
    // }
    setBackendErr({})
    setErrors(err)
    if (validation) {
      document.getElementById('submit').setAttribute('disabled', true)
      patch(`/api/v1/subuser/${id}`, subUserData)
        .then((response) => {
          dispatch(setShowAlert(true))
          dispatch(setMsg(response))
          history.push('/subuserlist')
        })
        .catch((error) => {
          document.getElementById('submit').removeAttribute('disabled')
          setBackendErr({})
          error.response.data.map((item) => {
            setBackendErr((values) => ({
              ...values,
              [item.param]: item.msg,
            }))
          })
        })
    }
  }

  return (
    <React.Fragment>
      <div className='page-content min-h-80'>
        <Container fluid={true} className='auto-fit'>
          <Row>
            <Col lg={12} className='text-center'>
              <div className='page-title-box justify-content-between'>
                <h2 className='top-title mb-4'>
                  <span>EDIT SUB USER</span>
                </h2>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg='12'>
              <form onSubmit={handleSubmit}>
                <Row>
                  <Col xl={12}>
                    <div className='mb-3'>
                      <Label className='form-label' htmlFor=''>
                        Parent User Name *
                      </Label>
                      <Input
                        type='text'
                        className=''
                        id='parent_user_name'
                        name='parent_user_name'
                        value={inputs.parent_user_name}
                        placeholder='Parent User Name'
                        disabled
                      />
                    </div>
                  </Col>

                  <Col xl={12}>
                    <div className='mb-3'>
                      <Label className='form-label' htmlFor=''>
                        Sub User’s First Name *
                      </Label>
                      <Input
                        type='text'
                        className=''
                        id=''
                        name='first_name'
                        placeholder='Enter Sub User’s First Name'
                        value={inputs.first_name}
                        onChange={handleChange}
                        autoFocus
                      />
                      {errors.first_name && (
                        <p className='text-danger'>{errors.first_name}</p>
                      )}
                    </div>
                  </Col>

                  <Col xl={12}>
                    <div className='mb-3'>
                      <Label className='form-label' htmlFor=''>
                        Sub User’s Last Name *
                      </Label>
                      <Input
                        type='text'
                        className=''
                        id=''
                        name='last_name'
                        value={inputs.last_name}
                        onChange={handleChange}
                        placeholder='Enter Sub User’s Last Name'
                      />
                      {errors.last_name && (
                        <p className='text-danger'>{errors.last_name}</p>
                      )}
                    </div>
                  </Col>

                  <Col xl={12}>
                    <div className='mb-3'>
                      <Label className='form-label' htmlFor=''>
                        Sub User’s ARBA No.
                      </Label>
                      <Input
                        type='text'
                        className=''
                        id=''
                        name='arba_no'
                        value={inputs.arba_no}
                        onChange={handleChange}
                        placeholder='Enter ARBA No.'
                      />
                      {errors.arba_number && (
                        <p className='text-danger'>{errors.arba_number}</p>
                      )}
                    </div>
                  </Col>

                  <Col xl={12}>
                    <div className='mb-3'>
                      <Label
                        className='form-label'
                        htmlFor='formrow-firstname-input'>
                        Status
                      </Label>
                      <div className='form-check text-left cust-pad'>
                        <input
                          className='form-check-input mt-2'
                          type='checkbox'
                          name='active'
                          id='defaultCheck1'
                          value={1}
                          checked={inputs.active == 1}
                          onClick={handleChange}
                        />{' '}
                        <label className='form-check-label p-5px' htmlFor='defaultCheck1'>
                          {/* Active{' '} */}
                          {Number(inputs.active) === 0
                                ? 'In Active'
                                : 'Active'}

                          {/* <span>
                            ( Main user is not active, You are not allowed to
                            active this subuser ){' '}
                          </span> */}
                        </label>
                      </div>
                    </div>
                  </Col>
                  <Col lg={12}>
                    <div className='mt-8 text-center'>
                      <Button
                        type='submit'
                        color='success'
                        id='submit'
                        className='w-lg waves-effect waves-light'
                        onClick={handleSubmit}>
                        SUBMIT
                      </Button>
                    </div>
                  </Col>
                </Row>
              </form>
            </Col>
          </Row>
        </Container>
        {/* <SuccessPopup /> */}
      </div>
    </React.Fragment>
  )
}

export default EditSubUser
