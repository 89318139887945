import React, { useEffect } from 'react'
import {
  Card,
  CardHeader,
  Modal,
  ModalBody,
  ModalHeader,
  TextField,
} from '../Atoms'
import { Button, Row, CardBody, Col, Label } from 'reactstrap'
import Breadcrumb from '../../../common/Breadcrumb'

import NewPedigree from './NewPedigree/NewPedigree'
import RabbitForm from './RabbitForm/RabbitForm'
import { useDispatch, useSelector } from 'react-redux'
import {
  getAllBreedList,
  getOnePedigreeDetail,
  rabbitList,
  showAndClosePedigreeModal,
  updatePedigreeUpdate,
} from '../../../store/actions'
import './style.css'
import { Field, Form, Formik } from 'formik'
import RabbitLevels from './RabbitLevels/RabbitLevels'
import _ from 'lodash'
import classNames from 'classnames'
import * as Yup from 'yup'
import * as validation from '../../../helpers/validation'
import { UrlEncodeHook } from '../../../hooks'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

const PedigreeForm = () => {
  const { urlDecode } = UrlEncodeHook()
  let location = useLocation()
  const history = useHistory()

  // let path = location.pathname.split('/')
  let { id } = useParams()

  const query = new URLSearchParams(location.search)
  let pedigreeId = query.get('p')
  pedigreeId = urlDecode(pedigreeId)
  id = urlDecode(id)

  ////////////////////////////////
  const dispatch = useDispatch()
  const { showModal, modalType, pedigreeForm } = useSelector((state) => {
    return {
      showModal: state.herdManagement.Pedigree.pedigreeModal.showModal,
      modalType: state.herdManagement.Pedigree.pedigreeModal.modalType,
      pedigreeForm: state.herdManagement.Pedigree.pedigreeForm.form,
    }
  })
  useEffect(() => {
    const data = {
      params: {
        published: 1,
      },
    }
    dispatch(getAllBreedList(data))
  }, [])
  useEffect(() => {
    dispatch(rabbitList())
  }, [showModal])

  useEffect(() => {
    if (pedigreeId) {
      const getPeDigreeParams = {
        params: {
          id: pedigreeId,
        },
      }
      dispatch(getOnePedigreeDetail(getPeDigreeParams))
    }
  }, [])

  const validationSchema = Yup.object({
    is_agree: Yup.boolean().oneOf([true], `This ${validation.REQUIRED}`),
    // pedigree_id: Yup.string().required(`Need to create pedigree for submit`),
  })
  const updatePedigreeApi = (body) => {
    return new Promise((resolve, reject) => {
      dispatch(
        updatePedigreeUpdate(body, (err, data) => {
          if (err) {
            reject(err)
          } else {
            resolve(data)
          }
        })
      )
    })
  }

  const getPedigreeApi = (body) => {
    return new Promise((resolve, reject) => {
      dispatch(
        getOnePedigreeDetail(body, (err, data) => {
          if (err) {
            reject(err)
          } else {
            resolve(data)
          }
        })
      )
    })
  }
  const onSubmit = async (values, formProps) => {
    try {
      const objForm = _.pick(values, [
        'pedigree_name',
        'rabbit_id',
        'archieve',
        'is_agree',
      ])
      const updateObj = {
        params: { id: pedigreeForm.pedigree_id },
        data: {
          ...objForm,
          archieve: objForm?.archieve ? objForm?.archieve : 0,
          is_agree: values.is_agree ? 1 : 0,
          rabbit_data: [],
        },
      }
      formProps.setStatus(true)
      const updatePedigreeRes = await updatePedigreeApi(updateObj)
      if (updatePedigreeRes) {
        toast.success('Record has been saved successfully')
        setTimeout(() => {
          history.push('/herd-management/pedigree')
        }, 1500)
      }
    } catch (err) {
      formProps.setStatus(false)
      if (Array.isArray(err)) {
        toast.error(_.get(_.first(err), 'msg'))
      } else if (err.response.data) {
        toast.error(err.response.data)
      } else if (err) {
        toast.error(err)
      }
    }
  }
  return (
    <Card className='layout pedigree-view'>
      <Row>
        <CardHeader>
          <Row>
            <Breadcrumb title={!id ? 'Add Pedigree' : 'Update Pedigree'} />
          </Row>
        </CardHeader>
        <CardBody style={{ overflow: 'auto' }}>
          <Formik
            initialValues={pedigreeForm}
            onSubmit={onSubmit}
            enableReinitialize
            validationSchema={validationSchema}
          >
            {({ values, status, errors }) => {
              return (
                <Form className='needs-validation club-selection'>
                  <div
                    style={{
                      overflow: 'auto',
                      width: '1500px',
                      margin: 'auto',
                      paddingTop: '10px',
                    }}
                    className='tree-body'
                  >
                    {_.size(values?.rabbit_data)
                      ? values.rabbit_data.map((rabbit, i) => {
                          return (
                            <React.Fragment key={i}>
                              <Button
                                className={classNames(
                                  `level-${rabbit.generation} position-relative rectangle bg-golden border-0`
                                )}
                                color='warning'
                                size='sm'
                                disabled={rabbit.disabled}
                                onClick={() =>
                                  dispatch(
                                    showAndClosePedigreeModal({
                                      showModal: true,
                                      modalType: 'newPedigree',
                                      ModalContent: {
                                        childRabbit: null,
                                        form: rabbit,
                                      },
                                    })
                                  )
                                }
                              >
                                {rabbit?.text || ''}
                                {!rabbit.rabbit_id ? (
                                  <span className='button__badge'></span>
                                ) : null}
                              </Button>
                              <RabbitLevels
                                childRabbit={rabbit}
                                rabbit={rabbit.parents}
                                level={rabbit.generation + 1}
                              />

                              <Col md='12' lg='12' sm='12'>
                                <div className='mb-4 mt-5'>
                                  <Field
                                    name='is_agree'
                                    type='checkbox'
                                    id='is_agree'
                                    component={TextField}
                                    labelComponent={
                                      <Label
                                        className='form-label ms-2'
                                        htmlFor='is_agree'
                                      >
                                        I certify this pedigree is finished, including if any data fields are blank.
                                      </Label>
                                    }
                                  />{' '}
                                </div>
                              </Col>
                              {!values.pedigree_id ? (
                                <Col md={12} className='text-center mt-3 mb-5'>
                                  <Button
                                    type='submit'
                                    disabled
                                    color='primary'
                                    title='Submit'
                                  >
                                    SUBMIT
                                  </Button>
                                </Col>
                              ) : (
                                <Col md={12} className='text-center mt-3 mb-5'>
                                  <Button
                                    type='submit'
                                    disabled={status}
                                    color='primary'
                                    title='Submit'
                                  >
                                    SUBMIT
                                  </Button>
                                </Col>
                              )}
                            </React.Fragment>
                          )
                        })
                      : null}
                  </div>
                </Form>
              )
            }}
          </Formik>

          <Modal
            isOpen={showModal && modalType === 'newPedigree' ? true : false}
            size='lg'
          >
            <ModalHeader
              toggle={() =>
                dispatch(
                  showAndClosePedigreeModal({
                    showModal: false,
                    modalType: '',
                    ModalContent: '',
                  })
                )
              }
            >
              New Pedigree
            </ModalHeader>
            <ModalBody>
              {showModal && modalType === 'newPedigree' ? (
                <NewPedigree />
              ) : null}
            </ModalBody>
          </Modal>
          <Modal
            isOpen={showModal && modalType === 'rabbit' ? true : false}
            size='lg'
          >
            <ModalHeader
              toggle={() =>
                dispatch(
                  showAndClosePedigreeModal({
                    showModal: false,
                    modalType: '',
                    ModalContent: '',
                  })
                )
              }
            >
              Rabbit
            </ModalHeader>
            <ModalBody>
              {showModal && modalType === 'rabbit' ? <RabbitForm /> : null}
            </ModalBody>
          </Modal>
        </CardBody>
      </Row>
    </Card>
  )
}

export default PedigreeForm

const flattenPedigreeRabbits = (rabbits) => {
  return _.flatMapDeep(rabbits, (rabbit) => {
    // Extract the current rabbit data without the `parents` field
    const rabbitData = _.omit(rabbit, ['parents'])
    // Recursively call the function to flatten the parents
    const parentData = flattenPedigreeRabbits(rabbit.parents || [])
    // Combine the current rabbit with its flattened parents
    return [rabbitData, ...parentData]
  })
}
