import ReactPaginate from 'react-paginate'
import { useSelector } from 'react-redux'

const Pagination = (props) => {
  //
  const { page, totalPage, totalRecords, PageLoading, limit } = useSelector(
    (state) => {
      return {
        page: state?.herdManagement?.pagination?.page || 1,
        totalPage: state?.herdManagement?.pagination?.totalPage || 1,
        totalRecords: state?.herdManagement?.pagination?.totalRecords || 1,
        limit: state?.herdManagement?.pagination?.limit || 1,
        PageLoading: state?.herdManagement?.pagination?.loading,
      }
    }
  )

  return !PageLoading && totalRecords > Number(limit) ? (
    <ReactPaginate
      {...props}
      breakLabel='...'
      breakClassName='page-item'
      breakLinkClassName='page-link'
      nextLabel='>'
      pageCount={totalPage}
      pageRangeDisplayed={4}
      marginPagesDisplayed={1}
      previousLabel='<'
      renderOnZeroPageCount={null}
      containerClassName='pagination mtb-4'
      pageLinkClassName='page-link page-link'
      pageClassName='page-item'
      activeClassName='active'
      nextLinkClassName='page-link'
      previousClassName='page-item'
      previousLinkClassName='page-link'
      nextClassName='page-item'
      onPageChange={props.paginationClick}
      forcePage={page - 1}
    />
  ) : null
}

export default Pagination
