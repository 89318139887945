import React, { useEffect, useState } from 'react'
import { Button, Col, FormGroup, Input, Label, Row } from 'reactstrap'
import * as validation from '../../../helpers/validation'
import * as Util from '../../../helpers/utils'
import { useDispatch, useSelector } from 'react-redux'
import {
  addAnimal,
  addAnimalListing,
  allClassTypeList,
  allCountryList,
  allStateList,
  updateAnimal,
} from '../../../store/actions'
import { useHistory, useParams } from 'react-router-dom'
import { UrlEncodeHook } from '../../../hooks'
import { get } from '../../../helpers/api_helper'
import _ from 'lodash'
const AnimalForm = () => {
  const { urlDecode } = UrlEncodeHook()
  const dispatch = useDispatch()
  const history = useHistory()
  let { ID } = useParams()
  ID = urlDecode(ID)
  const { BreedList } = useSelector((state) => state.Breeds)
  const { ClassTypeList } = useSelector((state) => state.ClassTypes)
  const { CountryList } = useSelector((state) => state.Country)
  const { StateList } = useSelector((state) => state.State)
  const { easyFiedsUser } = useSelector((state) => state.EasyfidesProfile)

  const [category_id, setCategory_id] = useState('')
  const [userId, setUserId] = useState('')
  const [breed_id, setBreed_id] = useState('')
  const [class_type, setClass_type] = useState('')
  const [amount, setAmount] = useState('')
  const [description, setDescription] = useState('')
  const [errors, setErrors] = useState({})
  const [state_id, setState_id] = useState('')
  const [country_id, setCountry_id] = useState('')
  const [city, setCity] = useState('')
  const [BreedCategory] = useState([
    {
      breed_category_id: 1,
      category_name: 'Rabbit Breed List',
    },
    {
      breed_category_id: 2,
      category_name: 'Cavy Breed List',
    },
    {
      breed_category_id: 3,
      category_name: 'Exhibition Breed List',
    },
    {
      breed_category_id: 4,
      category_name: 'Commercial Breed List',
    },
  ])

  //
  useEffect(() => {
    dispatch(allCountryList())
  }, [])

  useEffect(() => {
    if (country_id) {
      let params = {
        country_id: country_id,
      }
      dispatch(allStateList(params))
    }
  }, [country_id])

  useEffect(() => {
    // if (category_id)
    let obj = { breed_category_id: category_id }
    dispatch(addAnimalListing(obj))
  }, [category_id])

  //
  useEffect(() => {
    setClass_type('')
    dispatch(allClassTypeList())
    if (ID) {
      get(`/api/v1/easyfied/animal-listing/user/one/${ID}`).then((res) => {
        let finalData = res.data
        setCategory_id(finalData?.category_id)
        setBreed_id(finalData?.breed_id)
        setClass_type(finalData?.class_type_id)
        setDescription(finalData?.description)
        setAmount(finalData?.amount)
        setUserId(finalData?.user_id)
        setCity(finalData?.city)
        setCountry_id(finalData?.country_id)
        setState_id(finalData?.state_id)
      })
    } else {
      let address = _.first(easyFiedsUser?.address)
      setCity(address?.city)
      setCountry_id(address?.country_id)
      setState_id(address?.state_id)
    }
  }, [ID, easyFiedsUser?.address])
  const onSubmit = () => {
    let validationFlag = false
    let err = {}
    if (!category_id) {
      validationFlag = true
      err.category_id = `Category ${validation.REQUIRED}`
    }
    if (!country_id) {
      validationFlag = true
      err.country_id = `Country ${validation.REQUIRED}`
    }
    if (!state_id) {
      validationFlag = true
      err.state_id = `State ${validation.REQUIRED}`
    }
    if (!city?.trim()) {
      validationFlag = true
      err.city = `City ${validation.REQUIRED}`
    }
    if (city.trim()) {
      if (city?.trim().length >= 50) {
        validationFlag = true
        err.city = `City ${validation.MAX_CHAR_FUN(50)}`
      }
    }
    if (!breed_id) {
      validationFlag = true
      err.breed_id = `Breed ${validation.REQUIRED}`
    }
    // if (!class_type) {
    //   validationFlag = true
    //   err.class_type = `Class type ${validation.REQUIRED}`
    // }
    if (!amount) {
      validationFlag = true
      err.amount = `Amount ${validation.REQUIRED}`
    }
    if (amount) {
      if (!Util.PRICE_REGEX.test(amount)) {
        validationFlag = true
        err.amount = `Amount ${validation.INVALID}`
      } else if (Number(amount) > 999) {
        validationFlag = true
        err.amount = `Amount ${validation.MAX_NUMBER(999)}`
      }
    }
    if (!description) {
      validationFlag = true
      err.description = `Description ${validation.REQUIRED}`
    }
    if (description) {
      if (description.length >= 2000) {
        validationFlag = true
        err.description = `Description ${validation.MAX_CHAR_FUN(20000)}`
      }
    }
    setErrors(err)
    if (!validationFlag) {
      document.getElementById('submit-btn').setAttribute('disabled', true)
      let data = {
        category_id: +category_id,
        breed_id: +breed_id,
        class_type_id: +class_type,
        amount: +amount,
        description,
        country_id,
        state_id,
        city: city.trim(),
      }
      let obj = {
        ID,
        data: data,
        history,
      }
      if (!ID) {
        dispatch(addAnimal(obj))
      } else {
        obj = {
          ...obj,
          data: {
            ...obj.data,
            user_id: userId,
          },
        }
        dispatch(updateAnimal(obj))
      }
    }
  }

  return (
    <div className='profile-content-area my-6 card card-body mb-10'>
      <div className='pb-6 easyfied-form'>
        <Row>
          <Col md={12} sm={12} lg={12}>
            <h4 className='mb-3'>My Animal Listings</h4>
          </Col>

          <FormGroup className='my-3'>
            <select
              name='category_id'
              className='form-control form-select'
              onChange={(e) => {
                setBreed_id('')
                setCategory_id(e.target.value)
              }}
              value={category_id}
            >
              <option value=''>Select Category</option>
              {Array.isArray(BreedCategory) && BreedCategory.length
                ? BreedCategory.map((category, i) => {
                    return (
                      <option value={category?.breed_category_id} key={i}>
                        {category?.category_name}
                      </option>
                    )
                  })
                : null}
            </select>
            {errors?.category_id ? (
              <div className='text-danger mt-1'>{errors?.category_id}</div>
            ) : null}
          </FormGroup>

          <FormGroup className='my-3'>
            <select
              name='breed'
              className='form-control form-select'
              onChange={(e) => setBreed_id(e.target.value)}
              value={breed_id}
            >
              <option value=''>Select Breed</option>
              {Array.isArray(BreedList) && BreedList.length
                ? BreedList.map((_breed, i) => {
                    return (
                      <option value={_breed?.breed_id || ''} key={i}>
                        {_breed?.breed_name || ''}
                      </option>
                    )
                  })
                : null}
            </select>
            {errors?.breed_id ? (
              <div className='text-danger mt-1'>{errors?.breed_id}</div>
            ) : null}
          </FormGroup>

          <FormGroup className='my-3'>
            <select
              name='class_type_id'
              className='form-control form-select'
              onChange={(e) => setClass_type(e.target.value)}
              value={class_type}
            >
              <option value=''>Select Class Type</option>
              {Array.isArray(ClassTypeList) && ClassTypeList.length
                ? ClassTypeList.map((classes, i) => {
                    return (
                      <option value={classes?.class_id || ''} key={i}>
                        {' '}
                        {classes?.class_name || ''}
                      </option>
                    )
                  })
                : null}
            </select>
            {errors?.class_type ? (
              <div className='text-danger mt-1'>{errors?.class_type}</div>
            ) : null}
          </FormGroup>

          <FormGroup className='my-3'>
            <Label htmlFor='amount'>Amount ($) *</Label>
            <Input
              id='amount'
              name='amount'
              placeholder='Amount'
              type='text'
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
            />
            {errors?.amount ? (
              <div className='text-danger mt-1'>{errors?.amount}</div>
            ) : null}
          </FormGroup>

          <FormGroup className='my-3'>
            <Label htmlFor='Description' className='d-block'>
              Description *
            </Label>
            <Input
              id='Description'
              name='Description'
              placeholder='Description'
              type='textarea'
              rows='4'
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
            {errors?.description ? (
              <div className='text-danger mt-1'>{errors?.description}</div>
            ) : null}
          </FormGroup>
          <FormGroup className='my-3'>
            <Label htmlFor='country_id' className='d-block'>
              Country *
            </Label>
            <select
              name='country_id'
              className='form-control form-select'
              onChange={(e) => {
                setCountry_id(e.target.value)
                setState_id('')
              }}
              value={country_id}
            >
              <option value=''>Select Country</option>
              {Array.isArray(CountryList) && CountryList.length
                ? CountryList.map((country, i) => {
                    return (
                      <option value={country?.country_id || ''} key={i}>
                        {' '}
                        {country?.country_name || ''}
                      </option>
                    )
                  })
                : null}
            </select>
            {errors?.country_id ? (
              <div className='text-danger mt-1'>{errors?.country_id}</div>
            ) : null}
          </FormGroup>
          <FormGroup className='my-3'>
            <Label htmlFor='state_id' className='d-block'>
              State *
            </Label>
            <select
              name='state_id'
              className='form-control form-select'
              onChange={(e) => setState_id(e.target.value)}
              value={state_id}
              disabled={!country_id}
            >
              <option value=''>Select State</option>
              {Array.isArray(StateList) && StateList.length
                ? StateList.map((state, i) => {
                    return (
                      <option value={state?.state_id || ''} key={i}>
                        {' '}
                        {state?.state_code || ''}
                      </option>
                    )
                  })
                : null}
            </select>
            {errors?.state_id ? (
              <div className='text-danger mt-1'>{errors?.state_id}</div>
            ) : null}
          </FormGroup>
          <FormGroup className='my-3'>
            <Label htmlFor='city'>City *</Label>
            <Input
              id='city'
              name='city'
              placeholder='City'
              type='text'
              value={city}
              onChange={(e) => setCity(e.target.value)}
            />
            {errors?.city ? (
              <div className='text-danger mt-1'>{errors?.city}</div>
            ) : null}
          </FormGroup>

          <div className='p-20px-t mt-6'>
            <Button
              type='submit'
              name='btnSubmit'
              className='w-lg waves-effect waves-light btn btn-success'
              onClick={onSubmit}
              title='submit'
              id='submit-btn'
            >
              SUBMIT
            </Button>
          </div>
        </Row>
      </div>
    </div>
  )
}

export default AnimalForm
