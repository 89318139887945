import {
  SET_SHOW_ALERT,
  SET_MESSAGE,
  SET_MESSAGE_TITLE,
  SET_HANDLE_CLICK,
  SET_SHOW_BUTTON,
  SET_BUTTON_TEXT,
} from './actionTypes';

export const setShowAlertp = (value) => ({
  type: SET_SHOW_ALERT,
  payload: value,
});

export const setMsgp = (value) => ({
  type: SET_MESSAGE,
  payload: value,
});

export const setMessageTitlep = (value) => ({
  type: SET_MESSAGE_TITLE,
  payload: value,
});

export const setHandleClickp = (value) => ({
  type: SET_HANDLE_CLICK,
  payload: value,
});

export const setShowBtnp = (value) => ({
  type: SET_SHOW_BUTTON,
  payload: value,
});
export const setBtnTextp = (value) => ({
  type: SET_BUTTON_TEXT,
  payload: value,
});
