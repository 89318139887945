import { Table } from 'react-super-responsive-table'
import { Button, Col, Container, Row } from 'reactstrap'
import SuccessPopup from '../../CommonComponent/successPopup1'
import { Link, Redirect } from 'react-router-dom'
import ParticipationEvent from './ParticipationEvent'
import _ from 'lodash'
import $ from 'jquery'
import {
  defaultParticipate,
  furTypeReset,
  hasFurReset,
  resetFromBreed,
  resetFromBreedCategory,
  resetFromGroup,
  resetFromGroupOnlySingleFurData,
  resetFromShowType,
  resetFromVariety,
  resetFromVarietyDeadline,
  resetFromVarietyOnlySingleFurData,
  restFromShowList,
} from '../../../helpers/helper'

import { v4 as uuidv4 } from 'uuid'
import { toast } from 'react-toastify'
import List from './list'
import React, { useEffect, useMemo, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { get, post, put } from '../../../helpers/api_helper'
import { AddYourParticipationToShoppingCart } from '../../../store/actions'
import { DeadlineHook, UrlEncodeHook } from '../../../hooks'
import { GetInTouchModal } from './Modal/GetInTouchModal'
import CModal from './Modal'

const PreviewParticipateEvents = () => {
  const history = useHistory()
  let { eventId, showId } = useParams()
  const { urlDecode, urlEncode } = UrlEncodeHook()
  eventId = urlDecode(eventId)
  showId = urlDecode(showId)

  const dispatch = useDispatch()
  const YourParticipation = useSelector((state) => state.YourParticipation)

  const [participation, setParticipation] = useState(
    YourParticipation.previewAnimal
  )

  const [showData, setShowData] = useState([])
  const [exhibitorList, setExhibitorList] = useState([])
  const [error, setError] = useState([])
  const [event, setEvent] = useState()
  const { deadLineType } = DeadlineHook({
    eventId,
  })
  // Modal
  const [modal_center, setmodal_center] = useState(false)
  const [showModal, setShowModal] = useState(false)
  let [showSuccessPopup, setShowSuccessPopup] = useState(false)
  let [showBtn, setShowBtn] = useState(true)
  const [showAlert, setShowAlert] = useState('')
  const [msg, setMsg] = useState('')

  //
  function removeBodyCss() {
    document.body.classList.add('no_padding')
  }
  let deadline = deadLineType()

  let furDisable = useMemo(() => {
    let furDisable = false
    if (deadline.deadlineType === 3 && deadline.first_deadline) {
      furDisable = true
    }
    if (deadline.deadlineType === 2 && deadline.first_deadline) {
      furDisable = true
    }
    if (deadline.deadlineType === 1 && deadline.first_deadline) {
      furDisable = true
    }

    return furDisable
  }, [deadline])

  let disableGroupToClass = useMemo(() => {
    let furDisable = false
    if (deadline.deadlineType === 3 && deadline.second_deadline) {
      furDisable = true
    }
    if (deadline.deadlineType === 2 && deadline.second_deadline) {
      furDisable = true
    }
    if (deadline.deadlineType === 1 && deadline.first_deadline) {
      furDisable = true
    }
    return furDisable
  }, [deadline])

  let disabledEr = useMemo(() => {
    let furDisable = false
    if (deadline.deadlineType === 3 && deadline.third_deadline) {
      furDisable = true
    }
    if (deadline.deadlineType === 2 && deadline.second_deadline) {
      furDisable = true
    }
    if (deadline.deadlineType === 1 && deadline.first_deadline) {
      furDisable = true
    }

    return furDisable
  }, [deadline])
  //
  useEffect(() => {
    get(`/api/v1/events/sponsor/all-shows-by-event?event_id=${eventId}`).then(
      (res) => {
        if (res && Array.isArray(res.data)) {
          let obj = _.first(res.data)

          setEvent(obj)
        }

        // setEvents(res.data[0])
      }
    )
  }, [])

  useEffect(() => {
    get(`/api/v1/profile/exhibitor_list`).then((res) => {
      if (res) {
        let sortList = _.sortBy(res?.data || [], ['first_name'], ['asc'])

        setExhibitorList(sortList)
      }
    })
  }, [])

  useEffect(() => {
    const fun = async () => {
      const showData = await get(
        `/api/v1/shows/secretary/all?event_id=${eventId}`
      )
      if (showData && Array.isArray(showData.data)) {
        setShowData(showData.data)
      }
    }
    fun()
  }, [])

  const onChange = (id, v, type, name) => {
    if (!type) {
      if (v.target.name === 'ear_no' && v.target.value.length > 50) {
        toast.error('Max Ear. No Should be 50 Character', { toastId: 1 })
        return
      }
    }
    let list = participation.map((obj) => {
      if (id === obj.list_id) {
        let resetObj = {}

        if (type) {
          let value = v.map((data) => {
            return data.value
          })
          if (name === 'show_id') {
            resetObj = restFromShowList()
          }
          if (name === 'fur_type_id') {
            resetObj = furTypeReset()
          }

          return {
            ...obj,
            ...resetObj,
            [name]: value,
          }
        } else {
          let { name, value } = v.target
          // reset values start
          if (name === 'show_type') {
            resetObj = resetFromShowType()
          }

          if (name === 'has_fur' && obj.display_fur_data) {
            resetObj = hasFurReset()
          }
          if (name === 'variety_id') {
            if (furDisable) {
              resetObj = resetFromVarietyDeadline()
            } else if (!obj.display_fur_data) {
              resetObj = resetFromVarietyOnlySingleFurData()
            } else {
              resetObj = resetFromVariety()
            }
            // resetObj = resetFromVariety()
          }
          if (name === 'group_id') {
            if (furDisable) {
              resetObj = resetFromVarietyDeadline()
            } else if (!obj.display_fur_data) {
              resetObj = resetFromGroupOnlySingleFurData()
            } else {
              resetObj = resetFromGroup()
            }
          }
          if (name === 'breed_id') {
            // resetFromBreed
            resetObj = resetFromBreed()
          }
          if (name === 'breed_category_id') {
            resetObj = resetFromBreedCategory()
          }
          // reset value end
          if (name === 'ear_no') {
            value = value.toUpperCase()
          }
          if (name === 'breed_id') {
            let ele = v.target
            let variety_seq =
              ele.options[v.target.selectedIndex].getAttribute('variety_seq')
            let group_seq =
              ele.options[v.target.selectedIndex].getAttribute('group_seq')
            obj.variety_seq = Number(variety_seq) ? Number(variety_seq) : null
            obj.group_seq = Number(group_seq) ? Number(group_seq) : null
          }
          if (name === 'has_fur') {
            let ele = v.target.value
            if (Number(ele) === 0) {
              obj.removed_all_fur = true
            } else {
              obj.removed_all_fur = false
            }
          }

          return {
            ...obj,
            ...resetObj,
            [name]: value,
          }
        }
      }
      return obj
    })

    setParticipation(list)
  }

  const updateHasFur = (id, has, furTypeList, furData) => {
    let list = participation.map((par) => {
      if (par.list_id === id) {
        let display_fur_data = {
          display_fur_data: true,
        }
        if (
          Array.isArray(furData?.furList) &&
          furData.furList.length === 1 &&
          Array.isArray(furData?.furTypeList) &&
          furData.furTypeList.length === 1
        ) {
          display_fur_data = {
            display_fur_data: false,
            fur_type_id: [Number(_.first(furData.furTypeList).fur_type_id)],
            fur_id: Number(_.first(furData.furList).fur_id),
          }
        }
        return {
          ...par,
          ...display_fur_data,
          has_fur_data: has ? true : false,
          furTypeList: furTypeList,
        }
      }
      return par
    })
    setParticipation(list)
  }

  const onEdit = (id) => {
    let list = participation.map((obj) => {
      if (id === obj.list_id) {
        return {
          ...obj,
          isEdit: true,
        }
      }
      return obj
    })

    setParticipation(list)
  }
  const onSave = async (id) => {
    let validationFlag = false
    let validation = participation.filter((s) => s.list_id === id)
    let err = validation.map((val) => {
      let er = {}
      er.id = val.list_id
      if (!val.show_id && !val.show_id.length) {
        er.show_id = 'Please select show'
      }
      if (!val.show_type) {
        er.show_type = 'Please select show type'
      }
      if (!val.user_id) {
        er.user_id = 'Please select exhibitor'
      }
      if (!val.ear_no) {
        er.ear_no = 'Ear no. field is required'
      }
      if (val.ear_no) {
        let checkErNum = participation.find((s) => {
          if (
            s.list_id !== val.list_id &&
            s.ear_no === val.ear_no &&
            s.user_id === val.user_id &&
            s.show_id === val.show_id
          ) {
            return true
          }
          return false
        })
        if (checkErNum) {
          er.ear_no = 'Duplicate Ear no. found for same user.'
        }
      }
      if (!val.breed_category_id) {
        er.breed_category_id = 'Please select category'
      }
      if (!val.breed_id) {
        er.breed_id = 'Please select breed'
      }
      if (!val.class_id) {
        er.class_id = 'Please select class'
      }
      if (val.group_seq && !val.group_id) {
        er.group_id = 'Please select group'
      }
      if (val.variety_seq && !val.variety_id) {
        er.variety_id = 'Please select variety'
      }
      // debugger
      if (val.has_fur_data) {
        if (val.has_fur === '') {
          er.has_fur = 'Please select fur'
        }
        if (
          val.has_fur !== '' &&
          Number(val.has_fur) === 1 &&
          Array.isArray(val.fur_type_id) &&
          !val.fur_type_id.length
        ) {
          er.fur_type_id = 'Please select fur type'
        }
        if (val.has_fur !== '' && Number(val.has_fur) === 1 && !val.fur_id) {
          er.fur_id = 'Please select fur color'
        }
      }

      return er
    })
    setError(err)
    let valErr = _.map(err, function (obj) {
      return _.omit(obj, 'id')
    })

    valErr = valErr.filter((value) => Object.keys(value).length !== 0)
    if (valErr.length) {
      document.getElementById('submit-btn').removeAttribute('disabled')
      validationFlag = true
    }

    if (!validationFlag) {
      let valid = false
      document.getElementById('submit-btn').setAttribute('disabled', true)
      let finalParticipation = participation.map((p) => {
        if (p.class_id) {
          p.class_id = Number(p.class_id)
        }
        if (p.group_id) {
          p.group_id = Number(p.group_id)
        }
        if (p.variety_id) {
          p.variety_id = Number(p.variety_id)
        }
        if (p.breed_category_id) {
          p.breed_category_id = Number(p.breed_category_id)
        }
        if (p.breed_id) {
          p.breed_id = Number(p.breed_id)
        }
        if (p.has_fur === '') {
          p.has_fur = null
        }

        return p
      })
      let urlList = finalParticipation.map((san) => manualEntryValidation(san))
      const list = await Promise.allSettled(urlList)

      let validation = list.map((val) => {
        let err = {}
        // err.id = val.list_id
        if (val.status === 'rejected') {
          // debugger
          if (JSON.parse(val.reason.config.data).list_id) {
            err.id = JSON.parse(val.reason.config.data).list_id
          }
          if (
            val?.reason.response?.data &&
            Array.isArray(val?.reason.response?.data)
          ) {
            // console.log(val?.reason?.response.data)
            val?.reason.response?.data.forEach((s) => {
              if (s.param === 'group_seq') {
                err.group_seq = 'Please select group'
              }
              if (s.param === 'variety_seq') {
                err.variety_seq = 'Please select variety'
              }
              if (s.param === 'ear_no') {
                err.ear_no = 'Duplicate Ear no. found for same user '
              }
              if (s.param === 'fur_data[0].fur_id') {
                err.fur_id = 'Please select fur color'
              }
            })
          }
        }

        return err
      })

      setError(validation)
      // console.log(validation)
      let valErr = _.map(validation, function (obj) {
        return _.omit(obj, 'id')
      })
      valErr = valErr.filter((value) => Object.keys(value).length !== 0)
      if (valErr.length) {
        document.getElementById('submit-btn').removeAttribute('disabled')
        valid = true
      }
      if (!valid) {
        document.getElementById('submit-btn').removeAttribute('disabled')
        let list = participation.map((obj) => {
          if (id === obj.list_id) {
            return {
              ...obj,
              isEdit: false,
            }
          }
          return obj
        })

        setParticipation(list)
      }
    }
  }
  const onAccept = async () => {
    // for offline payment
    document.getElementById('payment-yes').setAttribute('disabled', true)
    $('#payment-no').addClass('disable-click')

    let finalParticipation = participation.map((par) => {
      if (Number(par.has_fur)) {
        let furData = []
        if (!par?.fur_data_selected.length) {
          furData = par.fur_type_id.map((s) => {
            return {
              fur_type_id: Number(s),
              fur_id: par.fur_id,
            }
          })
        } else {
          let finalFur = []

          let furTypeList = par.furTypeList
            .map((s) => {
              let findInSelected = par.fur_data_selected.find(
                (f) => f.fur_type_id === s.fur_type_id
              )

              let findInType = par.fur_type_id.find((f) => f === s.fur_type_id)

              if (findInSelected && findInType) {
                return findInSelected
              }
              if (findInSelected && !findInType) {
                return {
                  ...findInSelected,
                  is_deactivated: 1,
                }
              }

              if (!findInSelected && findInType) {
                return {
                  fur_type_id: Number(findInType),
                  fur_id: Number(par.fur_id),
                }
              }
            })
            .filter((s) => s)

          // debugger
          for (let i = 0; i < furTypeList.length; i++) {
            const fur = furTypeList[i]
            if (fur?.is_deactivated) {
              finalFur.push({
                ...fur,
              })
            } else {
              finalFur.push({
                ...fur,
                fur_id: Number(par.fur_id),
              })
            }
          }

          furData = finalFur
        }
        return {
          ...par,
          fur_data: furData,
        }
      }

      let furData = []
      furData = par.fur_data_selected
        .map((x) => {
          if (x.participant_fur_data_id)
            return {
              ...x,
              is_deactivated: 1,
            }
          return undefined
        })
        .filter((s) => s)
      return {
        ...par,
        fur_data: furData,
      }
    })
    let finalData = _.map(finalParticipation, function (obj) {
      return _.omit(obj, [
        'list_id',
        'fur_id',
        'fur_type_id',
        'fur_data_selected',
        'furTypeList',
        'previewScreen',
        'display_fur_data',
        'isEdit',
      ])
    })
    if (Number(event.is_online_payment)) {
      try {
        const res = await getCost(finalData, eventId)

        //res && Number(res?.pay)
        if (res && Number(res?.pay) === 0) {
          const success = await offlinePayment(finalData, eventId)
          toast.success(success)
          setTimeout(() => {
            history.push('/your_participation')
          }, 1500)
        } else if (res && Number(res?.pay) === -1) {
          showNotPayModal()
        } else {
          dispatch(AddYourParticipationToShoppingCart(res))
          history.push(
            `/your_participation/${urlEncode(eventId)}/${urlEncode(
              showId
            )}/participate_shopping_cart`
          )
        }
      } catch (err) {
        document.getElementById('payment-yes').removeAttribute('disabled')
        $('#payment-no').removeClass('disable-click')
        if (err.response.data) {
          toast.error(err.response.data)
        }
      }
    } else {
      try {
        const res = await offlinePayment(finalData, eventId)
        toast.success(res)
        setTimeout(() => {
          history.push('/your_participation')
        }, 1500)
      } catch (err) {
        document.getElementById('payment-yes').removeAttribute('disabled')
        $('#payment-no').removeClass('disable-click')
        if (err.response.data) {
          toast.error(err.response.data)
        }
      }
    }
  }
  if (!participation.length) {
    return <Redirect to={`/your_participation/`} />
  }

  // Additional Modal
  function OnInTouchModal() {
    setmodal_center(!modal_center)
    removeBodyCss()
  }
  function showNotPayModal() {
    setShowModal(!showModal)
    removeBodyCss()
  }

  const onCloseModal = () => {
    setShowModal(false)
    removeBodyCss()
    history.push('/your_participation')
  }

  let hasEdit = participation.find((x) => x.isEdit === true)
  // let deadline = deadLineType()
  return (
    <React.Fragment>
      <div className="page-content bg-gray-101 min-h-80 view-dis parti-dis">
        <section id="about" className="section1 pages">
          <Container fluid={true} className="auto-fit">
            <Col lg={12}>
              <Row>
                <Col lg={12} className="text-center">
                  <div className="page-title-box justify-content-between">
                    <h2 className="top-title mb-4">
                      <span>YOUR PARTICIPATION</span>
                    </h2>
                  </div>
                </Col>
              </Row>
            </Col>
            <Row>
              <Col lg={12}>
                <div className="table-responsive text-center mb-5">
                  <Table className="table mb-0 cust-tab">
                    <thead className="table-color">
                      <tr>
                        <th>SHOW INFORMATION</th>
                      </tr>
                    </thead>
                  </Table>
                </div>
              </Col>

              <ParticipationEvent />
              <Col lg={12}>
                <div className="table-responsive text-center mt-5">
                  <Table className="table mb-0 cust-tab">
                    <thead className="table-color">
                      <tr>
                        <th>PARTICIPATION INFORMATION</th>
                      </tr>
                    </thead>
                  </Table>
                </div>
              </Col>
              <Col lg={12} className="manual-row mt-5">
                {participation.length
                  ? participation.map((list, i) => {
                      return (
                        <List
                          list={list}
                          key={i}
                          showData={showData}
                          exhibitorList={exhibitorList}
                          onChange={onChange}
                          eventType={event?.event_type || ''}
                          updateHasFur={updateHasFur}
                          // updateFurData={updateFurData}
                          error={error}
                          onEdit={onEdit}
                          onSave={onSave}
                          deadline={deadline}
                          furDisable={furDisable}
                          disableGroupToClass={disableGroupToClass}
                          disabledEr={disabledEr}
                        />
                      )
                    })
                  : null}
              </Col>

              <Col lg={12} className="part-cont text-center mt-5">
                <p className="text-center">
                  <strong>You have made a change to your entry !</strong>
                </p>
                <p className="text-center">
                  There is <span className="text-red">NO REFUNDS,</span> If your
                  charge is less money than what you previously paid{' '}
                </p>
                <p className="text-center text-red">
                  YOU WILL NOT RECEIVE A REFUND!
                </p>
                <p className="text-center">
                  NOT from EASY2Show the "SHOW" or "CLUB" that you have entered!
                </p>
                <p className="text-center">
                  If your change results in you owing more money you will be
                  taken to a payment cart for you to pay the difference.
                </p>
                <h4 className="text-center mt-3 dis-btn">
                  DO YOU WANT TO ACCEPT THIS CHANGE WITH THE TERMS OUTLINED
                  ABOVED?{' '}
                  <Button
                    className="btn btn-success"
                    disabled={Boolean(hasEdit)}
                    id={'payment-yes'}
                    onClick={onAccept}
                  >
                    YES
                  </Button>{' '}
                  <span className={`${Boolean(hasEdit) ? 'dis-btn-view' : ''}`}>
                    <Link
                      to="/your_participation"
                      id={'payment-no'}
                      className={`btn btn-danger ${
                        Boolean(hasEdit) ? 'disable-click' : ''
                      }`}
                      disabled={Boolean(hasEdit)}
                      style={{ background: 'red' }}
                    >
                      NO
                    </Link>
                  </span>
                </h4>
              </Col>
            </Row>
          </Container>
        </section>
        <SuccessPopup />
        <CModal
          OnInTouchModal={OnInTouchModal}
          showModal={showModal}
          showNotPayModal={showNotPayModal}
          onCloseModal={onCloseModal}
        />
        <GetInTouchModal
          modal_center={modal_center}
          setmodal_center={setmodal_center}
          setMsg={setMsg}
          setShowAlert={setShowAlert}
          setShowBtn={setShowBtn}
          setShowSuccessPopup={setShowSuccessPopup}
          event_id={eventId}
          showNotPayModal={showNotPayModal}
          
        />
        {showSuccessPopup && (
          <SuccessPopup
            setShowAlert={setShowAlert}
            msg={msg}
            showAlert={showAlert}
            setMsg={setMsg}
            setShowBtn={setShowBtn}
            showBtn={showBtn}
          />
        )}
      </div>
    </React.Fragment>
  )
}

export default PreviewParticipateEvents
const manualEntryValidation = async (data) => {
  const res = await post(`/api/v1/participation/validate_participation`, data)
  return res.data
}
const offlinePayment = async (data, eventId) => {
  const res = await put(
    `/api/v1/your-participation/update-offline?event_id=${eventId}`,
    data
  )
  return res.data
}

const getCost = async (data, eventId) => {
  const res = await post(
    `/api/v1/your-participation/get-payment-cost-update?event_id=${eventId}`,
    data
  )
  return res
}
