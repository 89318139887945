import {
  ADD_ALL_PREVIEW,
  ADD_VALIDATION,
  GET_ALL_EVENT_FAIL,
  GET_ALL_EVENT_SUCCESS,
  SET_EVENT_LOADING,
  TOTAL_PAGE,
} from './actionTypes'

const initialState = {
  error: '',
  loading: false,
  allEvents: [],
  previewEvent: [],
  previewError: [],
  totalPages: 0,
}

const event = (state = initialState, action) => {
  switch (action.type) {
    case SET_EVENT_LOADING:
      state = { ...state, loading: true, error: '' }
      break
    case GET_ALL_EVENT_SUCCESS:
      state = { ...state, allEvents: action.payload.data, loading: false }
      break
    case GET_ALL_EVENT_FAIL:
      state = { ...state, error: action.payload, loading: false }
      break
    case TOTAL_PAGE:
      state = { ...state, totalPages: action.payload, loading: false }
      break
    case ADD_ALL_PREVIEW:
      console.log('action.payload-> ', action.payload)
      return {
        ...state,
        previewEvent: action.payload,
        loading: false,
      }
    case ADD_VALIDATION:
      return {
        ...state,
        previewError: action.payload,
        loading: false,
      }
    default:
      state = { ...state }
      break
  }
  return state
}
export default event
