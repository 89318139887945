import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Button, Table, Row, Spinner, Col } from 'reactstrap'
import {
  JudgeDelete,
  JudgeListing,
  pageChange,
  resetPage,
} from '../../../store/actions'
import { UrlEncodeHook } from '../../../hooks'
import Pagination from '../Pagination/Pagination'
import Swal from 'sweetalert2'

const JudgeList = () => {
  const { urlEncode } = UrlEncodeHook()
  const dispatch = useDispatch()

  const { judgeListing, loading } = useSelector((state) => state.EasyFiedsJudge)
  const { page, perPage } = useSelector((state) => state.pagination)
  const [reload, setReload] = useState(false)
  useEffect(() => {
    let params = {
      _limit: perPage,
      _page: 1,
      _sort: 'ss.created_on',
      _order: 'ASC',
    }
    dispatch(JudgeListing(params))
  }, [reload])
  useEffect(() => {
    //  reset Page
    return () => {
      dispatch(resetPage())
    }
  }, [])

  //
  const onDelete = async (ID) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      width: '380px',
      showCancelButton: true,
      confirmButtonColor: '#062575',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then(async (result) => {
      if (result.isConfirmed) {
        let obj = {
          ID,
          setReload,
          reload,
        }
        let finalPage = page
        if (Array.isArray(judgeListing) && judgeListing.length === 1) {
          finalPage -= 1
        }
        if (finalPage <= 0) {
          finalPage = 1
        }
        if (page === finalPage) {
          obj[`setReload`] = setReload
          obj[`reload`] = reload
        }

        dispatch(pageChange(finalPage))

        dispatch(JudgeDelete(obj))
      }
    })
  }

  const paginationClick = (nextPage) => {
    const { selected } = nextPage
    const finalPage = (Number(selected) || 0) + 1
    let params = {
      _limit: perPage,
      _page: finalPage,
      _sort: 'ss.created_on',
      _order: 'DESC',
    }
    dispatch(JudgeListing(params))
  }
  return (
    <div className='profile-content-area my-6 card card-body mb-10'>
      <div className='pb-6'>
        <Row>
          <div className='col-md-8 text-left msg-box'>
            <p className='text-red'>
              Note: All entries will be deleted in 30 days.
            </p>
          </div>
          <div className='col-md-4 text-end'>
            <Link
              to='/easyfides/profile/judge/inquiry'
              className='me-2'
              title='Inquiry'
            >
              <Button color='warning'>INQUIRY </Button>
            </Link>
            <Link
              to='/easyfides/profile/judge/create'
              className=''
              title='Create'
            >
              <Button> CREATE </Button>
            </Link>
          </div>
        </Row>
        
        <div className='col-md-12 mt-3'>
          <Table responsive hover1 className='table cust-tab table'>
            <thead className='table-color'>
              <tr>
                <th>#</th>
                <th>Service</th>
                <th>Breed</th>
                <th>Name</th>
                <th>Travel Radius ( Miles )</th>
                <th>Image </th>
                <th>Action </th>
              </tr>
            </thead>
            <tbody>
              {Array.isArray(judgeListing) && judgeListing.length ? (
                judgeListing.map((judge, index) => {
                  return (
                    <tr key={index}>
                      <td>{(page - 1) * perPage + (index + 1)}</td>
                      <td>
                        <>
                          {judge?.serve_as_judge ? 'Judge' : null}
                          {'  '}{' '}
                          {judge?.serve_as_judge && judge?.serve_as_registrar
                            ? ' & '
                            : null}
                          {judge?.serve_as_registrar ? 'Registrar' : null}
                        </>
                      </td>
                      <td>
                        {judge?.rabbit_judge || judge?.cavy_judge ? (
                          <>
                            {judge?.rabbit_judge ? 'Rabbit' : null}
                            {'  '}{' '}
                            {judge?.rabbit_judge && judge?.cavy_judge
                              ? ' & '
                              : null}
                            {judge?.cavy_judge ? 'Cavy' : null}
                          </>
                        ) : (
                          '-'
                        )}
                      </td>
                      <td>{judge?.display_name || ''}</td>
                      <td>{judge?.title || ''}</td>
                      <td>
                        {judge?.image_file ? (
                          <img
                            src={judge?.image_file}
                            alt={judge?.display_name}
                            className='img-thumbnail'
                            width='100'
                          />
                        ) : (
                          <img
                            src={'/assets/img/logo/logo.png'}
                            alt='profile'
                            className='img-thumbnail'
                            width='100'
                          />
                        )}
                      </td>
                      <td>
                        <Link
                          to={`/easyfides/profile/judge/update/${urlEncode(
                            judge?.sales_service_id
                          )}`}
                        >
                          <Button
                            size='sm'
                            color='warning'
                            className='me-2 mb-2'
                          >
                            <i className='fas fa-pen'></i>
                          </Button>
                        </Link>

                        <Button
                          size='sm'
                          color='danger'
                          onClick={() => onDelete(judge?.sales_service_id)}
                          className='mb-2'
                        >
                          <i className='fas fa-trash'></i>
                        </Button>
                      </td>
                    </tr>
                  )
                })
              ) : loading ? (
                <tr className='text-center'>
                  <td colSpan={10}>
                    <Spinner color='primary' />
                  </td>
                </tr>
              ) : (
                <tr className='text-center'>
                  <td colSpan={10}>No registrars & judges found</td>
                </tr>
              )}
            </tbody>
          </Table>
          <Row>
            <Col lg={12}>
              <Pagination paginationClick={paginationClick} />
            </Col>
          </Row>
        </div>
      </div>
    </div>
  )
}

export default JudgeList
