import { Field, Form, Formik, useFormikContext } from 'formik'
import React, { useEffect } from 'react'
import { Col, Label, Row } from 'reactstrap'
import { Button, SelectField, TextField, Switch } from '../../Atoms'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'
import * as validation from '../../../../helpers/validation'
import BreedSectionForm from '../BreedSectionForm/BreedSectionForm'
import _ from 'lodash'
import {
  addRabbit,
  getOnePedigreeDetail,
  showAndClosePedigreeModal,
  updatePedigreeUpdate,
  updateRabbit,
} from '../../../../store/actions'
import Swal from 'sweetalert2'
import { toast } from 'react-toastify'
import { useLocation } from 'react-router-dom'
import { UrlEncodeHook } from '../../../../hooks'

const RabbitForm = () => {
  const { urlEncode, urlDecode } = UrlEncodeHook()
  const dispatch = useDispatch()

  let location = useLocation()
  const query = new URLSearchParams(location.search)
  let pedigreeId = query.get('p')
  pedigreeId = urlDecode(pedigreeId)
  const { form, childRabbit, pedigreeForm, rabbitList } = useSelector(
    (state) => {
      return {
        form: state.herdManagement.Pedigree.pedigreeModal.ModalContent.form,
        childRabbit:
          state.herdManagement.Pedigree.pedigreeModal.ModalContent.childRabbit,
        pedigreeForm: state.herdManagement.Pedigree.pedigreeForm.form,
        rabbitList: state.herdManagement.Pedigree.rabbit.rabbitList,
      }
    }
  )
  const validationSchema = Yup.object({
    pedigree_id: Yup.string().when('add_from_existing_pedigree', {
      is: (value) => value,
      then: () =>
        Yup.string().required(
          `Select existing ${form.gender === 'M' ? 'Sire' : 'Dam'} rabbit`
        ),
      otherwise: () => Yup.string().notRequired(),
    }),
    ear_no: Yup.string()
      .required(`Ear No. ${validation.REQUIRED}`)
      .trim()
      .max(45, `Ear No. ${validation.MAX_CHAR_FUN(45)}`),
    animal_name:
      form.gender === 'M'
        ? Yup.string()
            .required(`Sire name ${validation.REQUIRED}`)
            .trim()
            .max(99, `Sire name ${validation.MAX_CHAR_FUN(99)}`)
        : Yup.string()
            .required(`Dam name ${validation.REQUIRED}`)
            .trim()
            .max(99, `Dam name ${validation.MAX_CHAR_FUN(99)}`),

    breed_id: Yup.string().when('add_new_breed', {
      is: (value) => !value,
      then: () => Yup.string().required(`Breed name ${validation.REQUIRED}`),
      otherwise: () => Yup.string().notRequired(),
    }),
    breed_name: Yup.string().when('add_new_breed', {
      is: (value) => value,
      then: () =>
        Yup.string()
          .required(`Breed name ${validation.REQUIRED}`)
          .trim()
          .max(99, `Breed name ${validation.MAX_CHAR_FUN(99)}`),
      otherwise: () => Yup.string().notRequired(),
    }),

    variety_id: Yup.string().when(
      ['variety_seq', 'add_new_variety'],
      ([variety_seq, add_new_variety]) => {
        if (variety_seq && !add_new_variety) {
          return Yup.string().notRequired()
          // Yup.string().required(`Variety name ${validation.REQUIRED}`)
        }

        return Yup.string().notRequired()
      }
    ),
    variety_name: Yup.string().when(
      ['variety_seq', 'add_new_variety'],
      ([variety_seq, add_new_variety]) => {
        if (variety_seq && add_new_variety) {
          return Yup.string()
            .required(`Variety name ${validation.REQUIRED}`)
            .max(99, `Variety name ${validation.MAX_CHAR_FUN(99)}`)
        }

        return Yup.string().notRequired()
      }
    ),
    group_id: Yup.string().when(
      ['group_seq', 'add_new_group'],
      ([group_seq, add_new_group]) => {
        if (group_seq && !add_new_group) {
          return Yup.string().notRequired().trim()
          // Yup.string().required(`Group name ${validation.REQUIRED}`)
        }

        return Yup.string().notRequired()
      }
    ),
    group_name: Yup.string().when(
      ['group_seq', 'add_new_group'],
      ([variety_seq, add_new_group]) => {
        if (variety_seq && add_new_group) {
          return (
            Yup.string()
              .notRequired()
              // .required(`Group name ${validation.REQUIRED}`)
              .trim()
              .max(99, `Variety name ${validation.MAX_CHAR_FUN(99)}`)
          )
        }
        return Yup.string().notRequired()
      }
    ),
    gender: Yup.string().required(`Gender ${validation.REQUIRED}`),
    weight: Yup.number()
      .positive(validation.POSITIVE_NUMBER_ALLOWED)
      .typeError(validation.NUMBER_INVALID)
      .nullable()
      // .required(`Weight ${validation.REQUIRED}`)
      .min(0.1, `Weight ${validation.MIN_NUMBER(0.1)}`)
      .max(999, `Weight ${validation.MAX_NUMBER(999)}`)
      .transform((value) => {
        return value ? value : null
      }),
    registration_number: Yup.string()
      .nullable()
      .notRequired()
      .trim()
      .max(45, `Reg. ${validation.MAX_CHAR_FUN(45)}`),
    grand_championship: Yup.string()
      .nullable()
      .notRequired()
      .trim()
      .max(45, `Grand Champion ${validation.MAX_CHAR_FUN(45)}`),
    winnings: Yup.string()
      .notRequired()
      .nullable()
      .max(45, `Winnings ${validation.MAX_CHAR_FUN(45)}`),
    add_from_existing_pedigree: Yup.boolean(),
    color_name: Yup.string().notRequired(),
  })

  const addRabbitApi = (body) => {
    return new Promise((resolve, reject) => {
      dispatch(
        addRabbit(body, (err, data) => {
          if (err) {
            reject(err)
          } else {
            resolve(data)
          }
        })
      )
    })
  }

  const updateRabbitApi = (body) => {
    return new Promise((resolve, reject) => {
      dispatch(
        updateRabbit(body, (err, data) => {
          if (err) {
            reject(err)
          } else {
            resolve(data)
          }
        })
      )
    })
  }

  const updatePedigreeApi = (body) => {
    return new Promise((resolve, reject) => {
      dispatch(
        updatePedigreeUpdate(body, (err, data) => {
          if (err) {
            reject(err)
          } else {
            resolve(data)
          }
        })
      )
    })
  }
  const getPedigreeApi = (body) => {
    return new Promise((resolve, reject) => {
      dispatch(
        getOnePedigreeDetail(body, (err, data) => {
          if (err) {
            reject(err)
          } else {
            resolve(data)
          }
        })
      )
    })
  }
  const onSubmit = async (values, formProps) => {
    const castValue = validationSchema.cast(values)
    formProps.setStatus(true)
    try {
      const formData = new FormData()
      formData.append('ear_no', castValue?.ear_no || '')
      formData.append('animal_name', castValue?.animal_name || '')
      formData.append('breed_name', castValue?.breed_name || '')
      formData.append('group_name', castValue?.group_name || '')
      formData.append('variety_name', castValue?.variety_name || '')
      formData.append('gender', castValue?.gender || '')
      formData.append('weight', castValue?.weight || 0)
      formData.append(
        'registration_number',
        castValue?.registration_number || ''
      )
      formData.append('grand_championship', castValue?.grand_championship || '')
      formData.append('winnings', castValue?.winnings || '')
      formData.append('color_name', castValue?.color_name || '')
      if (!values.rabbit_id) {
        formData.append('is_pedigree', 0)
        formData.append('date_of_birth', 0)
      }

      if (castValue?.img1) {
        formData.append('image', castValue.img1)
      }

      let res
      if (!values.rabbit_id) {
        res = await addRabbitApi(formData)
      } else {
        const data = {
          params: {
            rabbit_id: values.rabbit_id,
          },
          data: formData,
        }
        res = await updateRabbitApi(data)
      }
      //

      if (res) {
        dispatch(
          showAndClosePedigreeModal({
            showModal: false,
            modalType: '',
            ModalContent: '',
          })
        )
        await Swal.fire({
          title:
            'Saving pedigree information. Please do not refresh or close the page or browser.',
          allowOutsideClick: false,
          allowEscapeKey: false,
          stopKeydownPropagation: false,
          didOpen: async () => {
            Swal.showLoading()
            // formProps.setStatus(false)

            const rabbitObj = {
              ..._.pick(childRabbit, ['rabbit_id', 'anchestory_id']),
              is_update: 1,
            }
            if (castValue?.gender === 'M') {
              if (!res.rabbit_id) {
                rabbitObj['father_id'] = values.rabbit_id
                rabbitObj['mother_id'] = childRabbit?.mother_id || null
              } else {
                rabbitObj['father_id'] = res.rabbit_id
                rabbitObj['mother_id'] = childRabbit?.mother_id || null
              }
            }

            if (castValue?.gender === 'F') {
              if (!res.rabbit_id) {
                rabbitObj['mother_id'] = values.rabbit_id
                rabbitObj['father_id'] = childRabbit?.father_id || null
              } else {
                rabbitObj['mother_id'] = res.rabbit_id
                rabbitObj['father_id'] = childRabbit?.father_id || null
              }
            }
            const objForm = _.pick(pedigreeForm, [
              'pedigree_name',
              'rabbit_id',
              'archieve',
              'is_agree',
            ])
            const updateObj = {
              params: { id: pedigreeForm.pedigree_id },
              data: {
                ...objForm,
                archieve: objForm?.archieve ? objForm?.archieve : 'pedigree',
                is_agree: objForm?.is_agree ? objForm?.is_agree : 0,
                rabbit_data: [rabbitObj],
              },
            }

            const updatePedigreeRes = await updatePedigreeApi(updateObj)
            if (updatePedigreeRes) {
              const data = {
                params: {
                  id: pedigreeForm.pedigree_id,
                },
              }
              const getPedigreeRes = await getPedigreeApi(data)
              if (getPedigreeRes) {
                setTimeout(() => {
                  if (values.rabbit_id) {
                    toast.success('Record has been updated successfully')
                  } else {
                    toast.success('Record has been added successfully')
                  }
                  Swal.close()
                }, 1000)
              }
            }
          },
        })
      }
    } catch (err) {
      formProps.setStatus(false)
      if (Array.isArray(err)) {
        toast.error(_.get(_.first(err), 'msg'))
      } else if (err.response.data) {
        toast.error(err.response.data)
      } else if (err) {
        toast.error(err)
      }
    }
  }

  return (
    <Formik
      initialValues={form}
      onSubmit={onSubmit}
      enableReinitialize
      validationSchema={validationSchema}
    >
      {({
        values,
        status,
        errors,
        setFieldValue,
        setValues,
        setErrors,
        resetForm,
      }) => {
        return (
          <Form className='needs-validation club-selection'>
            <Row className='mt-3'>
              <Col sm='12' md='12' className='text-center'>
                {values.is_deactivated === 1 ? (
                  <h4 className='text-danger'> Rabbit Deleted</h4>
                ) : null}
              </Col>
              <Col sm='12' md='12'>
                <div className='mb-3'>
                  <Label className='form-label' htmlFor='pedigree_id'>
                    Select existing {values.gender === 'M' ? 'Sire' : 'Dam'}{' '}
                    rabbit
                    <span className='ms-2'>
                      <Field
                        name='add_from_existing_pedigree'
                        hasOnChange
                        onChange={() => {
                          resetForm()
                          const RabbitForm = {
                            rabbit_id: form.rabbit_id ? form.rabbit_id : '',
                            pedigree_id: '',
                            ear_no: '',
                            animal_name: '',
                            add_new_breed: false,
                            breed_id: '',
                            breed_name: '',
                            add_new_group: false,
                            group_id: '',
                            group_name: '',
                            add_new_variety: false,
                            variety_id: '',
                            variety_name: '',
                            weight: '',
                            registration_number: '',
                            date_of_birth: '',
                            grand_championship: '',
                            winnings: '',
                            father_id: '',
                            mother_id: '',
                            color_name: '',
                          }
                          const obj = {
                            ...values,
                            ...RabbitForm,
                          }
                          // setErrors(obj)
                          setValues(obj)

                          setFieldValue(
                            'add_from_existing_pedigree',
                            !values.add_from_existing_pedigree
                          )
                        }}
                        component={Switch}
                      />
                    </span>
                  </Label>
                  {values['add_from_existing_pedigree'] ? (
                    <Field
                      name='pedigree_id'
                      placeholder='Select Pedigree *'
                      type='select'
                      id='pedigree_id'
                      onlyShowOption
                      option={_.filter(
                        rabbitList,
                        (f) =>
                          f.gender === values.gender &&
                          Number(f?.rabbit_id) !==
                            Number(pedigreeForm?.rabbit_id)
                      )}
                      isSelect
                      hasOnChange
                      onChange={(v) => {
                        setFieldValue('pedigree_id', v?.value || '')
                      }}
                      component={SelectField}
                    />
                  ) : null}
                </div>
              </Col>
              <SetExistingPedigreeData />
              <Col sm='12' md='12'>
                <div className='mb-3'>
                  <Label className='form-label' htmlFor='ear_no'>
                    Ear No *
                  </Label>
                  <Field
                    name='ear_no'
                    placeholder='Ear No'
                    type='text'
                    id='ear_no'
                    component={TextField}
                  />
                </div>
              </Col>
              <Col sm='12' md='12'>
                <div className='mb-3'>
                  <Label className='form-label' htmlFor='animal_name'>
                    {values.gender === 'M' ? 'Sire' : 'Dam'} Name *
                  </Label>
                  <Field
                    name='animal_name'
                    placeholder={` ${
                      values.gender === 'M' ? 'Sire' : 'Dam'
                    } Name`}
                    type='text'
                    id='animal_name'
                    component={TextField}
                  />
                </div>
              </Col>
              <BreedSectionForm values={values} md={12} />
              <Col sm='12' md='12'>
                <div className='mb-3'>
                  <Label className='form-label' htmlFor='color_name'>
                    Color
                  </Label>
                  <div>
                    <Field
                      name='color_name'
                      type='text'
                      id='color_name'
                      placeholder='Color'
                      className='form-control'
                      component={TextField}
                    />
                  </div>
                </div>
              </Col>
              <Col sm='12' md='12'>
                <div className='mb-3'>
                  <Label className='form-label' htmlFor='weight'>
                    Weight (Lbs.)
                  </Label>
                  <div>
                    <Field
                      name='weight'
                      type='number'
                      id='weight'
                      placeholder='Weight'
                      className='form-control'
                      component={TextField}
                    />
                  </div>
                </div>
              </Col>
              <Col sm='12' md='12'>
                <div className='mb-3'>
                  <Label className='form-label' htmlFor='registration_number'>
                    Registration #
                  </Label>
                  <div>
                    <Field
                      name='registration_number'
                      type='text'
                      id='registration_number'
                      placeholder='Registration #'
                      className='form-control'
                      component={TextField}
                    />
                  </div>
                </div>
              </Col>
              <Col sm='12' md='12'>
                <div className='mb-3'>
                  <Label className='form-label' htmlFor='grand_championship'>
                    Grand Champion #
                  </Label>
                  <div>
                    <Field
                      name='grand_championship'
                      type='text'
                      placeholder='Grand Champion #'
                      id='grand_championship'
                      className='form-control'
                      component={TextField}
                    />
                  </div>
                </div>
              </Col>
              <Col sm='12' md='12'>
                <div className='mb-3'>
                  <Label className='form-label' htmlFor='winnings'>
                    Winnings
                  </Label>
                  <div>
                    <Field
                      name='winnings'
                      type='textarea'
                      id='winnings'
                      placeholder='Winnings'
                      className='form-control'
                      component={TextField}
                    />
                  </div>
                </div>
              </Col>

              <Col md='12' className='text-center mb-3'>
                <Button type='submit' disabled={status} color='primary'>
                  SUBMIT
                </Button>
              </Col>
            </Row>
          </Form>
        )
      }}
    </Formik>
  )
}

export default RabbitForm

const SetExistingPedigreeData = () => {
  const { rabbitList } = useSelector((state) => {
    return { rabbitList: state.herdManagement.Pedigree.rabbit.rabbitList }
  })

  const { values, setValues } = useFormikContext()
  useEffect(() => {
    if (values.add_from_existing_pedigree && values.pedigree_id) {
      const rabbit = _.find(
        rabbitList,
        (f) => Number(f.value) === Number(values.pedigree_id)
      )
      const obj = {
        ...values,
        ...rabbit,
      }
      setValues(obj)
    }
  }, [rabbitList, values.pedigree_id, values.add_from_existing_pedigree])

  return null
}
