import { GET_EASY_FIDES_PROFILE_SUCCESS } from './actionType'

//
const INIT_STATE = {
  easyFiedsUser: {},
  loading: true,
  error: {},
}

const EasyfidesProfileReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_EASY_FIDES_PROFILE_SUCCESS:
      return {
        ...state,
        easyFiedsUser: action.payload,
        loading: false,
      }
    default:
      return state
  }
}

export default EasyfidesProfileReducer
