import { combineReducers } from 'redux'
import herdConfigurationReducer from './Configuration/reducer'
import pedigreeHeaderReducer from './pedigreeHeader/reducer'
import pedigreePaginationReducer from './Pagination/reducer'
import PedigreeReducer from './Pedigree/reducer'
import AncestralArchiveReducer from './AncestralArchive/reducer'

const HerdManagementReducer = combineReducers({
  herdConfiguration: herdConfigurationReducer,
  pedigreeHeader: pedigreeHeaderReducer,
  pagination: pedigreePaginationReducer,
  Pedigree:PedigreeReducer,
  AncestralArchive:AncestralArchiveReducer

})
export default HerdManagementReducer
