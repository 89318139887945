import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Col, Spinner, Table } from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'
import { getAllYourParticipationAdditionProduct } from '../../../store/actions'
import _ from 'lodash'
import moment from 'moment'
import { getDateWithFormat } from '../../../helpers/date_helper'
import ReactPaginate from 'react-paginate'
import { differenceInDays } from 'date-fns'
import classNames from 'classnames'
const LIMIT = process.env.REACT_APP_LIMIT || 10
const AdditionalProduct = (props) => {
  const { event, isSearch, interval } = props
  const YourParticipation = useSelector((state) => state.YourParticipation)
  const { allAdditionProduct, loading, page, totalPages, totalRecords } =
    YourParticipation
  const dispatch = useDispatch()
  useEffect(() => {
    let pagination = {
      page: 1,
      limit: LIMIT,
    }
    let user = JSON.parse(localStorage.getItem('authUser'))
    let data = {
      user_id: Number(user.id),
      event_id: Number(event),
      interval: Number(interval),
    }

    dispatch(getAllYourParticipationAdditionProduct({ pagination, body: data }))
  }, [isSearch, interval])
  const paginationClick = (p) => {
    let nextPage = p.selected + 1
    let pagination = {
      page: nextPage,
      limit: LIMIT,
    }
    let user = JSON.parse(localStorage.getItem('authUser'))
    let data = {
      user_id: Number(user.id),
      event_id: Number(event),
      interval: Number(interval),
    }

    dispatch(getAllYourParticipationAdditionProduct({ pagination, body: data }))
  }
  return (
    <div>
      {' '}
      <Col lg={12}>
        <div className="table-responsive text-center mt-3">
          <Table className="table mb-0 cust-tab">
            <thead className="table-color">
              <tr>
                <th>Event Date</th>
                <th>Event Name</th>
                <th>Product Name</th>
                <th>Price</th>
                <th>Qty</th>
                <th>Total</th>
                <th>Purchased on</th>
              </tr>
            </thead>
            <tbody>
              {!loading && allAdditionProduct.length ? (
                allAdditionProduct.map((additional, index) => {
                  let diffDay = differenceInDays(
                    new Date(additional?.end_date_time),
                    new Date(additional?.start_date_time)
                  )
                  let total = additional.unit_price * additional.units
                  return (
                    <tr key={index}>
                      <td>
                        {diffDay <= 1 ? (
                          moment(
                            getDateWithFormat(additional?.start_date_time)
                          ).format('MMM Do YYYY')
                        ) : (
                          <>
                            {moment(
                              getDateWithFormat(additional?.start_date_time)
                            ).format('MMM Do YYYY')}
                            -
                            <br />
                            {moment(
                              getDateWithFormat(additional?.end_date_time)
                            ).format('MMM Do YYYY')}
                          </>
                        )}
                      </td>
                      <td>
                        <span
                          className={classNames({
                            'text-danger': additional.event_is_deactivated,
                          })}
                        >
                          {additional.event_name}
                          {additional.event_is_deactivated === 1
                            ? '-(event Deleted)'
                            : null}
                        </span>
                      </td>
                      <td>
                        <span
                          className={classNames({
                            'text-danger': additional.product_is_deactivated,
                          })}
                        >
                          {additional.product_name}
                          {additional.product_is_deactivated === 1
                            ? 'Deleted Product'
                            : null}
                        </span>{' '}
                      </td>
                      <td>${additional.unit_price.toFixed(2)}</td>
                      <td>{additional.units}</td>
                      <td>${total.toFixed(2)}</td>
                      <td>
                        {moment(
                          getDateWithFormat(additional.created_on)
                        ).format('MMM Do YYYY (LT)')}
                        {/* {' '}
                        January 20th 2022 <br />
                        (06:14 AM){' '} */}
                      </td>
                    </tr>
                  )
                })
              ) : loading ? (
                <tr>
                  <td colSpan={10} className="text-center">
                    Loading...
                  </td>
                </tr>
              ) : (
                <tr>
                  <td colSpan={10}>No additional product found</td>
                </tr>
              )}

              {/* <tr>
                <td>November 1st 2021</td>
                <td>Do Not Apply Show</td>
                <td>Book</td>
                <td>$12.00</td>
                <td>1</td>
                <td>$12.00</td>
                <td>
                  {' '}
                  October 5th 2021 <br />
                  (08:25 AM){' '}
                </td>
              </tr>

              <tr>
                <td>October 24th 2021</td>
                <td>Test Hybrid-show</td>
                <td>Book</td>
                <td>$12.00</td>
                <td>1</td>
                <td>$12.00</td>
                <td>
                  {' '}
                  November 15th 2021 <br />
                  (04:52 PM){' '}
                </td>
              </tr>

              <tr>
                <td>September 30th 2021</td>
                <td>Do Not Apply Show</td>
                <td>Parking</td>
                <td>$0.50</td>
                <td>2</td>
                <td>$1.00</td>
                <td>
                  {' '}
                  October 12th 2021 <br />
                  (12:34 PM){' '}
                </td>
              </tr> */}
            </tbody>
          </Table>
        </div>
      </Col>
      {totalRecords > LIMIT && (
        <Col lg={12}>
          <div data-test="datatable-pagination sponsor-page">
            <div className="dataTables_paginate">
              <ReactPaginate
                breakLabel="..."
                breakClassName="page-item"
                breakLinkClassName="page-link"
                nextLabel="Next"
                pageCount={totalPages}
                pageRangeDisplayed={4}
                marginPagesDisplayed={1}
                previousLabel="Previous"
                renderOnZeroPageCount={null}
                containerClassName="pagination mt-6 mb-2 justify-content-center"
                pageLinkClassName="page-link page-link"
                pageClassName="page-item"
                activeClassName="active"
                nextLinkClassName="page-link"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                nextClassName="page-item"
                onPageChange={paginationClick}
                forcePage={page - 1}
              />
            </div>
          </div>
        </Col>
      )}
      {/* <Col md={12} className="mt-5">
        <ul className="pagination justify-content-center">
          <li className="page-item active">
            <Link className="page-link" to="/">
              1 <span className="sr-only">(current)</span>
            </Link>
          </li>
          <li className="page-item">
            <Link className="page-link" to="/">
              2{' '}
            </Link>
          </li>
          <li className="page-item">
            <Link className="page-link" to="/">
              3
            </Link>
          </li>
          <li className="page-item">
            <Link className="page-link" to="/">
              Next <i className="fa fa-chevron-right"></i>
            </Link>
          </li>
        </ul>
      </Col> */}
    </div>
  )
}

AdditionalProduct.propTypes = {}

export default AdditionalProduct
