// MetisMenu
import MetisMenu from 'metismenujs'
import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useRef } from 'react'
//i18n
import { withTranslation } from 'react-i18next'
import { Link, withRouter } from 'react-router-dom'
// //Import Scrollbar
import SimpleBar from 'simplebar-react'

import { useSelector } from 'react-redux'
import _ from 'lodash'

const SidebarContent = (props) => {
  const ref = useRef()

  const activateParentDropdown = useCallback((item) => {
    item.classList.add('active')
    const parent = item.parentElement
    const parent2El = parent.childNodes[1]
    if (parent2El && parent2El.id !== 'side-menu') {
      parent2El.classList.add('mm-show')
    }

    if (parent) {
      parent.classList.add('mm-active')
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add('mm-show') // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.add('mm-active') // li
          parent3.childNodes[0].classList.add('mm-active') //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add('mm-show') // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add('mm-show') // li
              parent5.childNodes[0].classList.add('mm-active') // a tag
            }
          }
        }
      }
      scrollElement(item)
      return false
    }
    scrollElement(item)
    return false
  }, [])

  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    // const pathName = props.location.pathname
    let pathName = `/${_.join(
      _.filter(_.split(props.location.pathname, '/', 3), (o) => o),
      '/'
    )}`

    new MetisMenu('#side-menu')
    let matchingMenuItem = null
    const ul = document.getElementById('side-menu')
    const items = ul.getElementsByTagName('a')
    for (let i = 0; i < items.length; ++i) {
      if (pathName === items[i].pathname) {
        matchingMenuItem = items[i]
        break
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem)
    }
  }, [props.location.pathname, activateParentDropdown])

  useEffect(() => {
    ref.current.recalculate()
  })

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300
      }
    }
  }

  return (
    <React.Fragment>
      <SimpleBar
        style={{ maxHeight: '100%' }}
        ref={ref}
        className='sidebar-menu-scroll pedigree-sidebar'
      >
        <div id='sidebar-menu'>
          <ul className='metismenu list-unstyled' id='side-menu'>
            <li>
              <Link to='/herd-management' className='waves-effect'>
                <i className='uil-home-alt'></i>
                <span>{props.t('Dashboard')}</span>
              </Link>
            </li>
            <li>
              <a href='https://www.easy2show.com/assets/pdf/E2S_Herd_Management_Basic_Pedigree_Software_How_2.pdf' className='waves-effect' target='_blank'>
                <i className='uil-clipboard-alt'></i>
                <span>{props.t('How 2')}</span>
              </a>
            </li>
            <li>
              <Link
                to='/herd-management/configuration'
                className='waves-effect'
              >
                <i className='uil-cog'></i>
                <span>{props.t('Configuration')}</span>
              </Link>
            </li>
            <li>
              <Link
                to='/herd-management/pedigree-headers'
                className='waves-effect'
              >
                <i className='uil-grid'></i>
                <span> {props.t('Pedigree Headers')}</span>
              </Link>
            </li>
            <li>
              <Link to='/herd-management/pedigree' className='waves-effect'>
                <i className='uil-sitemap'></i>
                <span> {props.t('Pedigree')}</span>
              </Link>
            </li>
            <li>
              <Link
                to='/herd-management/ancestral-archive'
                className='waves-effect'
              >
                <i className='uil-archive'></i>
                <span> {props.t('Ancestral Archive')}</span>
              </Link>
            </li>
            <li>
              <Link to='/herd-management/sold-other' className='waves-effect'>
                <i className='uil-receipt-alt'></i>
                <span> {props.t('Sold / Other')}</span>
              </Link>
            </li>
            {/* <li>
              <Link to='/herd-management/my-breed' className='waves-effect'>
                <i className='uil-books'></i>
                <span> {props.t('My Breeds')}</span>
              </Link>
            </li> */}
            {/* <li>
              <Link to='/#' className='has-arrow waves-effect'>
                <i className='uil-chart'></i>
                <span>{props.t('Herd Header')}</span>
              </Link>
              <ul className='sub-menu'>
                <li>
                  <Link to='/herd-management/configuration'>
                    {props.t('Configuration')}
                  </Link>
                </li>
                <li>
                  <Link to='/herd-management/herd-headers'>
                    {props.t('Herd Headers')}
                  </Link>
                </li>
              </ul>
            </li> */}
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  )
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(SidebarContent))