import {
  GET_EASY_FIDES_PROFILE,
  GET_EASY_FIDES_PROFILE_SUCCESS,
} from './actionType'

//
export const easyFidesProfile = (data) => ({
  type: GET_EASY_FIDES_PROFILE,
  payload: data,
})

export const easyFidesProfileSuccess = (data) => ({
  type: GET_EASY_FIDES_PROFILE_SUCCESS,
  payload: data,
})
