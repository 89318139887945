import React from 'react'
import { Link, useLocation, useParams } from 'react-router-dom'
import _ from 'lodash'

import { Breadcrumb, BreadcrumbItem, Col, Row } from 'reactstrap'
import { HerdManagementRoutes } from '../routes/allRoutes'

const AppBreadcrumb = (props) => {
  const param = useParams()
  const newParam = _.invert(param)

  //
  // splitting path and replace params values
  const path = useLocation()
    ?.pathname?.split('/')
    ?.map((p) => {
      if (newParam[p]) {
        return `:${newParam[p]}`
      }
      return p
    })

  // route name
  const getRouteName = (pathname, routes) => {
    const currentRoute = routes.find((route) => route.path === pathname)
    return currentRoute ? currentRoute.name : false
  }

  // BreedCrumb
  const getBreadcrumbs = (location) => {
    const breadcrumbs = []
    location.reduce((prev, curr, index, array) => {
      const currentPathname = `${prev}/${curr}`
      const routeName = getRouteName(currentPathname, HerdManagementRoutes)
      const redirectPath = currentPathname
        .split('/')
        .map((q) => (param[q.replace(':', '')] ? param[q.replace(':', '')] : q))
        .join('/')
      if (routeName) {
        breadcrumbs.push({
          id: index,
          pathname: currentPathname,
          redirectPath,
          name: routeName,
          active: index + 1 === array.length,
        })
      }

      return currentPathname
    })
    return breadcrumbs
  }

  const breadcrumbs = getBreadcrumbs(path)
  return (
    <Row>
      <Col
        md='12'
        sm='12'
        lg='12'
        className={`d-flex justify-content-between ${props.className}`}
      >
        <h4 className='col-md-7  p-display-none'>{props.title}</h4>
        <div className='page-title-right  p-display-none'>
          <Breadcrumb className='p-display-none'>
            {breadcrumbs.map((b) => (
              <BreadcrumbItem
                active={b?.active}
                key={b?.id}
                className='p-display-none'
              >
                {b?.active ? (
                  <span className='cursor-default p-display-none'>
                    {b?.name}
                  </span>
                ) : (
                  <Link to={b.redirectPath} className='p-display-none'>
                    {b?.name}
                  </Link>
                )}
              </BreadcrumbItem>
            ))}
          </Breadcrumb>
        </div>
      </Col>
    </Row>
  )
}

export default React.memo(AppBreadcrumb)
