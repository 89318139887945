import React from 'react'
import Footer from '../Footer'
import Header from '../Header'
import { useSelector } from 'react-redux'

const PremierUserLayout = (props) => {
  const userData = useSelector((state) => state.Login)

  if (userData.premierUserLoading) {
    return <div />
  }
  return (
    <React.Fragment>
      <div id='layout-wrapper'>
        <header id='page-topbar'>
          <Header />
        </header>
        <div className='main-content'>{props.children}</div>
        <Footer />
      </div>
    </React.Fragment>
  )
}

export default PremierUserLayout