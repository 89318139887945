import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Button, Table, Row, Spinner, Col } from 'reactstrap'
import {
  AnimalDelete,
  listingAnimal,
  pageChange,
  resetPage,
} from '../../../store/actions'
import { useDispatch, useSelector } from 'react-redux'
import { UrlEncodeHook } from '../../../hooks'
import Pagination from '../Pagination/Pagination'
import Swal from 'sweetalert2'
import _ from 'lodash'
import { get } from '../../../helpers/api_helper'

const AnimalList = () => {
  const { urlEncode } = UrlEncodeHook()
  const dispatch = useDispatch()
  const { page, perPage } = useSelector((state) => state.pagination)
  const [reload, setReload] = useState(false)
  const { MyAnimalList, loading } = useSelector(
    (state) => state.EasyFiedsAnimal
  )
  //
  useEffect(() => {
    let params = {
      _limit: perPage,
      _page: 1,
      _sort: 'created_on',
      _order: 'DESC',
    }
    dispatch(listingAnimal(params))
  }, [reload])

  //
  const onDelete = async (ID) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      width: '380px',
      showCancelButton: true,
      confirmButtonColor: '#062575',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then(async (result) => {
      if (result.isConfirmed) {
        let obj = {
          ID,
          setReload,
          reload,
        }
        let finalPage = page
        if (Array.isArray(MyAnimalList) && MyAnimalList.length === 1) {
          finalPage -= 1
        }
        if (finalPage <= 0) {
          finalPage = 1
        }
        if (page === finalPage) {
          obj[`setReload`] = setReload
          obj[`reload`] = reload
        }

        dispatch(pageChange(finalPage))

        dispatch(AnimalDelete(obj))
      }
    })
  }

  const paginationClick = (nextPage) => {
    const { selected } = nextPage
    const finalPage = (Number(selected) || 0) + 1
    let params = {
      _limit: perPage,
      _page: finalPage,
      _sort: 'created_on',
      _order: 'DESC',
    }
    dispatch(listingAnimal(params))
  }
  return (
    <div className='profile-content-area my-6 card card-body mb-10'>
      <div className='pb-6'>
        <Row>
          <div className='col-md-8 text-left msg-box'>
            <p className='text-red'>
              Note: All entries will be deleted in 30 days.
            </p>
          </div>
          <div className='col-md-4 text-end'>
            <Link
              to='/easyfides/profile/my_animal/inquiry'
              className='me-2'
              title='Inquiry'
            >
              <Button color='warning' title='Inquiry'>
                {' '}
                INQUIRY{' '}
              </Button>
            </Link>
            <Link
              to='/easyfides/profile/my_animal/create'
              className=''
              title='Create'
            >
              <Button> CREATE </Button>
            </Link>
          </div>
        </Row>
        {/* <div className='col-md-12 mt-3 text-center'>
          <h5 className='text-danger'>
            {' '}
            Note:If the breed has been unpublished, and there are entries
            associated with it, 'Breed Deleted' will be displayed in the breed
            field.
          </h5>
        </div>
        <div className='col-md-12 mt-3 text-center'>
          <h5 className='text-danger'>
            {' '}
            Note:If the class has been deleted, and there are entries associated
            with it, 'class Deleted' will be displayed under the class field.
          </h5>
        </div> */}
        <div className='col-md-12 mt-3'>
          <Table responsive hover1 className='table cust-tab table'>
            <thead className='table-color'>
              <tr>
                <th>#</th>
                <th>Category</th>
                <th>Breed</th>
                <th>Amount</th>
                <th className='text-center'>Action </th>
              </tr>
            </thead>
            <tbody>
              {!loading &&
              Array.isArray(MyAnimalList) &&
              MyAnimalList.length ? (
                MyAnimalList.map((animal, index) => {
                  return (
                    <tr key={index}>
                      <td>{(page - 1) * perPage + (index + 1)}</td>
                      <td>
                        {_.get(
                          _.find(
                            Category,
                            (q) => q.breed_category_id === animal.category_id
                          ),
                          'category_name'
                        ) || '-'}
                      </td>
                      <td>{<BreedName ID={animal?.breed_id} />}</td>
                      <td>${animal?.amount || ''}</td>
                      <td className='text-center'>
                        <Link
                          to={`/easyfides/profile/my_animal/animal_image/${urlEncode(
                            animal?.animal_listing_id
                          )}`}
                          className='me-2'
                          title='Add Image'
                        >
                          <Button className='img-btn-add mb-2'>
                            {' '}
                            ADD IMAGE{' '}
                          </Button>
                        </Link>

                        <Link
                          to={`/easyfides/profile/my_animal/update/${urlEncode(
                            animal?.animal_listing_id
                          )}`}
                        >
                          <Button
                            size='sm'
                            color='warning'
                            className='me-2 mb-2'
                            title='Update'
                          >
                            <i className='fas fa-pen'></i>
                          </Button>
                        </Link>

                        <Button
                          className='mb-2'
                          size='sm'
                          color='danger'
                          title='Delete'
                          onClick={() => onDelete(animal?.animal_listing_id)}
                        >
                          <i className='fas fa-trash'></i>
                        </Button>
                      </td>
                    </tr>
                  )
                })
              ) : loading ? (
                <tr className='text-center'>
                  <td colSpan={10}>
                    <Spinner color='primary' />
                  </td>
                </tr>
              ) : (
                <tr className='text-center'>
                  <td colSpan={10}>No animal listing found</td>
                </tr>
              )}
            </tbody>
          </Table>
          <Row>
            <Col lg={12} className='easy-page'>
              <Pagination paginationClick={paginationClick} />
            </Col>
          </Row>
        </div>
      </div>
    </div>
  )
}

export default AnimalList

let Category = [
  {
    breed_category_id: 1,
    category_name: 'Rabbit Breed List',
  },
  {
    breed_category_id: 2,
    category_name: 'Cavy Breed List',
  },
  {
    breed_category_id: 3,
    category_name: 'Exhibition Breed List',
  },
  {
    breed_category_id: 4,
    category_name: 'Commercial Breed List',
  },
]

const BreedName = ({ ID }) => {
  const [breedData, setbreedData] = useState({})
  useEffect(() => {
    get(`/api/v1/breed-setup/class/one/${ID}`).then((res) => {
      setbreedData(_.first(res.data))
    })
  }, [ID])

  return breedData?.is_published ? (
    breedData?.breed_name || <span className='text-danger'>Breed Deleted</span>
  ) : (
    <span className='text-danger'>Breed Deleted</span>
  )
  // return null
}
