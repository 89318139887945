import classNames from 'classnames'
import React, { useMemo, useState } from 'react'
import { Col, Collapse, Row } from 'reactstrap'
import Variety from '../Variety/Variety'
import { useDispatch, useSelector } from 'react-redux'
import {
  LiveEventBreedTabClose,
  LiveEventBreedTabOpen,
} from '../../../../../store/actions'
import Class from '../Class/Class'
import _ from 'lodash'

const Group = ({ breed, recordType }) => {
  const dispatch = useDispatch()
  const { activeTabs } = useSelector((state) => ({
    activeTabs: state?.LiveEvent?.activeTabs || [],
    breedDetail: state?.LiveEvent?.breedData?.breedDetail || {},
  }))

  const activeCol = useMemo(() => {
    let find = false
    if (breed?.group_id) {
      find = activeTabs.find(
        (x) =>
          x.type === 'group' &&
          x?.class_id === breed?.judging_data?.class_id &&
          x?.group_id === breed?.judging_data?.group_id &&
          x?.breed_id === breed?.judging_data?.breed_id &&
          x?.variety_id === breed?.judging_data?.variety_id
      )
    }
    return Boolean(find)
  }, [breed?.group_id, activeTabs])

  return (
    <div className='accordion-item mb-3 front-acc '>
      <h2
        className='accordion-header acc-tab front-acc-tab fs-btn'
        id='flush-headingFour'
      >
        <button
          className={classNames(
            'accordion-button bg-primary1 text-white fs-6',
            {
              openArrow: activeCol,
              closeArrow: !activeCol,
            }
          )}
          type='button'
          onClick={() => {
            !activeCol
              ? dispatch(
                  LiveEventBreedTabOpen({
                    ...defaultObj,
                    type: 'group',
                    // group_id: breed?.group_id,
                    ...breed.judging_data,
                  })
                )
              : dispatch(
                  LiveEventBreedTabClose({
                    ...defaultObj,
                    type: 'group',
                    // group_id: breed?.group_id,
                    ...breed.judging_data,
                  })
                )
          }}
          style={{ cursor: 'pointer' }}
        >
          <span>{breed?.group_name}</span>
        </button>
      </h2>
      <Collapse
        id='flush-headingFour'
        className='accordion-collapse'
        isOpen={activeCol}
      >
        {breed.has_variety ? (
          <div className='accordion-body box-acc list-data'>
            <Row>
              <Col xl='12'>
                {Array.isArray(breed?.varietyList) && breed?.varietyList.length
                  ? breed?.varietyList.map((variety) => {
                      return (
                        <Variety
                          breed={variety}
                          key={variety?.variety_id}
                          recordType={recordType}
                        />
                      )
                    })
                  : null}
              </Col>
            </Row>
          </div>
        ) : (
          <div className='accordion-body box-acc list-data'>
            <Row>
              <Col xl='12'>
                {_.has(breed, 'classList') &&
                Array.isArray(breed?.classList) &&
                breed?.classList.length
                  ? breed?.classList.map((classes) => {
                      return (
                        <Class
                          breed={classes}
                          key={classes.class_id}
                          recordType={recordType}
                        />
                      )
                    })
                  : null}
              </Col>
            </Row>
          </div>
        )}
      </Collapse>
    </div>
  )
}

export default Group

const defaultObj = {
  breed_id: undefined,
  group_id: undefined,
  variety_id: undefined,
  class_id: undefined,
  fur_type_id: undefined,
  fur_id: undefined,
}
