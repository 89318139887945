// import RemoveRedEyeOutlinedIcon from '@material-ui/icons/RemoveRedEyeOutlined';
// import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Row, Container } from 'reactstrap';
import { get, post } from '../../../helpers/api_helper';


const ClerkLogin = (props) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState({
    username: '',
    password: '',
  });
  let [loading, setLoading] = useState(false);

  const handleLogin = (e) => {
    e.preventDefault();
    let validationFlag = false;
    let errUsername;
    let errPassword;

    if (!username.trim()) {
      validationFlag = true;
      errUsername = 'Login code field is required';
    }
    // if (!password) {
    //   validationFlag = true;
    //   errPassword = 'Password field is required';
    // }

    if (errUsername) {
      setErrors({
        username: errUsername,
      });
    } else {
      setErrors({
        username: '',
        password: '',
      });
    }

    if (!validationFlag) {
      setLoading(true);

      let accessToken = localStorage.getItem('accessToken');
      if (!accessToken) {
        post(`/api/v1/login`, {
          role: 'clerk',
          username: username,
          password: password,
        })
          .then((response) => {
            if (response?.data.role === 'clerk') {
              toast.success('Login Success !');
              localStorage.setItem('secretaryToken', response.accessToken);
              localStorage.setItem(
                'secretaryRefreshToken',
                response.refreshToken
              );

              localStorage.setItem('clerk', JSON.stringify(response.data));
              setTimeout(() => {
                window.open(
                  `${process.env.REACT_APP_SECRETARY_URL}/token?token=${response.accessToken}&refresh=${response.refreshToken}&showId=${response.data.show_id}`,
                  '_self'
                );
              }, 1500);
            }
          })
          .catch((error) => {
            setLoading(false);
            if (error.response?.status === 401) {
              if (error.response?.data === 'Invalid credentials.') {
                toast.error(error.response.data, { toastId: 1 });
              } else {
                if (
                  error.response?.data ===
                  'Please contact Admin you are currently blocked.'
                ) {
                }
              }
            } else if (error.response?.status === 422) {
              toast.error(error.response.data[0].msg);
            } else if (error.response?.status === 500) {
              toast.error(error.response.data);
            } else {
            }
          });
      } else {
        setLoading(false);
        toast.error(
          'You already have an active login session in this browser !',
          { toastId: 1 }
        );
      }
    }
  };

  return (
    <React.Fragment>
      {/* <header>
        <div className="navbar-header" style={{ background: '#00A310' }}>
          <div
            className="navbar-brand-box login-nav"
            style={{
              display: 'block',
              position: 'initial',
              background: '#062575',
            }}
          >
            <Link to="/" className="logo logo-dark">
              <span className="logo-sm">
                <img src="" alt="" height="30" />
              </span>
              <span className="logo-lg">
                <img src="" alt="" height="30" />
              </span>
            </Link>
          </div>
        </div>
      </header> */}

      <div
        className="header-main1 header-dark fixed-top"
        style={{ opacity: '1', background: '#00A310' }}
      >
        <div className="navbar navbar-expand-lg navbar-dark">
          <Container fluid={true} className="auto-fit">
            <Link className="navbar-brand" to="/events">
              <img
                className="logo-dark"
                src={`${process.env.PUBLIC_URL}/assets/img/logo/logo.png`}
                title=""
                alt=""
              />
              <img
                className="logo-light"
                src={`${process.env.PUBLIC_URL}/assets/img/logo/logo.png`}
                title=""
                alt=""
              />
            </Link>
          </Container>
        </div>
      </div>

      <div className="view-main mt-10 res-mt-view">
        <section className="bg-cover bg-no-repeat loginform">
          <div className="container">
            <div className="row align-items-center justify-content-center min-vh-100">
              <div className="col-md-12 col-xl-5 text-center">
                <img
                  src={`${process.env.PUBLIC_URL}/assets/img/clerk-login.png`}
                  alt="banner-logo"
                  className="logo-max-view"
                />
              </div>
              <div className="col-md-12 col-xl-2"></div>
              <div className="col-md-12 col-xl-5">
                <div className="card">
                  <div className="card-body user-tab">
                    <div className="card">
                      <div className="bg-blue card-body">
                        <div className="text-center mt-2">
                          <h3 className="text-white mb-3 clerk-log">
                            CLERK LOGIN
                          </h3>
                        </div>
                        <div className="tab-content p-3 bg-white pt-4">
                          <div className="tab-pane active">
                            <form>
                              <div className="form-group mb-4 mt-2 ">
                                <label className="form-label">
                                  Login Code *
                                </label>
                                <input
                                  type="text"
                                  name="username"
                                  placeholder="Login code"
                                  className="form-control"
                                  id="username"
                                  value={username}
                                  style={{ borderColor: 'black' }}
                                  onChange={(e) => setUsername(e.target.value)}
                                />
                                {errors.username && (
                                  <p style={{ color: 'red' }}>
                                    {errors?.username}
                                  </p>
                                )}
                              </div>

                              {/* <div className="form-group mb-4">
                                <label className="form-label">Password *</label>
                                <input
                                  name="password"
                                  placeholder="Password"
                                  className="form-control"
                                  value={password}
                                  style={{ borderColor: 'black' }}
                                  type="password"
                                  onChange={(e) => setPassword(e.target.value)}
                                />
                                {errors.password && (
                                  <p style={{ color: 'red' }}>
                                    {errors?.password}
                                  </p>
                                )}
                              </div> */}

                              <div className="col-md-12 col-xl-12 pt-2 mb-3 text-center">
                                <button
                                  className="col-xl-8 btn btn-primary w-1001 clerk-log-sub"
                                  id="submit"
                                  onClick={handleLogin}
                                  disabled={loading}
                                >
                                  SUBMIT
                                </button>
                              </div>
                            </form>
                          </div>
                          <div className="mt-2 text-center text-font fs-16px">
                          {' '}
                          <Link
                            className="font-weight-bold"
                            to="/events"
                          >
                            Back to Events
                          </Link>
                        </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <footer className="footer-page" style={{ background: '#00A310' }}>
        <Row>
          <div className="col-md-6">
            <div className=" text-white text-center1 d-sm-block rights">
              © {new Date().getFullYear()} Easy2Show. All Rights Reserved.
            </div>
          </div>
          <div className="col-md-6">
            <div className=" text-white text-right d-sm-block rights">
            <Link to="/terms_conditions" className='mb-3'>Website Terms, Conditions & Privacy Policy</Link> 
            </div>
          </div>
        </Row>
      </footer>
    </React.Fragment>
  );
};

export default ClerkLogin;