import React, { useState } from 'react';

import { Row, Col, InputGroup } from 'reactstrap';
// import 'react-datepicker/dist/react-datepicker.css'
// import 'flatpickr/dist/themes/material_blue.css'
// import Flatpickr from 'react-flatpickr'
import DatePicker from '../../../common/DatePicker';

export const ParticipationFilter = (props) => {
  const { dateHighLight, setDate, date, event, setEvent, setInterval, interval } = props;
  const [error, setError] = useState({
    event: '',
    date: '',
  });

  const onClick = () => {
    let validationFlag = false;
    let err = {};

    if (date && !event) {
      validationFlag = true;
      err.event = 'Please select event';
    }
    // if (!date) {
    //   validationFlag = true
    //   err.date = 'Please select date'
    // }
    // console.log(err)
    setError(err);
    if (!validationFlag) {
      props.setIsSearch(!props.isSearch);
    }
  };
  return (
    <React.Fragment>
      <div class="col-md-12 mb-4">
        <Row>
          <Col lg={3}>
            <div className="mb-4">
              <InputGroup>
                <DatePicker
                  selected={date}
                  onChange={(date) => {
                    setDate(date);
                    setEvent('');
                  }}
                  placeholderText="Search By Date"
                  className="form-control"
                  dateFormat="MMM do yyyy"
                  highlightDates={dateHighLight}
                />
                <i className="fa fa-calendar cal-icon"></i>
              </InputGroup>
              <div className="text-danger">{error?.date}</div>
            </div>
          </Col>
          <Col lg={3}>
            <div className="mb-4">
              <select
                type="text"
                name="event-name"
                id="event-name"
                className="form-control form-select"
                value={event}
                onChange={(e) => {
                  setEvent(e.target.value);
                }}
              >
                <option value="">Select Event Name</option>
                {props.eventList.map((event, index) => (
                  <option value={event.event_id} key={index}> {event.event_name} </option>
                ))}

                {/* <option value=""> Do Not Apply Show </option> */}
              </select>
              <div className="text-danger">{error?.event}</div>
            </div>
          </Col>

         

          <Col lg={3}>
            <button
              type="button"
              className="btn btn-primary btn-manual w-50 mb-4"
              onClick={() => onClick()}
              title="Search"
            >
              SEARCH
            </button>
          </Col>
          <Col lg={3}>
            <div className="mb-4">
              <select
                type="text"
                name="year"
                className="form-control form-select"
                value={interval}
                onChange={e=> setInterval(Number(e.target.value))}
              >
                {intervals.map((v, index)=>   <option value={v.value} key={index}>{v.label}</option>)}
              </select>
            </div>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};


const intervals =[

  {
    'value':1,
    'label':'Past 1 Month'
  },
  {
    'value':2,
    'label':'Past 2 Months'
  },
  {
    'value':3,
    'label':'Past 3 Months'
  },
  {
    'value':4,
    'label':'Past 4 Months'
  },
  {
    'value':5,
    'label':'Past 5 Months'
  },

  {
    'value':6,
    'label':'Past 6 Months'
  },
  {
    'value':7,
    'label':'Past 7 Months'
  },
  {
    'value':8,
    'label':'Past 8 Months'
  },
  {
    'value':9,
    'label':'Past 9 Months'
  },
  {
    'value':10,
    'label':'Past 10 Months'
  },
  {
    'value':11,
    'label':'Past 11 Months'
  },
  {
    'value':12,
    'label':'Past 12 Months'
  },
]