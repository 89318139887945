import React, { useEffect, useMemo, useState } from 'react'
import { Redirect, useHistory, useParams } from 'react-router-dom'
import { Table } from 'react-super-responsive-table'
import { Col, Container, Row, Spinner } from 'reactstrap'
import { get, post, POST } from '../../../helpers/api_helper'
import SuccessPopup from '../../CommonComponent/successPopup1'
import EventDetail from './EventDetail'
import _ from 'lodash'
import {
  defaultParticipate,
  furTypeReset,
  hasFurReset,
  resetFromBreed,
  resetFromBreedCategory,
  resetFromGroup,
  resetFromGroupOnlySingleFurData,
  resetFromShowType,
  resetFromVariety,
  resetFromVarietyDeadline,
  resetFromVarietyOnlySingleFurData,
  restFromShowList,
} from '../../../helpers/helper'

import { v4 as uuidv4 } from 'uuid'
import { toast } from 'react-toastify'
import List from './list'
import { useDispatch } from 'react-redux'
import { PreviewParticipation } from '../../../store/actions'
import ParticipationEvent from './ParticipationEvent'
import { DeadlineHook, UrlEncodeHook } from '../../../hooks'

const EditParticipateEvents = () => {
  const history = useHistory()
  let { eventId, showId, participateId } = useParams()
  const { urlDecode, urlEncode } = UrlEncodeHook()
  eventId = urlDecode(eventId)
  showId = urlDecode(showId)
  participateId = urlDecode(participateId)

  const dispatch = useDispatch()
  const [participation, setParticipation] = useState([])
  const [showData, setShowData] = useState([])
  const [exhibitorList, setExhibitorList] = useState([])
  const [error, setError] = useState([])
  const [event, setEvent] = useState()
  const { deadLineType } = DeadlineHook({
    eventId,
  })

  let deadline = deadLineType()

  let furDisable = useMemo(() => {
    let furDisable = false
    if (deadline.deadlineType === 3 && deadline.first_deadline) {
      furDisable = true
    }
    if (deadline.deadlineType === 2 && deadline.first_deadline) {
      furDisable = true
    }
    if (deadline.deadlineType === 1 && deadline.first_deadline) {
      furDisable = true
    }

    return furDisable
  }, [deadline])

  let disableGroupToClass = useMemo(() => {
    let furDisable = false
    if (deadline.deadlineType === 3 && deadline.second_deadline) {
      furDisable = true
    }
    if (deadline.deadlineType === 2 && deadline.second_deadline) {
      furDisable = true
    }
    if (deadline.deadlineType === 1 && deadline.first_deadline) {
      furDisable = true
    }
    return furDisable
  }, [deadline])

  let disabledEr = useMemo(() => {
    let furDisable = false
    if (deadline.deadlineType === 3 && deadline.third_deadline) {
      furDisable = true
    }
    if (deadline.deadlineType === 2 && deadline.second_deadline) {
      furDisable = true
    }
    if (deadline.deadlineType === 1 && deadline.first_deadline) {
      furDisable = true
    }

    return furDisable
  }, [deadline])

  useEffect(() => {
    const body = {
      event_id_list: [eventId],
    }
    get(`/api/v1/events/sponsor/all-shows-by-event?event_id=${eventId}`).then(
      (res) => {
        if (res && Array.isArray(res.data)) {
          let obj = _.first(res.data)

          setEvent(obj)
        }

        // setEvents(res.data[0])
      }
    )
  }, [])
  useEffect(() => {
    const fun = async () => {
      // const exhibitorData = await get(
      //   `/api/v1/participation/secretary/manual/frm-exh-list?visible=1`
      // )
      // if (exhibitorData && Array.isArray(exhibitorData.data)) {
      //   setExhibitorList(exhibitorData.data)
      // }
      let data = {
        event_id: Number(eventId),
        visible: 1,
        participant_id_list: [Number(participateId)],
      }
      const animalData = await POST(
        `/api/v1/participation/secretary/manual/entry-list-in-detail`,
        data
      )
      const showData = await get(
        `/api/v1/shows/secretary/all?event_id=${eventId}`
      )
      if (showData && Array.isArray(showData.data)) {
        setShowData(showData.data)
      }
      if (animalData && Array.isArray(animalData.data)) {
        let data = _.first(animalData.data)
        let obj = {
          list_id: uuidv4(),
          previous_amt_paid: data?.final_cost || '0',
          event_id: Number(eventId),
          in_cart: 0,
          show_id: data.show_id,
          show_type: data.show_type,
          participant_id: Number(participateId),
          user_id: data.user_id,
          ear_no: data.ear_no,
          breed_category_id: data.breed_category_id,
          breed_id: data.breed_id,
          class_id: data.class_id,
          variety_id: data.variety_id,
          group_id: data.group_id,
          has_fur: data.has_fur,
          has_fur_data: data.has_fur ? true : false,
          group_seq: data.group_seq,
          variety_seq: data.variety_seq,
          fur_type_id: data?.has_fur
            ? data.fur_data.map((fur) => fur.fur_type_id)
            : [],
          fur_id: data?.has_fur ? _.first(data.fur_data).fur_id : '',
          fur_data_selected: [],
          furTypeList: [],
          display_fur_data: true,
          removed_all_fur: false,
        }
        if (data?.has_fur) {
          obj.fur_data_selected = data.fur_data.map((s) => {
            return {
              fur_type_id: s.fur_type_id,
              fur_id: s.fur_id,
              participant_fur_data_id: s.participant_fur_data_id,
            }
          })
        }
        setParticipation([obj])
      }
    }
    fun()
  }, [])
  useEffect(() => {
    get(`/api/v1/profile/exhibitor_list`).then((res) => {
      if (res) {
        let sortList = _.sortBy(res?.data || [], ['last_name'], ['asc'])

        setExhibitorList(sortList)
      }
    })
  }, [])

  const onChange = (id, v, type, name) => {
    if (!type) {
      if (v.target.name === 'ear_no' && v.target.value.length > 50) {
        toast.error('Max Ear. No Should be 50 Character', { toastId: 1 })
        return
      }
    }
    let list = participation.map((obj) => {
      if (id === obj.list_id) {
        let resetObj = {}

        if (type) {
          let value = v.map((data) => {
            return data.value
          })
          if (name === 'show_id') {
            resetObj = restFromShowList()
          }
          if (name === 'fur_type_id') {
            resetObj = furTypeReset()
          }

          return {
            ...obj,
            ...resetObj,
            [name]: value,
          }
        } else {
          let { name, value } = v.target
          // reset values start
          if (name === 'show_type') {
            resetObj = resetFromShowType()
          }

          if (name === 'has_fur' && obj.display_fur_data) {
            resetObj = hasFurReset()
          }
          if (name === 'variety_id') {
            if (furDisable) {
              resetObj = resetFromVarietyDeadline()
            } else if (!obj.display_fur_data) {
              resetObj = resetFromVarietyOnlySingleFurData()
            } else {
              resetObj = resetFromVariety()
            }
            // resetObj = resetFromVariety()
          }
          if (name === 'group_id') {
            if (furDisable) {
              resetObj = resetFromVarietyDeadline()
            } else if (!obj.display_fur_data) {
              resetObj = resetFromGroupOnlySingleFurData()
            } else {
              resetObj = resetFromGroup()
            }
          }
          if (name === 'breed_id') {
            // resetFromBreed
            resetObj = resetFromBreed()
          }
          if (name === 'breed_category_id') {
            resetObj = resetFromBreedCategory()
          }
          // reset value end
          if (name === 'ear_no') {
            value = value.toUpperCase()
          }
          if (name === 'breed_id') {
            let ele = v.target
            let variety_seq =
              ele.options[v.target.selectedIndex].getAttribute('variety_seq')
            let group_seq =
              ele.options[v.target.selectedIndex].getAttribute('group_seq')
            obj.variety_seq = Number(variety_seq) ? Number(variety_seq) : null
            obj.group_seq = Number(group_seq) ? Number(group_seq) : null
          }
          if (name === 'has_fur') {
            let ele = v.target.value
            if (Number(ele) === 0) {
              obj.removed_all_fur = true
            } else {
              obj.removed_all_fur = false
            }
          }

          return {
            ...obj,
            ...resetObj,
            [name]: value,
          }
        }
      }
      return obj
    })

    setParticipation(list)
  }

  const updateHasFur = (id, has, furTypeList, furData) => {
    let list = participation.map((par) => {
      if (par.list_id === id) {
        let display_fur_data = {
          display_fur_data: true,
        }
        if (
          Array.isArray(furData?.furList) &&
          furData.furList.length === 1 &&
          Array.isArray(furData?.furTypeList) &&
          furData.furTypeList.length === 1
        ) {
          display_fur_data = {
            display_fur_data: false,
            fur_type_id: [Number(_.first(furData.furTypeList).fur_type_id)],
            fur_id: Number(_.first(furData.furList).fur_id),
          }
        }
        return {
          ...par,
          ...display_fur_data,
          has_fur_data: has ? true : false,
          furTypeList: furTypeList,
        }
      }
      return par
    })
    setParticipation(list)
  }
  // const updateFurData = (id, fur) => {
  //   let list = participation.map((par) => {
  //     if (par.list_id === id) {
  //       return {
  //         ...par,
  //         display_fur_data: false,
  //         fur_type_id: [Number(_.first(fur.furTypeList).fur_type_id)],
  //         fur_id: Number(_.first(fur.furList).fur_id),
  //       }
  //     }
  //     return par
  //   })
  //   setParticipation(list)
  // }
  const onSubmit = async () => {
    let validationFlag = false
    let err = participation.map((val) => {
      let er = {}
      er.id = val.list_id
      if (!val.show_id && !val.show_id.length) {
        er.show_id = 'Please select show'
      }
      if (!val.show_type) {
        er.show_type = 'Please select show type'
      }
      if (!val.user_id) {
        er.user_id = 'Please select exhibitor'
      }
      if (!val.ear_no) {
        er.ear_no = 'Ear no. field is required'
      }
      if (val.ear_no) {
        let checkErNum = participation.find((s) => {
          if (
            s.list_id !== val.list_id &&
            s.ear_no === val.ear_no &&
            s.user_id === val.user_id &&
            s.show_id === val.show_id
          ) {
            return true
          }
          return false
        })
        if (checkErNum) {
          er.ear_no = 'Duplicate Ear no. found for same user.'
        }
      }
      if (!val.breed_category_id) {
        er.breed_category_id = 'Please select category'
      }
      if (!val.breed_id) {
        er.breed_id = 'Please select breed'
      }
      if (!val.class_id) {
        er.class_id = 'Please select class'
      }
      if (val.group_seq && !val.group_id) {
        er.group_id = 'Please select group'
      }
      if (val.variety_seq && !val.variety_id) {
        er.variety_id = 'Please select variety'
      }

      if (val.has_fur_data) {
        if (val.has_fur === '') {
          er.has_fur = 'Please select fur'
        }
        if (
          val.has_fur !== '' &&
          Number(val.has_fur) === 1 &&
          Array.isArray(val.fur_type_id) &&
          !val.fur_type_id.length
        ) {
          er.fur_type_id = 'Please select fur type'
        }
        if (val.has_fur !== '' && Number(val.has_fur) === 1 && !val.fur_id) {
          er.fur_id = 'Please select fur color'
        }
      }

      return er
    })
    setError(err)
    let valErr = _.map(err, function (obj) {
      return _.omit(obj, 'id')
    })
    valErr = valErr.filter((value) => Object.keys(value).length !== 0)
    if (valErr.length) {
      validationFlag = true
    }
    if (!validationFlag) {
      let valid = false
      const manualEntryValidation = async (data) => {
        const res = await post(
          `/api/v1/participation/validate_participation`,
          data
        )
        return res.data
      }
      document.getElementById('submit-btn').setAttribute('disabled', true)
      let finalParticipation = participation.map((p) => {
        if (p.class_id) {
          p.class_id = Number(p.class_id)
        }
        if (p.group_id) {
          p.group_id = Number(p.group_id)
        }
        if (p.variety_id) {
          p.variety_id = Number(p.variety_id)
        }
        if (p.breed_category_id) {
          p.breed_category_id = Number(p.breed_category_id)
        }
        if (p.breed_id) {
          p.breed_id = Number(p.breed_id)
        }
        if (p.has_fur === '') {
          p.has_fur = null
        }

        return p
      })
      let urlList = finalParticipation.map((san) => manualEntryValidation(san))

      const list = await Promise.allSettled(urlList)

      let validation = list.map((val) => {
        let err = {}
        // err.id = val.list_id
        if (val.status === 'rejected') {
          // debugger
          if (JSON.parse(val.reason.config.data).list_id) {
            err.id = JSON.parse(val.reason.config.data).list_id
          }
          if (
            val?.reason.response?.data &&
            Array.isArray(val?.reason.response?.data)
          ) {
            val?.reason.response?.data.forEach((s) => {
              if (s.param === 'group_seq') {
                err.group_seq = 'Please select group'
              }
              if (s.param === 'variety_seq') {
                err.variety_seq = 'Please select variety'
              }
              if (s.param === 'ear_no') {
                err.ear_no = 'Duplicate Ear no. found for same user '
              }
              if (s.param === 'fur_data[0].fur_id') {
                err.fur_id = 'Please select fur color'
              }
            })
          }
        }
        return err
      })

      setError(validation)
      let valErr = _.map(validation, function (obj) {
        return _.omit(obj, 'id')
      })
      valErr = valErr.filter((value) => Object.keys(value).length !== 0)
      if (valErr.length) {
        document.getElementById('submit-btn').removeAttribute('disabled')
        valid = true
      }
      if (!valid) {
        document.getElementById('submit-btn').removeAttribute('disabled')

        let finalParticipation = participation.map((par) => {
          return {
            ...par,
            previewScreen: true,
            isEdit: false,
            fur_data: [],
          }
        })
        document.getElementById('submit-btn').setAttribute('disabled', false)
        dispatch(PreviewParticipation(finalParticipation))
        history.push(
          `/your_participation/${urlEncode(eventId)}/${urlEncode(
            showId
          )}/preview_participate_events`
        )
      }
    }
  }

  return (
    <React.Fragment>
      <div className="page-content bg-gray-101 min-h-80 view-dis parti-dis">
        <section id="about" className="section1 pages">
          <Container fluid={true} className="auto-fit">
            <Col lg={12}>
              <Row>
                <Col lg={12} className="text-center">
                  <div className="page-title-box justify-content-between">
                    <h2 className="top-title mb-4">
                      <span>YOUR PARTICIPATION</span>
                    </h2>
                  </div>
                </Col>
              </Row>
            </Col>
            <Row>
              <Col lg={12}>
                <div className="table-responsive text-center mb-5">
                  <Table className="table mb-0 cust-tab participate-str">
                    <thead className="table-color">
                      <tr>
                        <th>SHOW INFORMATION</th>
                      </tr>
                    </thead>
                  </Table>
                </div>
              </Col>
              <ParticipationEvent />

              <Col lg={12}>
                <div className="table-responsive text-center mt-5">
                  <Table className="table mb-0 cust-tab participate-str">
                    <thead className="table-color">
                      <tr>
                        <th>PARTICIPATION INFORMATION</th>
                      </tr>
                    </thead>
                  </Table>
                </div>
              </Col>
              <Col lg={12} className="manual-row mt-5 multi-select">
                {participation.length ? (
                  participation.map((list, i) => {
                    return (
                      <List
                        list={list}
                        key={i}
                        showData={showData}
                        exhibitorList={exhibitorList}
                        onChange={onChange}
                        eventType={event?.event_type}
                        updateHasFur={updateHasFur}
                        // updateFurData={updateFurData}
                        error={error}
                        deadline={deadline}
                        furDisable={furDisable}
                        disableGroupToClass={disableGroupToClass}
                        disabledEr={disabledEr}
                      />
                    )
                  })
                ) : (
                  <div className="text-center">
                    <p style={{ fontSize: 24 }}>Loading...</p>
                  </div>
                )}
              </Col>

              <Col lg={12} className="text-center mt-5">
                <button
                  id="submit-btn"
                  type="button"
                  className="btn btn-primary btn-easy"
                  onClick={() => onSubmit()}
                >
                  PREVIEW
                </button>
              </Col>
            </Row>
          </Container>
        </section>
        <SuccessPopup />
      </div>
    </React.Fragment>
  )
}

export default EditParticipateEvents
