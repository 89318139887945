import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';

import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <div
          aria-label="close"
          onClick={onClose}
          style={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: '#fff',
            width: '30px',
            backgroundColor: 'red',
            textAlign: 'center',
          }}
        >
          {/* <CloseIcon /> */}
        </div>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function CustomizedDialogs(props) {
  const handleClickOpen = () => {
    props.setShowAlert(true);
  };
  const handleClose = (event, reason) => {
    if (reason && reason == 'backdropClick') return;
    props.setShowAlert(false);
    props.setMsg('');
  };

  return (
    <div>
      {/* <Button variant="outlined" onClick={handleClickOpen}>
        Open dialog
      </Button> */}
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={true}
      >
        <div
         className='res-pop'
          style={{
            textAlign: 'center',
            width: '350px',
            background: 'rgb(3 18 58)',
            color: '#fff',
            padding: '10px',
          }}
        >
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={handleClose}
          ></BootstrapDialogTitle>
          <DialogContent style={{ marginTop: '10px' }}>
            <Typography className="para" gutterBottom>
              No Qualified entries found to participate click on continue for
              manual entry participation.
            </Typography>

            <button
              className="btn btn-primary btn-easy"
              style={{
                background: 'green',
                color: '#fff',
                margin: '20px 0 0 0',
              }}
              onClick={props.handleClicked}
            >
              Continue
            </button>
          </DialogContent>
        </div>
      </BootstrapDialog>
    </div>
  );
}
