import { HERD_MANAGEMENT_PAGINATION_DATA, NEXT_PAGE } from './actionType'

const initialState = {
  page: 1,
  limit: process.env.REACT_APP_PAGE_LIMIT || 10,
  // limit: 5,
  totalPage: 1,
  totalRecords: 0,
  loading: true,
}

const pedigreePaginationReducer = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case HERD_MANAGEMENT_PAGINATION_DATA:
      return {
        ...state,
        page: payload?.page || 1,
        totalPage: payload?.totalPage || 1,
        totalRecords: payload?.totalRecords || 0,
        loading: false,
      }
    case NEXT_PAGE:
      return {
        ...state,
        page: payload || 1,
      }
    default:
      return state
  }
}
export default pedigreePaginationReducer
