import {
  SET_SHOW_ALERT,
  SET_MESSAGE,
  SET_MESSAGE_TITLE,
  SET_HANDLE_CLICK,
  SET_SHOW_BUTTON,
  SET_BUTTON_TEXT,
} from './actionTypes';

const initialState = {
  showAlertp: false,
  msgp: '',
  handleClickedp: () => {},
  titlep: '',
  showBtnp: false,
  Btntextp: 'Click Here',
};

const popup = (state = initialState, action) => {
  switch (action.type) {
    case SET_SHOW_ALERT:
      state = { ...state, showAlertp: action.payload };
      break;
    case SET_MESSAGE_TITLE:
      state = { ...state, titlep: action.payload };
      break;
    case SET_MESSAGE:
      state = { ...state, msgp: action.payload };
      break;
    case SET_HANDLE_CLICK:
      state = { ...state, handleClickedp: action.payload };
      break;
    case SET_BUTTON_TEXT:
      state = { ...state, Btntextp: action.payload };
      break;
    case SET_SHOW_BUTTON:
      state = { ...state, showBtnp: action.payload };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};
export default popup;
