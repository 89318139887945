import { Field, Form, Formik, useFormikContext } from 'formik'
import React, { useEffect } from 'react'
import { Col, Label, Row } from 'reactstrap'
import { Button, FileField, RadioGroup, TextField } from '../../Atoms'
// import Switch from 'react-switch'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'
import * as validation from '../../../../helpers/validation'
import {
  MAX_FILE_SIZE_4,
  SUPPORTED_FORMATS_IMG,
} from '../../../../helpers/utils'
import BreedSectionForm from '../BreedSectionForm/BreedSectionForm'
import _ from 'lodash'
import {
  addRabbit,
  getOnePedigreeDetail,
  showAndClosePedigreeModal,
  updateRabbit,
} from '../../../../store/actions'
import moment from 'moment'
import { toast } from 'react-toastify'
import { useHistory, useLocation } from 'react-router-dom'
import { UrlEncodeHook } from '../../../../hooks'
import Swal from 'sweetalert2'
import { useState } from 'react'

const NewPedigree = (props) => {
  const { urlEncode, urlDecode } = UrlEncodeHook()
  const history = useHistory()
  const dispatch = useDispatch()
  let location = useLocation()

  const [isDelete, setIsDelete] = useState(false)
  const [componentReload, setComponentReload] = useState(false)

  const query = new URLSearchParams(location.search)
  let pedigreeId = query.get('p')
  pedigreeId = urlDecode(pedigreeId)

  const { form } = useSelector((state) => {
    return {
      form: state.herdManagement.Pedigree.pedigreeModal.ModalContent.form,
    }
  })
  const validationSchema = Yup.object({
    ear_no: Yup.string()
      .required(`Ear No. ${validation.REQUIRED}`)
      .trim()
      .max(45, `Ear No. ${validation.MAX_CHAR_FUN(45)}`),
    animal_name: Yup.string()
      .required(`Animal Name ${validation.REQUIRED}`)
      .trim()
      .max(99, `Animal Name ${validation.MAX_CHAR_FUN(99)}`),
    date_of_birth: Yup.string().required(
      `Date of Birth ${validation.REQUIRED}`
    ),
    breed_id: Yup.string().when('add_new_breed', {
      is: (value) => !value,
      then: () => Yup.string().required(`Breed name ${validation.REQUIRED}`),
      otherwise: () => Yup.string().notRequired(),
    }),
    breed_name: Yup.string().when('add_new_breed', {
      is: (value) => value,
      then: () =>
        Yup.string()
          .required(`Breed name ${validation.REQUIRED}`)
          .trim()
          .max(99, `Breed name ${validation.MAX_CHAR_FUN(99)}`),
      otherwise: () => Yup.string().notRequired(),
    }),

    variety_id: Yup.string().when(
      ['variety_seq', 'add_new_variety'],
      ([variety_seq, add_new_variety]) => {
        if (variety_seq && !add_new_variety) {
          return Yup.string().notRequired()
          // .required(`Variety name ${validation.REQUIRED}`)
        }

        return Yup.string().notRequired()
      }
    ),
    variety_name: Yup.string().when(
      ['variety_seq', 'add_new_variety'],
      ([variety_seq, add_new_variety]) => {
        if (variety_seq && add_new_variety) {
          return (
            Yup.string()
              .notRequired()
              // .required(`Variety name ${validation.REQUIRED}`)
              .max(99, `Variety name ${validation.MAX_CHAR_FUN(99)}`)
          )
        }

        return Yup.string().notRequired()
      }
    ),
    group_id: Yup.string().when(
      ['group_seq', 'add_new_group'],
      ([group_seq, add_new_group]) => {
        if (group_seq && !add_new_group) {
          return Yup.string().notRequired()
          // .required(`Group name ${validation.REQUIRED}`)
        }

        return Yup.string().notRequired()
      }
    ),
    group_name: Yup.string().when(
      ['group_seq', 'add_new_group'],
      ([variety_seq, add_new_group]) => {
        if (variety_seq && add_new_group) {
          return Yup.string()
            .notRequired()
            .max(99, `Group name ${validation.MAX_CHAR_FUN(99)}`)
            .trim()

          // Yup.string()
          //   .required(`Group name ${validation.REQUIRED}`)
          //   .max(99, `Group name ${validation.MAX_CHAR_FUN(99)}`)
        }
        return Yup.string().notRequired()
      }
    ),
    gender: Yup.string().required(`Gender ${validation.REQUIRED}`),
    weight: Yup.number()
      .positive(validation.POSITIVE_NUMBER_ALLOWED)
      .typeError(validation.NUMBER_INVALID)
      .nullable()
      // .required(`Weight ${validation.REQUIRED}`)
      .min(0.1, `Weight ${validation.MIN_NUMBER(0.1)}`)
      .max(999, `Weight ${validation.MAX_NUMBER(999)}`)
      .transform((value) => {
        return value ? value : null
      }),
    registration_number: Yup.string()
      .nullable()
      .notRequired()
      .trim()
      .max(45, `Reg. ${validation.MAX_CHAR_FUN(45)}`),
    grand_championship: Yup.string()
      .nullable()
      .notRequired()
      .trim()
      .max(45, `Grand Champion ${validation.MAX_CHAR_FUN(45)}`),
    winnings: Yup.string()
      .notRequired()
      .nullable()
      .max(45, `Winnings ${validation.MAX_CHAR_FUN(45)}`)
      .transform((value) => {
        return value ? value : null
      }),
    img1: Yup.mixed().nullable(),
    img_1: Yup.mixed()
      .test('fileSize', 'Please select less then 4 MB image', (value) => {
        if (value?.size) {
          let fsize = value?.size
          fsize = Math.round(fsize / 1024)
          return fsize <= MAX_FILE_SIZE_4
        }
        return true
      })
      .test(
        'fileFormat',
        'Please select valid image',
        (value) => !value || SUPPORTED_FORMATS_IMG.includes(value.type)
      ),
    color_name: Yup.string().notRequired(),
  })

  const addNewPedigreeApi = (body) => {
    return new Promise((resolve, reject) => {
      dispatch(
        addRabbit(body, (err, data) => {
          if (err) {
            reject(err)
          } else {
            resolve(data)
          }
        })
      )
    })
  }

  const updateRabbitApi = (body) => {
    return new Promise((resolve, reject) => {
      dispatch(
        updateRabbit(body, (err, data) => {
          if (err) {
            reject(err)
          } else {
            resolve(data)
          }
        })
      )
    })
  }

  const getPedigreeApi = (body) => {
    return new Promise((resolve, reject) => {
      dispatch(
        getOnePedigreeDetail(body, (err, data) => {
          if (err) {
            reject(err)
          } else {
            resolve(data)
          }
        })
      )
    })
  }

  const onSubmit = async (value, formProps) => {
    try {
      const castValue = validationSchema.cast(value)
      formProps.setStatus(true)
      const formData = new FormData()
      formData.append('ear_no', castValue?.ear_no || '')
      formData.append('animal_name', castValue?.animal_name || '')
      formData.append('breed_name', castValue?.breed_name || '')
      formData.append('group_name', castValue?.group_name || '')
      formData.append('variety_name', castValue?.variety_name || '')
      formData.append(
        'date_of_birth',
        castValue.date_of_birth
          ? moment(castValue.date_of_birth).format('YYYY-MM-DD')
          : ''
      )
      formData.append('gender', castValue?.gender || '')
      formData.append('weight', castValue?.weight || 0)
      formData.append(
        'registration_number',
        castValue?.registration_number || ''
      )
      formData.append('grand_championship', castValue?.grand_championship || '')
      formData.append('color_name', castValue?.color_name || '')
      formData.append('winnings', castValue?.winnings || '')
      if (!form.rabbit_id) {
        formData.append('archieve', 'pedigree')
        formData.append('is_pedigree', 1)
        formData.append('pedigree_name', '')
      }
      if (castValue?.img1) {
        formData.append('image', castValue.img1)
      }
      let res
      if (!form.rabbit_id) {
        res = await addNewPedigreeApi(formData)
      } else {
        const data = {
          params: {
            rabbit_id: form.rabbit_id,
          },
          data: formData,
        }
        res = await updateRabbitApi(data)
      }

      if (res) {
        const data = {
          params: {
            id: !form.rabbit_id ? res.pedigree_id : pedigreeId,
          },
        }
        const getPedigreeRes = await getPedigreeApi(data)
        if (getPedigreeRes) {
          if (pedigreeId) {
            history.push(
              `/herd-management/pedigree/create?p=${urlEncode(pedigreeId)}`
            )
          } else {
            history.push(
              `/herd-management/pedigree/create?p=${urlEncode(res.pedigree_id)}`
            )
          }
          if (form.rabbit_id) {
            toast.success('Pedigree has been updated successfully')
          } else {
            toast.success('Pedigree has been added successfully')
          }
          formProps.setStatus(false)
          dispatch(
            showAndClosePedigreeModal({
              showModal: false,
              modalType: '',
              ModalContent: '',
            })
          )
        }
      }
    } catch (err) {
      formProps.setStatus(false)
      if (Array.isArray(err)) {
        toast.error(_.get(_.first(err), 'msg'))
      } else if (err.response.data) {
        toast.error(err.response.data)
      } else if (err) {
        toast.error(err)
      }
    }
  }

  const onImageDelete = () => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      width: '380px',
      showCancelButton: true,
      confirmButtonColor: '#062575',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const formData = new FormData()
          formData.append('delete_image', 1)

          const data = {
            params: {
              rabbit_id: form.rabbit_id,
            },
            data: formData,
          }
          const res = await updateRabbitApi(data)
          if (res?.data) {
            setIsDelete(true)
            setComponentReload(!componentReload)
            toast.success(`Record has been deleted successfully`)
            const data = {
              params: {
                id: pedigreeId,
              },
            }
            await getPedigreeApi(data)

            // dispatch(getPedigreeHeader(id))
          }
        } catch (err) {
          if (Array.isArray(err)) {
            toast.error(_.get(_.first(err), 'msg'))
          } else if (err) {
            toast.error(err)
          }
        }
      }
    })
  }
  return (
    <Formik
      initialValues={{
        ...form,
        date_of_birth: form.date_of_birth ? new Date(form.date_of_birth) : '',
      }}
      onSubmit={onSubmit}
      enableReinitialize
      validationSchema={validationSchema}
    >
      {({ status, values, errors }) => {
        return (
          <Form className='needs-validation club-selection'>
            <Row className='mt-3'>
              <Col sm='12' md='6'>
                <div className='mb-3'>
                  <Label className='form-label' htmlFor='ear_no'>
                    Ear No *
                  </Label>
                  <Field
                    name='ear_no'
                    placeholder='Ear No'
                    type='text'
                    id='ear_no'
                    component={TextField}
                  />
                </div>
              </Col>
              <Col sm='12' md='6'>
                <div className='mb-3'>
                  <Label className='form-label' htmlFor='animal_name'>
                    Animal Name *
                  </Label>
                  <Field
                    name='animal_name'
                    placeholder=' Animal Name'
                    type='text'
                    id='animal_name'
                    component={TextField}
                  />
                </div>
              </Col>

              {/* Breed Section */}
              <BreedSectionForm values={values} />
              <Col sm='12' md='6'>
                <div className='mb-3'>
                  <Label className='form-label' htmlFor='color_name'>
                    Color
                  </Label>
                  <div>
                    <Field
                      name='color_name'
                      type='text'
                      id='color_name'
                      placeholder='Color'
                      className='form-control'
                      component={TextField}
                    />
                  </div>
                </div>
              </Col>
              {/*  */}
              <Col sm='12' md='6'>
                <Label className='form-label' htmlFor='date_of_birth'>
                  Date of Birth *
                </Label>
                <div className='mb-3 position-relative'>
                  <Field
                    name='date_of_birth'
                    type='datepicker'
                    id='date_of_birth'
                    className='form-control'
                    placeholderText='Date of birth'
                    component={TextField}
                    maxDate={new Date()}
                    startYear='2000'
                  />
                  <i
                    htmlFor='start_date'
                    className='bx bx-calendar-event cal-icon fs-box position-absolute'
                  />
                </div>
              </Col>
              <Col md='6' className='mt-4'>
                <Row>
                  <Col md='12'>
                    <Label className='form-label' htmlFor='gender me-2'>
                      Select Gender *
                    </Label>
                  </Col>
                  <Col
                    md='12'
                    className='d-flex flex-row flex-wrap align-content-center align-items-center'
                  >
                    <Field
                      name='gender'
                      TagName='span'
                      component={RadioGroup}
                      option={[
                        {
                          label: 'Buck',
                          value: 'M',
                        },
                        {
                          label: 'Doe',
                          value: 'F',
                        },
                      ]}
                    />
                  </Col>
                </Row>
              </Col>
              <Col sm='12' md='6'>
                <div className='mb-3'>
                  <Label className='form-label' htmlFor='weight'>
                    Weight (Lbs.)
                  </Label>
                  <div>
                    <Field
                      name='weight'
                      type='number'
                      id='weight'
                      placeholder='Weight'
                      className='form-control'
                      component={TextField}
                    />
                  </div>
                </div>
              </Col>
              <Col sm='12' md='6'>
                <div className='mb-3'>
                  <Label className='form-label' htmlFor='registration_number'>
                    Registration #
                  </Label>
                  <div>
                    <Field
                      name='registration_number'
                      type='text'
                      id='registration_number'
                      placeholder='Registration #'
                      className='form-control'
                      component={TextField}
                    />
                  </div>
                </div>
              </Col>
              <Col sm='12' md='6'>
                <div className='mb-3'>
                  <Label className='form-label' htmlFor='grand_championship'>
                    Grand Champion #
                  </Label>
                  <div>
                    <Field
                      name='grand_championship'
                      type='text'
                      id='grand_championship'
                      placeholder='Grand Champion #'
                      className='form-control'
                      component={TextField}
                    />
                  </div>
                </div>
              </Col>
              <Col sm='12' md='12'>
                <div className='mb-3'>
                  <Label className='form-label' htmlFor='winnings'>
                    Winnings
                  </Label>
                  <div>
                    <Field
                      name='winnings'
                      type='textarea'
                      id='winnings'
                      placeholder='Winnings'
                      className='form-control'
                      component={TextField}
                    />
                  </div>
                </div>
              </Col>
              <DeletedImage
                isDelete={isDelete}
                componentReload={componentReload}
              />
              <Col md={12}>
                <div className='mb-3'>
                  <Label className='form-label me-2' htmlFor='img_1'>
                    Image
                  </Label>
                  <Field
                    name='img_1'
                    inputName='img1'
                    previewImg='img'
                    type='file'
                    accept='image/png, image/gif, image/jpeg'
                    component={FileField}
                    className='form-control-file'
                    cropModal
                    useCrop
                    cropRectangle
                    aspect={1 / 1}
                  />
                  <small className='text-muted fs-14px'>
                    ( Note: Allowed format (png, gif, jpeg, jpg ) image size
                    limit: 4MB)
                  </small>
                  {values?.img ? (
                    <Col>
                      <i
                        className='bx bx-trash text-danger fs-4 mt-2'
                        id='deletetooltip-1'
                        role='button'
                        title='Delete'
                        onClick={() => onImageDelete()}
                      />
                    </Col>
                  ) : null}
                </div>
              </Col>
              <Col md={12} className='text-center'>
                <Button type='submit' disabled={status} color='primary'>
                  SUBMIT
                </Button>
              </Col>
            </Row>
          </Form>
        )
      }}
    </Formik>
  )
}

NewPedigree.propTypes = {}

export default NewPedigree

const DeletedImage = ({ componentReload, isDelete }) => {
  const { setFieldValue } = useFormikContext()

  useEffect(() => {
    if (isDelete) {
      setFieldValue('img', null)
      setFieldValue('image', null)
    }
  }, [componentReload])

  return null
}
