import React, { useEffect } from 'react'

import Breadcrumb from '../../../common/Breadcrumb'
import { useDispatch, useSelector } from 'react-redux'
import { ErrorMessage, Form, Formik } from 'formik'
import {
  addConfigureBreedList,
  herdConfigureBreedList,
  getConfigureBreedList,
} from '../../../store/actions'
import * as Yup from 'yup'
import _ from 'lodash'
import BreedList from './BreedList/BreedList'
import { Card, CardHeader } from '../Atoms'
import { Button, CardBody, Col, Row, Spinner } from 'reactstrap'
import { toast } from 'react-toastify'

const HerdConfigure = (props) => {
  const dispatch = useDispatch()
  const { herdConfigurationForm, herdConfigurationFormLoading } = useSelector(
    (state) => {
      return {
        herdConfigurationForm:
          state?.herdManagement?.herdConfiguration?.form || {},
        herdConfigurationFormLoading:
          state?.herdManagement?.herdConfiguration?.formLoading,
      }
    }
  )

  const validationSchema = Yup.object({
    breed_list: Yup.array().test(
      'at-least-one-checked',
      `Please select at least one breed`,
      (value) => {
        return Array.isArray(value)
          ? _(value)
              .map('breed')
              .flatten()
              .value()
              .some((breed) => breed.isSelected)
          : false
      }
    ),
  })

  useEffect(() => {
    dispatch(herdConfigureBreedList())
  }, [])

  const addApi = (body) => {
    return new Promise((resolve, reject) => {
      dispatch(
        addConfigureBreedList(body, (err, data) => {
          if (err) {
            reject(err)
          } else {
            resolve(data)
          }
        })
      )
    })
  }
  const onSubmit = async (values, formProps) => {
    formProps.setStatus(true)
    try {
      const obj = _(values.breed_list)
        .map((breed) => {
          return {
            // club_type: ClubTypeForm.club_type,
            breed_category: breed.breed_category_abbre,
            breed_list: _.map(
              _.filter(breed.breed, (q) => q.isSelected),
              (b) => ({
                breed_id: b.breed_id,
              })
            ),
          }
        })
        .filter((q) => _.size(q.breed_list))
        .value()
      const res = await addApi(obj)

      if (res) {
        toast.success(res)
      }
    } catch (err) {
      formProps.setStatus(false)
      if (Array.isArray(err)) {
        toast.error(_.get(_.first(err), 'msg'))
      } else if (err) {
        toast.error(err)
      }
    }
  }
  return (
    <Card className='layout'>
      <Row>
        <CardHeader>
          <Row>
            <Breadcrumb title='Configuration' />
          </Row>
        </CardHeader>
        <CardBody>
          {!herdConfigurationFormLoading ? (
            <Col lg='12'>
              <Formik
                initialValues={herdConfigurationForm}
                onSubmit={onSubmit}
                enableReinitialize
                // validateOnChange={false}
                // validateOnBlur={false}
                validationSchema={validationSchema}
              >
                {({ values, status }) => {
                  return (
                    <Form className='needs-validation club-selection'>
                      <Row className='mt-3'>
                        <Col md='12'>
                          {' '}
                          <BreedList />
                        </Col>
                        <Col md='12' className='text-center'>
                          <ErrorMessage
                            name='breed_list'
                            className='text-danger text-bold'
                            component='div'
                          />
                        </Col>
                      </Row>
                      <Col md={12} className='text-center'>
                        <Button
                          type='submit'
                          disabled={status || values?.config_user_id}
                          color='success'
                        >
                          SUBMIT
                        </Button>
                      </Col>
                    </Form>
                  )
                }}
              </Formik>
            </Col>
          ) : (
            <Col lg='12' className='text-center'>
              <Spinner color='primary' />
            </Col>
          )}
        </CardBody>
      </Row>
    </Card>
  )
}

HerdConfigure.propTypes = {}

export default HerdConfigure
