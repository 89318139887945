import { ALL_CLASS_TYPE_LIST_SUCCESS } from './actionType'

//

const INIT_STATE = {
  ClassTypeList: [],
  loading: true,
  error: {},
}

const ClassTypeListReducer = (state = INIT_STATE, action) => {
  const { type, payload } = action
  switch (type) {
    case ALL_CLASS_TYPE_LIST_SUCCESS:
      return {
        ...state,
        ClassTypeList: payload,
        loading: false,
      }

    default:
      return state
  }
}

export default ClassTypeListReducer
