//REGISTER
export const POST_FAKE_REGISTER = '/post-fake-register'

//LOGIN
export const POST_FAKE_LOGIN = '/post-fake-login'
export const POST_FAKE_JWT_LOGIN = '/post-jwt-login'
export const POST_FAKE_PASSWORD_FORGET = '/fake-forget-pwd'
export const POST_FAKE_JWT_PASSWORD_FORGET = '/jwt-forget-pwd'
export const SOCIAL_LOGIN = '/social-login'

//PROFILE
export const POST_EDIT_JWT_PROFILE = '/post-jwt-profile'
export const POST_EDIT_PROFILE = '/post-fake-profile'

//PRODUCTS
export const GET_PRODUCTS = '/products'
export const GET_PRODUCTS_DETAIL = '/product'

//CALENDER
export const GET_EVENTS = '/events'
export const ADD_NEW_EVENT = '/add/event'
export const UPDATE_EVENT = '/update/event'
export const DELETE_EVENT = '/delete/event'
export const GET_CATEGORIES = '/categories'

//CHATS
export const GET_CHATS = '/chats'
export const GET_GROUPS = '/groups'
export const GET_CONTACTS = '/contacts'
export const GET_MESSAGES = '/messages'
export const ADD_MESSAGE = '/add/messages'

//ORDERS
export const GET_ORDERS = '/orders'

//CART DATA
export const GET_CART_DATA = '/cart'

//CUSTOMERS
export const GET_CUSTOMERS = '/customers'

//SHOPS
export const GET_SHOPS = '/shops'

//CRYPTO
export const GET_WALLET = '/wallet'
export const GET_CRYPTO_ORDERS = '/crypto/orders'

//INVOICES
export const GET_INVOICES = '/invoices'
export const GET_INVOICE_DETAIL = '/invoice'

//PROJECTS
export const GET_PROJECTS = '/projects'
export const GET_PROJECT_DETAIL = '/project'

//TASKS
export const GET_TASKS = '/tasks'

//CONTACTS
export const GET_USERS = '/users'
export const GET_USER_PROFILE = '/user'

export const SAVE_ADDITIONAL_PRODUCT = '/v1/additional-product'
export const VERIFY_SECRETARY_EMAIL = '/api/v1/secretary/account-status?email='
export const RESEND_VERIFICATION_EMAIL =
  '/api/v1/secretary/verficiation-email-resend'
export const GET_SANCTION_SHOW_EVENT =
  '/api/v1/events/sponsor/all-shows-by-event'
export const GET_SANCTION_SHOW_CART = '/api/v1/shows/user-sponsor/shopping-cart'
export const ADD_SANCTION_NUMBER_CART =
  '/api/v1/shows/user-sponsor/shopping-cart/add-to-cart'
export const DEL_SANCTION_SHOPPING_CART =
  '/api/v1/shows/user-sponsor/shopping-cart/delete-from-cart'
export const GET_ALL_YOUR_PARTICIPATION_EVENT_DATES_URL =
  '/api/v1/your-participation/events'
export const GET_ALL_YOUR_PARTICIPATION_EVENT_URL =
  '/api/v1/events/multiple?_sort=event_name&_order=ASC'
export const MANUAL_ENTRY_CHECK_IN_SHEET =
  '/api/v1/participation/secretary/manual/check-in-sheet/single-user'
export const MANUAL_ENTRY_CHECK_IN_SHEET_LOG =
  '/api/v1/participation/secretary/manual/check-in-sheet/single-user-log'

export const GET_TRADITIONAL_PLANE_PAYMENT_INTENT_URL =
  '/api/v1/plan-purchase/renew-plan'
export const TRADITIONAL_PLAN_PAYMENT_RENEW_URL =
  '/api/v1/plan-purchase/process-payment-renew'
