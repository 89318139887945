import React, { useEffect, useMemo } from 'react'
import { Col, Container, Row, Card } from 'reactstrap'

import { profileMenu } from '../../common/data/EasyFiedsNav'
import ProfileCover from './ProfileCover/ProfileCover'
import { useLocation, Link, useParams } from 'react-router-dom'
import _ from 'lodash'
import classNames from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import MyProfile from './MyProfile/MyProfile'
import JudgeList from './Judge/JudgeList'
import JudgeForm from './Judge/JudgeForm'

import EquipmentList from './Equipment/EquipmentList'
import EquipmentForm from './Equipment/EquipmentForm'
import EquipmentImage from './Equipment/EquipmentImage'

import AnimalList from './AnimalList/AnimalList'
import AnimalForm from './AnimalList/AnimalForm'
import AnimalImage from './AnimalList/AnimalImage'

import Inquiry from './Inquiry/Inquiry'
import { easyFidesProfile } from '../../store/actions'
import AnimalInquiry from './AnimalList/AnimalInquiry'
import JudgeInquiry from './Judge/JudgeInquiry'
import EquipmentInquiry from './Equipment/EquipmentInquiry'

const Profile = () => {
  const dispatch = useDispatch()
  const param = useParams()
  const newParam = _.invert(param)

  const path = _.join(
    _.map(_.split(useLocation().pathname, '/'), (p) => {
      return newParam[p] ? `:${newParam[p]}` : p
    }),
    '/'
  )

  //
  //
  const { easyFiedsUser } = useSelector((state) => state.EasyfidesProfile)

  // Profile Menu

  useEffect(() => {
    dispatch(easyFidesProfile())
  }, [])

  //
  const Menu = useMemo(() => {
    let list = []
    if (Array.isArray(profileMenu)) {
      list = profileMenu.map((item) => {
        let sortPath = `/${_.join(
          _.slice(
            _.filter(_.split(path, '/'), (o) => o?.trim()?.length),
            0,
            3
          ),
          '/'
        )}`

        return {
          ...item,
          active: item.url === sortPath,
          show_tab: item.url === path,
        }
      })
      list = list.filter((q) => {
        if (!q.always_show) {
          if (easyFiedsUser[q.menu_abbr]) return q
          return false
        }

        return q
      })
    }

    return list
  }, [path, easyFiedsUser])

  const fullName = useMemo(
    () =>
      easyFiedsUser?.alias_name_easyfied
        ? easyFiedsUser?.alias_name_easyfied
        : `${easyFiedsUser?.last_name || ''}  ${
            easyFiedsUser?.first_name || ''
          }`,
    [
      easyFiedsUser?.alias_name_easyfied,
      easyFiedsUser?.first_name,
      easyFiedsUser?.last_name,
    ]
  )
  return (
    <React.Fragment>
      <div className=''>
        <ProfileCover />
        <section id='profile' className='section1 pages '>
          <Container fluid={true} className='auto-fit'>
            <Row>
              <Col lg={3}>
                <div className='profile-aside mt-n12'>
                  <Card className='mb-4'>
                    <div className='p-5 text-center'>
                      <div className='avatar-xl rounded-circle d-inline-block overflow-hidden'>
                        <img
                          src='https://www.time2enter.com/easyfides/resources/assets/img/logo/logo.png'
                          title=''
                          alt=''
                        />
                      </div>
                      <h2 className='font-w-500 f-name m-0'>
                        <span className='font-w-700  text-black'>
                          {fullName}
                        </span>
                      </h2>
                    </div>
                  </Card>
                  <Card className='mb-4 new-card'>
                    <div className='card-header text-black'>
                      <h6 className='my-2'>
                        My Account{' '}
                        <span className='pull-right'>
                          <Link
                            to=''
                            rel='noopener noreferrer'
                            className='text-green'
                          >
                            Logout
                          </Link>
                        </span>
                        <div className='clearfix'></div>
                      </h6>
                    </div>
                    <div className='list-group list-group-flush left-bar'></div>
                    {Array.isArray(Menu) && Menu.length
                      ? Menu.filter((q) => q.show_menu).map((menu, i) => {
                          return (
                            <Link
                              to={`${menu?.url}`}
                              className={classNames(
                                'list-group-item list-group-item-action d-flex justify-content-between py-3',
                                {
                                  active: menu.active,
                                }
                              )}
                              key={i}
                            >
                              <div>
                                {menu?.icon}
                                <span>{menu?.name || ''} </span>
                              </div>
                              <div>
                                <i className='fa fa-chevron-right'></i>
                              </div>
                            </Link>
                          )
                        })
                      : null}
                  </Card>
                </div>
              </Col>

              {Array.isArray(Menu) && Menu.length
                ? Menu.map((menu, i) => {
                    if (menu.show_tab) {
                      if (menu.abbr === 'my-profile') {
                        return (
                          <Col lg={9}>
                            <MyProfile />
                          </Col>
                        )
                      } else if (menu.abbr === 'judge-registrar') {
                        return (
                          <Col lg={9}>
                            <JudgeList />
                          </Col>
                        )
                      } else if (menu.abbr === 'judge_inquiry') {
                        return (
                          <Col lg={9}>
                            <JudgeInquiry />
                          </Col>
                        )
                      } else if (menu.abbr === 'judge_create') {
                        return (
                          <Col lg={9}>
                            <JudgeForm />
                          </Col>
                        )
                      } else if (menu.abbr === 'judge_update') {
                        return (
                          <Col lg={9}>
                            <JudgeForm />
                          </Col>
                        )
                      } else if (menu.abbr === 'equipment') {
                        return (
                          <Col lg={9}>
                            <EquipmentList />
                          </Col>
                        )
                      } else if (menu.abbr === 'equipments_inquiry') {
                        return (
                          <Col lg={9}>
                            <EquipmentInquiry />
                          </Col>
                        )
                      } else if (menu.abbr === 'equipment_create') {
                        return (
                          <Col lg={9}>
                            <EquipmentForm />
                          </Col>
                        )
                      } else if (menu.abbr === 'equipment_update') {
                        return (
                          <Col lg={9}>
                            <EquipmentForm />
                          </Col>
                        )
                      } else if (menu.abbr === 'equipment_image') {
                        return (
                          <Col lg={9}>
                            <EquipmentImage />
                          </Col>
                        )
                      } else if (menu.abbr === 'my_animal') {
                        return (
                          <Col lg={9}>
                            <AnimalList />
                          </Col>
                        )
                      } else if (menu.abbr === 'my_animal_create') {
                        return (
                          <Col lg={9}>
                            <AnimalForm />
                          </Col>
                        )
                      } else if (menu.abbr === 'my_animal_update') {
                        return (
                          <Col lg={9}>
                            <AnimalForm />
                          </Col>
                        )
                      } else if (menu.abbr === 'my_animal_image') {
                        return (
                          <Col lg={9}>
                            <AnimalImage />
                          </Col>
                        )
                      } else if (menu.abbr === 'my_animal_inquiry') {
                        return (
                          <Col lg={9}>
                            <AnimalInquiry />
                          </Col>
                        )
                      } else if (menu.abbr === 'inquiry') {
                        return (
                          <Col lg={9}>
                            <Inquiry />
                          </Col>
                        )
                      }
                    }
                    return null
                  })
                : null}
            </Row>
          </Container>
        </section>
      </div>
    </React.Fragment>
  )
}

export default Profile
