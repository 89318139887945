import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { Table } from 'react-super-responsive-table'
import { Col, Container, Row } from 'reactstrap'
import SuccessPopup from '../../CommonComponent/successPopup1'
import EventDetail from './EventDetail'
import _ from 'lodash'
import ParticipateUser from './ParticipateUser'
import { UrlEncodeHook } from '../../../hooks'
import { get } from '../../../helpers/api_helper'

const ParticipateEvents = (props) => {
  let { eventId, showId } = useParams()
  const { urlDecode } = UrlEncodeHook()
  eventId = urlDecode(eventId)
  const [event, setEvent] = useState()
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    get(`/api/v1/events/sponsor/all-shows-by-event?event_id=${eventId}`).then(
      (res) => {
        if (res && Array.isArray(res.data)) {
          let obj = _.first(res.data)
          setLoading(false)
          setEvent(obj)
        }
        // setEvents(res.data[0])
      }
    )
  }, [])
 
  return (
    <React.Fragment>
      <div className="page-content bg-gray-101 min-h-80 view-dis">
        <section id="about" className="section1 pages">
          <Container fluid={true} className="auto-fit">
            <Col lg={12}>
              <Row>
                <Col lg={12} className="text-center">
                  <div className="page-title-box justify-content-between">
                    <h2 className="top-title mb-4">
                      <span>YOUR PARTICIPATION</span>
                    </h2>
                  </div>
                </Col>
              </Row>
            </Col>
            <Row>
              <Col lg={12}>
                <div className="table-responsive text-center mb-5">
                  <Table className="table mb-0 cust-tab participate-str">
                    <thead className="table-color">
                      <tr>
                        <th>PARTICIPATED EVENT</th>
                      </tr>
                    </thead>
                  </Table>
                </div>
              </Col>
              <EventDetail event={event} loading={loading} />
              <ParticipateUser event={event} />
              <Col lg={12} className="text-center mt-5">
                <Link to="/your_participation">
                  <button
                    type="button"
                    className="btn btn-primary btn-back w-15 res-w-f"
                    Title="Back"
                  >
                    BACK
                  </button>
                </Link>
              </Col>
            </Row>
          </Container>
        </section>
        <SuccessPopup />
      </div>
    </React.Fragment>
  )
}

export default ParticipateEvents
