import { put, takeEvery } from 'redux-saga/effects'

import {
  GET_ALL_LIVE_EVENT_BREED_AS_FUR_DETAIL,
  GET_ALL_LIVE_EVENT_BREED_DETAIL,
  GET_ALL_LIVE_EVENT_BREED_DETAIL_SUCCESS,
  GET_ALL_LIVE_EVENT_BREED_FUR_LIST,
  GET_ALL_LIVE_EVENT_BREED_FUR_LIST_FOR_EXCEL,
  GET_ALL_LIVE_EVENT_BREED_FUR_LIST_SUCCESS,
  GET_ALL_LIVE_EVENT_LIST,
  GET_ALL_LIVE_EVENT_LIST_SUCCESS,
  GET_ALL_LIVE_EVENT_SHOW_LIST,
  GET_ALL_LIVE_EVENT_SHOW_LIST_SUCCESS,
  LIVE_EVENT_EXHIBITOR_LIST,
  LIVE_EVENT_EXHIBITOR_LIST_FOR_EXCEL,
  LIVE_EVENT_EXHIBITOR_LIST_SUCCESS,
  LIVE_EVENT_PARTICIPATION_LIST_SUCCESS,
} from './actionTypes'

import { toast } from 'react-toastify'
import { get, post } from '../../helpers/api_helper'
import _ from 'lodash'

function* getAllLiveEventListSagas({ payload }) {
  try {
    const res = yield post('/api/v1/premier-report/premier-event-list/all')

    yield put({
      type: GET_ALL_LIVE_EVENT_LIST_SUCCESS,
      payload: res.data || [],
    })
  } catch (err) {
    if (err?.response?.data) {
      toast.error(err?.response?.data)
    }
  }
}

function* getAllLiveEventShowListSagas({ payload }) {
  const { eventId } = payload

  try {
    const res = yield post(
      `/api/v1/premier-report/premier-event-list/show/${eventId}`,
      null,
      { params: { _fields: 'shows.*,ctrl_sheet_option.option_name' } }
    )

    const result = _.orderBy(
      res.data,
      ['show_type', 'show_char'],
      ['asc', 'asc']
    )
    yield put({
      type: GET_ALL_LIVE_EVENT_SHOW_LIST_SUCCESS,
      payload: result || [],
    })
  } catch (err) {
    if (err?.response?.data) {
      toast.error(err?.response?.data)
    }
  }
}

function* getAllLiveEventBreedListSagas({ payload }) {
  const { params } = payload

  try {
    const res = yield get(
      `/api/v1/ctrl-sheet/${params?.show_id || ''}/breed-fur-list`,
      { params: _.omit(params, ['show_id']) }
    )
    const resAlreadyAddedBreed = yield post(
      `/api/v1/premier-report-ctrl-sheet/get-breed-list/${
        params?.show_id || ''
      }`
    )

    const alreadyAddedBreed = [
      ...(resAlreadyAddedBreed?.participation_breed || []),
      ...(resAlreadyAddedBreed?.participation_fur || []),
    ]

    const result = _.filter(res.data, (f) => {
      if (_.has(f, 'breed_id')) {
        const find = _.find(alreadyAddedBreed, (q) => q.breed_id === f.breed_id)

        return Boolean(find)
      }
      if (_.has(f, 'fur_type_id')) {
        const find = _.find(
          alreadyAddedBreed,
          (q) => q.fur_type_id === f.fur_type_id
        )

        return Boolean(find)
      }
      return false
    })
    // const
    yield put({
      type: GET_ALL_LIVE_EVENT_BREED_FUR_LIST_SUCCESS,
      payload: result,
    })
  } catch (err) {
    if (err?.response?.data) {
      toast.error(err?.response?.data)
    }
  }
}
function* getAllLiveEventBreedListForExcelSagas({ payload }) {
  const { params } = payload

  try {
    const res = yield get(
      `/api/v1/ctrl-sheet/${params?.show_id || ''}/breed-fur-list`,
      { params: _.omit(params, ['show_id']) }
    )
    const resAlreadyAddedBreed = yield post(
      `/api/v1/premier-report-ctrl-sheet-dump/get-breed-list/${
        params?.show_id || ''
      }`
    )

    const alreadyAddedBreed = [
      ...(resAlreadyAddedBreed?.participation_breed || []),
      ...(resAlreadyAddedBreed?.participation_fur || []),
    ]

    const result = _.filter(res.data, (f) => {
      if (_.has(f, 'breed_id')) {
        const find = _.find(alreadyAddedBreed, (q) => q.breed_id === f.breed_id)

        return Boolean(find)
      }
      if (_.has(f, 'fur_type_id')) {
        const find = _.find(
          alreadyAddedBreed,
          (q) => q.fur_type_id === f.fur_type_id
        )

        return Boolean(find)
      }
      return false
    })
    // const
    yield put({
      type: GET_ALL_LIVE_EVENT_BREED_FUR_LIST_SUCCESS,
      payload: result,
    })
  } catch (err) {
    if (err?.response?.data) {
      toast.error(err?.response?.data)
    }
  }
}

function* getLiveEventBreedDetailSagas({ payload }) {
  const { params, body } = payload

  try {
    const res = yield get(
      `/api/v1/ctrl-sheet/${params?.show_id || ''}/breed-details/${
        params?.breed_id || ''
      }`,
      { params: _.omit(params, ['show_id', 'breed_id']) }
    )

    if (params?.sheet_option === 'excel') {
      const getList = yield post(
        `/api/v1/premier-report-ctrl-sheet-dump/get-list/${
          params?.show_id || ''
        }`,
        body,
        { params: _.omit(params, ['show_id', 'breed_id']) }
      )

      yield put({
        type: LIVE_EVENT_PARTICIPATION_LIST_SUCCESS,
        payload: _.sortBy(
          _.orderBy(
            _.flatten(
              _.map(
                {
                  ...(getList?.participation_breed || {}),
                  ...(getList?.participation_fur || {}),
                },
                (val) => val
              )
            ),
            [(s) => _.lowerCase(s.placing)],
            ['asc']
          ),
          (obj) => {
            return Number(obj.placing)
          }
        ),
      })
    }
    if (params?.sheet_option === 'generic') {
      const getList = yield post(
        `/api/v1/premier-report-ctrl-sheet/get-list/${params?.show_id || ''}`,
        body,
        { params: _.omit(params, ['show_id', 'breed_id']) }
      )
      yield put({
        type: LIVE_EVENT_PARTICIPATION_LIST_SUCCESS,
        payload: _.sortBy(
          _.orderBy(
            [
              ...(getList?.participation_breed || []),
              ...(getList?.participation_fur || []),
            ],
            [(s) => _.lowerCase(s.placing)],
            ['asc']
          ),
          (obj) => {
            return Number(obj.placing)
          }
        ),
      })
    }

    yield put({
      type: GET_ALL_LIVE_EVENT_BREED_DETAIL_SUCCESS,
      payload: res.data,
    })
  } catch (err) {
    if (err?.response?.data) {
      toast.error(err?.response?.data)
    }
  }
}

function* getLiveEventBreedAsFurDetailSagas({ payload }) {
  const { params } = payload

  try {
    const res = yield get(
      `/api/v1/ctrl-sheet/${params?.show_id || ''}/fur-as-breed-details/${
        params?.fur_type_id || ''
      }`,
      { params: _.omit(params, ['show_id', 'fur_type_id']) }
    )

    if (params?.sheet_option === 'generic') {
      const body = {
        breed_id: null,
        judging_level: 'fur',
        fur_type_id: params?.fur_type_id,
      }
      let getList = yield post(
        `/api/v1/premier-report-ctrl-sheet/get-list/${params?.show_id || ''}`,
        body,
        { params: _.omit(params, ['show_id', 'fur_type_id']) }
      )

      yield put({
        type: LIVE_EVENT_PARTICIPATION_LIST_SUCCESS,
        payload: _.sortBy(
          _.orderBy(
            [
              ...(getList?.participation_breed || []),
              ...(getList?.participation_fur || []),
              ...(getList?.participation_breed_fur || []),
            ],
            [(s) => _.lowerCase(s.placing)],
            ['asc']
          ),
          (obj) => {
            return Number(obj.placing)
          }
        ),
      })
    }

    if (params?.sheet_option === 'excel') {
      const body = {
        breed_id: null,
        judging_level: 'fur',
        fur_type_id: params?.fur_type_id,
      }
      let getList = yield post(
        `/api/v1/premier-report-ctrl-sheet-dump/get-list/${
          params?.show_id || ''
        }`,
        body,
        { params: _.omit(params, ['show_id', 'fur_type_id']) }
      )

      yield put({
        type: LIVE_EVENT_PARTICIPATION_LIST_SUCCESS,
        payload: _.sortBy(
          _.orderBy(
            _.flatten(
              _.map(
                {
                  ...(getList?.participation_breed || {}),
                  ...(getList?.participation_fur || {}),
                  ...(getList?.participation_breed_fur || {}),
                },
                (val) => val
              )
            ),
            [(s) => _.lowerCase(s.placing)],
            ['asc']
          ),
          (obj) => {
            return Number(obj.placing)
          }
        ),
      })

  
    }

    yield put({
      type: GET_ALL_LIVE_EVENT_BREED_DETAIL_SUCCESS,
      payload: res.data,
    })
  } catch (err) {
    if (err?.response?.data) {
      toast.error(err?.response?.data)
    }
  }
}

function* getLiveEventExhibitorListSagas({ payload }) {
  let { params, body } = payload
  try {
    const placingData = yield post(
      `/api/v1/premier-report-ctrl-sheet/exh-list/${params?.show_id || ''}`,
      body,
      { params: _.omit(params, ['show_id']) }
    )

    if (body.placing_type === 'fur' && !placingData) {
      body = {
        ...body,
        fur_to_class: 1,
      }
      const resPlacingData = yield post(
        `/api/v1/premier-report-ctrl-sheet/exh-list/${params?.show_id || ''}`,
        body,
        { params: _.omit(params, ['show_id']) }
      )
      yield put({
        type: LIVE_EVENT_EXHIBITOR_LIST_SUCCESS,
        payload: resPlacingData?.data || [],
      })
    } else {
      yield put({
        type: LIVE_EVENT_EXHIBITOR_LIST_SUCCESS,
        payload: placingData?.data || [],
      })
    }
  } catch (err) {
    if (err?.response?.data) {
      toast.error(err?.response?.data)
    }
  }
}
function* LiveEventExhibitorListForExcelSagas({ payload }) {
  let { params, body } = payload
  try {
    const placingData = yield post(
      `/api/v1/premier-report-ctrl-sheet-dump/exh-list/${
        params?.show_id || ''
      }`,
      body,
      { params: _.omit(params, ['show_id']) }
    )

    if (body.placing_type === 'fur' && !placingData) {
      body = {
        ...body,
        fur_to_class: 1,
      }
      const resPlacingData = yield post(
        `/api/v1/premier-report-ctrl-sheet/exh-list/${params?.show_id || ''}`,
        body,
        { params: _.omit(params, ['show_id']) }
      )
      yield put({
        type: LIVE_EVENT_EXHIBITOR_LIST_SUCCESS,
        payload: resPlacingData?.data || [],
      })
    } else {
      yield put({
        type: LIVE_EVENT_EXHIBITOR_LIST_SUCCESS,
        payload: placingData?.data || [],
      })
    }
  } catch (err) {
    if (err?.response?.data) {
      toast.error(err?.response?.data)
    }
  }
}
function* StatesSagas() {
  yield takeEvery(GET_ALL_LIVE_EVENT_LIST, getAllLiveEventListSagas)
  yield takeEvery(GET_ALL_LIVE_EVENT_SHOW_LIST, getAllLiveEventShowListSagas)
  yield takeEvery(
    GET_ALL_LIVE_EVENT_BREED_FUR_LIST,
    getAllLiveEventBreedListSagas
  )
  yield takeEvery(GET_ALL_LIVE_EVENT_BREED_DETAIL, getLiveEventBreedDetailSagas)
  yield takeEvery(
    GET_ALL_LIVE_EVENT_BREED_AS_FUR_DETAIL,
    getLiveEventBreedAsFurDetailSagas
  )
  yield takeEvery(LIVE_EVENT_EXHIBITOR_LIST, getLiveEventExhibitorListSagas)
  yield takeEvery(
    GET_ALL_LIVE_EVENT_BREED_FUR_LIST_FOR_EXCEL,
    getAllLiveEventBreedListForExcelSagas
  )
  yield takeEvery(
    LIVE_EVENT_EXHIBITOR_LIST_FOR_EXCEL,
    LiveEventExhibitorListForExcelSagas
  )
}

export default StatesSagas
