import React from "react";
import { Container, Row, Col } from 'reactstrap';
const Dashboard = () => {
  return (
    <React.Fragment>
    <div className='page-content bg-gray-101' style={{ minHeight: '85vh' }}>
            <section className='section1 bg-gray-101 pages event-list'>
              <Container fluid={true} className='auto-fit'>
                <Row>
                  <Col lg={12} className='text-center'>
                    <div className='page-title-box justify-content-between'>
                      <h2 className='mb-4'> DASHBOARD</h2>
                    </div>
                  </Col>
                </Row>
              </Container>
            </section>
          </div>
    </React.Fragment>
  );
};

export default Dashboard;
