import classNames from 'classnames'
import React, { useEffect, useMemo, useState } from 'react'
import { Button, Col, Collapse, Row, Spinner } from 'reactstrap'
import BreedDetail from '../BreedDetail/BreedDetail'
import { useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'
import {
  getAllLiveEventBreedList,
  getAllLiveEventBreedListForExcel,
  LiveEventBreedTabClose,
  LiveEventBreedTabOpen,
  LiveEventReload,
} from '../../../../store/actions'

const BreedList = ({ eventId, showId }) => {
  const dispatch = useDispatch()

  ////////////////////////////////
  const {
    showList,
    eventList,
    breedList,
    breedLoading,
    reloadData,
    activeTabs,
    eventLoading,
    showEventLoading,
    breedDetailLoading,
    exhibitorDataLoading,
    participationDataLoading,
  } = useSelector((state) => ({
    eventList: state?.LiveEvent?.eventData?.eventList || [],
    showList: state?.LiveEvent?.showData?.showList || [],
    breedList: state?.LiveEvent?.breedListData?.breedList || [],
    breedLoading: state?.LiveEvent?.breedListData?.loading,
    activeTabs: state?.LiveEvent?.activeTabs || [],
    reloadData: state?.LiveEvent?.reloadData,
    eventLoading: state?.LiveEvent?.eventData?.loading,
    showEventLoading: state?.LiveEvent?.showData?.loading,
    breedDetailLoading: state?.LiveEvent?.breedData?.loading,
    exhibitorDataLoading: state?.LiveEvent?.exhibitorData?.loading,
    exhibitorDataLoading: state?.LiveEvent?.exhibitorData?.loading,
    participationDataLoading: state?.LiveEvent?.participationData?.loading,
  }))
  ////////////////////////////////

  // const [breedData, setBreedData] = useState()

  const showAndEvent = useMemo(() => {
    if (showId && eventId) {
      const findShow = _.find(
        showList,
        (show) => Number(show.show_id) === Number(showId)
      )
      const findEvent = _.find(
        eventList,
        (event) => Number(event.event_id) === Number(eventId)
      )
      return {
        showData: findShow,
        eventData: findEvent,
      }
    }
  }, [showId, eventId, showList, eventList])
  ////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (
      showAndEvent?.showData?.show_id &&
      showAndEvent?.eventData?.event_id &&
      showAndEvent.showData?.option_name === 'generic'
    ) {
      const data = {
        params: {
          event_type: showAndEvent?.eventData?.event_type,
          judging_order_id: showAndEvent?.showData?.judging_order_id,
          sheet_option: showAndEvent?.showData?.sheet_option,
          show_id: showAndEvent?.showData?.show_id,
        },
      }

      dispatch(getAllLiveEventBreedList(data))
    }
    //

    if (
      showAndEvent?.showData?.show_id &&
      showAndEvent?.eventData?.event_id &&
      showAndEvent?.showData?.option_name === 'excel'
    ) {
      const data = {
        params: {
          event_type: showAndEvent?.eventData?.event_type,
          judging_order_id: showAndEvent?.showData?.judging_order_id,
          sheet_option: showAndEvent?.showData?.sheet_option,
          show_id: showAndEvent?.showData?.show_id,
        },
      }

      dispatch(getAllLiveEventBreedListForExcel(data))
    }
  }, [
    showAndEvent?.showData?.show_id,
    showAndEvent?.eventData?.event_id,
    showAndEvent?.showData?.sheet_options,
    reloadData,
  ])

  ////////////////////////////////////////////////////////////////

  const disableRefreshButton = useMemo(() => {
    if (_.size(activeTabs)) {
      if (
        !breedDetailLoading &&
        !exhibitorDataLoading &&
        !participationDataLoading &&
        !eventLoading &&
        !showEventLoading &&
        !breedLoading
      ) {
        return false
      }
    } else if (!eventLoading && !showEventLoading && !breedLoading) {
      return false
    }
    return true
  }, [
    activeTabs,
    breedDetailLoading,
    breedLoading,
    eventLoading,
    exhibitorDataLoading,
    participationDataLoading,
    showEventLoading,
  ])
  return (
    <Row>
      <Col md='12' className='mt-3 text-end'>
        <Button
          size='sm'
          disabled={disableRefreshButton}
          onClick={() => {
            dispatch(LiveEventReload())
          }}
        >
          REFRESH
        </Button>
      </Col>
      <Col md='12' className='mt-3'>
        {!breedLoading && _.size(breedList) ? (
          breedList.map((breed) => {
            let activeCol = activeTabs.find((x) =>
              _.isMatch(
                x,
                breed?.record_type === 'breed'
                  ? { type: breed?.record_type, breed_id: breed?.breed_id }
                  : {
                      type: breed?.record_type,
                      fur_type_id: breed?.fur_type_id,
                    }
              )
            )
            return (
              <div
                className='accordion-item mb-3 front-acc'
                key={
                  breed?.record_type === 'breed'
                    ? `${breed?.breed_id}-${breed?.breed_abbre}`
                    : `${breed?.fur_type_id}-${breed?.fur_type_abbre}`
                }
              >
                <h2
                  className='accordion-header acc-tab front-acc-tab'
                  id='flush-headingFour'
                >
                  <button
                    className={classNames(
                      'accordion-button bg-primary1 text-white fs-5',
                      {
                        openArrow: Boolean(activeCol),
                        closeArrow: !Boolean(activeCol),
                      }
                    )}
                    type='button'
                    onClick={() => {
                      let obj = {}
                      if (breed?.record_type === 'breed') {
                        obj = {
                          ...defaultObj,
                          type: breed?.record_type,
                          breed_id: breed.breed_id,
                        }
                      } else if (breed?.record_type === 'fur_as_breed') {
                        obj = {
                          ...defaultObj,
                          type: breed?.record_type,
                          fur_type_id: breed.fur_type_id,
                        }
                      }
                      !activeCol
                        ? dispatch(LiveEventBreedTabOpen(obj))
                        : dispatch(LiveEventBreedTabClose(obj))
                    }}
                    style={{ cursor: 'pointer' }}
                  >
                    {breed?.record_type === 'breed'
                      ? breed?.breed_name || ''
                      : null}
                    {breed?.record_type === 'fur_as_breed'
                      ? breed?.fur_type_name || ''
                      : null}
                  </button>
                </h2>
                <Collapse
                  id='flush-headingFour'
                  className='accordion-collapse'
                  isOpen={activeCol}
                >
                  <div className='accordion-body box-acc list-data'>
                    <Row>
                      <Col xl='12'>
                        <BreedDetail
                          breedData={activeCol}
                          breed={breed}
                          showAndEvent={showAndEvent}
                        />
                      </Col>
                    </Row>
                  </div>
                </Collapse>
              </div>
            )
          })
        ) : breedLoading ? (
          <div className='text-center'>
            <Spinner />
          </div>
        ) : !_.size(breedList) ? (
          <h5 className='text-center'>No breed found</h5>
        ) : null}
      </Col>
    </Row>
  )
}

export default BreedList

const defaultObj = {
  breed_id: undefined,
  group_id: undefined,
  variety_id: undefined,
  class_id: undefined,
  fur_type_id: undefined,
  fur_id: undefined,
}
