import classNames from 'classnames'
import React, { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  LiveEventBreedTabClose,
  LiveEventBreedTabOpen,
} from '../../../../../store/actions'
import { Col, Collapse, Row } from 'reactstrap'
import Placing from '../Placing/Placing'

const Class = ({ breed, recordType }) => {
  const dispatch = useDispatch()
  const { activeTabs } = useSelector((state) => ({
    activeTabs: state?.LiveEvent?.activeTabs || [],
  }))
  const activeCol = useMemo(() => {
    let find = false
    if (breed?.class_id) {
      find = activeTabs.find(
        (x) =>
          x.type === 'class' &&
          x?.class_id === breed?.judging_data?.class_id &&
          x?.group_id === breed?.judging_data?.group_id &&
          x?.breed_id === breed?.judging_data?.breed_id &&
          x?.variety_id === breed?.judging_data?.variety_id
      )
    }
    return Boolean(find)
  }, [breed?.class_id, activeTabs])

  return (
    <div className='accordion-item mb-3 front-acc '>
      <h2
        className='accordion-header acc-tab front-acc-tab fs-btn'
        id='flush-headingFour'
      >
        <button
          className={classNames(
            'accordion-button bg-primary1 text-white fs-6',
            {
              openArrow: activeCol,
              closeArrow: !activeCol,
            }
          )}
          type='button'
          onClick={() => {
            !activeCol
              ? dispatch(
                  LiveEventBreedTabOpen({
                    ...defaultObj,
                    type: 'class',
                    ...breed.judging_data,
                    // class_id: breed?.class_id,
                    // breed_id: breed?.breed_id,
                  })
                )
              : dispatch(
                  LiveEventBreedTabClose({
                    ...defaultObj,
                    type: 'class',
                    ...breed.judging_data,
                    // class_id: breed?.class_id,
                    // breed_id: breed?.breed_id,
                  })
                )
          }}
          style={{ cursor: 'pointer' }}
        >
          <span>{breed?.class_name}</span>
        </button>
      </h2>
      <Collapse
        id='flush-headingFour'
        className='accordion-collapse'
        isOpen={activeCol}
      >
        <div className='accordion-body box-acc list-data'>
          <Row>
            <Col xl='12'>
              <Placing
                tabType='class'
                openActiveTab={activeCol}
                recordType={recordType}
              />
            </Col>
          </Row>
        </div>
      </Collapse>
    </div>
  )
}

export default Class

const defaultObj = {
  breed_id: undefined,
  group_id: undefined,
  variety_id: undefined,
  class_id: undefined,
  fur_type_id: undefined,
  fur_id: undefined,
}
