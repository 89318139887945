import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Container, Spinner } from 'reactstrap'
import { useHistory } from 'react-router-dom'

import { get, post, put, del, patch } from '../../../helpers/api_helper'
import {
  setShowAlert,
  setMsg,
  setMessageTitle,
} from '../../../store/successpopup/actions'
import SuccessPopup from '../../CommonComponent/successPopup1'
import SelectInput from '../../../components/Common/SelectInput'
export const UserProfile = () => {
  const dispatch = useDispatch()

  const [userData, setUserData] = useState({})
  const [allCountry, setAllCountry] = useState([])
  const [allState, setAllState] = useState([])
  let [backendErr, setBackendErr] = useState({})
  const [submitLoading, setSubmitLoading] = useState(false)
  const [reload, setReload] = useState(true)
  const [inputs, setInputs] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    password: '',
    address1: '',
    address2: '',
    country: '',
    state: '',
    city: '',
    zipcode: '',
    arbaNo: '',
  })
  const [errors, setErrors] = useState({
    email: null,
    firstName: null,
    lastName: null,
    password: null,
    confirmPassword: null,
    address1: null,
    country: null,
    state: null,
    city: null,
    zipcode: null,
    phone: null,
    arbaNo: null,
    check: null,
  })
  const [errorsMsg, setErrorsMsg] = useState({})
  const history = useHistory()
  useEffect(() => {
    //alert(1);
    get(`/api/v1/address/country/all`)
      .then((data) => {
        setAllCountry(data.data)
      })
      .catch((error) => {
        console.log(error)
      })
    get(`/api/v1/profile/profile_details`)
      .then((data) => {
        console.log(data.data)
        setUserData(data.data)
        let userData = data.data
        let phone = `${userData.phone.substring(
          0,
          3
        )}-${userData.phone.substring(3, 6)}-${userData.phone.substring(
          6,
          userData.phone.length
        )}`
        setInputs({
          firstName: userData.first_name,
          lastName: userData.last_name,
          email: userData.email,
          phone: phone,
          user_role: userData.user_role,
          id: userData.id,
          arbaNo: userData.arba_number,
          address1: userData.address[0].address_1,
          address2: userData.address[0].address_2,
          country: userData.address[0].country_id,
          state: userData.address[0].state_id,
          city: userData.address[0].city,
          zipcode: userData.address[0].zipcode,
        })
        setReload(false)
        get(
          `/api/v1/address/state/all?country_id=${userData.address[0].country_id}&_order=asc&_sort=state_code`
        )
          .then((data) => {
            setAllState(data.data)
          })
          .catch((error) => {
            console.log(error)
            setReload(false)
          })
      })
      .catch((error) => {
        console.log(error)
      })
  }, [])

  const handleChange = (event) => {
    let name = event.target.name
    let value = event.target.value
    if (name === 'phone') {
      let newvalue = value.replace(/-/gm, '') //value.split("-").join("");
      //alert(newvalue);
      if (newvalue.length > 3) {
        value = `${newvalue.substring(0, 3)}-${newvalue.substring(
          3,
          6
        )}-${newvalue.substring(6, 10)}`
        value = value.replace(/^-|-$/g, '')
      }
    }
    setInputs((values) => ({ ...values, [name]: value }))
  }

  let handleCountryChange = () => {
    setAllState([])
    let d = document.getElementById('country').value
    setInputs((values) => ({
      ...values,
      ['country']: d == 'Select Country' ? '' : d,
    }))
    setInputs((values) => ({ ...values, ['state']: '' }))
    setInputs((values) => ({ ...values, ['city']: '' }))
    setInputs((values) => ({ ...values, ['zipcode']: '' }))

    get(`/api/v1/address/state/all?country_id=${d}&_order=asc&_sort=state_code`)
      .then((data) => {
        setAllState(data.data)
      })
      .catch((error) => {
        console.log(error)
      })

    //alert(d);
  }

  let handleStateChange = () => {
    let d = document.getElementById('state').value
    setInputs((values) => ({ ...values, ['state']: d }))
    setInputs((values) => ({ ...values, ['city']: '' }))
    setInputs((values) => ({ ...values, ['zipcode']: '' }))
  }
  const emailRegex = new RegExp('^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$')

  const handleReg = (e) => {
    let validation = true
    e.preventDefault()
    setBackendErr({})
    let err = {
      email: null,
    }

    let onlyCharExpr = /^[a-zA-Z \-]+$/g
    let charRegex = new RegExp(onlyCharExpr)

    if (inputs.firstName.trim().length === 0) {
      validation = false
      err.firstName = 'First name is required'
    } else if (inputs.firstName.trim().length > 50) {
      err.firstName =
        'First name must be of minimum 1 and maximum 50 character length'
      validation = false
    } else {
      if (!inputs.firstName.trim().match(charRegex)) {
        err.firstName = 'First name field must be alphabetic'
        validation = false
      }
    }

    if (inputs.lastName.trim().length === 0) {
      validation = false
      err.lastName = 'Last name is required'
    } else if (inputs.lastName.trim().length > 50) {
      err.lastName =
        'Last name must be of minimum 1 and maximum 50 character length'
      validation = false
    } else {
      if (!inputs.lastName.trim().match(charRegex)) {
        err.lastName = 'Last name field must be alphabetic'
        validation = false
      }
    }

    if (!inputs.email || !emailRegex.test(inputs.email)) {
      validation = false
      err.email = true
    }
    if (!inputs.address1.trim()) {
      validation = false
      err.address1 = 'Address 1 field is required'
    }
    if (!inputs.country) {
      validation = false
      err.country = 'Country field is required'
    }
    if (!inputs.state) {
      validation = false
      err.state = 'State field is required'
    }

    if (inputs.city.trim().length === 0) {
      validation = false
      err.city = 'City field is required'
    } else if (inputs.city.trim().length > 50) {
      err.city =
        'City field must be of minimum 1 and maximum 50 character length'
      validation = false
    } else {
      if (!inputs.city.trim().match(charRegex)) {
        err.city = 'City field must be alphabetic'
        validation = false
      }
    }
    if (inputs.zipcode.trim().length === 0) {
      validation = false
      err.zipcode = 'Zipcode field is required'
    }
    if (!inputs.phone) {
      validation = false
      err.phone = 'Phone number field is required'
    }
    if (inputs.phone.replace(/[^0-9]/g, '').length !== 10) {
      validation = false
      err.phone = 'Phone number must be 10 digits'
    }

    // console.log('err=', err)
    setErrors(err)
    if (validation) {
      setSubmitLoading(true)
      patch(`/api/v1/profile`, {
        first_name: inputs.firstName,
        last_name: inputs.lastName,
        phone: inputs.phone.replace(/-/gm, ''),
        user_address_id: userData.address[0].user_address_id,
        address_1: inputs.address1,
        address_2: inputs.address2,
        country_id: inputs.country,
        state_id: inputs.state,
        city: inputs.city,
        zipcode: inputs.zipcode,
        arba_number: inputs.arbaNo,
      })
        .then((response) => {
          let newUser = JSON.parse(localStorage.getItem('authUser'))
          let newObj = {
            id: newUser.id,
            role: newUser.role,
            first_name: inputs.firstName,
            last_name: inputs.lastName,
            email: inputs.email,
          }
          localStorage.setItem('authUser', JSON.stringify(newObj))
          dispatch(setShowAlert(true))
          dispatch(setMsg(response))
          setTimeout(() => {
            dispatch(setShowAlert(false))
            dispatch(setMsg(''))
          }, 3600)
          setTimeout(() => {
            setSubmitLoading(false)
            history.push({ pathname: '/auth-events' })
          }, 2100)
        })
        .catch((error) => {
          console.log(error.response.data)
          setSubmitLoading(false)
          if (Array.isArray(error.response.data)) {
            let phoneNumber = error.response.data.find(
              (s) => s.param === 'phone'
            )
            let zipcode = error.response.data.find((s) => s.param === 'zipcode')
            setErrors((values) => ({
              ...values,
              phone: phoneNumber?.msg || '',
              zipcode: zipcode?.msg || '',
            }))
          }
        })
    }
  }
  return (
    <React.Fragment>
      <div className='page-content-auth mt'>
        <Container fluid={true} className='auto-fit'>
          <div className='row'>
            <div className='text-left col-lg-12'>
              <div className='page-title-box text-center'>
                <h2 className='top-title mb-4'>
                  <span>UPDATE PROFILE</span>
                </h2>
              </div>
            </div>
          </div>
          {!reload ? (
            <>
              <div className='row'>
                <div className='col-lg-12'>
                  <form>
                    <div className='row'>
                      <div className='col-xl-6'>
                        <div className='mb-3'>
                          <label for='' className='form-label'>
                            First Name *
                          </label>
                          <input
                            type='text'
                            name='firstName'
                            value={inputs.firstName}
                            onChange={handleChange}
                            className='form-control'
                            style={{ borderColor: 'black' }}
                            autoFocus
                            placeholder='First Name'
                          />
                          {errors.firstName && (
                            <p style={{ color: 'red' }}>{errors.firstName}</p>
                          )}
                        </div>
                      </div>
                      <div className='col-xl-6'>
                        <div className='mb-3'>
                          <label for='' className='form-label'>
                            Last Name *
                          </label>
                          <input
                            type='text'
                            name='lastName'
                            value={inputs.lastName || ''}
                            onChange={handleChange}
                            className='form-control'
                            style={{ borderColor: 'black' }}
                            placeholder='Last Name'
                          />
                          {errors.lastName && (
                            <p style={{ color: 'red' }}>{errors.lastName}</p>
                          )}
                        </div>
                      </div>
                      <div className='col-xl-12'>
                        <div className='mb-3'>
                          <label for='' className='form-label'>
                            User Email *
                          </label>
                          <input
                            type='email'
                            name='email'
                            value={inputs.email || ''}
                            onChange={handleChange}
                            className='form-control'
                            style={{ borderColor: 'black' }}
                            placeholder='User Email'
                            disabled
                          />
                          {/* {backendErr.email && backendErr.email != 'VERIFIED' && (
                      <p style={{ color: 'red' }}>{backendErr.email}</p>
                    )}
                    {backendErr.email && backendErr.email == 'VERIFIED' && (
                      <p style={{ color: 'red' }}>
                        Email already exist{' '}
                        <span
                          style={{ color: '#6a93ff', cursor: 'pointer' }}
                          onClick={() => {
                            history.push({ pathname: '/login' })
                          }}>
                          Click Here
                        </span>{' '}
                        to login
                      </p>
                    )} */}
                          {/* {errors.email && msg != "" && (
                            <p style={{ color: "red" }}>{msg}</p>
                          )} */}
                        </div>
                      </div>

                      <div className='col-xl-12'>
                        <div className='mb-3'>
                          <label for='' className='form-label'>
                            Address 1 *
                          </label>
                          <input
                            type='text'
                            name='address1'
                            className='form-control'
                            value={inputs.address1 || ''}
                            onChange={handleChange}
                            style={{ borderColor: 'black' }}
                            placeholder='Address 1'
                          />
                          {errors.address1 && (
                            <p style={{ color: 'red' }}>{errors.address1}</p>
                          )}
                        </div>
                      </div>
                      <div className='col-xl-12'>
                        <div className='mb-3'>
                          <label for='' className='form-label'>
                            Address 2{' '}
                          </label>
                          <input
                            type='text'
                            name='address2'
                            className='form-control'
                            value={inputs.address2 || ''}
                            onChange={handleChange}
                            style={{ borderColor: 'black' }}
                            placeholder='Address 2'
                          />
                        </div>
                      </div>
                      <div className='col-xl-4'>
                        <div className='mb-3'>
                          <label
                            for='formrow-firstname-input'
                            className='form-label'
                          >
                            Country *
                          </label>
                          <select
                            type='text'
                            name='country'
                            id='country'
                            className='form-control form-select'
                            value={inputs.country || ''}
                            onChange={(e) => {
                              //handleChange();
                              handleCountryChange()
                            }}
                            style={{ borderColor: 'black' }}
                          >
                            <option>Select Country</option>
                            {allCountry.map((country) => {
                              return (
                                <option
                                  value={country.country_id}
                                  selected={
                                    country.country_id == inputs.country_id
                                  }
                                >
                                  {country.country_name}
                                </option>
                              )
                            })}
                          </select>
                          {errors.country && (
                            <p style={{ color: 'red' }}>{errors.country}</p>
                          )}
                        </div>
                      </div>
                      <div className='col-xl-4'>
                        <div className='mb-3'>
                          <label
                            for='formrow-firstname-input'
                            className='form-label'
                          >
                            State *
                          </label>

                          <select
                            type='text'
                            name='state'
                            id='state'
                            className='form-control form-select'
                            value={inputs.state}
                            onChange={(e) => {
                              //handleChange();
                              handleStateChange()
                            }}
                            style={{ borderColor: 'black' }}
                          >
                            <option value=''>Select State</option>
                            {allState.map((state) => {
                              return (
                                <option
                                  value={state.state_id}
                                  selected={inputs.state == state.state_id}
                                >
                                  {state.state_code}
                                </option>
                              )
                            })}
                          </select>
                          {errors.state && (
                            <p style={{ color: 'red' }}>{errors.state}</p>
                          )}
                        </div>
                      </div>
                      <div className='col-xl-4'>
                        <div className='mb-3'>
                          <label
                            for='formrow-firstname-input'
                            className='form-label'
                          >
                            City *
                          </label>
                          <input
                            type='text'
                            name='city'
                            value={inputs.city || ''}
                            onChange={handleChange}
                            className='form-control'
                            style={{ borderColor: 'black' }}
                            placeholder='City'
                          />
                          {errors.city && (
                            <p style={{ color: 'red' }}>{errors.city}</p>
                          )}
                        </div>
                      </div>
                      <div className='col-xl-4'>
                        <div className='mb-3'>
                          <label for='' className='form-label'>
                            Zipcode *
                          </label>
                          <input
                            type='text'
                            name='zipcode'
                            className='form-control'
                            value={inputs.zipcode || ''}
                            onChange={handleChange}
                            style={{ borderColor: 'black' }}
                            placeholder='Zipcode'
                          />
                          {errors.zipcode && (
                            <p style={{ color: 'red' }}>{errors.zipcode}</p>
                          )}
                        </div>
                      </div>
                      <div className='col-xl-4'>
                        <div className='mb-3'>
                          <label for='' className='form-label'>
                            Phone *
                          </label>
                          <input
                            type='text'
                            name='phone'
                            value={inputs.phone || ''}
                            onChange={handleChange}
                            className='form-control'
                            style={{ borderColor: 'black' }}
                            placeholder='Phone'
                          />
                          {errors.phone && (
                            <p style={{ color: 'red' }}>{errors.phone}</p>
                          )}
                        </div>
                      </div>
                      <div className='col-xl-4'>
                        <div className='mb-3'>
                          <label for='' className='form-label'>
                            ARBA No.
                          </label>
                          <input
                            type='text'
                            name='arbaNo'
                            value={inputs.arbaNo || ''}
                            onChange={handleChange}
                            className='form-control'
                            style={{ borderColor: 'black' }}
                            placeholder='ARBA No'
                          />
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div className='row'>
                <div className='col-lg-12'>
                  <div className='mt-4 text-center'>
                    <button
                      className='w-lg waves-effect waves-light btn btn-success'
                      onClick={handleReg}
                      disabled={submitLoading}
                    >
                      SUBMIT
                    </button>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div className='row'>
              <div className='col-lg-12 text-center'>
                <Spinner />
              </div>
            </div>
          )}
        </Container>
        <SuccessPopup />
      </div>
    </React.Fragment>
  )
}

export default UserProfile
