import React from 'react'
import { Badge, Button } from 'reactstrap'
import { showAndClosePedigreeModal } from '../../../../store/actions'
import { useDispatch } from 'react-redux'
import _ from 'lodash'
import classNames from 'classnames'

const RabbitLevels = ({ rabbit, childRabbit, level = 1 }) => {
  const dispatch = useDispatch()
  return (
    <ol className={`level-${level}-wrapper`}>
      {_.size(rabbit)
        ? rabbit.map((_rabbit, _i) => {
            return (
              <React.Fragment key={_i}>
                <li>
                  <Button
                    size='sm'
                    disabled={_rabbit.disabled}
                    className={classNames(
                      `level-${_rabbit.generation} rectangle position-relative`,
                      {
                        'bg-green': _rabbit.gender === 'F' ? true : false,
                        'bg-blue rounded-2':
                          _rabbit.gender === 'M' ? true : false,
                      }
                    )}
                    onClick={() =>
                      dispatch(
                        showAndClosePedigreeModal({
                          showModal: true,
                          modalType: 'rabbit',
                          ModalContent: {
                            childRabbit: childRabbit,
                            form: _rabbit,
                          },
                        })
                      )
                    }
                  >
                    {_rabbit.text}
                    {!_rabbit.rabbit_id ? (
                      <span className='button__badge'></span>
                    ) : null}
                  </Button>

                  {_rabbit.parents && _rabbit.parents.length > 0 && (
                    <RabbitLevels
                      childRabbit={_rabbit}
                      rabbit={_rabbit.parents}
                      level={_rabbit.generation + 1}
                    />
                  )}
                </li>
              </React.Fragment>
            )
          })
        : null}
    </ol>
  )
}

export default RabbitLevels
