import {
  GET_ALL_LIVE_EVENT_BREED_AS_FUR_DETAIL,
  GET_ALL_LIVE_EVENT_BREED_DETAIL,
  GET_ALL_LIVE_EVENT_BREED_DETAIL_SUCCESS,
  GET_ALL_LIVE_EVENT_BREED_FUR_LIST,
  GET_ALL_LIVE_EVENT_BREED_FUR_LIST_FOR_EXCEL,
  GET_ALL_LIVE_EVENT_BREED_FUR_LIST_SUCCESS,
  GET_ALL_LIVE_EVENT_LIST,
  GET_ALL_LIVE_EVENT_LIST_SUCCESS,
  GET_ALL_LIVE_EVENT_SHOW_LIST,
  GET_ALL_LIVE_EVENT_SHOW_LIST_SUCCESS,
  LIVE_EVENT_BREED_TAB_CLOSE,
  LIVE_EVENT_BREED_TAB_OPEN,
  LIVE_EVENT_EXHIBITOR_LIST,
  LIVE_EVENT_EXHIBITOR_LIST_SUCCESS,
  LIVE_EVENT_PARTICIPATION_LIST_SUCCESS,
  LIVE_EVENT_RELOAD,
  LIVE_EVENT_RESET_ACTIVE_TABS,
} from './actionTypes'

//
const INIT_STATE = {
  eventData: {
    eventList: [],
    loading: true,
  },
  showData: {
    showList: [],
    loading: true,
  },
  breedListData: {
    breedList: [],
    loading: true,
  },
  breedData: {
    breedDetail: {},
    loading: true,
  },
  exhibitorData: {
    exhibitorList: [],
    loading: true,
  },
  participationData: {
    participationList: [],
    loading: true,
  },
  activeTabs: [],
  activeBreedDetail: '',
  reloadData: true,
}

const PremierReport = (state = INIT_STATE, action) => {
  const { payload } = action
  switch (action.type) {
    case GET_ALL_LIVE_EVENT_LIST:
      return {
        ...state,
        eventData: {
          ...state.eventData,
          loading: true,
        },
        showData: {
          ...state.showData,
          showData: [],
          loading: true,
        },
      }
    case GET_ALL_LIVE_EVENT_LIST_SUCCESS:
      return {
        ...state,
        eventData: {
          ...state.eventData,
          eventList: payload,
          loading: false,
        },
      }

    case GET_ALL_LIVE_EVENT_SHOW_LIST:
      return {
        ...state,
        showData: {
          ...state.showData,
          loading: true,
        },
      }

    case GET_ALL_LIVE_EVENT_SHOW_LIST_SUCCESS:
      return {
        ...state,
        showData: {
          ...state.showData,
          showList: action.payload,
          loading: false,
        },
      }
    case GET_ALL_LIVE_EVENT_BREED_FUR_LIST_FOR_EXCEL:
    case GET_ALL_LIVE_EVENT_BREED_FUR_LIST:
      return {
        ...state,
        breedListData: {
          ...state.breedListData,
          loading: true,
        },
      }
    case GET_ALL_LIVE_EVENT_BREED_DETAIL:
      return {
        ...state,
        // activeTabs: [],
        breedData: {
          ...state.breedData,
          loading: true,
        },
      }
    case GET_ALL_LIVE_EVENT_BREED_AS_FUR_DETAIL:
      return {
        ...state,
        // activeTabs: [],
        breedData: {
          ...state.breedData,
          loading: true,
        },
      }
    case GET_ALL_LIVE_EVENT_BREED_DETAIL_SUCCESS:
      return {
        ...state,
        // activeTabs: [],
        breedData: {
          ...state.breedData,
          breedDetail: payload || {},
          loading: false,
        },
      }
    case GET_ALL_LIVE_EVENT_BREED_FUR_LIST_SUCCESS:
      return {
        ...state,
        breedListData: {
          ...state.breedListData,
          breedList: action.payload,
          loading: false,
        },
      }
    case LIVE_EVENT_BREED_TAB_OPEN:
      let finalList = [...state.activeTabs]
      // find index for change
      let findIndex = state.activeTabs.findIndex((x) => x.type === payload.type)
      if (findIndex >= 0) {
        finalList[findIndex]['breed_id'] = payload?.breed_id
        finalList[findIndex]['group_id'] = payload?.group_id
        finalList[findIndex]['variety_id'] = payload?.variety_id
        finalList[findIndex]['class_id'] = payload?.class_id
        finalList[findIndex]['fur_type_id'] = payload?.fur_type_id
        finalList[findIndex]['fur_id'] = payload?.fur_id
        finalList[findIndex]['type'] = payload.type
      } else {
        finalList.push(payload)
      }

      //  close already open tab when we move to class to group or variety collapse open reset all child close
      let arr = []
      if (payload.type === 'breed') {
        arr = [
          'group',
          'variety',
          'class',
          'furType',
          'furColor',
          'fur_as_breed',
        ]
      }
      if (payload.type === 'fur_as_breed') {
        arr = ['group', 'variety', 'class', 'furType', 'furColor', 'breed']
      }
      if (payload.type === 'furType') {
        arr = ['group', 'variety', 'class', 'furColor']
      }
      if (payload.type === 'group') {
        arr = ['variety', 'class', 'furType', 'furColor']
      }
      if (payload.type === 'variety') {
        arr = ['class', 'furType', 'furColor']
      }
      if (payload.type === 'class') {
        arr = ['furType', 'furColor']
      }

      finalList = finalList.map((y) => {
        if (arr.includes(y.type)) {
          return {
            ...y,
            group_id: undefined,
            variety_id: undefined,
            class_id: undefined,
            fur_type_id: undefined,
            fur_type: undefined,
            breed_id: undefined,
          }
        }
        return y
      })

      return {
        ...state,
        activeTabs: finalList,
      }
    case LIVE_EVENT_BREED_TAB_CLOSE:
      let result = [...state.activeTabs]

      result = result.filter((x) => x.type !== payload.type)
      return {
        ...state,
        activeTabs: result,
      }
    case LIVE_EVENT_RESET_ACTIVE_TABS:
      return {
        ...state,
        activeTabs: [],
      }
    case LIVE_EVENT_EXHIBITOR_LIST:
      return {
        ...state,
        exhibitorData: {
          ...state.exhibitorData,
          exhibitorList: [],
          loading: true,
        },
      }
    case LIVE_EVENT_EXHIBITOR_LIST_SUCCESS:
      return {
        ...state,
        exhibitorData: {
          ...state.exhibitorData,
          exhibitorList: payload || [],
          loading: false,
        },
      }
    case LIVE_EVENT_PARTICIPATION_LIST_SUCCESS:
      return {
        ...state,
        participationData: {
          ...state.participationData,
          participationList: payload || [],
          loading: false,
        },
      }
    case LIVE_EVENT_RELOAD:
      return {
        ...state,
        reloadData: !state.reloadData,
      }
    default:
      return state
  }
}

export default PremierReport
