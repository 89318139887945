import { SET_CLOSE_MODAL, SET_SHOW_MODAL } from './actionTypes'

const initialState = {
  showModal: false,
  modalContent: '',
}

const Modal = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case SET_SHOW_MODAL:
      return {
        ...state,
        showModal: payload.showModal,
        modalContent: payload.modalContent,
      }
    case SET_CLOSE_MODAL:
      return {
        ...state,
        showModal: false,
        modalContent: '',
      }

    default:
      return state
  }
}
export default Modal
