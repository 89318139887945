import CloseIcon from '@mui/icons-material/Close'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import $ from 'jquery'
import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Redirect, useHistory, useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Card, CardBody, Col, Container, Row } from 'reactstrap'
import Swal from 'sweetalert2'
import { get, POST, post } from '../../../helpers/api_helper'
import { getDate, getTime } from '../../../helpers/date_helper'
import { UrlEncodeHook } from '../../../hooks'
import ParticipateEasyEntryListAdd from './AddList'
const ParticipateManualEntryPreview = (props) => {
  const history = useHistory()

  const location = useLocation()
  // const event = JSON.parse(localStorage.getItem('events'))
  const query = new URLSearchParams(location.search)
  let event_id = query.get('event_id')
  const { urlDecode, urlEncode } = UrlEncodeHook()
  event_id = urlDecode(event_id)
  const has_products = query.get('has_products')
  const [modal, setModal] = useState(false)
  const [showData, setShowData] = useState([])
  const [showList, setShowList] = useState([])
  const [exhibitorList, setExhibitorList] = useState([])
  const [event, setEvent] = useState()
  const { previewEvent, previewError } = useSelector(({ event }) => event)

  const [previewList, setPreviewList] = useState(previewEvent)
  const [listValidation, setListValidation] = useState(previewError)
  // const [newInputArr, setNewInputArr] = useState([]);
  const [showToast, setShowToast] = useState(false)
  const [newCount, setCount] = useState(0)
  const [nextButton, setNextButton] = useState(false)

  const { additionalInfo } = (location && location.state) || {}

  const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
  }))
  const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props

    return (
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
        {onClose ? (
          <div
            aria-label='close'
            onClick={onClose}
            style={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: '#fff',
              width: '30px',
              backgroundColor: 'red',
              textAlign: 'center',
            }}
          >
            <CloseIcon />
          </div>
        ) : null}
      </DialogTitle>
    )
  }

  useEffect(() => {
    //to desable back buttion on browser

    window.history.pushState(null, document.title, window.location.href)
    window.addEventListener('popstate', function (event) {
      window.history.pushState(null, document.title, window.location.href)
    })
    get(`/api/v1/profile/exhibitor_list`).then((res) => {
      if (res) {
        let sortList = _.sortBy(res?.data || [], ['last_name'], ['asc'])

        setExhibitorList(sortList)
      }
    })
    const body = {
      event_id_list: [event_id],
    }
    POST(`/api/v1/events/multiple?published=1&active=1&deadlines=1`, body).then(
      (res) => {
        setEvent(res.data[0])
      }
    )

    const getShow = async () => {
      await getAll()
    }
    getShow()
  }, [])

  const getAll = async () => {
    let result
    const res = await get(`/api/v1/shows/all?event_id=${event_id}`)
    if (res) {
      setShowData(res.data)
      result = await res.data.map((data) => {
        return {
          show_id: data.show_id,
          show_type: data.show_type,
        }
      })
      let finalData = _.uniqBy(result, function (e) {
        return e.show_type
      })
      setShowList(finalData)
    }
  }

  const handleRemove = (index) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      width: '380px',
      showCancelButton: true,
      confirmButtonColor: '#062575',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        let list = [...previewList]
        list.splice(index, 1)

        setPreviewList(list)
      }
    })
  }

  const handleChange = async (i, e, type, n, breedList) => {
    let list = [...previewList]
    if (type) {
      if (n === 'show_type') {
        let value = e.map((data) => data.value)
        list[i][n] = value
        list[i]['show_id'] = []
        list[i]['ear_no'] = ''
        list[i]['breed_category_id'] = ''
        list[i]['breed_id'] = ''
        list[i]['group_seq'] = ''
        list[i]['user_id'] = ''
        list[i]['variety_seq'] = ''
        list[i]['variety_id'] = ''
        list[i]['class_id'] = ''
        list[i]['group_id'] = ''
        list[i]['has_fur'] = ''
        list[i]['fur_data'] = []
        list[i]['fur_type_id'] = []
        list[i]['fur_color'] = ''
        list[i]['isSelected'] = false
      }
      if (n === 'show_id') {
        list[i][n] = e.value
        list[i]['breed_category_id'] = ''
        list[i]['breed_id'] = ''
        list[i]['group_seq'] = ''
        list[i]['variety_seq'] = ''
        list[i]['variety_id'] = ''
        list[i]['class_id'] = ''
        list[i]['group_id'] = ''
        list[i]['has_fur'] = ''
        list[i]['fur_data'] = []
        list[i]['fur_type_id'] = []
        list[i]['fur_color'] = ''
        list[i]['isSelected'] = false
      }
      if (n === 'fur_type_id') {
        let value = e.map((data) => data.value)
        list[i][n] = value
        list[i]['fur_color'] = ''
      }
    } else {
      const { name, value } = e.target
      if (name === 'ear_no') {
        setShowToast(false)
        if (value.length < 50) {
          list[i]['ear_no'] = value.toUpperCase()
        }
        if (value.length === 49 && !showToast) {
          setShowToast(true)
          setCount(1)
        }
        if (showToast && newCount === 1) {
          toast.error('Max Ear. No Should be 50 Character', { toastId: 1 })
        }
      }

      if (name === 'show_type') {
        list[i]['show_type'] = value
        list[i]['user_id'] = ''
        list[i]['ear_no'] = ''
        list[i]['show_id'] = ''
        list[i]['breed_category_id'] = ''
        list[i]['breed_id'] = ''
        list[i]['group_seq'] = ''
        list[i]['variety_seq'] = ''
        list[i]['variety_id'] = ''
        list[i]['class_id'] = ''
        list[i]['group_id'] = ''
        list[i]['has_fur'] = ''
        list[i]['fur_data'] = []
        list[i]['fur_type_id'] = []
        list[i]['fur_color'] = ''
        list[i]['fur_data_validation'] = ''
        list[i]['isSelected'] = false
      }

      if (name === 'user_id') {
        let findUser = exhibitorList.find((item) => item.id === Number(value))
        list[i]['user_id'] = Number(value) ? Number(value) : ''
        list[i]['user_name'] = findUser
          ? ` ${findUser.last_name} ${findUser.first_name} `
          : ''
      }
      if (name === 'breed_category_id') {
        list[i]['breed_category_id'] = Number(value)
        list[i]['variety_id'] = ''
        list[i]['class_id'] = ''
        list[i]['group_id'] = ''
        list[i]['breed_id'] = ''
        list[i]['has_fur'] = ''
      }
      if (name === 'breed_id') {
        let breed = breedList.find((s) => s.breed_id === Number(value))
        list[i]['breed_id'] = value ? Number(value) : ''
        list[i]['group_seq'] = breed?.group_seq ? Number(breed?.group_seq) : ''
        list[i]['variety_seq'] = breed?.variety_seq
          ? Number(breed?.variety_seq)
          : ''

        list[i]['variety_id'] = ''
        list[i]['class_id'] = ''
        list[i]['group_id'] = ''
        list[i]['fur_type_id'] = []
        list[i]['fur_id'] = []
        list[i]['fur_data'] = []
        list[i]['has_fur'] = ''
      }
      if (name === 'group_id') {
        list[i]['group_id'] = value ? Number(value) : ''
        list[i]['class_id'] = ''
        list[i]['variety_id'] = ''
        list[i]['fur_type_id'] = []
        list[i]['fur_id'] = []
        list[i]['fur_data'] = []
        list[i]['has_fur'] = ''
      }
      if (name === 'variety_id') {
        list[i]['variety_id'] = value ? Number(value) : ''
        list[i]['class_id'] = ''
        list[i]['fur_type_id'] = []
        list[i]['fur_id'] = []
        list[i]['fur_data'] = []
        list[i]['has_fur'] = ''
      }
      if (name === 'class_id') {
        list[i]['class_id'] = value ? Number(value) : ''
      }
      if (name === 'has_fur') {
        list[i]['has_fur'] = value ? Number(value) : ''
        //has_fur

        if (Number(value)) {
          if (
            list[i].fur_default_data.length === 1 &&
            list[i].fur_default_data[0].fur_list.length === 1
          ) {
            list[i]['fur_type_id'] = [list[i].fur_default_data[0].fur_type_id]
            list[i]['fur_color'] =
              list[i].fur_default_data[0].fur_list[0].fur_id
          }
        } else {
          list[i]['fur_data'] = []
          list[i]['fur_type_id'] = []
          list[i]['fur_color'] = ''
        }
      }
      if (name === 'fur_color') {
        list[i]['fur_color'] = value ? Number(value) : ''
      }
      if (name === 'isSelected') {
        let newData = []

        let validationFlag = false
        for (let k = 0; k < newData.length; k++) {
          const element = newData[k]
          try {
            const res = await post(
              `/api/v1/participation/validate_participation`,
              element
            )
            if (res) {
              validationFlag = true
            }
          } catch (err) {
            validationFlag = false
            err.response.data.forEach((res) => {
              toast.error(res.msg)
            })
          }
        }
        if (validationFlag) {
          list[i]['isSelected'] = !list[i]['isSelected'] ? Number(value) : 0
        }
      }
    }

    setPreviewList(list)
  }
  const handleEdit = async (i) => {
    let list = [...previewList]

    let validationFlag = false
    let data = previewList.find((s) => s.ear_no === list[i].ear_no)
    if (data && !list[i].isDisable) {
      validationFlag = true
      toast.error('Duplicate Ear no. found for same user.')
    }
    if (!validationFlag) {
      list[i].isDisable = false
      setPreviewList(list)
    }
  }
  let count = 0
  const handleSave = async (i) => {
    // $('*').addClass('changeCursor')

    let list = [...previewList]

    let validationList = [...previewError]

    let valid = true
    let checkIndex = validationList.findIndex((s) => s.id === list[i].id)

    // validationList[i].id = list[i].id
    if (list[i].show_id.length === 0) {
      validationList[checkIndex].show_id = 'Please select show'
      valid = false
    } else {
      validationList[checkIndex].show_id = ''
    }
    if (!list[i].ear_no) {
      validationList[checkIndex].ear_no = 'Ear no. field is required'
      valid = false
    } else {
      let checkErNum = previewList.filter((s) => {
        if (
          s.ear_no === list[i].ear_no &&
          s.user_id === list[i].user_id &&
          s.show_id === list[i].show_id
        )
          return true
        return false
      })

      if (checkErNum.length > 1) {
        valid = false
        validationList[checkIndex].ear_no =
          'Duplicate Ear no. found for same user.'
      } else {
        validationList[checkIndex].ear_no = ''
      }
    }
    if (!list[i].user_id) {
      validationList[checkIndex].user_id = 'Please select exhibitor'
      valid = false
    } else {
      validationList[checkIndex].user_id = ''
    }
    if (!list[i].show_type) {
      validationList[checkIndex].show_type = 'Please select show type'
      valid = false
    } else {
      validationList[checkIndex].show_type = ''
    }

    if (!list[i].breed_category_id) {
      validationList[checkIndex].breed_category_id = 'Please select category'
      valid = false
    } else {
      validationList[checkIndex].breed_category_id = ''
    }

    if (!list[i].breed_id) {
      validationList[checkIndex].breed_id = 'Please select breed '
      valid = false
    } else {
      validationList[checkIndex].breed_id = ''
    }
    if (!list[i].class_id) {
      validationList[checkIndex].class_id = 'Please select class '
      valid = false
    } else {
      validationList[checkIndex].class_id = ''
    }
    if (list[i].group_seq && list[i].variety_seq) {
      if (!list[i].group_id) {
        validationList[checkIndex].group_id = 'Please select group'
        valid = false
      } else {
        validationList[checkIndex].group_id = ''
      }
      if (!list[i].variety_id) {
        validationList[checkIndex].variety_id = 'Please select Variety'
        valid = false
      } else {
        validationList[checkIndex].variety_id = ''
      }
    }
    if (!list[i].group_seq && list[i].variety_seq) {
      if (!list[i].variety_id) {
        validationList[checkIndex].variety_id = 'Please select Variety'
        valid = false
      } else {
        validationList[checkIndex].variety_id = ''
      }
    }
    if (list[i].group_seq && !list[i].variety_seq) {
      if (!list[i].group_id) {
        validationList[checkIndex].group_id = 'Please select group'
        valid = false
      } else {
        validationList[checkIndex].group_id = ''
      }
    }
    if (!list[i].group_seq && !list[i].variety_seq) {
      validationList[checkIndex].variety_id = ''
      validationList[checkIndex].group_id = ''
    }

    if (list[i].fur_data_validation) {
      if (!list[i].has_fur && list[i].has_fur !== 0) {
        valid = false
        validationList[checkIndex].has_fur = 'Please select fur'
      } else {
        validationList[checkIndex].has_fur = ''
      }
      if (list[i].has_fur && !list[i].fur_type_id.length) {
        valid = false
        validationList[checkIndex].fur_type_id = 'Please select fur type'
      } else {
        validationList[checkIndex].fur_type_id = ''
      }
      if (list[i].has_fur && !list[i].fur_color) {
        valid = false
        validationList[checkIndex].fur_color = 'Please select fur color'
      } else {
        validationList[checkIndex].fur_color = ''
      }
    }
    if (valid) {
      let newData = []
      for (let j = 0; j < 1; j++) {
        let ele = list[i]
        newData.push({
          in_cart: 1,
          event_id: event_id,
          show_id: ele.show_id,
          show_type: ele.show_type,
          user_id: ele.user_id,
          ear_no: ele.ear_no,
          breed_category_id: ele.breed_category_id,
          breed_id: ele.breed_id,
          class_id: ele.class_id,
          variety_id: Number(ele.variety_id) ? Number(ele.variety_id) : null,
          group_id: Number(ele.group_id) ? Number(ele.group_id) : null,
          has_fur: ele.has_fur,
          fur_data: Number(ele.has_fur)
            ? ele.fur_type_id.map((s) => {
                return {
                  fur_type_id: s,
                  fur_id: ele.fur_color,
                }
              })
            : [],
          group_seq: Number(ele.group_seq) ? Number(ele.group_seq) : null,
          variety_seq: Number(ele.variety_seq) ? Number(ele.variety_seq) : null,
        })
      }
      let validationFlag = false

      try {
        const res = await post(
          `/api/v1/participation/validate_participation`,
          newData[0]
        )
        if (res) {
          validationFlag = true
        }
      } catch (err) {
        validationFlag = false
        err.response.data.forEach((res) => {
          valid = false
          validationList[checkIndex].ear_no = 'Ear no. already exits'
        })
      }
      if (validationFlag) {
        list[i].isDisable = true
        let list1 = list

        list1 = await _.sortBy(
          list1,
          ['show_type', 'user_name'],
          ['asc', 'asc']
        )

        setPreviewList(list1)
      }
    }
    $('*').removeClass('changeCursor')
    setListValidation(validationList)
  }
  const handleNext = async () => {
    // let data = previewEvent.find((s) => s.isDisable === false)
    // if (data) {
    //   toast.error('Please save your all entry')
    //   return
    // }
    setModal(true)
  }

  const hasFurData = async (i, hasFur) => {
    let list = [...previewList]

    if (hasFur && hasFur.length) {
      list[i].fur_data_validation = true
      list[i].displayFur = true
      list[i].fur_default_data = hasFur
      if (hasFur.length === 1 && hasFur[0].fur_list.length === 1) {
        list[i].displayFur = false
      }
    } else {
      list[i].fur_data_validation = false
      list[i].has_fur = ''
      list[i].fur_data = []
      list[i].fur_type_id = []
      list[i].fur_id = []
      list[i].fur_default_data = []
      list[i].displayFur = true
    }
    setPreviewList(list)
  }
  const continues = async () => {
    //  id='agree-btn'
    document.getElementById('agree-btn').setAttribute('disabled', true)
    let validationList = [...previewError]

    let finalData = await previewList.map((e) => {
      return {
        in_cart: 1,
        event_id: Number(event_id),
        show_id: Number(e.show_id),
        show_type: e.show_type,
        user_id: Number(e.user_id),
        ear_no: e.ear_no,
        breed_category_id: Number(e.breed_category_id),
        breed_id: Number(e.breed_id),
        class_id: Number(e.class_id),
        variety_id: Number(e.variety_id) ? Number(e.variety_id) : null,
        group_id: Number(e.group_id) ? Number(e.group_id) : null,
        has_fur: Number(e.has_fur),
        fur_data: Number(e.has_fur)
          ? e.fur_type_id.map((s) => {
              return {
                fur_type_id: s,
                fur_id: e.fur_color,
              }
            })
          : [],
        group_seq: Number(e.group_seq) ? Number(e.group_seq) : null,
        variety_seq: Number(e.variety_seq) ? Number(e.variety_seq) : null,
      }
    })
    let validationFlag = false
    for (let i = 0; i < finalData.length; i++) {
      const element = finalData[i]
      try {
        await post(`/api/v1/participation/validate_participation`, element)
      } catch (err) {
        validationFlag = true
        err.response.data.forEach((res) => {
          validationList[i].id = element.id
          validationList[i].ear_no = 'Ear no. already exits'
        })
      }
    }

    let isAllSelectedFlag = false

    if (!isAllSelectedFlag && !validationFlag) {
      post(`/api/v1/participation`, finalData)
        .then((res) => {
          toast.success(res.data.message)
          history.push({
            pathname: `/discount_summary`,
            search: `?event_id=${urlEncode(
              event_id
            )}&has_products=${has_products}`,
            state: {
              additionalInfo: additionalInfo,
            },
          })
        })
        .catch((err) => {
          toast.error(
            'Something is not right! either you have a pending shopping cart to process or the event you wish to participate may be restricted by the event secretary. If you have any questions please get in touch with the event secretary.'
          )
          setTimeout(() => {
            history.push({
              pathname: `/shopping_cart`,
            })
          }, 1500)
        })
    } else {
      setListValidation(validationList)
      setModal(false)
    }
  }
  useEffect(() => {
    const isChecked = previewList.filter((s) => s.isDisable !== false)

    if (isChecked.length === previewList.length) {
      setNextButton(false)
    } else {
      setNextButton(true)
    }
  }, [nextButton, previewList])

  if (!previewList.length) {
    return <Redirect to='/auth-events' />
  }

  // let data = previewEvent.find((s) => s.isDisable === false)
  let displayEnd = true
  if (getDate(event?.start_date_time) === getDate(event?.end_date_time)) {
    displayEnd = false
  }
  return (
    <>
      <React.Fragment>
        <div className='page-content bg-gray-101'>
          <section className='section1 bg-gray-101 pages event-list'>
            <Container fluid={true} className='auto-fit'>
              <Row>
                <Col lg={12} className='text-center'>
                  <div className='page-title-box justify-content-between'>
                    <h2 className='top-title mb-4'>
                      <span>PREVIEW</span>
                    </h2>
                  </div>
                </Col>

                <Col lg={12} className='mb-7 event-list'>
                  <Card className='shadow-only-hover'>
                    <CardBody className='event-card-body'>
                      <Col lg={12} className='event-box'>
                        <div className='row'>
                          <div className='col-lg-4 col-xl-3 box-head'>
                            <div className='row'>
                              <div className='col-lg-5 col-xl-5 mb-4 mt-4'>
                                Event Date:{' '}
                              </div>
                              <div className='col-lg-7 col-xl-7 pl-0'>
                                <p className='highlight'>
                                  {event?.start_date_time &&
                                    getDate(event.start_date_time)}
                                  <br />

                                  {displayEnd && 'To'}
                                  {displayEnd && <br />}
                                  {displayEnd && getDate(event?.end_date_time)}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className='col-lg-5 col-xl-3 box-head mt-3'>
                            {event?.event_name}{' '}
                          </div>
                          <div className='col-lg-3 col-xl-3 box-head mt-3'>
                            {event?.address_1}
                            {event?.address_1 ? ', ' : ''}
                            <br />
                            {event?.address_2}
                            {event?.address_2 ? ', ' : ''}
                            {event?.city}
                            {event?.city ? ', ' : ''}
                            {event?.state_name}
                            {event?.state_name ? ', ' : ''}
                            {event?.zip_code}
                            {event?.zip_code ? ', ' : ''} {event?.country_name}
                          </div>
                          <div className='col-lg-6 col-xl-3 box-head'>
                            <div className='row'>
                              <div className='col-lg-4 col-xl-4 mt-4'>
                                Deadline:
                              </div>
                              {event?.deadlines?.map((item, index) => {
                                if (
                                  item?.deadline_status === 'upcoming' &&
                                  count === 0
                                ) {
                                  count++

                                  return (
                                    <div className='col-lg-8 col-xl-8 view-dline mt-2'>
                                      <span className='deadline'>
                                        <div>
                                          {getDate(item?.date_time)}
                                          <br />
                                          {`${getTime(item?.date_time)} CST`}
                                        </div>
                                      </span>
                                      <div className='input-group-append'>
                                        <span className='input-group-text'>
                                          1st
                                        </span>
                                      </div>
                                    </div>
                                  )
                                }
                              })}
                            </div>
                          </div>
                        </div>
                      </Col>
                    </CardBody>
                  </Card>
                </Col>

                <div className='col-md-12 mb-7'>
                  <div className='card shadow-only-hover'>
                    <div className='card-body event-card-body'>
                      <Col lg={12} className='mt-0'>
                        {/* <hr /> */}
                        <Col lg={12} className='manual-fill-box fs-20'>
                          Event Type: {event?.event_type}
                        </Col>
                        <Col lg={12} className='mt-4 manual-empty-box fs-20'>
                          Fill in the details of the animals that you want to{' '}
                          <span>PARTICIPATE </span>
                          with.
                        </Col>
                        <br />
                        {previewList.length &&
                          previewList.map((list, i) => {
                            let breakSection = false
                            if (i > 0) {
                              if (
                                list.show_type !==
                                  previewList[i - 1].show_type &&
                                list.previewScreen === true
                              ) {
                                breakSection = true
                              }
                            }

                            return (
                              <ParticipateEasyEntryListAdd
                                key={i}
                                index={i}
                                showData={showData}
                                showList={showList}
                                list={list}
                                exhibitorList={exhibitorList}
                                handleChange={handleChange}
                                handleRemove={handleRemove}
                                handleEdit={handleEdit}
                                handleSave={handleSave}
                                validationList={listValidation}
                                hasFurData={hasFurData}
                                breakSection={breakSection}
                              />
                            )
                          })}
                      </Col>

                      <hr />
                      <Col lg={12} className='text-center mt-5 mb-3 easy-box'>
                        <Row>
                          <Col lg={12}>
                            <button
                              disabled={nextButton}
                              type='submit'
                              id='submit'
                              className='btn btn-primary btn-easy w-15 res-w-f'
                              onClick={handleNext}
                            >
                              NEXT
                            </button>
                          </Col>
                        </Row>
                      </Col>
                    </div>
                  </div>
                </div>
              </Row>
            </Container>
          </section>
          <BootstrapDialog
            onClose={() => setModal(false)}
            aria-labelledby='customized-dialog-title'
            open={modal}
          >
            <div
            className='res-pop'
              style={{
                textAlign: 'center',
                width: '350px',
                background: 'rgb(3 33 114)',
                color: '#fff',
                padding: '10px',
              }}
            >
              <BootstrapDialogTitle
                id='customized-dialog-title'
                onClose={() => setModal(false)}
              ></BootstrapDialogTitle>
              <DialogContent style={{ marginTop: '10px' }}>
                <Typography className='para' gutterBottom>
                  Changes to animal entries cannot done past this point.
                </Typography>

                <button
                  id='agree-btn'
                  className='btn btn-primary btn-easy'
                  style={{
                    background: 'green',
                    color: '#fff',
                    margin: '20px 0 0 0',
                  }}
                  onClick={() => {
                    continues()
                  }}
                >
                  Agree
                </button>
              </DialogContent>
            </div>
          </BootstrapDialog>
        </div>
      </React.Fragment>
    </>
  )
}
export default ParticipateManualEntryPreview
