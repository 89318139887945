import { getMonth, getYear } from 'date-fns'
import React from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

const datePicker = (props) => {
  const { selected, onChange, dateFormat } = props

  const years = generateYearsBetween(2018, getYear(new Date()) + 15, 1)

  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]
  return (
    <DatePicker
      minDate={new Date(years[0], '0', '01')}
      maxDate={new Date(years[years.length - 1], '11', '31')}
      renderCustomHeader={({
        date,
        changeYear,
        changeMonth,
        decreaseMonth,
        increaseMonth,
        prevMonthButtonDisabled,
        nextMonthButtonDisabled,
      }) => (
        <div
          style={{
            margin: 10,
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <button
            type="button"
            onClick={decreaseMonth}
            disabled={prevMonthButtonDisabled}
          >
            {'<'}
          </button>
          <select
            value={getYear(date)}
            onChange={({ target: { value } }) => changeYear(value)}
          >
            {years.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>

          <select
            value={months[getMonth(date)]}
            onChange={({ target: { value } }) =>
              changeMonth(months.indexOf(value))
            }
          >
            {months.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>

          <button
            type="button"
            onClick={increaseMonth}
            disabled={nextMonthButtonDisabled}
          >
            {'>'}
          </button>
        </div>
      )}
      selected={selected}
      onChange={onChange}
      dateFormat={!dateFormat && 'MMM do yyyy'}
      // onKeyDown={(e) => {
      //   if (e.key !== 'Backspace' || e.key === 'Delete') {
      //     e.preventDefault();
      //   }
      // }}
      autoComplete="off"
      {...props}
    />
  )
}

export default datePicker

//
function generateYearsBetween(startYear = 2000, endYear) {
  const endDate = endYear || new Date().getFullYear()
  let years = []
  for (var i = startYear; i <= endDate; i++) {
    years.push(startYear)
    startYear++
  }
  return years
}
