import React, { useEffect } from 'react';
import { ForgetPwdform } from './Components/forgetpwdform';
import { useHistory } from 'react-router-dom';

const ForgetPassword = (props) => {
  // handleValidSubmit
  const history = useHistory();

  useEffect(() => {
    if (localStorage.getItem('accessToken')) {
      history.push('/auth-events');
    }
  }, []);
  return (
    <React.Fragment>
      <ForgetPwdform />
    </React.Fragment>
  );
};
export default ForgetPassword;
