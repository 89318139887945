import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Col, Container, Label, Row } from 'reactstrap';
import { patch } from '../../../helpers/api_helper';
import { setMsg, setShowAlert } from '../../../store/successpopup/actions';
import SuccessPopup from '../../CommonComponent/successPopup1';
const ChangePassword = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  let initial = {
    old_password: '',
    n_password: '',
    c_password: '',
  };
  const [inputs, setInputs] = useState(initial);
  const [backendErr, setBackendErr] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [errors, setErrors] = useState({
    old_password: '',
    n_password: '',
    c_password: '',
  });
  let showPwd2 = () => {
    setShowPassword2(true);
  };
  let hidePwd2 = () => {
    setShowPassword2(false);
  };
  let showPwd1 = () => {
    setShowPassword1(true);
  };
  let hidePwd1 = () => {
    setShowPassword1(false);
  };
  let showPwd = () => {
    setShowPassword(true);
  };
  let hidePwd = () => {
    setShowPassword(false);
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value.trim();
    let newvalue = value.replaceAll(' ', '');
    setInputs((values) => ({ ...values, [name]: newvalue }));
    // if (name == 'old_password') {
    //   let newvalue = value.replaceAll(' ', '')
    //   setInputs((values) => ({ ...values, [name]: newvalue }))
    // }
    // if (name == 'n_password') {
    //   let newvalue = value.replaceAll(' ', '')
    //   setInputs((values) => ({ ...values, [name]: newvalue }))
    // }
    // if (name == 'c_password') {
    //   let newvalue = value.replaceAll(' ', '')
    //   setInputs((values) => ({ ...values, [name]: newvalue }))
    // }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    let data = {};
    let validationFlag = false;

    let errPassword = '';
    let errNPassword = '';
    let errCPassword = '';
    if (!inputs.old_password) {
      errPassword = 'Old Password field is required';
    }
    if (!inputs.n_password) {
      errNPassword = 'New password field is required';
    }
    // Password must have: Minimum 8 and maximum 15 characters, at least one uppercase letter, one lowercase letter, one number and one special character

    if (!inputs.c_password) {
      errCPassword = 'Confirm password field is required';
    }
    let textRegex =
      /^(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[~`!@#$%^&*()--+={}\[\]|\\:;"'<>,.?/_₹])[a-zA-Z0-9~`!@#$%^&*()--+={}\[\]|\\:;"'<>,.?/_₹\s]{8,15}$/g;
    if (inputs.n_password && !textRegex.test(inputs.n_password)) {
      errNPassword =
        'Password must have: Minimum 8 and maximum 15 characters, at least one uppercase letter, one lowercase letter, one number and one special character';
    }
    if (inputs.c_password && inputs.n_password !== inputs.c_password) {
      errCPassword = 'Confirm password does not match with password';
    }
    if (errPassword || errCPassword || errNPassword) {
      validationFlag = true;
      setErrors({
        old_password: errPassword,
        n_password: errNPassword,
        c_password: errCPassword,
      });
    } else {
      setErrors({
        old_password: '',
        n_password: '',
        c_password: '',
      });
    }

    data = {
      password: inputs.n_password,
      confirm_password: inputs.c_password,
      old_password: inputs.old_password,
    };
    let token = localStorage.getItem('accessToken');
    if (!token) {
      history.push({ pathname: '/login' });
      //  <Redirect to='/login' />
    }
    if (token && !validationFlag) {
      patch(`/api/v1/password/change_password`, data)
        .then((response) => {
          setInputs({});
          dispatch(setShowAlert(true));
          dispatch(setMsg(response));
          setTimeout(() => {
            dispatch(setShowAlert(false));
            dispatch(setMsg(''));
            history.push('/auth-events');
          }, 3600);
        })
        .catch((error) => {
          setBackendErr({});
          error.response?.data?.map((item, index) => {
            toast.error(item.msg, { autoClose: 10000, toastId: index });
            // setBackendErr((values) => ({
            //   ...values,
            //   [item.param]: item.msg,
            // }));
          });
          //console.log(error)
        });
    }
  };
  return (
    <React.Fragment>
      <div className="page-content-auth page-content-view">
        <Container fluid={true} className="auto-fit">
          <Row>
            <Col lg={12} className="text-center mt-4">
              <div className="page-title-box justify-content-between">
                <h2 className="top-title mb-4">
                  <span>Change Password</span>
                </h2>
              </div>
            </Col>
          </Row>

          <Row>
            <Col lg="12">
              <Row>
                <form
                  onSubmit={(e) => {
                    handleSubmit(e);
                  }}
                >
                  <Col xl={12}>
                    <div className="mb-3">
                      <Label className="form-label">Old Password * </Label>
                      <input
                        className="form-control"
                        name="old_password"
                        id="old_password"
                        placeholder="Old Password"
                        value={inputs.old_password ? inputs.old_password : ''}
                        onChange={handleChange}
                        type={showPassword ? 'text' : 'password'}
                        autoFocus
                      />
                      {showPassword && (
                        <RemoveRedEyeIcon
                          className="pwd-icon"
                          onClick={hidePwd}
                        />
                      )}
                      {!showPassword && (
                        <VisibilityOffIcon
                          className="pwd-icon"
                          onClick={showPwd}
                        />
                      )}
                      {errors.old_password && (
                        <p style={{ color: 'red' }}>{errors.old_password}</p>
                      )}
                    </div>
                  </Col>
                  <Col xl={12}>
                    <div className="mb-3">
                      <Label className="form-label">New Password * </Label>
                      <input
                        className="form-control"
                        name="n_password"
                        id="n_password"
                        placeholder="New Password "
                        value={inputs.n_password ? inputs.n_password : ''}
                        onChange={handleChange}
                        type={showPassword1 ? 'text' : 'password'}
                      />
                      {showPassword1 && (
                        <RemoveRedEyeIcon
                          className="pwd-icon"
                          onClick={hidePwd1}
                        />
                      )}
                      {!showPassword1 && (
                        <VisibilityOffIcon
                          className="pwd-icon"
                          onClick={showPwd1}
                        />
                      )}
                      <span className="text-muted">
                        (Note:Minimum 8 and maximum 15 characters, at least one
                        uppercase letter, one lowercase letter, one number and
                        one special character)
                      </span>
                      {errors.n_password && (
                        <p style={{ color: 'red' }}>{errors.n_password}</p>
                      )}
                    </div>
                  </Col>
                  <Col xl={12}>
                    <div className="mb-3">
                      <Label className="form-label">Confirm Password *</Label>
                      <input
                        className="form-control"
                        name="c_password"
                        id="c_password"
                        placeholder="Confirm Password"
                        value={inputs.c_password ? inputs.c_password : ''}
                        onChange={handleChange}
                        type={showPassword2 ? 'text' : 'password'}
                      />
                      {showPassword2 && (
                        <RemoveRedEyeIcon
                          className="pwd-icon"
                          onClick={hidePwd2}
                        />
                      )}
                      {!showPassword2 && (
                        <VisibilityOffIcon
                          className="pwd-icon"
                          onClick={showPwd2}
                        />
                      )}
                      {errors.c_password && (
                        <p style={{ color: 'red' }}>{errors.c_password}</p>
                      )}
                    </div>
                  </Col>
                  <Col lg={12}>
                    <div className="mt-8 text-center">
                      <Button
                        type="submit"
                        color="success"
                        className="btn btn-success w-lg waves-effect waves-light"
                        // onClick={(e) => {
                        //   handleSubmit(e)
                        // }}
                      >
                        SUBMIT
                      </Button>
                    </div>
                  </Col>
                </form>
              </Row>
            </Col>
          </Row>
        </Container>
        <SuccessPopup />
      </div>
    </React.Fragment>
  );
};

export default ChangePassword;
