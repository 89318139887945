import {
  GET_FULL_PLAN_DETAILS,
  PLAN_DETAILS_ERROR,
  PLAN_DETAILS_LOADING,
} from './actionType';

//
const INIT_STATE = {
  planDetails: [],
  loading: true,
  error: null,
};

const planDetailsReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case PLAN_DETAILS_LOADING:
      return {
        ...state,
        loading: true,
      };
    case GET_FULL_PLAN_DETAILS:
      return {
        ...state,
        planDetails: action.payload,
        loading: false,
      };
    case PLAN_DETAILS_ERROR:
      return {
        ...state,
        planDetails: [],
        error: action.payload,
        loading: false,
      };

    default:
      return state;
  }
};

export default planDetailsReducer;
