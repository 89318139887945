import { put, takeEvery } from 'redux-saga/effects'
import { get, post } from '../../helpers/api_helper'
import { getAllEventDataSuccess } from './action'
import { toast } from 'react-toastify'
import {
  GET_ALL_YOUR_PARTICIPATION_ADDITIONAL_PRODUCT,
  GET_ALL_YOUR_PARTICIPATION_ADDITIONAL_PRODUCT_SUCCESS,
  GET_ALL_YOUR_PARTICIPATION_ANIMAL_LIST,
  GET_ALL_YOUR_PARTICIPATION_ANIMAL_LIST_SUCCESS,
  GET_ALL_YOUR_PARTICIPATION_EVENT,
  GET_ALL_YOUR_PARTICIPATION_EVENT_DATES,
  GET_ALL_YOUR_PARTICIPATION_EVENT_SUCCESS,
  TOTAL_PAGE_YOUR_PARTICIPATION,
} from './actionType'
import { GET_ALL_YOUR_PARTICIPATION_EVENT_DATES_URL } from '../../helpers/url_helper'
import { CURRENT_PAGE } from '../sponsorEvent/actionType'
import _ from 'lodash'
function* getAllParticipationDate(data) {
  try {
    const response = yield get(`${GET_ALL_YOUR_PARTICIPATION_EVENT_DATES_URL}`)

    // return response

    yield put(getAllEventDataSuccess(response.data))
  } catch (err) {
    if (err.response.data) {
      toast.error(err.response.data)
    }
  }
}
function* getAllParticipatedEvent(data) {
  const {
    payload: { pagination, body },
  } = data
  // const {pagination} = pa
  try {
    const response = yield post(
      `/api/v1/your-participation/event-all?_limit=${
        pagination?.limit || ''
      }&_page=${pagination?.page || ''}`,
      body
    )

    let totalPage = 0
    totalPage = Math.ceil(response.totalRecords / pagination.limit) || 1

    yield put({
      type: GET_ALL_YOUR_PARTICIPATION_EVENT_SUCCESS,
      payload: response,
    })
    yield put({
      type: TOTAL_PAGE_YOUR_PARTICIPATION,
      payload: totalPage,
    })
    yield put({
      type: CURRENT_PAGE,
      payload: pagination?.page,
    })
    // yield put({
    //   type: GET_CATEGORY_LIST_SUCCESS,
    //   payload: response,
    // });
  } catch (err) {
    if (err.response.data) {
      toast.error(err.response.data)
    }
  }
}

function* getAllYourParticipationAdditionProductSagas(data) {
  const {
    payload: { pagination, body },
  } = data

  try {
    const response = yield post(
      `/api/v1/your-participation/product-list?_order=DESC&_sort=updated_on&_limit=${
        pagination?.limit || ''
      }&_page=${pagination?.page || ''}`,
      body
    )

    let totalPage = 0
    totalPage = Math.ceil(response.totalRecords / pagination.limit) || 1

    yield put({
      type: GET_ALL_YOUR_PARTICIPATION_ADDITIONAL_PRODUCT_SUCCESS,
      payload: response,
    })
    yield put({
      type: TOTAL_PAGE_YOUR_PARTICIPATION,
      payload: totalPage,
    })
    yield put({
      type: CURRENT_PAGE,
      payload: pagination?.page,
    })
    // yield put({
    //   type: GET_CATEGORY_LIST_SUCCESS,
    //   payload: response,
    // });
  } catch (err) {
    if (err.response.data) {
      toast.error(err.response.data)
    }
  }
}
function* yourAnimalListByShow(data) {
  try {
    let body = {
      show_id: Number(data.payload.show_id),
    }
    const res = yield post(
      `/api/v1/participation/entry-list-in-detail?by_user=1`,
      body
    )
    if (res && Array.isArray(res.data)) {
      // console.log(res.data)
      //  ({ user_id: key, animals: value }))
      // console.log(
      let finalData = _.chain(res.data)
        .groupBy('user_id')
        .map((value, key) => {
          let userName = value.find((user) => user.user_id === Number(key))
          return {
            user_id: Number(key),
            user_name: userName.user_full_name,
            animals: value,
          }
        })
        .value()

      yield put({
        type: GET_ALL_YOUR_PARTICIPATION_ANIMAL_LIST_SUCCESS,
        payload: finalData,
      })

      // )
    }
  } catch (err) {
    if (err.response.data) {
      toast.error(err.response.data)
    }
  }
}

function* YourParticipation() {
  yield takeEvery(
    GET_ALL_YOUR_PARTICIPATION_EVENT_DATES,
    getAllParticipationDate
  )
  yield takeEvery(GET_ALL_YOUR_PARTICIPATION_EVENT, getAllParticipatedEvent)
  yield takeEvery(
    GET_ALL_YOUR_PARTICIPATION_ADDITIONAL_PRODUCT,
    getAllYourParticipationAdditionProductSagas
  )
  yield takeEvery(GET_ALL_YOUR_PARTICIPATION_ANIMAL_LIST, yourAnimalListByShow)
}

export default YourParticipation
