import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Card, Col, Row, Spinner, Table } from 'reactstrap'
import {
  getAllPremierReportBreedList,
  getAllPremierReportDetailByBreed,
  getAllPremierReportShowFinalized,
  getAllPremierReportShowFinalizedStatus,
} from '../../../../../store/actions'
import _ from 'lodash'

const ShowContent = ({ selectedShow, show }) => {
  const dispatch = useDispatch()
  const { eventDetail } = useSelector(
    (state) => state.PremierReport.eventDetailData
  )
  const { breedList, loading } = useSelector(
    (state) => state.PremierReport.breedListData
  )
  const { finalizedStatus } = useSelector(
    (state) => state.PremierReport.finalizedData
  )
  const { detailByBreed } = useSelector((state) => state.PremierReport)

  useEffect(() => {
    if (selectedShow === show.show_id && eventDetail?.event_type) {
      const data = {
        params: {
          show_id: selectedShow,
          event_type: eventDetail?.event_type || '',
          judging_order_id: show?.judging_order_id,
        },
      }
      dispatch(getAllPremierReportBreedList(data))
    }
  }, [selectedShow, show.show_id, eventDetail?.event_type])
  useEffect(() => {
    if (selectedShow === show.show_id && show?.option_name) {
      const data = {
        params: {
          show_id: selectedShow,
          sheet_option: show?.option_name,
        },
      }
      dispatch(getAllPremierReportShowFinalizedStatus(data))
      dispatch(getAllPremierReportDetailByBreed(data))
    }
  }, [selectedShow, show.show_id, show?.option_name])

  return (
    <Card body>
      {_.size(finalizedStatus) || _.size(detailByBreed) ? (
        <Row>
          <Col md='12' className='text-end mb-2'>
            {eventDetail?.event_type === 'national' ? (
              finalizedStatus?.media_link ? (
                <Button
                  color='success'
                  size='md'
                  className='ms-2'
                  onClick={() => {
                    window.open(finalizedStatus?.media_link, '_blank')
                  }}
                  title='Finalized'
                >
                  FINALIZED{' '}
                  <i className='fa fa-download ms-2' aria-hidden='true'></i>
                </Button>
              ) : (
                <h5 className='fw-bolder text-danger'>
                  Finalized Report has not been generated yet
                </h5>
              )
            ) : null}
          </Col>
          <Col md='12'>
            <Table className='table mb-0 cust-tab' responsive>
              <thead className='table-color'>
                <tr>
                  <th>#</th>
                  <th>Breed Name</th>
                  <th>Report</th>
                </tr>
              </thead>
              <tbody>
                {!loading && Array.isArray(breedList) && breedList.length ? (
                  breedList.map((breed, i) => {
                    const detailByBreedReport = _.find(
                      detailByBreed,
                      (b) =>
                        Number(b?.breed_id) === Number(breed.breed_id) &&
                        Number(b?.show_id) === selectedShow
                    )

                    return (
                      <tr key={breed.breed_id}>
                        <td>{i + 1}</td>
                        <td>{breed.breed_name}</td>
                        <td>
                          <>
                            {detailByBreedReport?.media_link ? (
                              <Button
                                color='success'
                                size='md'
                                onClick={() => {
                                  window.open(
                                    detailByBreedReport?.media_link,
                                    '_blank'
                                  )
                                }}
                                title='Detail By Breed'
                              >
                                DETAIL BY BREED{' '}
                                <i
                                  className='fa fa-download ms-2'
                                  aria-hidden='true'
                                ></i>
                              </Button>
                            ) : (
                              <span className='text-danger'>
                                Detail by breed report has not been generated
                                yet
                              </span>
                            )}
                          </>
                        </td>
                      </tr>
                    )
                  })
                ) : loading ? (
                  <tr>
                    <td className='text-center' colSpan={3}>
                      <Spinner />
                    </td>
                  </tr>
                ) : (
                  <tr>
                    <td className='text-center' colSpan={3}>
                      No breed found
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </Col>
        </Row>
      ) : (
        <Row>
          <Col md='12' className='mt-4 text-center'>
            <h5>
              Reports for this show are not yet available, please contact the
              show secretary
            </h5>
          </Col>
        </Row>
      )}
    </Card>
  )
}

export default ShowContent
