import { put, takeEvery } from 'redux-saga/effects';
import { post } from '../../helpers/api_helper';
// event Redux States
import {
  GET_ALL_EVENT,
  GET_ALL_EVENT_FAIL,
  GET_ALL_EVENT_SUCCESS,
  SET_EVENT_LOADING,
  TOTAL_PAGE,
} from './actionTypes';

function* getEvent({ payload }) {
  try {
    yield put({
      type: SET_EVENT_LOADING,
    });
    const response = yield post(
      `api/v1/events/multiple?deadlines=1&_page=${payload.pageNo}&_limit=${payload.perPage}&_sort=start_date_time&_order=ASC&active=1&plan_type=traditional`,
      payload.data
    );

    let totalPage = 0;
    totalPage = Math.ceil(response.totalRecords / payload.perPage);

    yield put({
      type: GET_ALL_EVENT_SUCCESS,
      payload: response,
    });
    yield put({
      type: TOTAL_PAGE,
      payload: Number(totalPage) === 1 ? 0 : totalPage,
    });
  } catch (err) {
    yield put({
      type: GET_ALL_EVENT_FAIL,
      payload: 'Error in getEvent()',
    });
  }
}

function* eventSaga() {
  yield takeEvery(GET_ALL_EVENT, getEvent);
}

export default eventSaga;
