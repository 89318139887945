import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Col, Container, Input, Label, Row } from 'reactstrap';
import { get, post } from '../../../helpers/api_helper';
import { setMsg, setShowAlert } from '../../../store/successpopup/actions';
const CreateSubUser = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [inputs, setInputs] = useState({
    first_name: '',
    last_name: '',
    active: 1,
    arba_no: '',
  });
  let [backendErr, setBackendErr] = useState({});
  const [subUsers, setSubUsers] = useState([]);
  const [toggle, setToggle] = useState(false);
  const [errors, setErrors] = useState({
    first_name: null,
    last_name: null,
    arba_no: null,
  });

  const handleChange = (event) => {
    const name = event.target.name;
    let value = event.target.value;
    if (name === 'active') {
      let Checked = event.target.checked;
      if (Checked) {
        value = 1;
      } else {
        value = 0;
      }
    }
    setInputs((values) => ({ ...values, [name]: value }));
  };
  useEffect(() => {
    get(`/api/v1/subuser`)
      .then((data) => {
        setSubUsers(data.data);
      })
      .catch((error) => {
        console.log(error);
      });
    get(`/api/v1/profile/profile_details`)
      .then((data) => {
        let userData = data.data;
        setInputs((values) => ({
          ...values,
          parent_user_name: ` ${userData.last_name} ${userData.first_name}`,
        }));
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  const handleSubmit = (e) => {
    e.preventDefault();
    let validation = true;
    let subUserData = {};
    subUserData = {
      first_name: inputs.first_name.toString().trim(),
      last_name: inputs.last_name.toString().trim(),
      active: inputs.active,
      arba_number: inputs.arba_no.toString().trim(),
    };
    let err = {
      first_name: '',
      last_name: '',
      arba_number: '',
    };
    if (!inputs.first_name.toString().trim()) {
      validation = false;
      err.first_name = 'First name field is required';
    }
    if (inputs.first_name.toString().trim()) {
      let expression = /^[a-zA-Z \-]+$/g;
      let regex = new RegExp(expression);
      if (!inputs.first_name.toString().trim().match(regex)) {
        validation = false;
        err.first_name = 'First name field allow only characters';
      }
      if (inputs.first_name.toString().trim().length > 50) {
        validation = false;
        err.first_name =
          'First name must be of minimum 1 and maximum 50 character length';
      }
    }
    if (!inputs.last_name.toString().trim()) {
      validation = false;
      err.last_name = 'Last name field is required';
    }
    if (inputs.last_name.toString().trim()) {
      let expression = /^[a-zA-Z \-]+$/g;
      let regex = new RegExp(expression);
      if (!inputs.last_name.toString().trim().match(regex)) {
        validation = false;
        err.last_name = 'Last name field allow only characters';
      }
      if (inputs.last_name.toString().trim().length > 50) {
        validation = false;
        err.last_name =
          'Last name must be of minimum 1 and maximum 50 character length';
      }
    }
    // if (!inputs.arba_no) {
    //   validation = false
    //   err.arba_number = 'ARBA no. is required'
    // }
    // err.first_name = !inputs.first_name ? true : null;
    // err.last_name = !inputs.last_name ? true : null;
    //err.arba_no = !inputs.arba_no ? true : null;
    setErrors(err);
    setBackendErr({});

    if (validation) {
      document.getElementById('submit').setAttribute('disabled', true);
      post(`/api/v1/subuser`, subUserData)
        .then((response) => {
          dispatch(setShowAlert(true));
          dispatch(setMsg(response));
          history.push('/subuserlist');
        })
        .catch((error) => {
          document.getElementById('submit').removeAttribute('disabled');
          setBackendErr({});
          let subUser = subUsers.length;
          if (subUser === 10) {
            toast.error(
              'You can not add more than 10 subusers into your account'
            );
          }
        });
    }
  };
  return (
    <React.Fragment>
      <div className="page-content min-h-80">
        <Container fluid={true} className="auto-fit">
          <Row>
            <Col lg={12} className="text-center">
              <div className="page-title-box justify-content-between">
                <h2 className="top-title mb-4">
                  <span>ADD SUB USER LIST</span>
                </h2>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg="12">
              <form onSubmit={handleSubmit}>
                <Row>
                  <Col xl={12}>
                    <div className="mb-3">
                      {' '}
                      <Label className="form-label">
                        Note: You can not add more than 10 sub users into your
                        account.
                      </Label>
                    </div>
                  </Col>
                  <Col xl={12}>
                    <div className="mb-3">
                      <Label className="form-label" htmlFor="">
                        Parent User Name *
                      </Label>
                      <Input
                        type="text"
                        className=""
                        id="parent_user_name"
                        name="parent_user_name"
                        value={inputs.parent_user_name}
                        placeholder="Parent User Name"
                        disabled
                      />
                      {backendErr.first_name && (
                        <p style={{ color: 'red' }}>{backendErr.first_name}</p>
                      )}
                    </div>
                  </Col>

                  <Col xl={12}>
                    <div className="mb-3">
                      <Label className="form-label" htmlFor="">
                        Sub User’s First Name *
                      </Label>
                      <Input
                        type="text"
                        className=""
                        id=""
                        name="first_name"
                        placeholder="Enter Sub User’s First Name"
                        value={inputs.first_name}
                        onChange={handleChange}
                        autoFocus
                      />
                      {errors.first_name && (
                        <p className="text-danger">{errors.first_name}</p>
                      )}
                      {/* {backendErr.first_name && (
                        <p style={{ color: 'red' }}>{backendErr.first_name}</p>
                      )} */}
                    </div>
                  </Col>

                  <Col xl={12}>
                    <div className="mb-3">
                      <Label className="form-label" htmlFor="">
                        Sub User’s Last Name *
                      </Label>
                      <Input
                        type="text"
                        className=""
                        id=""
                        name="last_name"
                        value={inputs.last_name}
                        onChange={handleChange}
                        placeholder="Enter Sub User’s Last Name"
                      />
                      {errors.last_name && (
                        <p className="text-danger">{errors.last_name}</p>
                      )}
                    </div>
                  </Col>

                  <Col xl={12}>
                    <div className="mb-3">
                      <Label className="form-label" htmlFor="">
                        Sub User’s ARBA No.
                      </Label>
                      <Input
                        type="text"
                        className=""
                        id=""
                        name="arba_no"
                        value={inputs.arba_no}
                        onChange={handleChange}
                        placeholder="Enter ARBA No."
                      />
                      {errors.arba_number && (
                        <p className="text-danger">{errors.arba_number}</p>
                      )}
                    </div>
                  </Col>

                  <Col xl={12}>
                    <div className="mb-3">
                      <Label
                        className="form-label"
                        htmlFor="formrow-firstname-input"
                      >
                        Status
                      </Label>
                      <div className="form-check text-left cust-pad">
                        <input
                          className="form-check-input mt-2"
                          type="checkbox"
                          name="active"
                          id="defaultCheck1"
                          onClick={handleChange}
                          checked={inputs.active == 1 ? true : false}
                        />{' '}
                        <label className="form-check-label p-5px" htmlFor='defaultCheck1'>
                        {Number(inputs.active) === 0
                                ? 'In Active'
                                : 'Active'}
                          {/* <span>
                            ( Main user is not active, You are not allowed to
                            active this subuser ){' '}
                          </span> */}
                        </label>
                      </div>
                    </div>
                  </Col>
                  <Col lg={12}>
                    <div className="mt-8 text-center">
                      <Button
                        type="submit"
                        id="submit"
                        color="success"
                        className="w-lg waves-effect waves-light"
                        onClick={handleSubmit}
                      >
                        SUBMIT
                      </Button>
                    </div>
                  </Col>
                </Row>
              </form>
            </Col>
          </Row>
        </Container>
        {/* <SuccessPopup /> */}
      </div>
    </React.Fragment>
  );
};

export default CreateSubUser;
