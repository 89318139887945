import { LISTING_JUDGE_SUCCESS } from './actionType'

//

const INIT_STATE = {
  judgeListing: [],
  loading: true,
  error: {},
}

const EasyFiedsAnimalReducer = (state = INIT_STATE, action) => {
  const { type, payload } = action
  switch (type) {
    case LISTING_JUDGE_SUCCESS:
      return {
        ...state,
        judgeListing: payload,
        loading: false,
      }
    default:
      return state
  }
}

export default EasyFiedsAnimalReducer
