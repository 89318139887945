import { put, takeEvery } from 'redux-saga/effects';
import { get } from '../../helpers/api_helper';
import { getAllPlanDetail } from './action';
import { GET_EMAIL, GET_PLAN_DETAILS, PLAN_DETAILS_ERROR } from './actionType';
import { toast } from 'react-toastify';

function* getFullPlanDetails({ payload }) {
  const { plan_id } = payload;
  try {
    const res = yield get(`api/v1/master-plan/single/${plan_id}`);

    yield put(getAllPlanDetail(res));
  } catch (error) {
    // const err = 'Error in getPlanDetails()';
    const err = '';
    yield put({
      type: PLAN_DETAILS_ERROR,
      payload: err,
    });
    if (!window.navigator.onLine) {
      toast.error(
        'You are currently offline. Please connect to the internet and try again!'
      );
    }
    if (err) {
      toast.error(err);
    }
  }
}

function* getPlanDetails() {
  yield takeEvery(GET_PLAN_DETAILS, getFullPlanDetails);
}

export default getPlanDetails;
