import React, { useState } from 'react';
import { TabContent, TabPane, NavLink, NavItem, Nav, Row } from 'reactstrap';
import classnames from 'classnames';
import { getDate, getDateWithFormat } from '../../../helpers/date_helper';
import { Tooltip } from 'react-tippy';

import { fixed } from '../../../helpers/utils'

export const CostInformation = (props) => {
  let costInfo = props.costInfo.sort(function (a, b) {
    // Turn your strings into dates, and then subtract them
    // to get a value that is either negative, positive, or zero.
    return (
      new Date(getDateWithFormat(a.cost_date)) -
      new Date(getDateWithFormat(b.cost_date))
    );
  });
  let showYouth = props.showYouth;
  let showOpen = props.showOpen;

  const [activeTab, setactiveTab] = useState(0);

  function toggle(tab) {
    if (activeTab !== tab) {
      setactiveTab(tab);
    }
  }
  return (
    <div className="event-nav">
      <hr />
      <Row>
        <div className="col-lg-12 col-xl-12 mb-4  mt-4 box-head tab-nav ">
          <strong>Entry Fee Information :</strong>
        </div>

        <Nav tabs className='res-mul-tab'> 
          {costInfo?.map((costitem, index) => {
            return (
              <NavItem>
                <NavLink
                  className={classnames({
                    active: activeTab == index,
                  })}
                  onClick={() => {
                    toggle(index);
                  }}
                >
                  <span className="d-none1 d-sm-block">
                    {getDate(costitem.cost_date)}
                  </span>
                </NavLink>
              </NavItem>
            );
          })}
        </Nav>

        <TabContent activeTab={activeTab} className="p-3">
          {costInfo?.map((costitem, index) => {
            return (
              <TabPane tabId={index}>
                <div className="row justify-content-center1 event-nav">
                  <div className="col-lg-12 text-center">
                    <div className="row">
                      {costitem?.cost_data?.map((item, index) => {
                        return (
                          <React.Fragment>
                            {item.amount ? (
                              <div className="col-lg-3 text-center">
                                <div className="row">
                                  <div className="col-lg-8 mb-3">
                                    <div
                                      className={
                                        index % 2 === 0
                                          ? 'head-cost'
                                          : 'head-cost-c'
                                      }
                                    >
                                      {item.display_name}
                                    </div>
                                  </div>
                                  <div className="col-lg-4 mb-3 ">
                                    <div
                                      className={
                                        index % 2 === 0
                                          ? 'head-cost'
                                          : 'head-cost-c'
                                      }
                                    >
                                      {' '}
                                      $ {fixed(item.amount, 2, 2)}
                                      {/* $ {item.amount}{' '} */}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ) : null}
                          </React.Fragment>
                        );
                      })}
                    </div>
                  </div>
                </div>
                <hr />
                <br />
                {showOpen?.length > 0 &&
                  showOpen.filter(
                    (word) =>
                      getDate(word.show_date) === getDate(costitem.cost_date)
                  ).length > 0 && (
                    <div>
                      <div className="col-lg-12 col-xl-12 mb-4 box-head">
                        <strong>Show Information : OPEN</strong>
                      </div>
                      <div className="row text-center">
                        <div className="col-lg-2 col-xl-2 mb-2">
                          <div className="tab-head-cost">Show No. </div>
                        </div>

                        <div className="col-lg-2 col-xl-2 mb-2">
                          <div className="tab-head-cost">Specialty</div>
                        </div>
                        <div className="col-lg-8 col-xl-8 mb-2">
                          <div className="tab-head-cost">Breed </div>
                        </div>
                      </div>
                      {showOpen &&
                        showOpen.map((item) => {
                          if (
                            getDate(item.show_date) ===
                            getDate(costitem.cost_date)
                          ) {
                            let show_category;
                            if (item.show_category === 'non-speciality') {
                              show_category = 'All Breeds';
                            } else {
                              //console.log(item.breed_list, "item.breed_list");
                              let breed_name = [];
                              item.breed_list?.map((iitem) => {
                                // console.log("iitem", iitem);
                                return breed_name.push(iitem.breed_name);
                              });

                              show_category = breed_name.join(', ').toString();
                              //console.log("show_category", breed_name);
                            }
                            return (
                              <div class="row text-center">
                                <div class="col-lg-2 col-xl-2 mb-2">
                                  <div
                                    class={
                                      item.show_category === 'non-speciality'
                                        ? 'tab-head-alt'
                                        : 'head-cost'
                                    }
                                  >
                                    {item.show_char}{' '}
                                    <Tooltip
                                      // options
                                      title={item.char_description}
                                      position="bottom"
                                      arrow={true}
                                      distance={20}
                                      trigger="mouseenter"
                                    >
                                      <i
                                        className="fa fa-info-circle ml-2 "
                                        // style={{ cursor: 'pointer' }}
                                        aria-hidden="true"
                                      ></i>
                                    </Tooltip>
                                  </div>
                                </div>

                                <div class="col-lg-2 col-xl-2 mb-2">
                                  <div
                                    class={
                                      item.show_category === 'non-speciality'
                                        ? 'tab-head-alt'
                                        : 'head-cost'
                                    }
                                  >
                                    {item.show_category === 'non-speciality'
                                      ? 'No'
                                      : 'Yes'}{' '}
                                  </div>
                                </div>
                                <div class="col-lg-8 col-xl-8 mb-2">
                                  <div
                                    class={
                                      item.show_category === 'non-speciality'
                                        ? 'tab-head-alt'
                                        : 'head-cost'
                                    }
                                  >
                                    {show_category}{' '}
                                  </div>
                                </div>
                              </div>
                            );
                          }
                        })}
                    </div>
                  )}
                <br />
                {showYouth?.length > 0 &&
                  showYouth.filter(
                    (word) =>
                      getDate(word.show_date) === getDate(costitem.cost_date)
                  ).length > 0 && (
                    <div className="row justify-content-center">
                      <div className="col-lg-12 col-xl-12 mb-4 box-head">
                        <strong>Show Information : YOUTH</strong>
                      </div>
                      <div className="row text-center">
                        <div className="col-lg-2 col-xl-2 mb-2">
                          <div className="tab-head-cost">Show No. </div>
                        </div>

                        <div className="col-lg-2 col-xl-2 mb-2">
                          <div className="tab-head-cost">Specialty</div>
                        </div>
                        <div className="col-lg-8 col-xl-8 mb-2">
                          <div className="tab-head-cost">Breed </div>
                        </div>
                      </div>
                      {showYouth &&
                        showYouth.map((item) => {
                          if (
                            getDate(item.show_date) ===
                            getDate(costitem.cost_date)
                          ) {
                            let show_category;
                            if (item.show_category === 'non-speciality') {
                              show_category = 'All Breeds';
                            } else {
                              //console.log(item.breed_list, "item.breed_list");
                              let breed_name = [];
                              item.breed_list?.map((iitem) => {
                                // console.log("iitem", iitem);
                                return breed_name.push(iitem.breed_name);
                              });

                              show_category = breed_name.join(', ').toString();
                              //console.log("show_category", breed_name);
                            }
                            return (
                              <div class="row text-center">
                                <div class="col-lg-2 col-xl-2 mb-2">
                                  <div
                                    class={
                                      item.show_category === 'non-speciality'
                                        ? 'tab-head-alt'
                                        : 'head-cost'
                                    }
                                  >
                                    {item.show_char}{' '}
                                    <Tooltip
                                      // options
                                      title={item.char_description}
                                      position="bottom"
                                      arrow={true}
                                      distance={20}
                                      trigger="mouseenter"
                                    >
                                      <i
                                        className="fa fa-info-circle ml-2 "
                                        // style={{ cursor: 'pointer' }}
                                        aria-hidden="true"
                                      ></i>
                                    </Tooltip>
                                  </div>
                                </div>

                                <div class="col-lg-2 col-xl-2 mb-2">
                                  <div
                                    class={
                                      item.show_category === 'non-speciality'
                                        ? 'tab-head-alt'
                                        : 'head-cost'
                                    }
                                  >
                                    {item.show_category === 'non-speciality'
                                      ? 'No'
                                      : 'Yes'}{' '}
                                  </div>
                                </div>
                                <div class="col-lg-8 col-xl-8 mb-2">
                                  <div
                                    class={
                                      item.show_category === 'non-speciality'
                                        ? 'tab-head-alt'
                                        : 'head-cost'
                                    }
                                  >
                                    {show_category}{' '}
                                  </div>
                                </div>
                              </div>
                            );
                          }
                        })}
                    </div>
                  )}
              </TabPane>
            );
          })}
        </TabContent>
      </Row>
    </div>
  );
};
