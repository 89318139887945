export const ADD_MORE_MEDIA_EQUIPMENT = 'ADD_MORE_MEDIA_EQUIPMENT'
export const DELETE_MEDIA_EQUIPMENT = 'DELETE_MEDIA_EQUIPMENT'
export const DELETE_MEDIA_SUCCESS_EQUIPMENT = 'DELETE_MEDIA_SUCCESS_EQUIPMENT'
export const ALL_MEDIA_LIST_EQUIPMENT = 'ALL_MEDIA_LIST_EQUIPMENT'
export const ALL_MEDIA_LIST_SUCCESS_EQUIPMENT = 'ALL_MEDIA_LIST_SUCCESS_EQUIPMENT'
export const SAVE_MEDIA_EQUIPMENT = 'SAVE_MEDIA_EQUIPMENT'
export const UPDATE_MEDIA_EQUIPMENT = 'UPDATE_MEDIA_EQUIPMENT'



export const ADD_EQUIPMENT ='ADD_EQUIPMENT'
export const LISTING_EQUIPMENT ='LISTING_EQUIPMENT'
export const LISTING_SUCCESS_EQUIPMENT ='LISTING_SUCCESS_EQUIPMENT'
export const DELETE_EQUIPMENT ='DELETE_EQUIPMENT'
export const UPDATE_EQUIPMENT ='UPDATE_EQUIPMENT'
export const LISTING_EQUIPMENT_FRONT ='LISTING_EQUIPMENT_FRONT'