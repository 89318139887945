import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js'

import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Button, Col, Input, Row } from 'reactstrap'
import { post, get } from '../../helpers/api_helper'
import { shopCartPrice } from '../../helpers/utils'
import { UrlEncodeHook } from '../../hooks'
import { getAllEventCart, getAllShopCartValue } from '../../store/actions'

// import { getAllEventCart, getAllShopCartValue } from '../store/actions';

export default function CheckoutForm(props) {
  const { urlDecode, urlEncode } = UrlEncodeHook()
  let user = JSON.parse(localStorage.getItem('authUser'))
  // let user = JSON.parse(localStorage.getItem('authUser'));
  const stripe = useStripe()
  const elements = useElements()

  const location = useLocation()
  const history = useHistory()
  const dispatch = useDispatch()

  let data = {
    user_id: user?.id,
  }
  const query = new URLSearchParams(location.search)
  let event_id = query.get('event_id')
  event_id = urlDecode(event_id)
  const event_type = query.get('event_type')
  const [message, setMessage] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  const shoppingCart = useSelector((state) => state.shopping_cart)
  const { shopping_product, eventList } = shoppingCart
  const { final_price } = eventList
  let total = 0
  if (shopping_product && shopping_product.length > 0) {
    total = shopCartPrice(shopping_product)
  }

  useEffect(() => {
    // debugger
    setIsLoading(true)
    dispatch(getAllShopCartValue(data))
    dispatch(getAllEventCart(user?.id))
    if (!stripe) {
      return
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      'payment_intent_client_secret'
    )

    if (!clientSecret) {
      setIsLoading(false)
      return
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case 'succeeded':
          setMessage('Payment succeeded!')
          break
        case 'processing':
          toast.error('Your payment is processing.')
          break
        case 'requires_payment_method':
          toast.error('Your payment was not successful, please try again.')
          break
        default:
          toast.error('Something went wrong.')
          break
      }
    })
  }, [stripe])

  const handleSubmit = async (e) => {
    e.preventDefault()

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return
    }

    setIsLoading(true)
    // debugger
    total = total + Number(final_price) || 0
    post(`api/v1/payment/get-total?in_cart=1&event_id=${event_id}`).then(
      (res) => {
        if (total !== res.total_final_price) {
          history.push({
            pathname: '/shopping_cart',
            state: {
              hasAdditionalProduct: true,
              hasShoppingCart: true,
              showAdditional: true,
            },
          })
        } else {
          stripe
            .confirmPayment({
              elements,
              confirmParams: {
                // Make sure to change this to your payment completion page
                return_url: `${
                  process.env.REACT_APP_URL
                }checkout?event_id=${urlEncode(
                  event_id
                )}&event_type=${event_type}`,
              },
            })
            .then((res) => {
              if (res.error) {
                if (
                  res.error.code !== 'incomplete_number' &&
                  res.error.code !== 'incomplete_expiry' &&
                  res.error.code !== 'invalid_expiry_month_past' &&
                  res.error.code !== 'incomplete_cvc'
                ) {
                  toast.error(res?.error?.message || 'invalid card details')
                } else if (res?.error?.message) {
                  toast.error(res?.error?.message)
                }
                setIsLoading(false)
              }
            })
            .catch((err) => {
              setIsLoading(false)
            })
        }
      }
    )

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    // if (error.type === 'card_error' || error.type === 'validation_error') {
    //   setMessage(error.message);
    // } else {
    //   setMessage('An unexpected error occured.');
    // }
  }

  return (
    <Row>
      <Col lg="12">
        <div className="card shadow-only-hover mt-8 ">
          <div className="card-body pt-5 pb-5">
            <Col lg="12">
              <form id="payment-form" onSubmit={handleSubmit}>
                <Row>
                  <Col xl={6}>
                    <div className="mb-4">
                      <Input
                        type="text"
                        className=""
                        readOnly
                        name="exhibitor_name"
                        id="exhibitor_name"
                        placeholder="Exhibitor Name"
                        value={props.user_name || ''}
                      />
                    </div>
                  </Col>

                  <Col xl={6}>
                    <div className="mb-4">
                      <Input
                        type="email"
                        className=""
                        name="email"
                        id="email"
                        readOnly
                        placeholder="Exhibitor Email Id "
                        value={user?.email || ''}
                      />
                    </div>
                  </Col>
                  <PaymentElement id="payment-element" />
                  {/* <CardElement /> */}
                  <Col lg={12}>
                    <div className="mt-4 text-center">
                      <Button
                        type="submit"
                        color="success"
                        disabled={!stripe || isLoading}
                        className="w-lg waves-effect waves-light"
                      >
                        PAY
                      </Button>
                    </div>
                  </Col>
                </Row>
              </form>
            </Col>
          </div>
        </div>
      </Col>
    </Row>
  )
}

/**

 */
