import React from 'react'
import { Link } from 'react-router-dom'
import { getDate } from '../../../helpers/date_helper'
import { UrlEncodeHook } from '../../../hooks'

const SponsorSanctionEventBox = (props) => {
  const { urlEncode } = UrlEncodeHook()

  const { sponsor } = props

  let displayEnd = true
  if (getDate(sponsor?.start_date_time) === getDate(sponsor?.end_date_time)) {
    displayEnd = false
  }
  let user = JSON.parse(localStorage.getItem('authUser'))
  return (
    <div className="col-md-12 mb-9">
      <div className="card shadow-only-hover">
        <Link
          to={
            user?.id
              ? `/sponsor_sanction/${urlEncode(sponsor.event_id)}`
              : `/sponsor_sanctions/${urlEncode(sponsor.event_id)}`
          }
        >
          <div className="card-body event-card-body">
            <div className="col-lg-12 col-xl-12 event-box">
              <div className="row">
                <div className="col-lg-4 col-xl-3 box-head">
                  <div className="row">
                    <div className="col-lg-5 col-xl-5 mb-4 mt-3 res-txt-center">
                      Event Date :
                    </div>
                    <div className="col-lg-7 col-xl-7 mb-4 pl-0">
                      <div className="highlight">
                        {getDate(sponsor?.start_date_time)}
                        <br />

                        {displayEnd && 'To'}
                        {displayEnd && <br />}
                        {displayEnd && getDate(sponsor?.end_date_time)}
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="col-lg-6 col-xl-6 box-head mt-3"
                  style={{ textAlign: 'center' }}
                >
                  {sponsor.event_name}
                </div>

                <div className="col-lg-3 col-xl-3 box-head">
                  <div className="mb-4 mt-2 p-0 text-center">
                    {sponsor.address_1}
                    {sponsor.address_1 ? ', ' : ''}
                    <br />
                    {sponsor.address_2}
                    {sponsor.address_2 ? ', ' : ''}
                    {sponsor.city}
                    {sponsor.city ? ', ' : ''}
                    {sponsor.state_name}
                    {sponsor.state_name ? ', ' : ''}
                    {sponsor?.zip_code}
                    {sponsor?.zip_code ? ', ' : ''}
                    {sponsor?.country_name || 'USA'}
                  </div>
                </div>

                <div className="col-lg-12 col-xl-12 box-btn text-center mt-2">
                  <div
                    className="col-lg-12 col-xl-12 mb-3 mt-6 toggle-example collapse"
                    id={`toggle-example`}
                  >
                    <div className="row justify-content-center">
                      {/* <div className="col-lg-3 text-center">
                        <button type="button" className="btn btn-primary">
                          Sponsor Sanction
                        </button>
                      </div> */}
                      {/* <div className="col-lg-3 text-center">
                        <button type="button" className="btn btn-primary">
                          Sponsor Awards
                        </button>
                      </div> */}
                    </div>
                    <br />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Link>
      </div>
    </div>
  )
}
export default SponsorSanctionEventBox
