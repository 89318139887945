import React, { useEffect, useState } from 'react'
import { Row, Col, Container, Label, Input } from 'reactstrap'
import { Link, Redirect, useHistory, useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'
import classNames from 'classnames'
import { post } from '../../../helpers/api_helper'

const TraditionalPlan = () => {
  const history = useHistory()
  const location = useLocation()

  const query = new URLSearchParams(location.search)
  const secretaryId = query.get('secretary')

  const [licenceLength, setLicenceLength] = useState(0)
  const [clubList, setClubList] = useState([])
  const [error_shows, setErrorShows] = useState('')

  let info = localStorage.getItem('purchaseInfo')
  if (info) info = JSON.parse(info)

  useEffect(() => {
    if (secretaryId) {
      post(`/api/v1/plan-purchase/get-secretary-details-for-plan-purchase`, {
        unique_string: secretaryId,
      })
        .then((data) => {
          if (data === 'Something went wrong.') {
            toast.error(data)
          }
          if (data) {
            let userInfo = {
              user_role: 'secretary',
              first_name: data.first_name.trim(),
              last_name: data.last_name.trim(),
              email: data.email,
              phone: data.phone,
              plan_type: 'traditional',
              club_list: [],
              open: 0,
              youth: 0,
            }

            localStorage.setItem('purchaseInfo', JSON.stringify(userInfo))
          }
        })
        .catch((error) => {
          toast.error('Something went wrong!')
          console.log(error)
        })
    }
  }, [secretaryId])

  const handleLicenceFields = (value) => {
    setLicenceLength(value)
    if (value) setErrorShows('')
    const clubs = []
    for (let index = 0; index < value; index++) {
      clubs.push({ club_name: '', error: '' })
    }
    setClubList(clubs)
  }

  const handleClubNameChange = (value, i) => {
    const temp = [...clubList]
    temp[i].club_name = value
    if (value) temp[i].error = ''
    setClubList(temp)
  }

  

  if (!info && !secretaryId) {
    return <Redirect to="/traditional_plan" />;
  }
  const onSubmitPlan = (e) => {
    e.preventDefault()
    // let onlyCharExpr = /^[a-zA-Z \-]+$/g;
    // let charRegex = new RegExp(onlyCharExpr);
    const temp = [...clubList]
    if (temp.length === 0) {
      setErrorShows('Please select atleast one license')
    } else {
      setErrorShows('')
      let validate = true
      for (let i = 0; i < temp.length; i++) {
        const element = temp[i]

        if (element.club_name.trim().length === 0) {
          element.error = 'Club name is required'
          validate = false
        } else if (element.club_name.trim().length > 50) {
          element.error =
            'Club name must be of minimum 1 and maximum 50 character length'
          validate = false
        } else {
          element.error = ''
        }
      }
      if (validate === false) {
        setClubList(temp)
      } else {
        info.plan_type = 'traditional'
        info.club_list = clubList
        localStorage.setItem('purchaseInfo', JSON.stringify(info))
        history.push({
          pathname: '/plan_shopping_cart',
        })
      }
    }
    // if (element.club_name.trim().length === 0) {
    //   errFirst = 'First name is required';
    // } else if (firstName.trim().length > 50) {
    //   errFirst =
    //     'First name must be of minimum 1 and maximum 50 character length';
    // } else {
    //   if (!firstName.trim().match(charRegex)) {
    //     errFirst = 'First name field must be alphabetic';
    //   }
    // }
  }
  return (
    <React.Fragment>
      <div className="page-content bg-gray-101 min-h-80">
        <section id="about" className="section1 bg-gray-101 pages event-list">
          <Container fluid={true} className="auto-fit">
            <Row>
              <Col lg={12} className="mt-5 text-center">
                <h2 className="top-title-plan mb-4">
                  <span>Plan Purchase</span>
                </h2>
              </Col>
            </Row>

            <Container>
              <form onSubmit={onSubmitPlan}>
                <Row>
                  <Col xl={12} className="plan-structure mt-5">
                    <div className="page-title-box justify-content-between">
                      <h2 className="mt-4 top-title mb-4 text-center">
                        <span>TRADITIONAL PLAN</span>
                      </h2>
                    </div>

                    <Col lg={6} className="mb-5 m-auto">
                      <div className="plan-input">
                        <Label className="form-label" htmlFor="">
                          How many License would you like to buy?{' '}
                        </Label>
                        <select
                          type="text"
                          name="licenses"
                          id="licenses"
                          className={classNames('form-control form-select', {
                            'is-invalid': error_shows.length,
                          })}
                          value={licenceLength}
                          onChange={(e) =>
                            handleLicenceFields(parseInt(e.target.value))
                          }
                        >
                          <option value={0}>Select Number of Licenses</option>
                          <option value={1}>1</option>
                          <option value={2}>2</option>
                          <option value={3}>3</option>
                          <option value={4}>4</option>
                          <option value={5}>5</option>
                          <option value={6}>6</option>
                          <option value={7}>7</option>
                          <option value={8}>8</option>
                          <option value={9}>9</option>
                          <option value={10}>10</option>
                        </select>
                      </div>
                      <div className="text-danger">{error_shows}</div>
                    </Col>
                    <Col lg={12} className="m-auto mb-4">
                      <hr />
                      {clubList.length > 0 &&
                        clubList.map((club, i) => {
                          console.log(club.error)
                          return (
                            <Col xl={12} key={i}>
                              <div className="mt-4 mb-3">
                                <Label className="form-label" htmlFor="club">
                                  Club Name *
                                </Label>
                                <Input
                                  type="text"
                                  className={classNames('form-control', {
                                    'is-invalid': club.error.length,
                                  })}
                                  id="club"
                                  value={club.club_name}
                                  onChange={(e) =>
                                    handleClubNameChange(e.target.value, i)
                                  }
                                  placeholder="Enter Club Name"
                                />
                                <div className="text-danger">{club.error}</div>
                              </div>
                            </Col>
                          )
                        })}
                      <div className="plan-btn text-center mt-5 col-lg-12">
                        {/* <button type="button" className="btn btn-primary btn-back w-lg"> BACK </button>
                        <button type="button" className="btn btn-primary btn-easy w-lg">CONTINUE</button> */}
                        {!Boolean(secretaryId) ? (
                          <Link
                            to="/traditional_plan"
                            className="btn btn-primary btn-back w-lg"
                          >
                            BACK
                          </Link>
                        ) : null}
                        {/* <Link
                          to="/traditional_plan"
                          className="btn btn-primary btn-back w-lg"
                        >
                          {' '}
                          BACK{' '}
                        </Link> */}
                        <button
                          type="submit"
                          className="btn btn-primary btn-easy w-lg"
                          onClick={(e) => onSubmitPlan(e)}
                        >
                          CONTINUE
                        </button>
                      </div>
                    </Col>
                  </Col>
                </Row>
              </form>
            </Container>
          </Container>
        </section>
      </div>
    </React.Fragment>
  )
}

export default TraditionalPlan