import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { Row, Col, Container, Label, Input } from 'reactstrap';
import InputMask from 'react-input-mask';
import { useHistory } from 'react-router-dom';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { get, post } from '../../../helpers/api_helper';
import * as url from '../../../helpers/url_helper';
import ResendVerifyModal from '../ResendVerifyModal';
import { toast } from 'react-toastify';

const TraditionalPlanForm = () => {
  const history = useHistory();

  const [firstName, setfirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword1, setShowPassword1] = useState(false);
  const [activeStatus, setActiveStatus] = useState(null);
  const [uniqueString, setUniqueString] = useState(null);
  const [submitLoading, setSubmitLoading] = useState(false);

  let info = localStorage.getItem('purchaseInfo');
  if (info) info = JSON.parse(info);

  useEffect(() => {
    if (info) {
      setfirstName(info.first_name);
      setLastName(info.last_name);
      setPhone(info.phone);
      setEmail(info.email);
      checkPassword(info.email);
    }
  }, []);

  const [error, setError] = useState({
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    password: '',
  });

  let showPwd1 = () => {
    setShowPassword1(true);
  };

  let hidePwd1 = () => {
    setShowPassword1(false);
  };

  const checkPassword = (pwd) => {
    get(url.VERIFY_SECRETARY_EMAIL + pwd).then((res) => {
      if (res) {
        setActiveStatus(res?.data?.active);
        setUniqueString(res?.data?.unique_string);
      } else {
        setActiveStatus(null);
        setUniqueString(null);
        setPassword('');
      }
    });
  };

  const handleEmailChange = (value) => {
    setEmail(value);
    checkPassword(value);
  };

  const formSubmit = (e) => {
    e.preventDefault();

    let errFirst = '';
    let errLast = '';
    let errEmail = '';
    let errPhone = '';
    let errPwd = '';

    // let onlyCharExpr = /^[a-zA-Z0-9 \-]+$/g;
    let onlyCharExpr = /^[a-zA-Z \-]+$/g;
    let charRegex = new RegExp(onlyCharExpr);

    if (firstName.trim().length === 0) {
      errFirst = 'First name is required';
    } else if (firstName.trim().length > 50) {
      errFirst =
        'First name must be of minimum 1 and maximum 50 character length';
    } else {
      if (!firstName.trim().match(charRegex)) {
        errFirst = 'First name field must be alphabetic';
      }
    }

    if (lastName.trim().length === 0) {
      errLast = 'Last name is required';
    } else if (lastName.trim().length > 50) {
      errLast =
        'Last name must be of minimum 1 and maximum 50 character length';
    } else {
      if (!lastName.trim().match(charRegex)) {
        errLast = 'Last name field must be alphabetic';
      }
    }

    errEmail = !email && 'Email is required';
    var emailregex =
      /^[a-zA-Z0-9_]+(\.[a-zA-Z0-9_]+)*@[a-zA-Z0-9]+([a-zA-Z0-9]+)*(\.[a-zA-Z0-9]+([a-zA-Z0-9]+)*)*\.[a-zA-Z]{2,4}$/;
    if (email && !emailregex.test(email)) {
      errEmail = 'Invalid email address';
    }
    if (phone.replace(/[^0-9]/g, '').length === 0) {
      errPhone = 'Phone number is required';
    } else if (phone.replace(/[^0-9]/g, '').length !== 10) {
      errPhone = 'Phone number must be 10 digits';
    }

    let textRegex =
      /^(?!.*\s)(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[~`!@#$%^&*()--+={}\[\]|\\:;"'<>,.?/_₹]).{8,15}$/;
    // /^(?=.*([A-Z]){1,})(?=.*[!@#$:=%^*-_+?;<>,.&*]{1,})(?=.*[0-9]{1,})(?=.*[a-z]{1,}).{8,15}$/;

    if (activeStatus === 1 && password.trim().length === 0) {
      errPwd = 'Password is required';
    } else if (activeStatus === 1 && password && !textRegex.test(password)) {
      errPwd =
        'Password must have: Minimum 8 and maximum 15 characters, at least one uppercase letter, one lowercase letter, one number and one special character';
    }

    if (errFirst || errEmail || errLast || errPhone || errPwd) {
      setError({
        firstName: errFirst,
        lastName: errLast,
        email: errEmail,
        phone: errPhone,
        password: errPwd,
      });
    } else {
      setError({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        password: '',
      });

      let userInfo = {
        user_role: 'secretary',
        first_name: firstName.trim(),
        last_name: lastName.trim(),
        email,
        phone: phone.replace(/[^0-9]/g, ''),
        plan_type: 'traditional',
        club_list: [],
        open: info?.open || 0,
        youth: info?.youth || 0,
      };
      const loginData = {
        email,
        password,
        role: 'secretary',
      };
      if (activeStatus === 1) {
        setSubmitLoading(true);
        post(`/api/v1/login`, loginData)
          .then((response) => {
            if (response) {
              setSubmitLoading(false);
              localStorage.setItem('purchaseInfo', JSON.stringify(userInfo));
              history.push('/traditional_plan/licences');
            }
          })
          .catch((err) => {
            setSubmitLoading(false);
            toast.error(err.response.data);
          });
      } else if (activeStatus === null) {
        localStorage.setItem('purchaseInfo', JSON.stringify(userInfo));
        history.push('/traditional_plan/licences');
      } else if (activeStatus === 0) {
        setActiveStatus(null);
        setTimeout(() => {
          setActiveStatus(0);
        }, 1000);
      }
    }
  };

  const clearFields = (e) => {
    setfirstName('');
    setLastName('');
    setPhone('');
    setEmail('');
    setPassword('');
  };

  return (
    <React.Fragment>
      <div className="page-content bg-gray-101 min-h-80">
        <section id="about" class="section1 bg-gray-101 pages event-list">
          <Container fluid={true} className="auto-fit">
            <Row>
              <Col lg={12} className="mt-5 text-center">
                <h2 className="top-title-plan mb-4">
                  <span>Plan Purchase</span>
                </h2>
              </Col>
            </Row>

            <Container>
              <Row>
                <Col xl={12} className="plan-structure mt-5">
                  <div className="page-title-box justify-content-between">
                    <h2 className="mt-4 top-title mb-4 text-center">
                      <span>TRADITIONAL PLAN</span>
                    </h2>
                  </div>
                  <form onSubmit={formSubmit} autocomplete="off">
                    <Col lg={10} className="mb-3 m-auto">
                      <Row>
                        <Col lg={6}>
                          <div className="mt-4 mb-3">
                            <Label className="form-label" htmlFor="">
                              First Name *
                            </Label>
                            <Input
                              type="text"
                              id="fname"
                              placeholder="Enter First Name"
                              value={firstName}
                              onChange={(e) => {
                                setfirstName(e.target.value);
                              }}
                              className={classNames('form-control', {
                                'is-invalid': error.firstName.length,
                              })}
                            />
                            <div className="text-danger">{error.firstName}</div>
                          </div>
                        </Col>
                        <Col lg={6}>
                          <div className="mt-4 mb-3">
                            <Label className="form-label" htmlFor="">
                              Last Name *
                            </Label>
                            <Input
                              type="text"
                              id="lname"
                              placeholder="Enter Last Name"
                              value={lastName}
                              onChange={(e) => {
                                setLastName(e.target.value);
                              }}
                              className={classNames('form-control', {
                                'is-invalid': error.lastName.length,
                              })}
                            />
                            <div className="text-danger">{error.lastName}</div>
                          </div>
                        </Col>
                        <Col lg={6}>
                          <div className="mt-4 mb-3">
                            <Label className="form-label" htmlFor="">
                              Email * (Note- Email will be used as Username.){' '}
                            </Label>
                            <Input
                              type="text"
                              id="email"
                              placeholder="Enter Email"
                              value={email}
                              onChange={(e) => {
                                handleEmailChange(e.target.value);
                              }}
                              className={classNames('form-control', {
                                'is-invalid': error.email.length,
                              })}
                            />
                            <div className="text-danger">{error.email}</div>
                          </div>
                        </Col>
                        {activeStatus === 1 && (
                          <Col lg={6}>
                            <div className="mt-4 mb-3">
                              <Label className="form-label" htmlFor="">
                                Password *
                              </Label>
                              <Input
                                name="password"
                                placeholder="Password"
                                value={password}
                                onChange={(e) => {
                                  setPassword(e.target.value);
                                }}
                                // style={{ borderColor: 'black' }}
                                className={classNames('form-control', {
                                  'is-invalid': error.password.length,
                                })}
                                type={showPassword1 ? 'text' : 'password'}
                              />
                              {showPassword1 && (
                                <RemoveRedEyeIcon
                                  className="pwd-icon"
                                  onClick={hidePwd1}
                                />
                              )}
                              {!showPassword1 && (
                                <VisibilityOffIcon
                                  className="pwd-icon"
                                  onClick={showPwd1}
                                />
                              )}
                              <div className="text-danger">
                                {error.password}
                              </div>
                              <span className="mt- 2 text-muted">
                                (Note: Minimum 8 and maximum 15 characters, at
                                least one uppercase letter, one lowercase
                                letter, one number and one special character)
                              </span>
                            </div>
                          </Col>
                        )}
                        <Col lg={6}>
                          <div className="mt-4 mb-3">
                            <Label className="form-label" htmlFor="">
                              Phone *
                            </Label>
                            <InputMask
                              mask="999-999-9999"
                              value={phone}
                              className={classNames('form-control', {
                                'is-invalid': error.phone.length,
                              })}
                              placeholder="Enter Phone Number"
                              onChange={(e) => setPhone(e.target.value)}
                            />
                            <div className="text-danger">{error.phone}</div>
                          </div>
                        </Col>
                      </Row>

                      <div className="plan-btn text-center mt-5 col-lg-12">
                        <button
                          type="submit"
                          className="btn btn-primary btn-easy w-lg mb-3"
                          // onClick={(e) => formSubmit(e)}
                          disabled={submitLoading}
                        >
                          SUBMIT
                        </button>
                      </div>
                    </Col>
                  </form>
                </Col>
              </Row>
            </Container>
            {activeStatus === 0 && (
              <ResendVerifyModal
                active={activeStatus}
                uniqueString={uniqueString}
                resetFields={clearFields}
                redirectLink={'/traditional_plan'}
              />
            )}
          </Container>
        </section>
      </div>
    </React.Fragment>
  );
};

export default TraditionalPlanForm;
