
// Additional Product
export const GET_ALL_ADITIONAL_PRODUCT = "GET_ALL_ADITIONAL_PRODUCT"
export const SET_ADITIONAL_PRODUCT_LOADING  = "SET_ADITIONAL_PRODUCT_LOADING"
export const SET_ADITIONAL_PRODUCT_ERROR  = "SET_ADITIONAL_PRODUCT_ERROR"
export const GET_ALL_ADDITIONAL_PRODUCT_SUCCESS = "GET_ALL_ADDITIONAL_PRODUCT_SUCCESS"
export const SET_LOADING_ADDITIONAL_PRODUCT = "SET_LOADING_ADDITIONAL_PRODUCT"
export const ADITIONAL_PRODUCT_INCREMENT  = "ADITIONAL_PRODUCT_INCREMENT"
export const ADITIONAL_PRODUCT_DECREMENT = 'ADITIONAL_PRODUCT_DECREMENT'
export const SAVE_ADITIONAL_PRODUCT = 'SAVE_ADITIONAL_PRODUCT'

