import React, { useState, useEffect } from 'react';
import { get } from '../../../helpers/api_helper';

const RunningTotal = (props) => {
  const [allBreedShow, setAllBreedShow] = useState([]);
  let totalYouth = 0;
  let totalOpen = 0;
  useEffect(() => {
    get(
      `/api/v1/participation/running-total/${props.event.event_id}/breed-show-type`
    )
      .then((responce) => {
        setAllBreedShow(responce.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [props.event]);
  return (
    <div
      className="collapse runningTotal"
      id={`RunningTotal-${props.event.event_id}`}
    >
      <div className="container">
        <div className="col-md-12 mb-7">
          <hr />
          {/* Collpase code */}
          <div className="row justify-content-center text-center running-total">
            {' '}
            <p>
              <strong>Running Total: </strong> “Running totals shown below are
              for all ‘shows’ within each category (i.e - ‘Open’ is the combined
              total of A &amp; B)
            </p>
            <div className="row">
              <div className="row">
                <div className="col-lg-4 col-xl-4 mb-3">
                  <div className="tab-running">BREED</div>
                </div>
                <div className="col-lg-4 col-xl-4 mb-3">
                  <div className="tab-run-show">OPEN </div>
                </div>
                <div className="col-lg-4 col-xl-4 mb-3">
                  <div className="tab-run-show">YOUTH </div>
                </div>
              </div>
              {allBreedShow?.map((item, index) => {
                item.open
                  ? (totalOpen = totalOpen + item.open)
                  : (totalOpen = totalOpen);
                item.youth
                  ? (totalYouth = totalYouth + item.youth)
                  : (totalYouth = totalYouth);
                return (
                  <div className="row">
                    <div className="col-lg-4 col-xl-4 mb-3">
                      <div className="tab-running">{item.breed_name}</div>
                    </div>
                    <div className="col-lg-4 col-xl-4 mb-3">
                      <div className="tab-run-show">
                        {item.open ? item.open : '-'}
                      </div>
                    </div>
                    <div className="col-lg-4 col-xl-4 mb-3">
                      <div className="tab-run-show">
                        {item.youth ? item.youth : '-'}
                      </div>
                    </div>
                  </div>
                );
              })}
              <div className="row">
                <div className="col-lg-4 col-xl-4 mb-3">
                  <div className="tab-running">Total</div>
                </div>
                <div className="col-lg-4 col-xl-4 mb-3">
                  <div className="tab-run-show">{totalOpen}</div>
                </div>
                <div className="col-lg-4 col-xl-4 mb-3">
                  <div className="tab-run-show">{totalYouth}</div>
                </div>
              </div>
              <div className="text-center mt-4 col-lg-12">
                <button
                  type="button"
                  className="btn btn-primary btn-red w-15 r-w-f"
                  onClick={() => {
                    props.handlRunningTotalColapse(props.event.event_id);
                  }}
                >
                  CLOSE
                </button>
              </div>
            </div>
          </div>
          {/* Collpase code end*/}
        </div>
      </div>
    </div>
  );
};

export default RunningTotal;
