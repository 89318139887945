import {
  GET_ALL_EXHIBITOR_LIST_SUCCESS,
  GET_ALL_EXHIBITOR_LIST_FAIL,
  SET_EXHIBITOR_LOADING,
  GET_CATEGORY_LIST_SUCCESS,
  GET_CATEGORY_LIST_FAIL,
  GET_ENTRY_LIST_SUCCESS,
  GET_ENTRY_LIST_FAIL,
  GET_CATEGORY_LIST_BY_EVENT_SUCCESS,
  GET_CATEGORY_LIST_BY_EVENT_FAIL,
} from './actionTypes';

const initialState = {
  error: '',
  loading: false,
  exhibitorList: [],
  breedCategory: [],
  breedCategoryByEvent: [],
  easyEntryList: [],
};

const easyEntry = (state = initialState, action) => {
  switch (action.type) {
    case SET_EXHIBITOR_LOADING:
      state = { ...state, loading: true, error: '' };
      break;
    case GET_ALL_EXHIBITOR_LIST_SUCCESS:
      state = { ...state, exhibitorList: action.payload.data, loading: false };
      break;
    case GET_ALL_EXHIBITOR_LIST_FAIL:
      state = { ...state, error: action.payload, loading: false };
      break;
    case GET_CATEGORY_LIST_SUCCESS:
      state = { ...state, breedCategory: action.payload.data, loading: false };
      break;
    case GET_CATEGORY_LIST_FAIL:
      state = { ...state, error: action.payload, loading: false };
      break;
    case GET_CATEGORY_LIST_BY_EVENT_SUCCESS:
      state = {
        ...state,
        breedCategoryByEvent: action.payload.data,
        loading: false,
      };
      break;
    case GET_CATEGORY_LIST_BY_EVENT_FAIL:
      state = { ...state, error: action.payload, loading: false };
      break;
    case GET_ENTRY_LIST_SUCCESS:
      state = {
        ...state,
        easyEntryList: [...action.payload.data],
        loading: false,
      };
      break;
    case GET_ENTRY_LIST_FAIL:
      state = { ...state, error: action.payload, loading: false };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};
export default easyEntry;
