import { ALL_STATE_LIST, STATE_LIST_RESET } from './actionType'

//
export const allStateList = (data) => ({
  type: ALL_STATE_LIST,
  payload: data,
})
export const resetState = (data) => ({
  type: STATE_LIST_RESET,
  payload: data,
})