import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { width } from '@mui/system';
import {
  setShowAlertp,
  setMsgp,
  setMessageTitlep,
} from '../../store/popup/actions';
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <div
          aria-label="close"
          onClick={onClose}
        >
          <CloseIcon  
            style={{
              background: 'red',
              position: 'absolute',
              top: 10,
              right: 10,
              color: '#fff',
              padding: 2,
              height: 30,
              width: 30,
              cursor: 'pointer',
          }}/>
        </div>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function CustomizedDialogs(props) {
  let dispatch = useDispatch();

  const { showAlertp, msgp, handleClickedp, titlep, showBtnp, Btntextp } =
    useSelector(({ popup }) => popup);
  const handleClickOpen = () => {
    props.setShowAlert(true);
  };
  const handleClose = () => {
    dispatch(setShowAlertp(false));
    dispatch(setMsgp(''));
  };

  return (
    <div>
      {/* <Button variant="outlined" onClick={handleClickOpen}>
        Open dialog
      </Button> */}
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={showAlertp}
      >
        <div
         className='res-pop'
          style={{
            textAlign: 'center',
            width: '350px',
            background: 'rgb(3 33 114)',
            color: '#fff',
            padding: '10px',
          }}
        >
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={handleClose}
          ></BootstrapDialogTitle>
          <DialogContent style={{ marginTop: '10px' }}>
            <Typography className="para" gutterBottom>
              {msgp}
            </Typography>
            {showBtnp && (
              <button
                className="btn btn-primary btn-easy"
                style={{
                  background: 'green',
                  color: '#fff',
                  margin: '20px 0 0 0',
                }}
                onClick={() => {
                  dispatch(setShowAlertp(false));
                  handleClickedp();
                }}
              >
                {Btntextp}
              </button>
            )}
          </DialogContent>
        </div>
      </BootstrapDialog>
    </div>
  );
}
