import { Field, useFormikContext } from 'formik'
import React, { useEffect } from 'react'
import { Col, Label } from 'reactstrap'
import { SelectField, Switch, TextField } from '../../Atoms'

import { useDispatch, useSelector } from 'react-redux'
import {
  getBreedHierarchy,
  resetBreedHierarchy,
} from '../../../../store/actions'
import _ from 'lodash'

const BreedSectionForm = ({ values, md = 6, ...props }) => {
  const { breed_list, group_list, variety_list } = useSelector((state) => {
    return {
      breed_list: state?.herdManagement?.Pedigree?.configureData?.breed_list,
      group_list: state?.herdManagement?.Pedigree?.configureData?.group_list,
      variety_list:
        state?.herdManagement?.Pedigree?.configureData?.variety_list,
    }
  })
  return (
    <React.Fragment>
      <ResetExistingPedigreeData />
      {!values.rabbit_id && !values?.add_from_existing_pedigree ? (
        <React.Fragment>
          <Col sm='12' md={md}>
            <div className='mb-3'>
              <Label className='form-label' htmlFor=''>
                Breed Name *
                <span className='ms-2'>
                  <Field
                    name='add_new_breed'
                    component={Switch}
                    resetvalue={['breed_id', 'breed_name']}
                  />
                  (Add New Breed)
                </span>
              </Label>
              {!values['add_new_breed'] ? (
                <Field
                  name='breed_id'
                  type='select'
                  id='breed_id'
                  resetvalue={[
                    'group_id',
                    'variety_id',
                    'group_name',
                    'variety_name',
                    'group_seq',
                    'variety_seq',
                  ]}
                  onlyShowOption
                  option={[
                    {
                      label: 'Select Breed',
                      value: '',
                    },
                  ].concat(breed_list)}
                  component={SelectField}
                />
              ) : (
                <Field
                  name='breed_name'
                  placeholder='Breed Name'
                  type='text'
                  id='breed_name'
                  component={TextField}
                />
              )}
            </div>
          </Col>
          {/*  */}
          <GetBreedHierarchyComponent />
          {/*  */}

          <Col sm='12' md={md}>
            <div className='mb-3'>
              <Label className='form-label' htmlFor=''>
                Group 
                {/* {values?.group_seq ? '*' : ''} */}
                <span className='ms-2'>
                  <Field
                    name='add_new_group'
                    resetvalue={['group_id', 'group_name', 'variety_seq']}
                    component={Switch}
                  />
                  (Add New Group)
                </span>
              </Label>
              {!values['add_new_group'] ? (
                <Field
                  name='group_id'
                  type='select'
                  id='group'
                  onlyShowOption
                  option={[
                    {
                      label: 'Select Group',
                      value: '',
                    },
                  ].concat(group_list)}
                  resetvalue={['variety_id', 'variety_name']}
                  component={SelectField}
                />
              ) : (
                <Field
                  name='group_name'
                  placeholder='Group Name'
                  type='text'
                  id='group_name'
                  component={TextField}
                />
              )}
            </div>
          </Col>

          <Col sm='12' md={md}>
            <div className='mb-3'>
              <Label className='form-label' htmlFor=''>
                Variety 
                {/* {values?.variety_seq ? '*' : ''} */}
                <span className='ms-2'>
                  <Field
                    name='add_new_variety'
                    resetvalue={['variety_id', 'variety_name']}
                    component={Switch}
                  />
                  (Add New Variety)
                </span>
              </Label>
              {!values['add_new_variety'] ? (
                <Field
                  name='variety_id'
                  type='select'
                  id='variety_id'
                  onlyShowOption
                  option={[
                    {
                      label: 'Select Variety',
                      value: '',
                    },
                  ].concat(
                    _.filter(
                      variety_list,
                      (v) => Number(v?.group_id) === Number(values?.group_id)
                    )
                  )}
                  component={SelectField}
                />
              ) : (
                <Field
                  name='variety_name'
                  placeholder='Variety Name'
                  type='text'
                  id='variety_name'
                  component={TextField}
                />
              )}
            </div>
          </Col>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Col sm='12' md={md}>
            <div className='mb-3'>
              <Label className='form-label' htmlFor=''>
                Breed Name *
              </Label>
              <Field
                name='breed_name'
                placeholder='Breed Name'
                type='text'
                id='breed_name'
                component={TextField}
              />
            </div>
          </Col>
          <Col sm='12' md={md}>
            <div className='mb-3'>
              <Label className='form-label' htmlFor=''>
                Group
              </Label>
              <Field
                name='group_name'
                placeholder='Group Name'
                type='text'
                id='group_name'
                component={TextField}
              />
            </div>
          </Col>

          <Col sm='12' md={md}>
            <div className='mb-3'>
              <Label className='form-label' htmlFor=''>
                Variety
              </Label>
              <Field
                name='variety_name'
                placeholder='Variety Name'
                type='text'
                id='variety_name'
                component={TextField}
              />
            </div>
          </Col>
        </React.Fragment>
      )}
    </React.Fragment>
  )
}

export default BreedSectionForm

const GetBreedHierarchyComponent = () => {
  const { values, setFieldValue } = useFormikContext()
  const dispatch = useDispatch()
  const { breed_list, group_list, variety_list } = useSelector((state) => {
    return {
      breed_list: state?.herdManagement?.Pedigree?.configureData?.breed_list,
      group_list: state?.herdManagement?.Pedigree?.configureData?.group_list,
      variety_list:
        state?.herdManagement?.Pedigree?.configureData?.variety_list,
    }
  })

  useEffect(() => {
    if (values.breed_id && _.size(breed_list)) {
      const findBreed = _.find(
        breed_list,
        (b) => Number(b.breed_id) === Number(values.breed_id)
      )

      setFieldValue('group_seq', findBreed?.group_seq || null, false)
      setFieldValue('variety_seq', findBreed?.variety_seq || null, false)
      setFieldValue('breed_name', findBreed?.breed_name || null, false)
      if (_.size(findBreed)) {
        const data = {
          body: {
            breed_category_id_list: [findBreed?.breed_category_id],
            breed_id_list: [findBreed?.breed_id],
            tree: 0,
            visible: 1,
            sort_by_class: 1,
          },
          param: {},
        }
        dispatch(getBreedHierarchy(data))
      }
    } else {
      dispatch(resetBreedHierarchy())
    }
  }, [breed_list, values.breed_id])

  //   Set Group Data
  useEffect(() => {
    if (values.group_id && _.size(group_list)) {
      const findBreed = _.find(
        group_list,
        (b) => Number(b.group_id) === Number(values.group_id)
      )
      setFieldValue('group_name', findBreed?.group_name || null, false)
    }
  }, [group_list, values.group_id])

  //   Set Variety Data

  useEffect(() => {
    if (values.variety_id && _.size(variety_list)) {
      const findBreed = _.find(
        variety_list,
        (b) => Number(b.variety_id) === Number(values.variety_id)
      )
      setFieldValue('variety_name', findBreed?.variety_name || null, false)
    }
  }, [variety_list, values.variety_id])

  //

  return null
}

const ResetExistingPedigreeData = () => {
  const { values, setFieldValue } = useFormikContext()
  useEffect(() => {
    if (values?.add_from_existing_pedigree) {
      setFieldValue('add_new_group', true, false)
      setFieldValue('add_new_breed', true, false)
      setFieldValue('add_new_variety', true, false)
    }
    if (values.rabbit_id) {
      setFieldValue('add_new_group', true, false)
      setFieldValue('add_new_breed', true, false)
      setFieldValue('add_new_variety', true, false)
    }
    if (!values?.add_from_existing_pedigree && !values.rabbit_id) {
      setFieldValue('add_new_group', false, false)
      setFieldValue('add_new_breed', false, false)
      setFieldValue('add_new_variety', false, false)
    }
  }, [values?.add_from_existing_pedigree])

  return null
}
