import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Container } from 'reactstrap'
import { connect } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'

// Redux Store
import { showRightSidebarAction, toggleLeftmenu } from '../../store/actions'

//i18n
import { withTranslation } from 'react-i18next'

const Header = (props) => {
  const [isActive, setIsActive] = useState(false)

  const handleClick = (event) => {
    // 👇️ toggle isActive state on click
    setIsActive((current) => !current)
  }

  const location = useLocation()
  let lastUrl = location.pathname.substring(
    location.pathname.lastIndexOf('/') + 1
  )

  const [isSearch, setSearch] = useState(false)
  const [socialDrp, setsocialDrp] = useState(false)

  function toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen()
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen()
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        )
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen()
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen()
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen()
      }
    }
  }
  return (
    <>
      {location.pathname !== '/clerk_login' && (
        <React.Fragment>
          <div
            className='header-main header-dark fixed-top'
            style={{ opacity: '1' }}
          >
            <div className='navbar navbar-expand-lg navbar-dark'>
              <Container fluid={true} className='auto-fit'>
                <Link className='navbar-brand' to='/events'>
                  <img
                    className='logo-dark'
                    src={`${process.env.PUBLIC_URL}/assets/img/logo/logo.png`}
                    title=''
                    alt=''
                  />
                  <img
                    className='logo-light'
                    src={`${process.env.PUBLIC_URL}/assets/img/logo/logo.png`}
                    title=''
                    alt=''
                  />
                </Link>

                <button
                  className='navbar-toggler'
                  onClick={handleClick}
                  type='button'
                  data-bs-toggle='collapse'
                  data-bs-target='#navbarSupportedContent'
                  aria-controls='navbarSupportedContent'
                  aria-expanded='false'
                  aria-label='Toggle navigation'
                >
                  <span className='navbar-toggler-icon'></span>
                </button>

                <div
                  className={
                    isActive
                      ? 'show collapse navbar-collapse'
                      : 'collapse navbar-collapse'
                  }
                  id='navbarSupportedContent'
                >
                  <ul className='navbar-nav ms-auto align-items-center'>
                    <li className='nav-item'>
                      <Link
                        to='/events'
                        className={`nav-link ${
                          lastUrl == 'events' ? 'active' : ''
                        }  ${lastUrl == '' ? 'active' : ''}`}
                      >
                        Events
                      </Link>
                    </li>
                    <li className='nav-item'>
                      <Link
                        to='/sponsor_sanctions'
                        className={`nav-link ${
                          lastUrl === 'sponsor_sanctions' ? 'active' : ''
                        }`}
                      >
                        Sponsors
                      </Link>
                      {/* <Link className='nav-link'> </Link> */}
                    </li>
                    <li className='nav-item'>
                      <Link
                        to='/premier-membership'
                        className={`nav-link ${
                          lastUrl === 'premier-membership' ? 'active' : ''
                        }`}
                      >
                        Upgrade Plan
                      </Link>
                      {/* <Link className='nav-link'> </Link> */}
                    </li>
                    <li className='nav-item'>
                      <Link
                        to='/register'
                        className={`nav-link ${
                          lastUrl == 'register' ? 'active' : ''
                        }`}
                      >
                        User Registration
                      </Link>
                    </li>
                    <li className='nav-item'>
                      <Link
                        to='/login'
                        className={`nav-link ${
                          lastUrl == 'login' ? 'active' : ''
                        }`}
                      >
                        Login
                      </Link>
                    </li>

                    <li className='nav-item'>
                      <Link
                        to='/clerk_login'
                        className={`nav-link ${
                          lastUrl == 'clerk_login' ? 'active' : ''
                        }`}
                      >
                        Clerk Login
                      </Link>
                    </li>
                  </ul>
                </div>
              </Container>
            </div>
          </div>
        </React.Fragment>
      )}
    </>
  )
}

Header.propTypes = {
  leftMenu: PropTypes.any,
  showRightSidebar: PropTypes.any,
  showRightSidebarAction: PropTypes.func,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func,
}

const mapStatetoProps = (state) => {
  const { layoutType, showRightSidebar, leftMenu } = state.Layout
  return { layoutType, showRightSidebar, leftMenu }
}

export default connect(mapStatetoProps, {
  showRightSidebarAction,
  toggleLeftmenu,
})(withTranslation()(Header))