import {
  GET_ALL_PREMIER_REPORT_BREED_LIST,
  GET_ALL_PREMIER_REPORT_DETAIL_BY_BREED,
  GET_ALL_PREMIER_REPORT_EVENT_DETAIL,
  GET_ALL_PREMIER_REPORT_EVENT_LIST,
  GET_ALL_PREMIER_REPORT_SHOW_FINALIZED,
  GET_ALL_PREMIER_REPORT_SHOW_FINALIZED_STATUS,
  GET_ALL_PREMIER_REPORT_SHOW_LIST,
} from './actionType'

export const getAllPremierReportEventList = (data) => {
  return {
    type: GET_ALL_PREMIER_REPORT_EVENT_LIST,
    payload: data,
  }
}
export const getAllPremierReportEventDetail = (data) => {
  return {
    type: GET_ALL_PREMIER_REPORT_EVENT_DETAIL,
    payload: data,
  }
}
export const getAllPremierReportShowList = (data) => {
  return {
    type: GET_ALL_PREMIER_REPORT_SHOW_LIST,
    payload: data,
  }
}
export const getAllPremierReportBreedList = (data) => {
  return {
    type: GET_ALL_PREMIER_REPORT_BREED_LIST,
    payload: data,
  }
}

export const getAllPremierReportShowFinalizedStatus = (data) => {
  return {
    type: GET_ALL_PREMIER_REPORT_SHOW_FINALIZED_STATUS,
    payload: data,
  }
}
export const getAllPremierReportShowFinalized = (data) => {
  return {
    type: GET_ALL_PREMIER_REPORT_SHOW_FINALIZED,
    payload: data,
  }
}
export const getAllPremierReportDetailByBreed = (data) => {
  return {
    type: GET_ALL_PREMIER_REPORT_DETAIL_BY_BREED,
    payload: data,
  }
}
