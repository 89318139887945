import classNames from 'classnames'
import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import InputMask from 'react-input-mask'
import { Row, Col, Input } from 'reactstrap'
import { get, post } from '../../helpers/api_helper'
import { toast } from 'react-toastify'
import SuccessPopup from './successPopup'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'

const RegisterSecretary = () => {
  const location = useLocation()
  const history = useHistory()
  useEffect(() => {
    get(`/api/v1/address/country/all`)
      .then((data) => {
        setAllCountry(data.data)
      })
      .catch((error) => {
        console.log(error)
      })
  }, [])
  const query = new URLSearchParams(location.search)
  const secretaryId = query.get('secretary')

  let info = localStorage.getItem('purchaseInfo')
  if (info) info = JSON.parse(info)

  const [id, setId] = useState('')
  const [allCountry, setAllCountry] = useState([])
  const [allState, setAllState] = useState([])
  const [firstName, setfirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [phone, setPhone] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [address1, setAddress1] = useState('')
  const [address2, setAddress2] = useState('')
  const [country, setCountry] = useState('')
  const [state, setState] = useState('')
  const [city, setCity] = useState('')
  const [zipcode, setZipcode] = useState('')
  const [showAlert, setShowAlert] = useState('')
  const [msg, setMsg] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const [showPassword1, setShowPassword1] = useState(false)
  const [loading, setLoading] = useState(false)
  let showPwd1 = () => {
    setShowPassword1(true)
  }
  let hidePwd1 = () => {
    setShowPassword1(false)
  }
  let showPwd = () => {
    setShowPassword(true)
  }
  let hidePwd = () => {
    setShowPassword(false)
  }
  useEffect(() => {
    post(`/api/v1/plan-purchase/get-secretary-details-for-plan-purchase`, {
      unique_string: secretaryId,
    })
      .then((data) => {
        if (data === 'Something went wrong.') {
          toast.error(data)
        }
        if (data && data.active === 1) {
          history.push('/login')
          toast.info('Secretary is already registered')
        } else if (data && data.active === 0) {
          setfirstName(data.first_name)
          setLastName(data.last_name)
          setPhone(data.phone)
          setEmail(data.email)
          setId(data.id)
        }
      })
      .catch((error) => {
        toast.error('Something went wrong!')
        console.log(error)
        history.push('/login')
      })
  }, [secretaryId])

  const [error, setError] = useState({
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    password: '',
    confirmPassword: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    country: '',
    zipcode: '',
  })

  let handleCountryChange = () => {
    setAllState([])
    let d = document.getElementById('country').value
    setCountry(d == 'Select Country' ? null : d)
    setState('')
    setCity('')
    setZipcode('')

    get(`/api/v1/address/state/all?country_id=${d}&_order=asc&_sort=state_code`)
      .then((data) => {
        setAllState(data.data)
      })
      .catch((error) => {
        console.log(error)
      })

    //alert(d);
  }

  let handleStateChange = () => {
    let d = document.getElementById('state').value
    setState(d)
    setCity('')
    setZipcode('')
  }

  const formSubmit = (e) => {
    e.preventDefault()
    let errFirst = ''
    let errLast = ''
    let errEmail = ''
    let errPhone = ''
    let errPwd = ''
    let errCPwd = ''
    let errAddress1 = ''
    let errAddress2 = ''
    let errCity = ''
    let errState = !state ? true : null
    let errCountry = !country ? true : null
    let errZipcode = !zipcode.toString().trim() ? true : null

    // let onlyCharExpr = /^[a-zA-Z0-9 \-]+$/g;
    let onlyCharExpr = /^[a-zA-Z \-]+$/g
    let charRegex = new RegExp(onlyCharExpr)
    if (firstName.trim().length === 0) {
      errFirst = 'First name is required'
    } else if (firstName.trim().length > 50) {
      errFirst =
        'First name must be of minimum 1 and maximum 50 character length'
    } else {
      if (!firstName.trim().match(charRegex)) {
        errFirst = 'First name field must be alphabetic'
      }
    }

    if (lastName.trim().length === 0) {
      errLast = 'Last name is required'
    } else if (lastName.trim().length > 50) {
      errLast = 'Last name must be of minimum 1 and maximum 50 character length'
    } else {
      if (!lastName.trim().match(charRegex)) {
        errLast = 'Last name field must be alphabetic'
      }
    }

    errEmail = !email && 'Email is required'
    var emailregex =
      /^[a-zA-Z0-9_]+(\.[a-zA-Z0-9_]+)*@[a-zA-Z0-9]+([a-zA-Z0-9]+)*(\.[a-zA-Z0-9]+([a-zA-Z0-9]+)*)*\.[a-zA-Z]{2,4}$/
    if (email && !emailregex.test(email)) {
      errEmail = 'Invalid email address'
    }

    if (phone.replace(/[^0-9]/g, '').length === 0) {
      errPhone = 'Phone number is required'
    } else if (phone.replace(/[^0-9]/g, '').length !== 10) {
      errPhone = 'Phone number must be 10 digits'
    }

    let textRegex =
      /^(?!.*\s)(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[~`!@#$%^&*()--+={}\[\]|\\:;"'<>,.?/_₹]).{8,15}$/
    // /^(?=.*([A-Z]){1,})(?=.*[!@#$:=%^*-_+?;<>,.&*]{1,})(?=.*[0-9]{1,})(?=.*[a-z]{1,}).{8,15}$/;
    if (password.trim().length === 0) {
      errPwd = 'Password is required'
    } else if (password && !textRegex.test(password)) {
      errPwd =
        'Password must have: Minimum 8 and maximum 15 characters, at least one uppercase letter, one lowercase letter, one number and one special character'
    }

    if (confirmPassword.trim().length === 0) {
      errCPwd = 'Confirm password is required'
    }

    if (password !== confirmPassword) {
      errCPwd = 'Confirm password does not match with password'
    }

    if (address1.trim().length === 0) {
      errAddress1 = 'Address 1 field is required'
    } else if (address1.trim().length > 50) {
      errAddress1 = 'Address 1 must be of maximum 50 character length'
    }
    if (address2.trim().length > 50) {
      errAddress2 = 'Address 2 must be of maximum 50 character length'
    }
    if (city.trim().length === 0) {
      errCity = 'City is required'
    } else if (city.trim().length > 50) {
      errCity = 'City must be of maximum 50 character length'
    } else {
      if (!city.trim().match(charRegex)) {
        errCity = 'City field must be alphabetic'
      }
    }
    if (
      errFirst ||
      errEmail ||
      errLast ||
      errPhone ||
      errPwd ||
      errCPwd ||
      errAddress1 ||
      errAddress2 ||
      errCity ||
      errState ||
      errCountry ||
      errZipcode
    ) {
      setError({
        firstName: errFirst,
        lastName: errLast,
        email: errEmail,
        phone: errPhone,
        password: errPwd,
        confirmPassword: errCPwd,
        address1: errAddress1,
        address2: errAddress2,
        city: errCity,
        state: errState,
        country: errCountry,
        zipcode: errZipcode,
      })
    } else {
      setLoading(true)
      setError({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        password: '',
        confirmPassword: '',
        address1: '',
        address2: '',
        city: '',
        state: '',
        country: '',
        zipcode: '',
      })
      let userInfo = {
        first_name: firstName.trim(),
        last_name: lastName.trim(),
        email,
        password: password,
        phone: phone.replace(/-/gm, ''),
        id: id,
        unique_string: secretaryId,
        address_1: address1.toString().trim(),
        address_2: address2.toString().trim(),
        country_id: country.toString().trim(),
        state_id: state,
        city: city,
        zipcode: zipcode.toString().trim(),
      }

      post(`/api/v1/plan-purchase/activate-secretary`, userInfo)
        .then((data) => {
          setShowAlert(true)
          localStorage.setItem('registeredUser', JSON.stringify(email))
          setLoading(false)
          setMsg(
            'Registration completed successfully. Please check your registered email for login details.'
          )
        })
        .catch((error) => {
          error.response.data.map((item, index) => {
            toast.error(item.msg, {
              autoClose: 5000,
              toastId: index,
            })
          })

          setShowAlert(false)
          setLoading(false)
          // toast.error('Something went wrong!');
        })

      // history.push('/hybrid_plan/shows');
    }
  }
  return (
    <React.Fragment>
      <div className="page-content-auth mt">
        <div className="container-fuild">
          <div className="row">
            <div className="text-left col-lg-12">
              <div className="page-title-box justify-content-between">
                <h2 className="mb-4"> Register Your Account</h2>
              </div>
            </div>
          </div>
          <Row>
            <Col lg={12}>
              <form autocomplete="off" onSubmit={(e) => formSubmit(e)}>
                <Row>
                  <div className="col-xl-6">
                    <div className="mb-3">
                      <label className="form-label">First Name *</label>
                      <Input
                        type="text"
                        id="fname"
                        placeholder="Enter First Name"
                        value={firstName}
                        onChange={(e) => {
                          setfirstName(e.target.value)
                        }}
                        autoFocus
                        className={classNames('form-control', {
                          'is-invalid': error.firstName.length,
                        })}
                      />
                      <div className="text-danger">{error.firstName}</div>
                    </div>
                  </div>
                  <div className="col-xl-6">
                    <div className="mb-3">
                      <label className="form-label">Last Name *</label>
                      <Input
                        type="text"
                        id="lname"
                        placeholder="Enter Last Name"
                        value={lastName}
                        onChange={(e) => {
                          setLastName(e.target.value)
                        }}
                        className={classNames('form-control', {
                          'is-invalid': error.lastName.length,
                        })}
                      />
                      <div className="text-danger">{error.lastName}</div>
                    </div>
                  </div>
                  <div className="col-xl-6">
                    <div className="mb-3">
                      <label className="form-label">
                        Email * ( Note - Email will be used as Username. )
                      </label>
                      <Input
                        type="text"
                        id="email"
                        placeholder="Enter Email"
                        value={email}
                        disabled
                        onChange={(e) => {
                          setEmail(e.target.value)
                        }}
                        className={classNames('form-control', {
                          'is-invalid': error.email.length,
                        })}
                      />
                      <div className="text-danger">{error.email}</div>
                    </div>
                  </div>
                  <div className="col-xl-6">
                    <div className="mb-3">
                      <label className="form-label">Phone *</label>
                      <InputMask
                        mask="999-999-9999"
                        value={phone}
                        className={classNames('form-control', {
                          'is-invalid': error.phone.length,
                        })}
                        placeholder="Enter Phone Number"
                        onChange={(e) => setPhone(e.target.value)}
                      />
                      <div className="text-danger">{error.phone}</div>
                    </div>
                  </div>

                  <div className="col-xl-6">
                    <div className="mb-3">
                      <label className="form-label">Password *</label>

                      <Input
                        name="password"
                        placeholder="Password"
                        value={password}
                        onChange={(e) => {
                          setPassword(e.target.value)
                        }}
                        // style={{ borderColor: 'black' }}
                        className={classNames('form-control', {
                          'is-invalid': error.password.length,
                        })}
                        type={showPassword1 ? 'text' : 'password'}
                      />
                      {showPassword1 && (
                        <RemoveRedEyeIcon
                          className="pwd-icon"
                          onClick={hidePwd1}
                        />
                      )}
                      {!showPassword1 && (
                        <VisibilityOffIcon
                          className="pwd-icon"
                          onClick={showPwd1}
                        />
                      )}
                      <div className="text-danger">{error.password}</div>
                      <span className="mt- 2 text-muted">
                        (Note: Minimum 8 and maximum 15 characters, at least one
                        uppercase letter, one lowercase letter, one number and
                        one special character)
                      </span>
                    </div>
                  </div>
                  <div className="col-xl-6">
                    <div className="mb-3">
                      <label className="form-label">Confirm Password *</label>
                      <Input
                        name="confirmPassword"
                        placeholder="Confirm Password"
                        value={confirmPassword}
                        onChange={(e) => {
                          setConfirmPassword(e.target.value)
                        }}
                        // style={{ borderColor: 'black' }}
                        className={classNames('form-control', {
                          'is-invalid': error.confirmPassword.length,
                        })}
                        type={showPassword ? 'text' : 'password'}
                      />
                      {showPassword && (
                        <RemoveRedEyeIcon
                          className="pwd-icon"
                          onClick={hidePwd}
                        />
                      )}
                      {!showPassword && (
                        <VisibilityOffIcon
                          className="pwd-icon"
                          onClick={showPwd}
                        />
                      )}
                      <div className="text-danger">{error.confirmPassword}</div>
                    </div>
                  </div>

                  <div className="col-xl-12">
                    <div className="mb-3">
                      <label className="form-label">Address 1*</label>
                      <Input
                        type="text"
                        name="address"
                        placeholder="Enter Address"
                        value={address1}
                        onChange={(e) => {
                          setAddress1(e.target.value)
                        }}
                        className={classNames('form-control', {
                          'is-invalid': error.address1.length,
                        })}
                      />
                      <div className="text-danger">{error.address1}</div>
                      {/* <input
                        type="text"
                        name="address"
                        placeholder="Enter Address"
                        className="form-control"
                        style={{ borderColor: 'black' }}
                      /> */}
                    </div>
                  </div>

                  <div className="col-xl-12">
                    <div className="mb-3">
                      <label for="" className="form-label">
                        Address 2{' '}
                      </label>
                      <input
                        type="text"
                        name="address2"
                        placeholder="Enter Address 2"
                        value={address2}
                        onChange={(e) => {
                          setAddress2(e.target.value)
                        }}
                        className={classNames('form-control', {
                          'is-invalid': error.address2.length,
                        })}
                      />
                      <div className="text-danger">{error.address2}</div>
                    </div>
                  </div>
                  <div className="col-xl-3">
                    <div className="mb-3">
                      <label
                        for="formrow-firstname-input"
                        className="form-label"
                      >
                        Country *
                      </label>
                      <select
                        type="text"
                        name="country"
                        id="country"
                        className="form-control form-select"
                        value={country}
                        onChange={(e) => {
                          //handleChange();
                          handleCountryChange()
                        }}
                      >
                        <option>Select Country</option>
                        {allCountry.map((country) => {
                          return (
                            <option
                              value={country.country_id}
                              //selected={country.country_id == inputs.country_id}
                            >
                              {country.country_name}
                            </option>
                          )
                        })}
                      </select>
                      {error.country && (
                        <p style={{ color: 'red' }}>Please Select Country</p>
                      )}
                    </div>
                  </div>
                  <div className="col-xl-3">
                    <div className="mb-3">
                      <label
                        for="formrow-firstname-input"
                        className="form-label"
                      >
                        State *
                      </label>

                      <select
                        type="text"
                        name="state"
                        id="state"
                        className="form-control form-select"
                        value={state}
                        onChange={(e) => {
                          //handleChange();
                          handleStateChange()
                        }}
                      >
                        <option value="">Select State</option>
                        {allState.map((state) => {
                          return (
                            <option value={state.state_id}>
                              {state.state_code}
                            </option>
                          )
                        })}
                      </select>
                      {error.state && (
                        <p style={{ color: 'red' }}>Please Select State</p>
                      )}
                    </div>
                  </div>
                  <div className="col-xl-3">
                    <div className="mb-3">
                      <label
                        for="formrow-firstname-input"
                        className="form-label"
                      >
                        City *
                      </label>
                      <input
                        type="text"
                        name="city"
                        value={city || ''}
                        onChange={(e) => {
                          setCity(e.target.value)
                        }}
                        placeholder="Enter City"
                        className="form-control"
                      />
                      {error.city && (
                        <p style={{ color: 'red' }}>{error.city}</p>
                      )}
                    </div>
                  </div>
                  <div className="col-xl-3">
                    <div className="mb-3">
                      <label for="" className="form-label">
                        Zipcode *
                      </label>
                      <input
                        type="text"
                        name="zipcode"
                        placeholder="Enter Zipcode"
                        className="form-control"
                        value={zipcode}
                        onChange={(e) => {
                          setZipcode(e.target.value)
                        }}
                      />
                      {error.zipcode && (
                        <p style={{ color: 'red' }}>Zipcode is Required</p>
                      )}
                    </div>
                  </div>
                </Row>
              </form>
            </Col>
          </Row>

          <Row>
            <div className="col-lg-12">
              <div className="mt-4 text-center">
                <button
                  type="submit"
                  className="w-lg waves-effect waves-light btn btn-success"
                  id="submit"
                  disabled={loading}
                  onClick={(e) => formSubmit(e)}
                >
                  SUBMIT
                </button>
              </div>
            </div>
          </Row>
        </div>
      </div>
      <SuccessPopup
        setShowAlert={setShowAlert}
        msg={msg}
        showAlert={showAlert}
        setMsg={setMsg}
        setShowBtn={false}
        showBtn={false}
        handleClicked={false}
      />
    </React.Fragment>
  )
}

export default RegisterSecretary
