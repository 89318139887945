import { put, takeEvery } from 'redux-saga/effects';
import {
  GET_ALL_CART_EVENT,
  GET_SHOPPING_CART,
  GET_SHOPPING_CART_ERROR,
  REMOVE_SHOPPING_CART,
} from './actionTypes';
import { get, post } from '../../helpers/api_helper';
import { getAllShopCartProduct, getAllEventCartList } from './action';
import { toast } from 'react-toastify';
export const config = {
  headers: {
    'Content-Type': 'application/json',
  },
};

function* getShoppingCartList({ payload: { user_id, event_id } }) {
  try {
    const response = yield get(
      `api/v1/additional-product?user_id=${user_id}&event_id=${event_id}`
    );

    yield put(getAllShopCartProduct(response.data));
  } catch (error) {
    yield put({
      type: GET_SHOPPING_CART_ERROR,
      payload: 'Error in getproduct()',
    });
  }
}

function* getAllEventCart({ payload }) {
  try {
    const response = yield post(`api/v1/participation/shopping-cart-data`);

    yield put(getAllEventCartList(response));
  } catch (error) {
    yield put({
      type: GET_SHOPPING_CART_ERROR,
      payload: 'Error in getproduct()',
    });
  }
}

function* deleteShoppingProduct(data) {
  try {
    const response = yield post(
      `api/v1/additional-product/delete`,
      data,
      config
    );
    toast.success(response.data);
  } catch (error) {
    yield put({
      type: GET_SHOPPING_CART_ERROR,
      payload: 'Error in deleteshopCart()',
    });
  }
}

function* getShoppingCart() {
  yield takeEvery(GET_SHOPPING_CART, getShoppingCartList);
  yield takeEvery(REMOVE_SHOPPING_CART, deleteShoppingProduct);
  yield takeEvery(GET_ALL_CART_EVENT, getAllEventCart);
}

export default getShoppingCart;
