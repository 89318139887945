import React, { useState, useEffect } from 'react';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import { Link, useLocation } from 'react-router-dom';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { Input } from 'reactstrap';

import { get, post, put, del } from '../../../../helpers/api_helper';
import CustomizedDialogs from './popup';

function IsAlphaNumeric(e) {
  var val = e.target.value;
  if (val == '') return true;
  if (!val.match(/^[a-zA-Z0-9,*.@!#$%^&*]+$/)) {
    e.preventDefault();
    return false;
  } else {
    return true;
  }
}
export const ForgetPwdform = () => {
  const location = useLocation();

  const query = new URLSearchParams(location.search);
  const user = query.get('user');
  const history = useHistory();
  const [inputs, setInputs] = useState({});
  const [errors, setErrors] = useState({
    email: null,
    vemail: null,
  });
  const [errorMsg, setErrorMsg] = useState('');
  let [showBtn, setShowBtn] = useState(true);
  const [showAlert, setShowAlert] = useState('');
  const [msg, setMsg] = useState('');
  let config = {
    headers: {
      'Content-Type': 'application/json',
      Host: '<calculated when request is sent>',
      'Content-Length': '<calculated when request is sent>',
      'Postman-Token': '<calculated when request is sent>',
    },
  };
  let handleClicked = () => {
    document.getElementById('pop-btn').disabled = true;
    post(`/api/v1/user_activation/resend_verify_link`, {
      email: inputs.email,
    })
      .then((response) => {
        document.getElementById('pop-btn').disabled = false;
        setShowBtn(false);
        setMsg(
          'Email verify link has been successfully sent to your registered Email'
        );
      })
      .catch((error) => {
        document.getElementById('pop-btn').disabled = false;
        console.log(error);
      });
  };
  const handleChange = (event) => {
    let name = event.target.name;
    let value = event.target.value;
    //console.log(event.target.value);
    // alert(name);
    // alert(value);
    // setInputs((values) => ({ ...values, [name]: value }));
    if (name == 'email') {
      //let IsAlNumeric = IsAlphaNumeric(event);
      let newvalue = value.replaceAll(' ', '');
      //console.log('newvalue=', newvalue);
      //alert(newvalue);
      setInputs((values) => ({ ...values, [name]: newvalue }));
      // if (newvalue) {
      if (newvalue != '') {
        get(
          `/api/v1/register/is_email_exist?email=${newvalue}&user_role=${user}`
        )
          .then((data) => {
            if (
              data.is_exist === true &&
              data.account_status === 'UNVERIFIED'
            ) {
              setShowAlert(true);

              setShowBtn(true);
              setMsg(
                'Your account is not verified yet. Please click on resend verification link on website. Verify  your account to login.'
              );
            }
          })
          .catch((error) => {
            if (error.response?.status === 401) {
              if (error.response?.data === 'Invalid credentials.') {
              } else {
                setShowAlert(true);
                if (
                  error.response?.data ===
                  'Please contact Admin you are currently blocked.'
                ) {
                  setShowBtn(false);
                } else {
                  setShowBtn(true);
                }
                setMsg(error.response.data);
              }
            }
          });
      }
    }
  };
  //console.log('inputs.email', inputs.email);
  const emailRegex = new RegExp('^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$');
  const handleSubmit = (e) => {
    e.preventDefault();
    let err = {};
    err.email = !inputs.email ? true : null;
    err.vemail = inputs.email && !emailRegex.test(inputs.email) ? true : null;
    setErrors(err);
    setErrorMsg('');
    if (!Object.values(err).includes(true)) {
      document.getElementById('btn-submit').disabled = true;

      post(`/api/v1/password/forgot_password`, {
        email: inputs.email,
        user_role: user,
      })
        .then((response) => {
          setInputs({});
          setErrorMsg(response);
          setTimeout(() => {
            document.getElementById('btn-submit').disabled = false;

            setErrorMsg('');
          }, 4000);
          setTimeout(() => {
            if (response === 'Login details sent to your email address.') {
              document.getElementById('btn-submit').disabled = false;
              // history.push({ pathname: '/events' });
            }
          }, 1500);
        })
        .catch((error) => {
          document.getElementById('btn-submit').disabled = false;
          console.log(error);
        });
    }
  };
  return (
    <React.Fragment>
      <div className='container mt-10 res-mt-view'>
        <div className='row align-items-center justify-content-center min-vh-100'>
          

          <div className='col-md-12 col-xl-5 text-center'>
            <img
              src={`${process.env.PUBLIC_URL}/assets/img/forgot-pwd.png`}
              alt="banner-logo"
              className="logo-max-view"
            />
          </div>
          <div className='col-md-12 col-xl-2'></div>
          <div className='col-md-12 col-xl-5 forgot-pwd'>
          {errorMsg != '' && (
            <div
              className='col-xs-12 text-center mb-3 mt-2'
              // style={{ marginLeft: '36%', width: '-webkit-fill-available' }}
              >
              <Stack sx={{ width: '100%' }} spacing={2}>
                <Alert
                  severity='info'
                  icon={false}
                  style={{ fontSize: '20px' }}>
                  {errorMsg}
                </Alert>
              </Stack>
            </div>
          )}
            <div className='card'>
              <div className='card-body'>
                <div className='pb-4 mt-3 text-center'>
                  <h3 className='mb-2'>FORGOT PASSWORD</h3>
                </div>
                <form onSubmit={handleSubmit}>
                  <div className="form-group mb-4">
                    <label className="form-label">Email Id *</label>
                    <Input
                      type="text"
                      className="form-control"
                      name="email"
                      value={inputs.email ? inputs.email : ''}
                      onChange={handleChange}
                      // onKeyUp={(e) => {
                      //   handleChange(e);
                      // }}
                      placeholder="Email Id "
                      autoFocus
                    />
                    {errors.email && (
                      <p style={{ color: 'red' }}>email address is required.</p>
                    )}
                    {errors.vemail && (
                      <p style={{ color: 'red' }}>Invalid email address.</p>
                    )}
                  </div>
                  <div className="col-md-12 col-xl-12 pt-2 text-center">
                    <button
                      type="submit"
                      id="btn-submit"
                      className="col-xl-8 btn btn-primary w-1001"
                      onClick={handleSubmit}
                      color="#062575"
                    >
                      SUBMIT
                    </button>
                  </div>
                  <div className="mt-4 text-center text-font">
                    <Link to="/login" className="font-weight-bold">
                      LOGIN
                    </Link>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <CustomizedDialogs
          setShowAlert={setShowAlert}
          msg={msg}
          showAlert={showAlert}
          setMsg={setMsg}
          setShowBtn={setShowBtn}
          showBtn={showBtn}
          handleClicked={handleClicked}
        />
      </div>
    </React.Fragment>
  );
};
