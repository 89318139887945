export const ADD_MORE_MEDIA = 'ADD_MORE_MEDIA'
export const DELETE_MEDIA = 'DELETE_MEDIA'
export const DELETE_MEDIA_SUCCESS = 'DELETE_MEDIA_SUCCESS'
export const ALL_MEDIA_LIST = 'ALL_MEDIA_LIST'
export const ALL_MEDIA_LIST_SUCCESS = 'ALL_MEDIA_LIST_SUCCESS'
export const SAVE_MEDIA = 'SAVE_MEDIA'
export const UPDATE_MEDIA = 'UPDATE_MEDIA'



export const ADD_MY_ANIMALS ='ADD_MY_ANIMALS'
export const LISTING_MY_ANIMALS ='LISTING_MY_ANIMALS'
export const LISTING_MY_ANIMALS_SUCCESS ='LISTING_MY_ANIMALS_SUCCESS'
export const DELETE_ANIMAL ='DELETE_ANIMAL'
export const UPDATE_MY_ANIMALS ='UPDATE_MY_ANIMALS'