import {
  GET_ALL_SPONSOR_LIST,
  SANCTION_SHOPPING_CART,
  SANCTION_SHOPPING_PAYMENT_INTENT,
} from './actionType'

export const getAllSponsorList = (page, limit, data) => {
  return {
    type: GET_ALL_SPONSOR_LIST,
    payload: { page, limit, data },
  }
}

export const sanctionShoppingCart = () => {
  return {
    type: SANCTION_SHOPPING_CART,
    // payload: { page, limit, data },
  }
}
export const paymentIntentSanction = () => {
  return {
    type: SANCTION_SHOPPING_PAYMENT_INTENT,
    // payload: { page, limit, data },
  }
}
// export const upa
