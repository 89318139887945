export const SHOW_CLOSE_PEDIGREE_MODAL = 'SHOW_CLOSE_PEDIGREE_MODAL'

////////////////////////////////
export const BREED_LIST = 'BREED_LIST'
export const BREED_LIST_SUCCESS = 'BREED_LIST_SUCCESS'
export const BREED_HIERARCHY_DETAIL = 'BREED_HIERARCHY_DETAIL'
export const BREED_HIERARCHY_RESET = 'BREED_HIERARCHY_RESET'
export const BREED_HIERARCHY_DETAIL_SUCCESS = 'BREED_HIERARCHY_DETAIL_SUCCESS'

/////
export const ADD_RABBIT = 'ADD_RABBIT'
export const ADD_RABBIT_SUCCESS = 'ADD_RABBIT_SUCCESS'
export const UPDATE_RABBIT = 'UPDATE_RABBIT'

//
export const GET_ONE_PEDIGREE_DETAIL = 'GET_ONE_PEDIGREE_DETAIL'
export const GET_ONE_PEDIGREE_DETAIL_SUCCESS = 'GET_ONE_PEDIGREE_DETAIL_SUCCESS'
export const UPDATE_PEDIGREE_DETAIL = 'UPDATE_PEDIGREE_DETAIL'

export const PEDIGREE_LIST = 'PEDIGREE_LIST'
export const PEDIGREE_LIST_SUCCESS = 'PEDIGREE_LIST_SUCCESS'

export const RABBIT_LIST = 'RABBIT_LIST'
export const RABBIT_LIST_SUCCESS = 'RABBIT_LIST_SUCCESS'

//
export const DELETE_PEDIGREE = 'DELETE_PEDIGREE'
export const GENERATE_PEDIGREE_PDF = 'GENERATE_PEDIGREE_PDF'
