import { useFormikContext } from 'formik'
import { Col, FormGroup, Label, Row } from 'reactstrap'

import AnimalList from './AnimalList'

const BreedList = () => {
  const { values } = useFormikContext()

  return (
    <Col md='12' lg='12' sm='12'>
      <FormGroup>
        <Row className='checkbox-lg'>
          {Array.isArray(values.breed_list) && values.breed_list.length
            ? values.breed_list.map((breed, index) => {
                return (
                  <Col md='12' sm='12' lg='12' key={index}>
                    <Label
                      className='me-3 fw-600 mb-0'
                      htmlFor={`breed_list.${index}.isSelected`}
                    >
                      {breed?.category_name}{' '}
                    </Label>

                    <AnimalList
                      breed={breed?.breed || []}
                      breedCategoryIndex={index}
                    />
                  </Col>
                )
              })
            : null}
        </Row>
      </FormGroup>
    </Col>
  )
}

export default BreedList
