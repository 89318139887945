import { put, takeEvery } from 'redux-saga/effects'
import { post } from '../../helpers/api_helper'
import { UrlEncodeHook } from '../../hooks'
import { getSummaryDiscounts } from './action'
import {
  GET_ALL_DISCOUNT_SUMMARY,
  GET_DISCOUNT_SUMMARY_ERROR,
  SET_SUMMARY_PRODUCT_LOADING,
} from './actionType'

const { urlEncode } = UrlEncodeHook()

function* getAllSummaryProduct({ payload }) {
  const { event_id, history, state, historyData } = payload

  try {
    yield put({
      type: SET_SUMMARY_PRODUCT_LOADING,
    })
    const res = yield post(
      `api/v1/participation/list-with-price?in_cart=1&event_id=${event_id}`
    )

    if (!res.data) {
      const { eventId, has_products } = historyData
      // debugger
      if (
        Boolean(Number(has_products)) &&
        state?.additionalInfo &&
        state?.additionalInfo?.secretary?.product_permission === 1
      ) {
        state.showSkipBTN = true
        history.push({
          pathname: '/additional_products',
          search: `?event_id=${urlEncode(
            eventId
          )}&has_products=${has_products}&skip=${1}`,
          state: state,
        })
      } else {
        history.push({ pathname: '/shopping_cart', state: state })
      }
    } else {
      yield put(getSummaryDiscounts(res.data))
    }
  } catch (error) {
    yield put({
      type: GET_DISCOUNT_SUMMARY_ERROR,
      payload: 'Error in additionalProduct()',
    })
  }
}

function* getDiscountSummary() {
  yield takeEvery(GET_ALL_DISCOUNT_SUMMARY, getAllSummaryProduct)
}

export default getDiscountSummary
