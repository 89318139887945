import React, { useState, useEffect } from 'react'
import { Table, Row, Col, Spinner } from 'reactstrap'

import viewImg from '../../../assets/images/users/view.png'
import downImg from '../../../assets/images/users/catalog.png'
import { get } from '../../../helpers/api_helper'
import { toast } from 'react-toastify'
import _ from 'lodash'
import { saveAs } from 'file-saver'
import axios from 'axios'
const DownloadCatalog = (props) => {
  const { closeBTN, eventId } = props
  const [downloadCatalogList, setDownloadCatalogList] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    get(`api/v1/events/${eventId}/documents`)
      .then((res) => {
        let list = []
        if (res?.data) {
          list = _.orderBy(res?.data, ['display_order'], ['asc'])
          console.log(
            `🚀 -> file: DownloadCatalog.js -> line 20 -> .then -> list`,
            list
          )
        }
        setLoading(false)
        setDownloadCatalogList(list)
      })
      .catch((err) => {
        if (err?.response?.data) toast.error(err?.response?.data?.message)
      })
  }, [eventId])
  const docPreview = (doc) => {
    // 👇️ setting target to _blank with window.open
    window.open(doc.doc_file, '_blank', 'noopener,noreferrer')
  }

  return (
    <div className="cat-info" id="">
      <div className="container-fuild">
        <div className="col-md-12 mb-7">
          {/* Collpase code */}
          <div className="row justify-content-center text-center ">
            {' '}
            <Row>
              <Col lg={12}>
                {!loading && downloadCatalogList.length ? (
                  <React.Fragment>
                    <p className="cat-info-head">Catalog Information</p>
                    <div className="table-responsive">
                      <Table className="table mb-0 cust-tab">
                        <thead className="table-color-black">
                          <tr>
                            <th>No.</th>
                            <th>Name</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {downloadCatalogList.length &&
                            downloadCatalogList.map((doc, i) => {
                              return (
                                <tr>
                                  <td>{i + 1}</td>
                                  <td>{doc.doc_name}</td>
                                  <td>
                                    <img
                                      src={viewImg}
                                      onClick={() => docPreview(doc)}
                                      alt="View"
                                      className="p-r-3 mb-2"
                                    />
                                    <a
                                      href={doc?.doc_file_download || ''}
                                      // target='_blank'
                                      rel="noreferrer"
                                      // download={doc.doc_file}
                                    >
                                      <img
                                        src={downImg}
                                        // onClick={(e) => saveFile(e, doc)}
                                        alt="down"
                                        className="p-r-3 mb-2"
                                      />
                                    </a>
                                  </td>
                                </tr>
                              )
                            })}
                        </tbody>
                      </Table>
                    </div>
                  </React.Fragment>
                ) : loading ? (
                  <div className="text-center">
                    <p style={{ fontSize: 24 }}>Loading...</p>
                  </div>
                ) : (
                  <p className="cat-info-head-red">
                    Catalog information not found !
                  </p>
                )}

                <div className="text-center mt-5 col-lg-12">
                  <button
                    type="button"
                    className="btn btn-primary btn-back w-15 w-res-full"
                    onClick={closeBTN}
                  >
                    BACK
                  </button>
                </div>
              </Col>
            </Row>
          </div>
          {/* Collpase code end*/}
        </div>
      </div>
    </div>
  )
}

export default DownloadCatalog
