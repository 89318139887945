import {
  PAYMENT_CREATE_INTENT,
  PAYMENT_CREATE_INTENT_DATA,
  PURCHASED_PREMIER_LIST,
  PURCHASED_PREMIER_LIST_SUCCESS,
  SET_MODAL,
  PREMIER_USER_SHOPPING_CART_RESET,
} from './actionTypes'

//
const INIT_STATE = {
  Modal: {
    showModal: false,
    loading: true,
  },
  paymentIntent: {},
  purchasedMembership: [],
  loading: true,
  totalPages: 0,
  totalRecords: 0,
  page: 1,
}

const PremiumModal = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_MODAL:
      return {
        ...state,
        Modal: {
          ...state.Modal,
          showModal: action.payload.showModal,
        },
      }
    case PAYMENT_CREATE_INTENT:
      return {
        ...state,
      }
    case PREMIER_USER_SHOPPING_CART_RESET:
      return {
        ...state,
        paymentIntent: {},
      }

    case PAYMENT_CREATE_INTENT_DATA:
      return {
        ...state,
        paymentIntent: action.payload,
      }
    case PURCHASED_PREMIER_LIST:
      return {
        ...state,
        loading: true,
      }
    case PURCHASED_PREMIER_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        purchasedMembership: action?.payload?.data || [],
        totalPages: action?.payload?.totalPages || 0,
        totalRecords: action?.payload?.totalRecords || 0,
        page: action?.payload?.page || 1,
      }
    default:
      return state
  }
}

export default PremiumModal