import React from 'react'
import ContactUs from '../Contact/ContactUs'
import { Link } from 'react-router-dom'

const Footer = () => {
  return (
    <React.Fragment>
      <footer className='footer-page'>
        <div>
          <div className='row'>
            <div className='col-md-6'>
              <div className='text-center1 d-sm-block rights'>
                © {new Date().getFullYear()} Easy2Show. All Rights Reserved.
              </div>
            </div>
            <div className='col-md-6'>
              <div className='text-right d-sm-block rights'>
                <Link to='/easyfides/terms_conditions' className='mb-3'>
                  Website Terms, Conditions & Privacy Policy
                </Link>
              </div>
            </div>
            {/* <ContactUs /> */}
          </div>
        </div>
      </footer>
    </React.Fragment>
  )
}

export default Footer
