import React, { useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import {
  TabContent,
  TabPane,
  NavLink,
  NavItem,
  Nav,
  Row,
  Col,
  Container,
} from 'reactstrap'

import classnames from 'classnames'
import { ParticipationFilter } from './Components/ParticipationFilter'
import { toast } from 'react-toastify'
import { get } from '../../helpers/api_helper'
import { GET_ALL_YOUR_PARTICIPATION_EVENT_DATES_URL } from '../../helpers/url_helper'
import { getDateWithFormat } from '../../helpers/date_helper'

import ParticipatedEvent from './Components/ParticipatedEvent'
import AdditionalProduct from './Components/AdditionalProduct'
const Participation = () => {
  // const dispatch = useDispatch()
  const [activeTabJustify1, setactiveTabJustify1] = useState('20')
  const [dateHighLight, setDateHighLight] = useState([])

  const [eventList, setEventList] = useState([])
  const [event, setEvent] = useState(null)
  const [isSearch, setIsSearch] = useState(false)
  const [date, setDate] = useState(null)
  const [interval, setInterval] = useState(1)
  function toggleCustomJustified1(tab) {
    if (activeTabJustify1 !== tab) {
      setactiveTabJustify1(tab)
    }
  }

  useEffect(() => {
    // console.log(true)
    const fun = async () => {
      try {
        const res = await get(GET_ALL_YOUR_PARTICIPATION_EVENT_DATES_URL)
        if (res && Array.isArray(res.data)) {
          const dates = res.data.map((s) => {
            let dt = new Date(getDateWithFormat(s.start_date_time))
            // dt.setHours(0, 0, 0, 0)
            return dt
          })
          const eventData = res.data.map((event) => ({
            event_id: event.event_id,
            event_name: event.event_name,
            date: event.start_date_time,
          }))
          setEventList(eventData)

          setDateHighLight(dates)
        }
      } catch (err) {
        console.log(err)
        if (err.response.data) {
          toast.error(err.response.data)
        }
      }
    }
    fun()
  }, [])

  // useEffect(() => {
  //   if (date) {
  //     const event = async () => {
  //       try {
  //         let data = {
  //           start_date: moment(new Date(date).toISOString()).format(
  //             'YYYY-MM-DD'
  //           ),
  //         }

  //         const res = await post(
  //           `${GET_ALL_YOUR_PARTICIPATION_EVENT_URL}`,
  //           data
  //         )

  //         if (res && res.data) {
  //           setEventList(res.data)
  //         }
  //       } catch (err) {
  //         console.error(err)
  //         if (err.response.data) {
  //           toast.error(err.response.data)
  //         }
  //       }
  //     }
  //     event()
  //   }
  // }, [date])
  const eventData = useMemo(() => {
    let data = []
    if (date) {
      // debugger
      data = eventList.filter((ev) => {
        let dt = new Date(getDateWithFormat(ev.date))
        dt.setHours(0, 0, 0, 0)
        let dt2 = new Date(date)
        dt2.setHours(0, 0, 0, 0)
        let isSame = Boolean(dt.valueOf() === dt2.valueOf())
        return isSame
      })
    }
    return data
  }, [date])

  return (
    <React.Fragment>
      <div className='page-content bg-gray-101 min-h-80 view-dis'>
        <section id='about' class='section1 bg-gray-101 pages event-list'>
          <Container fluid={true} className='auto-fit'>
            <Row>
              <Col lg={12} className='text-center'>
                <div className='page-title-box justify-content-between'>
                  <h2 className='top-title mb-4'>
                    <span>YOUR PARTICIPATION</span>
                  </h2>
                </div>
              </Col>
            </Row>

            <Row>
              <div className='note mb-5'>
                <h5 class='min-order text-bold text-danger'>
                  Note: Reports and legs will only be available under your
                  participation for 12 months from the show date.
                  {/* Note: Events prior to 8-1-23, were in Version 3 and are not
                  visible here. Only Version 4 event results/reports will show
                  here. <a href="https://www.time2enter.com/user_activity/user/login" target="_blank" className='text-danger text-decoration-underline' rel="noreferrer">Click this Link</a> to go to Version 3 to
                  view/print/save past event reports/legs and use your Version 3
                  “username” and “password”. Version 3 event/show information
                  will only be available for 15 months from the event date. */}
                </h5>
              </div>
              <ParticipationFilter
                dateHighLight={dateHighLight}
                setDate={setDate}
                date={date}
                eventList={eventData}
                event={event}
                setEvent={setEvent}
                setIsSearch={setIsSearch}
                isSearch={isSearch}
                setInterval={setInterval}
                interval={interval}
              />

              <Col xl={12} className='tab-structure res-tab-str'>
                <Nav tabs className='nav-justified'>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: activeTabJustify1 === '20',
                      })}
                      onClick={() => {
                        toggleCustomJustified1('20')
                      }}
                    >
                      <span className='d-none1 d-sm-block res-fpnt'>
                        Participated Events
                      </span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: activeTabJustify1 === '21',
                      })}
                      onClick={() => {
                        toggleCustomJustified1('21')
                      }}
                    >
                      <span className='d-none1 d-sm-block res-fpnt'>
                        Purchased Additional Products
                      </span>
                    </NavLink>
                  </NavItem>
                </Nav>
                <TabContent
                  activeTab={activeTabJustify1}
                  className='p-3 text-muted'
                >
                  <TabPane tabId='20'>
                    {activeTabJustify1 === '20' ? (
                      <ParticipatedEvent
                        event={event}
                        isSearch={isSearch}
                        interval={interval}
                      />
                    ) : null}
                  </TabPane>
                  <TabPane tabId='21'>
                    {activeTabJustify1 === '21' ? (
                      <AdditionalProduct
                        event={event}
                        isSearch={isSearch}
                        interval={interval}
                      />
                    ) : null}
                  </TabPane>
                </TabContent>
              </Col>
            </Row>
          </Container>
        </section>
      </div>
    </React.Fragment>
  )
}

export default Participation