// Collapse View
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import Collapse from '@mui/material/Collapse'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { Table } from 'react-super-responsive-table'
import { Card, CardBody, Col, Container, Row } from 'reactstrap'
import { POST } from '../../helpers/api_helper'
import { getDate, getTime } from '../../helpers/date_helper'
import { discountPrices, fixed } from '../../helpers/utils'
import { UrlEncodeHook } from '../../hooks'
import { getSummaryDiscount } from '../../store/actions'

const DiscountSummary = (props) => {
  const history = useHistory()
  const location = useLocation()
  const query = new URLSearchParams(location.search)
  let eventId = query.get('event_id')
  const { urlDecode, urlEncode } = UrlEncodeHook()
  eventId = urlDecode(eventId)
  const has_products = query.get('has_products')

  const dispatch = useDispatch()
  const discountSummary = useSelector((state) => state.DiscountSummary)
  const { discount_summary, loading } = discountSummary
  const [open, setOpen] = React.useState(null)
  const [events, setEvents] = React.useState()
  useEffect(() => {
    //to desable back buttion on browser
    window.history.pushState(null, document.title, window.location.href)
    window.addEventListener('popstate', function (event) {
      window.history.pushState(null, document.title, window.location.href)
    })
  }, [])
  const {
    event,
    additionalInfo,
    costInfo,
    showOpen,
    showYouth,
    newInputArr,
    hasAdditionalProduct,
  } = (props.location && props.location.state) || {}

  useEffect(() => {
    let state = {
      event,
      additionalInfo,
      costInfo,
      showOpen,
      showYouth,
      newInputArr,
      hasShoppingCart: true,
      hasAdditionalProduct,
    }
    let historyData = {
      has_products: has_products,
      eventId: eventId,
    }

    dispatch(getSummaryDiscount(eventId, history, state, historyData))
    // get(`/api/v1/subuser`)
    //   .then((data) => {
    //     console.log(data);
    //     setSubUsers(data?.data || []);
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });
    const body = {
      event_id_list: [eventId],
    }
    POST(`/api/v1/events/multiple?published=1&active=1&deadlines=1`, body).then(
      (res) => {
        setEvents(res.data[0])
      }
    )
  }, [])
  const handleClick = (data) => {
    if (data === open) {
      setOpen(null)
    } else {
      setOpen(data)
    }
  }
  let count = 0

  const nextPage = () => {
    if (
      Boolean(Number(has_products)) &&
      additionalInfo &&
      additionalInfo?.secretary?.product_permission === 1
    ) {
      history.push({
        pathname: '/additional_products',
        search: `?event_id=${urlEncode(
          eventId
        )}&has_products=${has_products}&skip=${1}`,
        state: {
          event,
          additionalInfo,
          costInfo,
          showOpen,
          showYouth,
          newInputArr,
          hasShoppingCart: true,
          showSkipBTN: true,
        },
      })
    } else {
      history.push({
        pathname: '/shopping_cart',
        search: `?event_id=${urlEncode(
          eventId
        )}&has_products=${has_products}&skip=${1}`,
        state: {
          event,
          additionalInfo,
          costInfo,
          showOpen,
          showYouth,
          newInputArr,
          hasShoppingCart: true,
          showSkipBTN: true,
        },
      })
    }
  }
  const { price, discountPrice, finalPrice } = discountPrices(discount_summary)
  return (
    <>
      {!loading ? (
        <React.Fragment>
          <div className="page-content bg-gray-101 min-h-85">
            <section className="section1 bg-gray-101 pages event-list">
              <Container fluid={true} className="auto-fit">
                <Row>
                  <Col lg={12} className="text-center">
                    <div className="page-title-box justify-content-between">
                      <h2 className="mb-4"> DISCOUNT SUMMARY</h2>
                    </div>
                  </Col>

                  <Col lg={12} className="mb-7 event-list">
                    <Card className="shadow-only-hover">
                      <CardBody className="event-card-body">
                        <Col lg={12} className="event-box">
                          <div className="row">
                            <div className="col-lg-4 col-xl-3 box-head">
                              <div className="row">
                                <div className="col-lg-5 col-xl-5 mb-4 mt-4">
                                  Event Date:{' '}
                                </div>
                                <div className="col-lg-7 col-xl-7 pl-0">
                                  <p className="highlight">
                                    {events?.start_date_time &&
                                      getDate(events.start_date_time)}
                                    <br />

                                    {events?.end_date_time && 'To'}
                                    {events?.end_date_time && <br />}
                                    {events?.end_date_time &&
                                      getDate(events?.end_date_time)}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-5 col-xl-3 box-head mt-3">
                              {events?.event_name}{' '}
                            </div>
                            <div className="col-lg-3 col-xl-3 box-head mt-3">
                              {events?.address_1}
                              {events?.address_1 ? ', ' : ''}
                              <br />
                              {events?.address_2}
                              {events?.address_2 ? ', ' : ''}
                              {events?.city}
                              {events?.city ? ', ' : ''}
                              {events?.state_name}
                              {events?.state_name ? ', ' : ''}
                              {events?.zip_code}
                              {events?.zip_code ? ', ' : ''}
                              {events?.country_name}
                            </div>
                            <div className="col-lg-6 col-xl-3 box-head">
                              <div className="row">
                                <div className="col-lg-4 col-xl-4 mt-4">
                                  Deadline:
                                </div>
                                {events?.deadlines?.map((item, index) => {
                                  if (
                                    item?.deadline_status == 'upcoming' &&
                                    count === 0
                                  ) {
                                    count++

                                    return (
                                      <div className="col-lg-8 col-xl-8 view-dline mt-2">
                                        <span className="deadline">
                                          <div>
                                            {getDate(item?.date_time)}
                                            <br />
                                            {`${getTime(item?.date_time)} CST`}
                                          </div>
                                        </span>
                                        <div className="input-group-append">
                                          <span className="input-group-text">
                                            1st
                                          </span>
                                        </div>
                                      </div>
                                    )
                                  }
                                })}
                              </div>
                            </div>
                          </div>
                        </Col>
                      </CardBody>
                    </Card>
                  </Col>

                  <Col lg={12}>
                    <div className="table-responsive text-center">
                      <Table className="table mb-0 cust-tab">
                        <thead className="table-color">
                          <tr>
                            <th>No.</th>
                            <th>Exhibitor</th>
                            <th>Ear No.</th>
                            <th>Breed </th>
                            <th>Total Shows</th>
                            <th>Show Type</th>
                            <th className="text-right">Price</th>
                            <th className="text-right">Discount</th>
                            <th className="text-right">Final Price</th>
                          </tr>
                        </thead>
                        <tbody>
                          {!loading && discount_summary.length > 0
                            ? discount_summary.map((discount, i) => {
                                let show = String(discount.total_shows)
                                return (
                                  <React.Fragment key={i}>
                                    <tr>
                                      <td>{i + 1}</td>
                                      <td>{discount.exh_name}</td>
                                      <td className="w-break">
                                        {discount.ear_no}
                                      </td>
                                      <td className="w-break">
                                        {discount.breed_name}
                                      </td>
                                      <td>{show.padStart(2, '0')}</td>
                                      {discount?.show_details.length && (
                                        <React.Fragment>
                                          <td
                                            style={{
                                              textTransform: 'capitalize',
                                            }}
                                          >
                                            {discount.show_type_list.join(', ')}
                                          </td>
                                          <td style={{ textAlign: 'right' }}>
                                            {' '}
                                            $
                                            {fixed(
                                              discount.final_total_cost,
                                              2,
                                              2
                                            )}
                                          </td>
                                          {discount.final_total_discount <=
                                          0 ? (
                                            <td className="text-right">
                                              <span
                                                className="disc-val "
                                                onClick={() => handleClick(i)}
                                              >
                                                <span>
                                                  $
                                                  {fixed(
                                                    discount.final_total_discount,
                                                    2,
                                                    2
                                                  )}
                                                </span>
                                              </span>
                                            </td>
                                          ) : (
                                            <td
                                              style={{
                                                textAlign: 'right',
                                                cursor: 'pointer',
                                                width: '13%',
                                              }}
                                            >
                                              <span
                                                className="disc-val-col"
                                                onClick={() => handleClick(i)}
                                              >
                                                <span>
                                                  $
                                                  {fixed(
                                                    discount.final_total_discount,
                                                    2,
                                                    2
                                                  )}
                                                </span>
                                                {i === open ? (
                                                  <ExpandLess />
                                                ) : (
                                                  <ExpandMore />
                                                )}
                                              </span>
                                            </td>
                                          )}

                                          <td style={{ textAlign: 'right' }}>
                                            ${fixed(discount.final_cost, 2, 2)}
                                          </td>
                                        </React.Fragment>
                                      )}
                                    </tr>
                                    {discount?.discount_type_list && (
                                      <tr className="disc-collapse">
                                        <td colSpan={9}>
                                          <Collapse
                                            in={i === open ? true : false}
                                            timeout="auto"
                                            unmountOnExit
                                          >
                                            <div className="disc-box text-left">
                                              {discount?.discount_type_list.map(
                                                (dis) => (
                                                  <>
                                                    <i className="fa fa-circle icon-dot"></i>{' '}
                                                    {dis} {'   '}
                                                  </>
                                                )
                                              )}
                                            </div>
                                          </Collapse>
                                        </td>
                                      </tr>
                                    )}
                                  </React.Fragment>
                                )
                              })
                            : null}

                          <tr className="total-box">
                            <td colSpan={6}>TOTAL</td>
                            <td style={{ textAlign: 'right' }}>
                              ${fixed(price, 2, 2)}
                            </td>
                            <td style={{ textAlign: 'right' }}>
                              <span className="disc-val">
                                ${fixed(discountPrice, 2, 2)}
                              </span>
                            </td>
                            <td style={{ textAlign: 'right' }}>
                              ${fixed(finalPrice, 2, 2)}
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </Col>

                  <Col lg={12} className="text-center mt-5">
                    <button
                      type="button"
                      className="btn btn-primary btn-easy w-15 res-w-f"
                      onClick={() => nextPage()}
                    >
                      CONTINUE
                    </button>
                  </Col>
                </Row>
              </Container>
            </section>
          </div>
        </React.Fragment>
      ) : (
        <Row>
          <Col lg={12} className="easy-middle-btn">
            <div
              className="justify-content-center align-items-center title-center"
              style={{
                textAlign: 'center',
                fontSize: 'xx-large',
                height: '100vh',
              }}
            >
              Loading ...
            </div>
          </Col>
        </Row>
      )}
    </>
  )
}

export default DiscountSummary
