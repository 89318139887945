import {
  GET_SHOPPING_CART,
  GET_LIST_SHOPPING_CART,
  REMOVE_SHOPPING_CART,
  GET_ALL_CART_EVENT,
  GET_ALL_CART_EVENT_SUCCESS,
} from './actionTypes';

export const getAllShopCartValue = (data) => {
  return {
    type: GET_SHOPPING_CART,
    payload: data,
  };
};

export const getAllShopCartProduct = (products) => ({
  type: GET_LIST_SHOPPING_CART,
  payload: products,
});

export const removeShopingCard = (data) => {
  return {
    type: REMOVE_SHOPPING_CART,
    payload: data,
  };
};

export const getAllEventCart = (data) => {
  return {
    type: GET_ALL_CART_EVENT,
    payload: data,
  };
};

export const getAllEventCartList = (products) => {
  let data = {
    cart_data: products?.cart_data || [],
    event_details: products?.event_details || {},
    final_price: products?.final_price || 0,
  };
  return {
    type: GET_ALL_CART_EVENT_SUCCESS,
    payload: data,
  };
};
