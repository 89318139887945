import { put, takeEvery } from 'redux-saga/effects'

import { MILES_LIST, MILES_LIST_SUCCESS } from './actionType'
import { get } from '../../../helpers/api_helper'
import { toast } from 'react-toastify'

function* MileListSaga() {
  try {
    const res = yield get(`/api/v1/admin/easyfied/travel-radius/all`)
  
    yield put({
      type: MILES_LIST_SUCCESS,
      payload: res?.data || [],
    })
  } catch (err) {
    if (err?.response?.data) {
      toast.error(err?.response?.data)
    }
  }
}
function* EasyfidesProfileSaga() {
  yield takeEvery(MILES_LIST, MileListSaga)
}

export default EasyfidesProfileSaga
