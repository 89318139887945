import 'flatpickr/dist/themes/material_blue.css'
import React, { useEffect, useState } from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Button, Col, Row } from 'reactstrap'
import { get } from '../../helpers/api_helper'

const EventsReports = () => {
  const { id } = useParams()

  const [reportData, setReportData] = useState()
  const [docs_data, setDocs_data] = useState()
  const [isLoaded, setIsLoaded] = useState(false)

  useEffect(() => {
    setIsLoaded(true)
    const params = {
      _sort: 'doc_name',
      _order: 'asc',
    }

    get(`/api/v1/report-events/one/${id}`)
      .then((res) => {
        if (res?.data) {
          setReportData(res.data)
          get(`/api/v1/report-events/${id}/doc/all`, { params: params })
            .then((docRes) => {
              setIsLoaded(false)
              if (docRes?.data) {
                setDocs_data(docRes?.data)
              }
            })
            .catch((error) => {
              console.log('error', error)
            })
        } else {
          setIsLoaded(false)
        }
      })
      .catch((err) => {
        console.log('err', err)
      })

    return () => {
      setIsLoaded(false)
    }
  }, [])

  const handleView = (url) => {
    function isValidURL(string) {
      var res = string.match(
        /(http(s)?:\/\/.)(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
      )
      return res !== null
    }

    const isUrl = isValidURL(url)
    if (isUrl) {
      window.open(url, '_blank', 'noopener,noreferrer')
    } else {
      toast.error('Given URL is invalid.')
    }
  }

  return (
    <React.Fragment>
      <section className="section bg-gray-101 pages event-list mt-12">
        <div class="auto-fit container-fluid">
          <div class="row mb-6">
            <div class="col-md-12 col-lg-12 col-xs-12 text-center">
              <h2 className="top-title">
                <span>Event Reports</span>
              </h2>
            </div>
          </div>

          {isLoaded ? (
            <div className="text-center">
              <p style={{ fontSize: 24 }}>Loading...</p>
            </div>
          ) : (
            <>
              {reportData?.event_name ? (
                <>
                  <div className="col-md-12 mb-7">
                    <div className="card shadow-only-hover1">
                      <div className="card-body event-card-body">
                        <div className="col-lg-12 col-xl-12 event-box">
                          <Row>
                            <Col xs={12} className="box-head text-left">
                              <span className="fw-bold">Event Name</span> :{' '}
                              {reportData.event_name}
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </div>
                  </div>

                  {docs_data?.length > 0 ? (
                    docs_data.map((obj, i) => (
                      <div className="col-md-12 mb-7" key={i}>
                        <div className="card shadow-only-hover1">
                          <div className="card-body event-card-body">
                            <div className="col-lg-12 col-xl-12 event-box">
                              <Row>
                                <Col
                                  xs={12}
                                  className="box-head text-left mb-3"
                                >
                                  <span className="fw-bold">Title</span>:{' '}
                                  {obj.doc_name}
                                </Col>
                                <Col
                                  xs={12}
                                  className="box-head text-left mb-3"
                                >
                                  <span className="fw-bold">Document</span>:
                                  {obj?.document_file && (
                                    <Button
                                      color="primary"
                                      onClick={() =>
                                        handleView(obj.document_file)
                                      }
                                      title="View"
                                      className="text-uppercase mx-4 py-2"
                                    >
                                      View Document
                                    </Button>
                                  )}
                                  {obj?.video_url && (
                                    <Button
                                      color="primary"
                                      onClick={() => handleView(obj.video_url)}
                                      title="View"
                                      className="text-uppercase mx-4 py-2"
                                    >
                                      View Video
                                    </Button>
                                  )}
                                </Col>
                                {obj?.doc_desc && (
                                  <Col xs={12} className="box-head text-left">
                                    <span className="fw-bold">Description</span>
                                    : {obj.doc_desc}
                                  </Col>
                                )}
                              </Row>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="text-center">
                      <p style={{ fontSize: 20 }}>No Document Found</p>
                    </div>
                  )}
                </>
              ) : (
                <div className="text-center">
                  <p style={{ fontSize: 20 }}>No Event report found</p>
                </div>
              )}
            </>
          )}
        </div>

        {/* <div className="table-responsive">
          <Table className="table mb-0 cust-tab">
            <thead className="table-color">
              <tr>
                <th>Title</th>
                <th>Document</th>
                <th>Description</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Cavy - Abyssinian</td>
                <td>
                  <Button
                    color="primary"
                    title="Video"
                    className="text-uppercase mx-2 py-2"
                  >
                    View Document
                  </Button>

                  <Button
                    color="primary"
                    title="Video"
                    className="text-uppercase mx-2 py-2"
                  >
                    View Video
                  </Button>
                </td>
                <td>Variety_color</td>
              </tr>
              <tr>
                <td>Cavy - Abyssinian Satin</td>
                <td>AS</td>
                <td>Variety_color</td>
              </tr>
            </tbody>
          </Table>
        </div> */}
      </section>
    </React.Fragment>
  )
}

export default EventsReports
