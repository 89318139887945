import { ALL_COUNTRY_LIST_SUCCESS } from './actionType'

//

const INIT_STATE = {
  CountryList: [],
  loading: true,
  error: {},
}

const CountryListReducer = (state = INIT_STATE, action) => {
  const { type, payload } = action
  switch (type) {
    case ALL_COUNTRY_LIST_SUCCESS:
      return {
        ...state,
        CountryList: payload,
        loading: false,
      }

    default:
      return state
  }
}

export default CountryListReducer
