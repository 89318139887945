import React, { useState, useEffect } from 'react'
export const DiscountInformation = (props) => {
  let discounts = props.discounts

  return (
    <div class='row justify-content-center mt-4'>
      <div class='col-lg-12 col-xl-12 mb-4 box-disc'>
        <p className='mb-0 text-center' style={{ color: '#000' }}>
          <strong>Discount Applicable</strong>
        </p>
        <ul>
          {discounts?.map((item) => {
            return <li>{item.description}</li>
          })}
          {/* <li>{"Test"}</li> */}
        </ul>
      </div>
    </div>
  )
}
