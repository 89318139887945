import React, { useEffect, useMemo, useState } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { Table } from 'react-super-responsive-table'
import { Col, Container, Row, Spinner, Input, Modal } from 'reactstrap'
import Collapse from '@mui/material/Collapse'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
// Image
import deleteImg from '../../assets/images/users/delete.png'
import classnames from 'classnames'
import {
  DEL_SANCTION_SHOPPING_CART,
  GET_SANCTION_SHOW_EVENT,
} from '../../helpers/url_helper'
import { get, post } from '../../helpers/api_helper'
import _ from 'lodash'
import classNames from 'classnames'
import { toast } from 'react-toastify'
import Swal from 'sweetalert2'
import { useDispatch, useSelector } from 'react-redux'
import { sanctionShoppingCart } from '../../store/actions'
import checkImg from '../../assets/images/users/check.png'
import { UrlEncodeHook } from '../../hooks'

const SponsorSanctionShoppingCart = (props) => {
  const { urlEncode } = UrlEncodeHook()

  const dispatch = useDispatch()
  const { shoppingCartList } = useSelector((state) => state.SponsorSanction)
  const location = useLocation()
  const [eventData, setEventData] = useState({})
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState('')
  const [value, setValue] = useState('')
  const [open, setOpen] = useState(null)
  const [modal_center, setmodal_center] = useState(false)

  // const {user} = useSelector((state)=> state.)
  const query = new URLSearchParams(location.search)
  let user = JSON.parse(localStorage.getItem('authUser'))
  const paymentIntentId = query.get('payment_intent')

  const history = useHistory()
  useEffect(() => {
    if (!paymentIntentId) {
      const fun = async () => {
        if (shoppingCartList.length) {
          let event = _.first(shoppingCartList)
          let getOneEvent = await get(
            `${GET_SANCTION_SHOW_EVENT}?event_id=${event.event_id}`
          )

          if (getOneEvent && Array.isArray(getOneEvent.data)) {
            let eventData = _.first(getOneEvent.data)
            setEventData(eventData)
          }
        }

        return true
      }
      fun().then((res) => {
        setLoading(false)
      })
    }
  }, [shoppingCartList])

  useEffect(() => {
    if (paymentIntentId) {
      console.log(true)
      setmodal_center(true)
      const cartData = async () => {
        let data = {
          paymentIntentId: paymentIntentId,
        }

        await post(`/api/v1/shows/user-sponsor/process-payment`, data)
        setLoading(false)
        setTimeout(() => {
          history.push('/sponsor_sanction')
        }, 1500)
      }
      cartData()
    }
  }, [])

  // useEffect(() => {
  //   if (paymentIntentId) {
  //     const unloadCallback = (event) => {
  //       event.preventDefault()
  //       event.returnValue = ''
  //       return ''
  //     }

  //     window.addEventListener('beforeunload', unloadCallback)
  //     return () => window.removeEventListener('beforeunload', unloadCallback)
  //   }
  // }, [])
  useEffect(() => {
    dispatch(sanctionShoppingCart())
  }, [])

  const total = useMemo(() => totalSum(shoppingCartList), [shoppingCartList])

  const deleteAll = async () => {
    let user = JSON.parse(localStorage.getItem('authUser'))
    const { id } = user
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      width: '380px',
      showCancelButton: true,
      confirmButtonColor: '#062575',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then(async (result) => {
      if (result.isConfirmed) {
        const res = await post(`${DEL_SANCTION_SHOPPING_CART}?user_id=${id}`)
        toast.success(res.data)
        dispatch(sanctionShoppingCart())
      }
    })
  }

  const oneDelete = async (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      width: '380px',
      showCancelButton: true,
      confirmButtonColor: '#062575',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then(async (result) => {
      if (result.isConfirmed) {
        const res = await post(
          `${DEL_SANCTION_SHOPPING_CART}?sponsor_sanctions_id=${id}`
        )
        toast.success(res.data)
        dispatch(sanctionShoppingCart())
      }
    })
  }
  if (loading) {
    return (
      <div className="page-content bg-gray-101">
        <section id="about" class="section1 bg-gray-101 pages event-list">
          <div className="text-center">
            <p style={{ fontSize: 24 }}>Loading...</p>
          </div>{' '}
        </section>
      </div>
    )
  }

  let stockOut = shoppingCartList.find((s) => Number(s.purchase_status) === 1)
  const paymentData = () => {
    let validation = false
    let err = ''
    if (!value) {
      err = 'Please accept terms and conditions'
    } else {
      err = ''
    }
    if (err) {
      validation = true
      setError(err)
    } else {
      setError(err)
    }
    if (!validation) {
      history.push('/sponsor_sanction_checkout')
    }
  }
  const handleClick = (data) => {
    if (data === open) {
      setOpen(null)
    } else {
      setOpen(data)
    }
  }
  return (
    <div className="page-content bg-gray-101">
      <section id="about" class="section1 bg-gray-101 pages event-list">
        {shoppingCartList.length ? (
          <Container fluid={true} className="auto-fit">
            <Row>
              <Col lg={12} className="text-center">
                <div className="page-title-box justify-content-between">
                  <h2 className="top-title">
                    <span>SHOPPING CART</span>
                  </h2>
                </div>
              </Col>
            </Row>

            <Row>
              <Col lg={12}>
                <div className="table-responsive text-center mb-5">
                  <Table className="table mb-0 cust-tab">
                    <thead className="table-color">
                      <tr>
                        <th>{eventData?.event_name}</th>
                      </tr>
                    </thead>
                  </Table>
                </div>
              </Col>

              <Col lg={12}>
                <div className="table-responsive text-center mb-5">
                  <Table className="table mb-0 cust-tab">
                    <thead className="table-color">
                      <tr>
                        <th>No.</th>
                        <th className="">Club Type</th>
                        <th className="">Club Title</th>
                        <th>Show Type</th>
                        {/* <th>Breed Name</th> */}
                        <th>Price</th>
                        <th style={{ textAlign: 'right' }}>Final Price</th>
                        <th className="w-10">
                          <img
                            onClick={() => deleteAll()}
                            src={deleteImg}
                            role="button"
                            alt="delete"
                            className="p-r-3 res-cart-icon"
                          />
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {shoppingCartList.length > 0 ? (
                        shoppingCartList.map((cart, i) => {
                          let discount = _.first(cart?.discount_details)

                          return (
                            <React.Fragment>
                              <tr>
                                <td>{i + 1}</td>
                                <td className="text-capitalize">
                                  {cart.club_type}
                                </td>
                                <td>
                                  {cart.club_title}{' '}
                                  {cart.purchase_status === 1 &&
                                    '( Sold Out ) '}
                                </td>
                                <td className="text-uppercase">{`${cart?.show_type
                                  } ${cart?.show_char || ''}`}</td>

                                <td>
                                  $
                                  {cart?.total_cost
                                    ? cart?.total_cost.toFixed(2) || ''
                                    : '0.00'}
                                </td>
                                <td
                                  style={{
                                    textAlign: 'right',
                                    cursor: 'pointer',
                                    width: '13%',
                                  }}
                                >
                                  <span>
                                    {Number(cart?.total_cost_after_discount) <
                                      Number(cart?.total_cost) ? (
                                      <span
                                        className={classNames('disc-val-col')}
                                        onClick={() => handleClick(i)}
                                      >
                                        $
                                        {cart?.total_cost_after_discount
                                          ? cart?.total_cost_after_discount.toFixed(
                                            2
                                          ) || ''
                                          : 0.0}
                                        {i === open ? (
                                          <ExpandLess />
                                        ) : (
                                          <ExpandMore />
                                        )}
                                      </span>
                                    ) : (
                                      <span>
                                        $
                                        {cart?.total_cost_after_discount
                                          ? cart?.total_cost_after_discount.toFixed(
                                            2
                                          ) || ''
                                          : 0.0}
                                      </span>
                                    )}
                                  </span>
                                </td>
                                <td>
                                  <img
                                    onClick={() =>
                                      oneDelete(cart.sponsor_sanctions_id)
                                    }
                                    role="button"
                                    src={deleteImg}
                                    alt="delete"
                                    className="p-r-3 res-cart-icon"
                                  />
                                </td>
                              </tr>
                              {discount?.applied_discount_string ? (
                                <tr className="disc-collapse">
                                  <td colSpan={9}>
                                    <Collapse
                                      in={i === open ? true : false}
                                      timeout="auto"
                                      unmountOnExit
                                    >
                                      <div className="disc-box text-left">
                                        {discount.applied_discount_string}
                                      </div>
                                    </Collapse>
                                  </td>
                                </tr>
                              ) : null}
                            </React.Fragment>
                          )
                        })
                      ) : (
                        <tr>
                          <th>No Shopping Data found</th>
                        </tr>
                      )}

                      <tr className="total-box">
                        <td colSpan={5}>TOTAL</td>
                        <td style={{ textAlign: 'right' }}>
                          ${total?.total_amount || 0.0}
                        </td>
                        <td></td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </Col>

              <Col lg={12}>
                <div className="table-responsive text-center">
                  <Table className="table mb-0 cust-tab">
                    <thead className="table-color">
                      <tr>
                        <th colSpan={5}>GRAND TOTAL</th>
                        <th style={{ textAlign: 'right' }}>
                          {' '}
                          ${total?.total_amount || 0.0}
                        </th>
                        <th className="w-10"></th>
                      </tr>
                    </thead>
                  </Table>
                </div>
              </Col>

              <Row>
                <Col lg="12">
                  <div className="card shadow-only-hover mt-8 shop-card text-center">
                    <div className="card-body">
                      <h4 className="mb-3">
                        NOTE - ALL ENTRIES ARE FINAL AND NON-REFUNDABLE.
                      </h4>
                      <p>
                        You as the exhibitor understands and is agreeing to the
                        fact that your entry is FINAL and NON-REFUNDABLE for any
                        reason. It is at the discrection of the host club if
                        they will refund any part of or all monies, but
                        Easy2Show will not guarantee that the show might/will
                        and Easy2Show does not refund any monies/fees.
                      </p>
                      <p>
                        If the show cancels or postpones, for any reason, your
                        paid entry is still active. It can be modified for the
                        furture show date within the same animal "category". To
                        make changes: "login" as a user, goto "your
                        participation", search for the Show/Event and click
                        "update" next to the animal you want to update. You need
                        to update each animal in each Event. Changing an
                        animal's information in Event A will NOT automatically
                        change it in Event B.
                      </p>
                      <p>
                        Any changes after the show deadline is at the discretion
                        of the show committee. Easy2Show does NOT guarantee that
                        any change requested will be granted by the show
                        committee.
                      </p>

                      <Col xl={12}>
                        <div className="mt-3 agree-check text-left">
                          <input
                            className="form-check-input mr-2"
                            type="checkbox"
                            id="check"
                            value=""
                            disabled={
                              Boolean(stockOut) ||
                              !Boolean(Number(total?.total_amount))
                            }
                            onChange={(e) => {
                              setValue((prev) => !prev)
                            }}
                          />
                          <label
                            className={'form-check-label p-r-3 mt-1'}
                            htmlFor="check"
                          >
                            {' '}
                            ACCEPT
                          </label>
                          {error && <div className="text-danger">{error}</div>}
                        </div>
                      </Col>
                    </div>
                  </div>
                </Col>
              </Row>

              <Col lg={12} className="text-center mt-5">
                {/* <Link to="/sponsor_sanction_checkout"> */}
                <button
                  disabled={
                    Boolean(stockOut) || !Boolean(Number(total?.total_amount))
                  }
                  type="button"
                  onClick={() => paymentData()}
                  className={classnames('btn btn-primary btn-easy w-15', {})}
                >
                  PAY / CHECKOUT
                </button>
                {/* </Link> */}
                <Link
                  to={`/sponsor_sanction/${urlEncode(
                    eventData?.event_id || ''
                  )}`}
                >
                  <button
                    type="button"
                    // onClick={() => history.goBack()}
                    className="btn btn-primary btn-back w-15 res-w-f"
                  >
                    BACK
                  </button>
                </Link>
              </Col>
            </Row>
          </Container>
        ) : (
          <Container fluid={true} className="auto-fit">
            <Row>
              <Col lg={12} className="text-center">
                <div className="page-title-box justify-content-between">
                  <h2 className="top-title">
                    <span>SHOPPING CART</span>
                  </h2>
                </div>
              </Col>

              <Col lg={12} className="easy-middle-btn">
                <div
                  className="justify-content-center align-items-center title-center"
                  style={{
                    textAlign: 'center',
                    fontSize: 'xx-large',
                    height: '60vh',
                  }}
                >
                  Shopping cart is empty
                </div>
              </Col>
            </Row>
          </Container>
        )}
        <Modal
          className="sucess_modal"
          isOpen={modal_center}
          toggle={() => {
            // contact_center_modal();
          }}
          centered={true}
        >
          <div className="modal-header">
            <h4>&nbsp;</h4>
            <button
              type="button"
              onClick={() => {
                setmodal_center(false)
              }}
              className="close close-btn"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <Row className="mb-2 text-center pay-modal">
              <Col lg={12}>
                <h4>PAYMENT SUCCESSFUL</h4>
                <p className="m-0 ">
                  <img src={checkImg} alt="sucess" className="p-r-3" />
                </p>
                <p className="m-0 ">
                  Dear, {user.last_name} {user.first_name}
                </p>
                {/* <p className="m-0 ">
                    Thank you for participating in the event.
                  </p> */}
                <p>
                  The sponsors purchased sheet has been sent to your e-mail.
                </p>
              </Col>
            </Row>
          </div>
        </Modal>
      </section>
    </div>
  )
}

export default SponsorSanctionShoppingCart
const totalSum = (cart) => {
  let total_amount = 0

  total_amount = _.sumBy(cart, function (o) {
    return Number(o.total_cost_after_discount)
  })

  let obj = {
    total_amount: total_amount.toFixed(2),
  }
  return obj
}
