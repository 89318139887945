import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Col, Container, Modal, Row } from 'reactstrap'
import { get } from '../../helpers/api_helper'

const VerifyAccount = () => {
  function useQuery() {
    return new URLSearchParams(useLocation().search)
  }
  const query = useQuery()
  const [modal_center, setmodal_center] = useState(false)
  const [apiRes, setApiRes] = useState('')
  function removeBodyCss() {
    document.body.classList.add('no_padding')
  }
  function tog_center() {
    setmodal_center(!modal_center)
    removeBodyCss()
  }
  useEffect(() => {
    let verify_token = query.get('verify_token')
    get(`/api/v1/user_activation?verify_token=${verify_token}`)
      .then((response) => {
        setmodal_center(true)
        setApiRes(response)
      })
      .catch((error) => {
        // console.log(error.response.data);
        setmodal_center(true)
        if (error.response.data == 'Unauthorized')
          setApiRes('Verification link has expired.')
      })
  }, [])

  return (
    <React.Fragment>
      <div className='page-content' style={{ minHeight: '80vh' }}>
        <Container fluid={true}>
          <Row>
            <Col className='col-12'>
              <div className='mt-4 text-center'>
                <Row>
                  <Col xl='12' md='12'>
                    <Modal
                      className='agree_modal w-100'
                      isOpen={modal_center}
                      toggle={() => {
                        tog_center()
                      }}
                      centered={true}
                      backdrop='static'
                      keyboard={false}>
                      <div className='modal-body mt-4'>
                        <p className='text-center'>{apiRes}</p>
                        <div className='d-flex justify-content-center mb-3'>
                          <Link
                            to='/login'
                            type='button'
                            title="Back to Login"
                            className='btn btn-primary btn-easy mt-3 w-30'>
                            BACK TO LOGIN
                          </Link>
                        </div>
                      </div>
                    </Modal>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default VerifyAccount
