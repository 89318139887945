import { put, takeEvery } from 'redux-saga/effects'
import { get } from '../../helpers/api_helper'
import { GET_SANCTION_SHOW_CART } from '../../helpers/url_helper'
import { GET_ALL_YOUR_PARTICIPATION_ADDITIONAL_PRODUCT_SUCCESS } from '../yourParticipation/actionType'
import {
  CURRENT_PAGE,
  GET_ALL_SPONSOR_FAIL,
  GET_ALL_SPONSOR_LIST,
  TOTAL_PAGE_SPONSOR,
  SANCTION_SHOPPING_CART_SUCCESS,
  SANCTION_SHOPPING_CART,
  GET_ALL_SPONSOR_SUCCESS,
} from './actionType'

function* sponsorList({ payload }) {
  try {
    const response = yield get(
      `/api/v1/events/sponsor/all?_limit=${payload?.limit || ''}&_page=${
        payload?.page || ''
      }&_sort=start_date_time&_order=ASC`,
      payload.data
    )

    let totalPage = 0
    totalPage = Math.ceil(response.totalRecords / payload.limit) || 1

    yield put({
      type: GET_ALL_SPONSOR_SUCCESS,
      payload: response,
    })
    yield put({
      type: TOTAL_PAGE_SPONSOR,
      payload: totalPage,
    })
    yield put({
      type: CURRENT_PAGE,
      payload: payload?.page,
    })
  } catch (err) {
    yield put({
      type: GET_ALL_SPONSOR_FAIL,
      payload: 'Error in sponsor()',
    })
  }
}

function* sponsorCart() {
  try {
    const response = yield get(`${GET_SANCTION_SHOW_CART}`)

    yield put({
      type: SANCTION_SHOPPING_CART_SUCCESS,
      payload: response,
    })
  } catch (err) {
    yield put({
      type: GET_ALL_SPONSOR_FAIL,
      payload: 'Error in sponsor()',
    })
  }
}
function* sponsorSagas() {
  yield takeEvery(GET_ALL_SPONSOR_LIST, sponsorList)
  yield takeEvery(SANCTION_SHOPPING_CART, sponsorCart)
}

export default sponsorSagas
