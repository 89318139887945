import React, { PureComponent } from 'react'

export default class Rightbar extends PureComponent {
  render() {
    return (
      <div>
        
      </div>
    )
  }
}
