import React, { useEffect } from 'react'
import { Col, Container, Row, Table } from 'reactstrap'
import { TraditionalPlanPaymentCreateIntentAction } from '../../store/actions'
import { useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'
import PaymentForm from '../../components/PaymentForm'
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'
import { useLocation } from 'react-router'
import { useHistory } from 'react-router-dom'
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE)
const TraditionalPlanRenewal = (props) => {
  const history = useHistory()
  const location = useLocation()
  const dispatch = useDispatch()
  const { loading, paymentIntent } = useSelector(
    (state) => state.TraditionalPayment
  )
  const query = new URLSearchParams(location.search)
  const payment = query.get('payment')
  // console.log("🚀 ~ file: index.js:19 ~ TraditionalPlanRenewal ~ payment:", payment)
  //   const { TraditionalPlanPaymentCreateIntentAction } = props
  // //   1) Create payment Intent
  // POST:
  // https://production-time2enter-back-front-kktdokwd3q-uc.a.run.app/api/v1/plan-purchase/renew-plan?payment=652

  // // pass payment id from email button URL

  useEffect(() => {
    if (payment) {
      let params = {
        payment: payment,
      }

      dispatch(TraditionalPlanPaymentCreateIntentAction(params, history))
    }
  }, [payment])
  const appearance = {
    theme: 'stripe',
  }
  const options = {
    clientSecret: paymentIntent?.clientSecret,
    appearance,
    hidePostalCode: true,
    country: true,
  }
  return (
    <React.Fragment>
      <div className='page-content bg-gray-101 min-h-85'>
        <section id='about' class='section1 bg-gray-101 pages event-list'>
          <Container fluid={true} className='auto-fit'>
            <Row>
              <Col lg={12} className='text-center'>
                <div className='page-title-box justify-content-between'>
                  <h2 className='top-title mb-4'>
                    <span>PROCESS PAYMENT</span>
                  </h2>
                </div>
              </Col>
              {!loading && _.size(paymentIntent) ? (
                <Col lg={12}>
                  <div className='table-responsive'>
                    <Table className='table mb-0 cust-tab tab-img'>
                      <tbody className='table-color text-white '>
                        <tr>
                          <td className='text-right'>
                            <h4>
                              <strong>Final Payment</strong>
                            </h4>
                          </td>
                          <td style={{ width: 140 }}>
                            <h4>
                              <strong>
                                ${' '}
                                {(
                                  (paymentIntent?.paymentIntent?.amount || 0) /
                                  100
                                ).toFixed(2)}
                              </strong>
                            </h4>{' '}
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                  {paymentIntent?.clientSecret && (
                    <Elements options={options} stripe={stripePromise}>
                      {/* <CheckoutForm isDisabled={paymentIntent?.clientSecret} /> */}
                      <PaymentForm
                        returnUrl={`${process.env.REACT_APP_URL}traditional_plan_renewal_redirect`}
                      />
                    </Elements>
                  )}
                </Col>
              ) : loading ? (
                <Col lg={12}>
                  <div className='table-responsive'>
                    <h1>Loading...</h1>
                  </div>
                </Col>
              ) : null}
            </Row>
          </Container>
        </section>
      </div>
    </React.Fragment>
  )
}

export default TraditionalPlanRenewal