import React from 'react'
import { useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'

const ShoppingCartSanction = (props) => {
  const { shoppingCount } = useSelector((state) => state.SponsorSanction)

  const history = useHistory()
  let user = JSON.parse(localStorage.getItem('authUser'))
  if (!user) {
    return null
  }
  return (
    <li className="nav-item list-type-none" >
      <span
        role="button"
        className="nav-link res-cart"
        onClick={() => {
          history.push({
            pathname: '/sponsor_sanction_shopping_cart',
          })
        }}
      >
        <i className="fa fa-shopping-cart cart-font" aria-hidden="true"></i>

        <Link to="/sponsor_sanction_shopping_cart">
          <p className="cart-qty-sponsor res-right-7px">{shoppingCount}</p>
        </Link>
      </span>
    </li>
  )
}

export default ShoppingCartSanction
