import { GET_FULL_PLAN_DETAILS, GET_PLAN_DETAILS } from './actionType';

export const getPlanDetail = (planId) => {
  return {
    type: GET_PLAN_DETAILS,
    payload: { plan_id: planId },
  };
};

export const getAllPlanDetail = (data) => {
  return {
    type: GET_FULL_PLAN_DETAILS,
    payload: data?.data || [],
  };
};
