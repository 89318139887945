import {
  CURRENT,
  PAGE_CHANGE,
  PaGINATION_DATA,
  RESET_PAGE,
} from './actionTypes'

export const setCurrentPage = (pageNo) => ({
  type: CURRENT,
  payload: pageNo,
})

export const pageChange = (data) => {
  return {
    type: PAGE_CHANGE,
    payload: data,
  }
}

// PaGINATION_DATA

export const paginationData = (data) => {
  return {
    type: PaGINATION_DATA,
    payload: data,
  }
}
export const resetPage = (data) => {
  return {
    type: RESET_PAGE,
    payload: data,
  }
}
