import classnames from 'classnames'
import React, { useEffect, useState } from 'react'
import { Row } from 'reactstrap'
import deleteImg from '../../../assets/images/users/close.png'
import updateImg from '../../../assets/images/users/update.png'
import { get } from '../../../helpers/api_helper'

export const EditEntryList = ({
  item,
  n,
  onUpdate,
  exhibitorList,
  breedList,
  editable,
  onAdd,
  handleDelete,
}) => {
  const [breeds, setBreeds] = useState([])
  const [groups, setGroups] = useState([])
  const [classes, setClasses] = useState([])
  const [variety, setVariety] = useState([])
  const [groupDisable, setGroupDisable] = useState(false)
  const [varietyDisable, setVarietyDisable] = useState(false)
  useEffect(() => {
    // when user change dropdown value then set by default value
    if (!item.breed_category_id) setBreeds([])
    if (!item.breed_id) setClasses([])
    if (!item.group_id) setGroups([])
    if (!item.variety_id) setVariety([])

    //
    if (item.breed_category_id) {
      get(
        `/api/v1/breed/all?published=1&breed_category_id=${item.breed_category_id}`
      ).then((res) => {
        setBreeds(res?.data || [])
        if (item.breed_id) {
          // Save all classes

          get(`api/v1/breed/${item.breed_id}/class/all?sort_by_class=1`).then(
            (res) => {
              setClasses(res?.data || [])
            }
          )

          let data = res.data.find((res) => res.breed_id === item.breed_id)

          let { group_seq, variety_seq } = data

          if (group_seq && variety_seq) {
            setGroupDisable(false)
            setVarietyDisable(false)
            get(`api/v1/breed/${item.breed_id}/group/all?has_variety=1`).then(
              (res) => {
                setGroups(res?.data || [])
              }
            )
            if (item.group_id) {
              get(
                `api/v1/breed/${item.breed_id}/group/${item.group_id}/variety/all`
              ).then((res) => {
                setVariety(res?.data || [])
              })
            }
          }
          if (!group_seq && variety_seq) {
            setGroupDisable(true)
            setVarietyDisable(false)
            get(`api/v1/breed/${item.breed_id}/variety/all`).then((res) => {
              setVariety(res?.data || [])
            })
          }
          if (group_seq && !variety_seq) {
            setVarietyDisable(true)
            setGroupDisable(false)
            // api/v1/breed/{breed_id}/group/all
            get(`api/v1/breed/${item.breed_id}/group/all`).then((res) => {
              setGroups(res?.data || [])
            })
          }
          if (!group_seq && !variety_seq) {
            setGroupDisable(true)
            setVarietyDisable(true)
          }
        }
      })
    }
  }, [
    item.breed_category_id,
    item.breed_id,
    item.group_id,
    item.variety_id,
    item.show_type,
    item.class_id,
    item.ear_no,
    item.group_seq,
    item.user_id,
    item.variety_seq,
    item.isAdd,
    item.isDisable,
  ])

  useEffect(() => {
    if (!item.variety_seq) {
      setVarietyDisable(false)
    }
    if (!item.group_seq) {
      setGroupDisable(false)
    }
  }, [item.group_seq, item.variety_seq])

  return (
    <React.Fragment>
      <Row className='res-mb-4 res-width'>
        <div className='mb-3 w4'>
          <div className='form-control bg-sr'>{n + 1}</div>
        </div>

        <div className='mb-3 w12 '>
          {/* <span>{n + 1}</span> */}
          <select
            type='text'
            name='user_id'
            className='form-control form-select'
            onChange={(e) => {
              item.isAdd ? onAdd(e, n) : onUpdate(e, n)
            }}
            value={item.user_id}
            disabled={item.isDisable}
          >
            <option value={''}>Select</option>
            {exhibitorList?.map((item, i) => {
              return (
                <option value={item.id} key={i}>
                  {item.last_name} {item.first_name}
                </option>
              )
            })}
          </select>
        </div>
        <div className='mb-3 w8'>
          <input
            type='text'
            name='ear_no'
            className='form-control'
            value={item.ear_no}
            onChange={(e) => {
              item.isAdd ? onAdd(e, n) : onUpdate(e, n)
            }}
            disabled={item.isDisable}
            placeholder='Ear No.'
          />
        </div>

        <div className='mb-3 w8'>
          <select
            name='breed_category_id'
            id='breed_category_id'
            className='form-control form-select'
            onChange={(e) => {
              item.isAdd ? onAdd(e, n) : onUpdate(e, n)
            }}
            value={item?.breed_category_id || ''}
            disabled={item.isDisable}
          >
            <option value={''}>Select</option>
            {breedList?.map((item, i) => {
              return (
                <option value={item.breed_category_id} key={i}>
                  {item.category_name}
                </option>
              )
            })}
          </select>
        </div>

        <div className='mb-3 w14'>
          <select
            name='breed_id'
            className='form-control form-select'
            value={item?.breed_id || ''}
            onChange={(e) => {
              item.isAdd ? onAdd(e, n, breeds) : onUpdate(e, n, breeds)
            }}
            disabled={item.isDisable}
          >
            <option value={''}>Select</option>
            {breeds?.map((item, i) => {
              return (
                <option value={item.breed_id} key={i}>
                  {item.breed_name}
                </option>
              )
            })}
          </select>
        </div>

        <div className='mb-3 w14'>
          <select
            type='text'
            name='group_id'
            id='group_id'
            className='form-control form-select'
            onChange={(e) => {
              item.isAdd ? onAdd(e, n) : onUpdate(e, n)
            }}
            value={item?.group_id || ''}
            disabled={groupDisable || item.isDisable}
          >
            {groupDisable ? (
              <option>-</option>
            ) : (
              <>
                <option value={''}>Select</option>
                {groups?.map((item, index) => {
                  return (
                    <option
                      value={item.group_id}
                      key={index}
                      // selected={item.group_id == inputs.group_id}
                    >
                      {item.group_name}
                    </option>
                  )
                })}
              </>
            )}
          </select>
        </div>

        <div className='mb-3 w14'>
          <select
            type='text'
            name='variety_id'
            id='variety_id'
            className='form-control form-select'
            value={item?.variety_id || ''}
            onChange={(e) => {
              item.isAdd ? onAdd(e, n) : onUpdate(e, n)
            }}
            disabled={varietyDisable || item.isDisable}
          >
            {varietyDisable ? (
              <option>-</option>
            ) : (
              <>
                <option value={''}>Select</option>
                {variety?.map((item, i) => {
                  return (
                    <option value={item.variety_id} key={i}>
                      {item.variety_name}
                    </option>
                  )
                })}
              </>
            )}
          </select>
        </div>
        <div className='mb-3 w8'>
          <select
            type='text'
            name='class_id'
            id='class_id'
            className='form-control form-select'
            value={item?.class_id}
            onChange={(e) => {
              item.isAdd ? onAdd(e, n) : onUpdate(e, n)
            }}
            disabled={item.isDisable}
          >
            <option value={''}>Select</option>
            {classes?.map((item, i) => {
              return (
                <option value={item.class_id} key={i}>
                  {item.class_name}
                </option>
              )
            })}
          </select>
        </div>
        <div className='mb-3 w8'>
          <select
            type='text'
            name='show_type'
            id='show_type'
            className='form-control form-select'
            onChange={(e) => {
              item.isAdd ? onAdd(e, n) : onUpdate(e, n)
            }}
            value={item.show_type}
            disabled={item.isDisable}
          >
            <option value={''}>Select</option>
            <option value='open'>Open</option>
            <option value='youth'>Youth</option>
          </select>
        </div>
        <div className='mb-3 text-center pt-0 w10'>
          <img
            src={updateImg}
            alt='update'
            // onClick={() => {
            //   setIsDisabled(false);
            // }}
            onClick={() => editable(n)}
            className={classnames(
              'p-r-3 mb-2',
              { 'not-allow': !item.isDisable },
              { 'pe-auto': item.isDisable }
            )}
            disabled={item.isDisable}
          />
          <img
            src={deleteImg}
            alt='delete'
            //'p-r-3 pe-auto'

            className={classnames(
              'p-r-3 mb-2',
              { 'not-allow': !item.isDisable },
              { 'pe-auto': item.isDisable }
            )}
            onClick={() => {
              item.isDisable && handleDelete(item.easy_entry_id, n)
            }}
            disabled={item.isDisable}
          />
        </div>
      </Row>
    </React.Fragment>
  )
}