import React, { useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Container } from 'reactstrap'
import { EasyFiedsNav } from '../../../common/data'
import _ from 'lodash'
// import moduleName from 'module';
const Header = () => {
  const location = useLocation()
  let lastUrl = location.pathname.substring(
    location.pathname.lastIndexOf('/') + 1
  )
  const [isActive, setIsActive] = useState(false)
  const handleClick = (event) => {
    // 👇️ toggle isActive state on click
    setIsActive((current) => !current)
  }


  const user = JSON.parse(localStorage.getItem('authUser'))
  return (
    <React.Fragment>
      <div
        className='header-main header-easy header-dark fixed-top'
        style={{ opacity: '1 ' }}
      >
        <div className='navbar navbar-expand-lg navbar-dark'>
          <Container fluid={true} className='auto-fit'>
            <Link className='navbar-brand' to='/auth-events'>
              <img
                className='logo-dark'
                src={'/assets/img/logo/logo.png'}
                title=''
                alt=''
              />
              <img
                className='logo-light'
                src={'/assets/img/logo/logo.png'}
                title=''
                alt=''
              />
            </Link>
            <button
              className='navbar-toggler'
              onClick={handleClick}
              type='button'
              data-bs-toggle='collapse'
              data-bs-target='#navbarSupportedContent'
              aria-controls='navbarSupportedContent'
              aria-expanded='false'
              aria-label='Toggle navigation'
            >
              <span className='navbar-toggler-icon'></span>
            </button>

            <div
              className={
                isActive
                  ? 'show collapse navbar-collapse'
                  : 'collapse navbar-collapse'
              }
            id='navbarSupportedContent'>
              <ul className='navbar-nav ms-auto align-items-center easyfide-navbar'>

              <li className='nav-item'>
                  <a
                    href='https://www.easy2show.com/assets/pdf/Easyfieds_How_2.pdf'
                    target='_blank'
                    className='nav-link'
                    rel='noreferrer'
                  >
                    How 2 Easyfieds
                  </a>
                </li>

                {Array.isArray(EasyFiedsNav)
                  ? EasyFiedsNav.map((nav, index) => {
                      return (
                        <li className='nav-item' key={index}>
                          <Link
                            to={nav.url}
                            className={`nav-link ${
                              lastUrl === nav.url ? 'active' : ''
                            }`}
                          >
                            {nav?.name || ''}{' '}
                          </Link>
                        </li>
                      )
                    })
                  : null}
                {/* 
                <li className='nav-item'>
                  <Link
                    to='/easy-entry'
                    className={`nav-link ${
                      lastUrl == 'easy-entry' ? 'active' : ''
                    }`}
                  >
                    Easy Entry{' '}
                  </Link>
                </li>
                <li className='nav-item'>
                  <Link
                    to='/sponsor_sanction'
                    className={`nav-link ${
                      lastUrl === 'sponsor_sanction' ? 'active' : ''
                    }`}
                  >
                    Sponsors
                  </Link>
                </li> */}

                {/* <li className='nav-item dropdown res-text-c'>
                  <Link
                    className={`nav-link d-flex  align-items-center ${
                      lastUrl === 'edit-profile' ||
                      lastUrl === 'subuserlist' ||
                      lastUrl === 'change-password'
                        ? 'active'
                        : ''
                    }`}
                    data-toggle='dropdown'
                    to='/easyfides/profile'
                  >
                    <i className='fa fa-user' aria-hidden='true'></i>
                    <div className='text-break text-truncate ps-2'>
                      {_.truncate(
                        `${user?.last_name || ''} ${user?.first_name || ''}`,
                        {
                          length: 18,
                          omission: '...',
                        }
                      )}
                    </div>
                  </Link>
                </li> */}

                <li className='nav-item dropdown res-text-c prof-btn temo-m-l'>
                  <Link
                    className='btn-secondary btn-sm'
                    to='/easyfides/profile'>
                    My Profile
                  </Link>
                </li>
              </ul>
            </div>
          </Container>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Header