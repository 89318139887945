import React from 'react'
import { Col, Row, Table } from 'reactstrap'
import { useParams, useHistory } from 'react-router-dom'
import _ from 'lodash'
import { UrlEncodeHook } from '../../../hooks'

const SponsorShow = (props) => {
  let { id } = useParams()
  const { urlDecode } = UrlEncodeHook()
  id = urlDecode(id)
  const history = useHistory()
  const { showClubPrice, show, addTOCart } = props

  let clubData = showClubPrice.find(
    (club) => Number(club.show_id) === Number(show.show_id)
  )

  let clubList = []
  if (clubData?.club_list && clubData?.club_list.length) {
    clubList = clubData.club_list.filter((s) => {
      if (Array.isArray(s?.costing_details)) {
        return s?.costing_details.length
      }
    })
  }

  let user = JSON.parse(localStorage.getItem('authUser'))

  const postFixShow = `${show.show_category === 'speciality' ? '/ SP' : ''}`

  return (
    <Row>
      <Col lg={12} className="mb-5">
        <div className="table-responsive1">
          <Table className="table mb-0 cust-tab sponsor-view text-left">
            <thead className="table-color">
              <tr>
                <th className="text-uppercase">
                  {' '}
                  {show?.show_type || ''} {show.show_char} {postFixShow}
                </th>
                <th className="text-center w-15">Price</th>
              </tr>
            </thead>
            <tbody>
              {Number(clubData?.active) && clubList.length ? (
                clubList.map((club, i) => {
                  let cost = _.first(club.costing_details)

                  let price = 0.0
                  if (show.show_type === 'open') {
                    price = cost.open_amt
                  } else if (show.show_type === 'youth') {
                    price = cost.youth_amt
                  }
                  return (
                    <tr key={i}>
                      <td>
                        {club?.club_title || ''} <br />
                      </td>
                      <td className="text-center">
                        {club.purchase ? (
                          <button
                            type="button"
                            class="btn btn-danger w-100"
                            disabled
                          >
                            Sold Out
                          </button>
                        ) : (
                          <>
                            ${Number(price).toFixed(2)} <br />
                            {/* $ {price ? price.toFixed(2) : ''} <br />{' '} */}
                            <button
                              type="button"
                              class="btn btn-primary btn-easy w-100 m-0"
                              onClick={() => {
                                if (!user?.id) {
                                  history.push(`/login`)
                                  return
                                }
                                addTOCart({
                                  event_id: Number(id),
                                  sanction_number_club_id:
                                    club.sanction_number_club_id,
                                  show_id: Number(clubData.show_id),
                                  show_type: show?.show_type,
                                  show_char: show?.show_char,
                                  club_id: club.club_id,
                                  in_cart: 1,
                                })
                              }}
                            >
                              Add to Cart
                            </button>
                          </>
                        )}

                        {/* </Link> */}
                      </td>
                    </tr>
                  )
                })
              ) : (
                <tr>
                  <td colSpan={6} className="text-center">
                    No data found
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      </Col>
    </Row>
  )
}

SponsorShow.propTypes = {}

export default SponsorShow
