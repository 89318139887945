import { put, takeEvery } from 'redux-saga/effects';
import { get, post } from '../../helpers/api_helper';
// event Redux States
import {
  GET_ALL_EXHIBITOR_LIST,
  GET_ALL_EXHIBITOR_LIST_FAIL,
  GET_ALL_EXHIBITOR_LIST_SUCCESS,
  GET_ALL_SHOWS,
  GET_CATEGORY_LIST,
  GET_CATEGORY_LIST_BY_EVENT,
  GET_CATEGORY_LIST_BY_EVENT_FAIL,
  GET_CATEGORY_LIST_BY_EVENT_SUCCESS,
  GET_CATEGORY_LIST_FAIL,
  GET_CATEGORY_LIST_SUCCESS,
  GET_ENTRY_LIST_FAIL,
  SET_EXHIBITOR_LOADING,
} from './actionTypes';

function* getAllExhibitor() {
  //console.log('payload==========',payload)
  try {
    yield put({
      type: SET_EXHIBITOR_LOADING,
    });
    const response = yield get(`/api/v1/profile/exhibitor_list`);
    // console.log('response===============', response);
    // let list = response.data?.sort((a, b) =>
    //   a.first_name.toUpperCase() > b.first_name.toUpperCase()
    //     ? 1
    //     : b.first_name.toUpperCase() > a.first_name.toUpperCase()
    //     ? -1
    //     : 0
    // );

    yield put({
      type: GET_ALL_EXHIBITOR_LIST_SUCCESS,
      payload: response,
    });
  } catch (err) {
    yield put({
      type: GET_ALL_EXHIBITOR_LIST_FAIL,
      payload: 'Error in getEvent()',
    });
  }
}

function* getAllBreedCategory() {
  //console.log('payload==========',payload)
  try {
    const response = yield get(`/api/v1/breed/category/all`);
    //console.log('response===============',response)
    yield put({
      type: GET_CATEGORY_LIST_SUCCESS,
      payload: response,
    });
  } catch (err) {
    yield put({
      type: GET_CATEGORY_LIST_FAIL,
      payload: 'Error in getEvent()',
    });
  }
}
function* getAllBreedCategoryByEvent({ payload }) {
  // console.log('payload==========', payload);
  try {
    const response = yield post(
      `/api/v1/events/${payload.event_id}/breeds/breed_category`,
      { show_id_list: payload.show_id_list }
    );
    // console.log('response===============', response);
    yield put({
      type: GET_CATEGORY_LIST_BY_EVENT_SUCCESS,
      payload: response,
    });
  } catch (err) {
    yield put({
      type: GET_CATEGORY_LIST_BY_EVENT_SUCCESS,
      payload: [],
    });
    yield put({
      type: GET_CATEGORY_LIST_BY_EVENT_FAIL,
      payload: 'Error in getAllBreedCategoryByEvent()',
    });
  }
}
function* getAllShows({ payload }) {
  try {
    const response = yield get(
      `/api/v1/shows/all?event_id=${payload.event_id}`
    );
    console.log('file: saga.js -> line 94 -> response', response);

    // yield put({
    //   type: ,
    //   payload: response,
    // })
  } catch (err) {
    yield put({
      type: GET_ENTRY_LIST_FAIL,
      payload: 'Error in getEvent()',
    });
  }
}

function* easyEntrySaga() {
  yield takeEvery(GET_ALL_EXHIBITOR_LIST, getAllExhibitor);
  yield takeEvery(GET_CATEGORY_LIST, getAllBreedCategory);
  yield takeEvery(GET_ALL_SHOWS, getAllShows);
  yield takeEvery(GET_CATEGORY_LIST_BY_EVENT, getAllBreedCategoryByEvent);
}

export default easyEntrySaga;
