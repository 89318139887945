export const GET_ALL_LIVE_EVENT_LIST = 'GET_ALL_LIVE_EVENT_LIST'
export const GET_ALL_LIVE_EVENT_LIST_SUCCESS = 'GET_ALL_LIVE_EVENT_LIST_SUCCESS'

export const GET_ALL_LIVE_EVENT_SHOW_LIST = 'GET_ALL_LIVE_EVENT_SHOW_LIST'
export const GET_ALL_LIVE_EVENT_SHOW_LIST_SUCCESS =
  'GET_ALL_LIVE_EVENT_SHOW_LIST_SUCCESS'

export const GET_ALL_LIVE_EVENT_BREED_FUR_LIST =
  'GET_ALL_LIVE_EVENT_BREED_FUR_LIST'
export const GET_ALL_LIVE_EVENT_BREED_FUR_LIST_SUCCESS =
  'GET_ALL_LIVE_EVENT_BREED_FUR_LIST_SUCCESS'

export const GET_ALL_LIVE_EVENT_BREED_DETAIL = 'GET_ALL_LIVE_EVENT_BREED_DETAIL'
export const GET_ALL_LIVE_EVENT_BREED_AS_FUR_DETAIL =
  'GET_ALL_LIVE_EVENT_BREED_AS_FUR_DETAIL'
export const GET_ALL_LIVE_EVENT_BREED_DETAIL_SUCCESS =
  'GET_ALL_LIVE_EVENT_BREED_DETAIL_SUCCESS'

export const LIVE_EVENT_BREED_TAB_OPEN = 'LIVE_EVENT_BREED_TAB_OPEN'
export const LIVE_EVENT_BREED_TAB_CLOSE = 'LIVE_EVENT_BREED_TAB_CLOSE'

export const LIVE_EVENT_EXHIBITOR_LIST = 'LIVE_EVENT_EXHIBITOR_LIST'
export const LIVE_EVENT_EXHIBITOR_LIST_SUCCESS =
  'LIVE_EVENT_EXHIBITOR_LIST_SUCCESS'

export const LIVE_EVENT_PARTICIPATION_LIST_SUCCESS =
  'LIVE_EVENT_PARTICIPATION_LIST_SUCCESS'

export const LIVE_EVENT_RELOAD = 'LIVE_EVENT_RELOAD'

export const LIVE_EVENT_RESET_ACTIVE_TABS = 'LIVE_EVENT_RESET_ACTIVE_TABS'

export const GET_ALL_LIVE_EVENT_BREED_FUR_LIST_FOR_EXCEL =
  'GET_ALL_LIVE_EVENT_BREED_FUR_LIST_FOR_EXCEL'

export const LIVE_EVENT_EXHIBITOR_LIST_FOR_EXCEL =
  'LIVE_EVENT_EXHIBITOR_LIST_FOR_EXCEL'
