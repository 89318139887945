import {
  ALL_LISTING_JUDGE,
  Add_JUDGE,
  DELETE_JUDGE,
  LISTING_JUDGE,
  UPDATE_JUDGE,
} from './actionType'

//
export const JudgeCreate = (data) => ({
  type: Add_JUDGE,
  payload: data,
})
export const JudgeUpdate = (data) => ({
  type: UPDATE_JUDGE,
  payload: data,
})

export const JudgeListing = (data) => {
  return {
    type: LISTING_JUDGE,
    payload: data,
  }
}

export const allJudgeListing = (data) => {
  return {
    type: ALL_LISTING_JUDGE,
    payload: data,
  }
}

export const JudgeDelete = (data) => {
  return {
    type: DELETE_JUDGE,
    payload: data,
  }
}
