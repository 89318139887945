import {
  ALL_LISTING_ANIMAL_INQUIRY,
  ALL_LISTING_EQUIPMENT_INQUIRY,
  ALL_LISTING_JUDGE_INQUIRY,
} from './actionType'

export const AnimalInquiryList = (data) => {
  return {
    type: ALL_LISTING_ANIMAL_INQUIRY,
    payload: data,
  }
}
export const JudgeInquiryList = (data) => {
  return {
    type: ALL_LISTING_JUDGE_INQUIRY,
    payload: data,
  }
}
export const EquipmentInquiryList = (data) => {
  return {
    type: ALL_LISTING_EQUIPMENT_INQUIRY,
    payload: data,
  }
}
