import {
  GET_ALL_BREED_LIST,
  ADD_BREED_CONFIGURE,
  GET_BREED_CONFIGURE_DATA,
} from './actionTypes'

export const herdConfigureBreedList = () => ({
  type: GET_ALL_BREED_LIST,
})

export const addConfigureBreedList = (data, callback) => ({
  type: ADD_BREED_CONFIGURE,
  payload: { data, callback },
})
export const getConfigureBreedList = (data, callback) => ({
  type: GET_BREED_CONFIGURE_DATA,
  payload: data,
})
