


import {
    GET_ALL_DISCOUNT_SUMMARY_SUCCESS,
    SET_SUMMARY_PRODUCT_LOADING
  } from './actionType';
  
  //
  const INIT_STATE = {
    discount_summary: [],
    loading: true,
    error: {},
  };
  
  const discountSummary = (state = INIT_STATE, action) => {
  
    switch (action.type) {
      case SET_SUMMARY_PRODUCT_LOADING:
        return {
          ...state,
          loading: true,
        };
      case GET_ALL_DISCOUNT_SUMMARY_SUCCESS:
        return {
          ...state,
          discount_summary: action.payload,
          loading: false,
        }
      default:
        return state;
    }
  };
  
  export default discountSummary;