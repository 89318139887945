import {
  ADD_YOUR_PARTICIPATION_ANIMAL_LIST_SUCCESS,
  ADD_YOUR_PARTICIPATION_TO_SHOPPING_CART,
  GET_ALL_YOUR_PARTICIPATION_ADDITIONAL_PRODUCT,
  GET_ALL_YOUR_PARTICIPATION_ANIMAL_LIST,
  GET_ALL_YOUR_PARTICIPATION_EVENT,
  GET_ALL_YOUR_PARTICIPATION_EVENT_DATES,
} from './actionType'

export const getAllEventDate = (data) => ({
  type: GET_ALL_YOUR_PARTICIPATION_EVENT_DATES,
  payload: data,
})

export const getAllParticipatedEvent = (data) => ({
  type: GET_ALL_YOUR_PARTICIPATION_EVENT,
  payload: data,
})

export const getAllEventDataSuccess = (data) => {
  return data
}

export const getAllYourParticipationAdditionProduct = (data) => ({
  type: GET_ALL_YOUR_PARTICIPATION_ADDITIONAL_PRODUCT,
  payload: data,
})

export const getAllYourParticipationByShow = (data) => ({
  type: GET_ALL_YOUR_PARTICIPATION_ANIMAL_LIST,
  payload: data,
})

export const PreviewParticipation = (data) => {
  return {
    type: ADD_YOUR_PARTICIPATION_ANIMAL_LIST_SUCCESS,
    payload: data,
  }
}

export const AddYourParticipationToShoppingCart = (data) => {
  return {
    type: ADD_YOUR_PARTICIPATION_TO_SHOPPING_CART,
    payload: data,
  }
}
