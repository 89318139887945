import { put, takeEvery } from 'redux-saga/effects'
import { get, post } from '../../helpers/api_helper'
import {
  GET_TRADITIONAL_PLANE_PAYMENT_INTENT_URL,
  TRADITIONAL_PLAN_PAYMENT_RENEW_URL,
} from '../../helpers/url_helper'
import {
  TRADITIONAL_PLAN_PAYMENT_INTENT,
  TRADITIONAL_PLAN_PAYMENT_INTENT_ACTION,
  TRADITIONAL_PLAN_PAYMENT_INTENT_SUCCESS,
  TRADITIONAL_RENEW_PLAN_PAYMENT_ACTION,
  TRADITIONAL_RENEW_PLAN_PAYMENT_SUCCESS,
} from './actionType'
import { toast } from 'react-toastify'

//
// function* sponsorList({ payload }) {
function* TraditionalPlanPaymentSaga({ payload: { params, history } }) {
  try {
    const response = yield post(
      `${GET_TRADITIONAL_PLANE_PAYMENT_INTENT_URL}`,
      null,
      {
        params: params,
      }
    )

    yield put({
      type: TRADITIONAL_PLAN_PAYMENT_INTENT,
      payload: response,
    })
  } catch (err) {
    if (err?.response?.data.data) {
      setTimeout(() => {
        history.push('/login?logout=true')
      }, 1000)
      toast.error(err?.response?.data.data)
    } else if (err?.response?.data) {
      toast.error(err?.response?.data)
    }
  }
}
function* TraditionalPlaneRenew({ payload }) {
  try {
    const response = yield post(
      `${TRADITIONAL_PLAN_PAYMENT_RENEW_URL}`,
      payload
    )
    if (response) {
      yield put({
        type: TRADITIONAL_RENEW_PLAN_PAYMENT_SUCCESS,
        payload: {
          message: 'PAYMENT SUCCESSFUL',
          success: true,
        },
      })
    }
  } catch (err) {
    if (err?.response?.data) {
      yield put({
        type: TRADITIONAL_RENEW_PLAN_PAYMENT_SUCCESS,
        payload: {
          message: '',
          success: false,
        },
      })
      toast.error(err?.response?.data)
    }
  }
}

function* TraditionalPlanePaymentSagas() {
  yield takeEvery(
    TRADITIONAL_PLAN_PAYMENT_INTENT_ACTION,
    TraditionalPlanPaymentSaga
  )
  yield takeEvery(TRADITIONAL_RENEW_PLAN_PAYMENT_ACTION, TraditionalPlaneRenew)
}

export default TraditionalPlanePaymentSagas
