import _ from 'lodash'
import {
  ADD_BREED_CONFIGURE,
  GET_ALL_BREED_LIST,
  GET_ALL_BREED_LIST_SUCCESS,
  GET_BREED_CONFIGURE_DATA,
  GET_BREED_CONFIGURE_DATA_SUCCESS,
} from './actionTypes'

const initialState = {
  form: {
    config_user_id: '',
    breed_list: [],
  },
  formLoading: true,
}

const herdConfigurationReducer = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case GET_ALL_BREED_LIST:
      return {
        ...state,
        formLoading: true,
      }
    case GET_ALL_BREED_LIST_SUCCESS:
      // 
      return {
        ...state,
        form: {
          ...state.form,
          breed_list: _.map(
            _.groupBy(_.filter(payload, o=> o.breed_category_abbre !== 'commercial') || [], 'breed_category_abbre'),

            (breed, breed_category_abbre) => {
              const findCatData = breed.find(
                (b) => b.breed_category_abbre === breed_category_abbre
              )
              return {
                breed_category_abbre,
                breed_category_id: findCatData.breed_category_id,
                category_name: findCatData.category_name,
                isSelected: false,
                breed: _.map(breed, (b) => {
                  return _.extend({}, b, { isSelected: false })
                }),
              }
            }
          ),
        },
        formLoading: true,
      }
      case GET_BREED_CONFIGURE_DATA:
        return{
          ...state,
          formLoading: true,
        }
    case GET_BREED_CONFIGURE_DATA_SUCCESS:
      const first = _.first(payload)

      const finalData = _.map(state.form.breed_list, (b) => {
        const breedCat = _.find(
          payload,
          (_cat) => _cat.breed_category === b.breed_category_abbre
        )
        if (breedCat) {
          return {
            ...b,
            config_user_id: breedCat.config_user_id,
            breed_category: breedCat.breed_category,
            club_type: breedCat.club_type,
            club_id: breedCat.club_id,
            is_deactivated: breedCat.is_deactivated,
            isSelected: true,
            breed: _.map(b.breed, (_breed) => {
              const findBreed = _.find(
                breedCat?.breed_list || [],
                (_b) => _b.breed_id === _breed.breed_id
              )
              if (findBreed) {
                return {
                  ..._breed,
                  ...findBreed,
                  isSelected: true,
                }
              }
              return { ..._breed }
            }),
          }
        }

        return { ...b }
      })

      return {
        ...state,
        form: {
          ...state.form,
          config_user_id: first?.config_user_id || '',
          breed_list: finalData,
        },
        formLoading: false,
      }

    default:
      return state
  }
}
export default herdConfigurationReducer
