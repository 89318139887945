import { combineReducers } from 'redux'

// Front
import Layout from './layout/reducer'

// Authentication
import Login from './auth/login/reducer'
import Account from './auth/register/reducer'
import ForgetPassword from './auth/forgetpwd/reducer'
import Profile from './auth/profile/reducer'

//Calendar
import calendar from './calendar/reducer'

//E-commerce
import ecommerce from './e-commerce/reducer'

//chat
import chat from './chat/reducer'

//invoices
import invoices from './invoices/reducer'

//contacts
import contacts from './contacts/reducer'

//events
import event from './event/reducer'

//pagination
import pagination from './pagination/reducer'

//successpopup
import successpopup from './successpopup/reducer'

//popup
import popup from './popup/reducer'

//easyentry
import easyEntry from './easyEntry/reducer'

// Additional Product

import additional_product from './additionalProducts/reducer'

//
import shopping_cart from './shoppingCart/reducer'
import DiscountSummary from './discount/reducer'
import purchasePlan from './purchasePlan/reducer'
import SponsorSanction from './sponsorEvent/reducer'
//
import YourParticipation from './yourParticipation/reducer'
import TraditionalPayment from './TraditionalPayment/reducer'

//
import Breeds from './Breeds/reducer'
import ClassTypes from './ClassTypes/reducer'
///

import State from './state/reducer'
import Country from './country/reducer'
//
// Easyfides
import EasyfidesProfile from './easyFides/Profile/reducer'
import EasyFiedsAnimal from './easyFides/Animal/reducer'
import EasyFiedsJudge from './easyFides/Judge/reducer'
import EasyFiedsMiles from './easyFides/Miles/reducer'
import VendorCategory from './easyFides/VendorCategory/reducer'
import Equipment from './easyFides/Equipment/reducer'
import Inquiry from './easyFides/Inquiry/reducer'
import PremierMembership from './premierMembership/reducer'
import Modal from './Modal/reducer'
import PremierReport from './premierReport/reducer'
import LiveEvent from './LiveEvent/reducer'
import herdManagement from './HerdManagement/reducer'

const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  Account,
  ForgetPassword,
  Profile,
  calendar,
  chat,
  ecommerce,
  invoices,
  contacts,
  event,
  pagination,
  successpopup,
  easyEntry,
  popup,
  //
  State,
  Country,

  //
  additional_product,
  shopping_cart,
  DiscountSummary,
  purchasePlan,
  SponsorSanction,
  YourParticipation,
  TraditionalPayment,
  EasyfidesProfile,
  EasyFiedsAnimal,
  EasyFiedsJudge,
  EasyFiedsMiles,
  VendorCategory,
  Breeds,
  ClassTypes,
  Equipment,
  Inquiry,
  PremierMembership,
  Modal,
  PremierReport,
  LiveEvent,
  herdManagement,
})

export default rootReducer
