import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import Breadcrumb from '../../../common/Breadcrumb'
import {
  Card,
  CardHeader,
  FileField,
  SelectField,
  Spinner,
  TextField,
} from '../Atoms'
import { Button, CardBody, Col, Label, Row } from 'reactstrap'
import { Field, Form, Formik, useFormikContext } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import * as validation from '../../../helpers/validation'
import * as Yup from 'yup'
import {
  CA_ZIP_REG_EX,
  EMAIL_REGEX,
  MAX_FILE_SIZE_5,
  ONLY_ALLOW_NUMBER_AND_CHAR,
  ONLY_CHAR_REGEX,
  ONLY_NUMBER_WITH_DASH,
  SUPPORTED_FORMATS_IMG,
  US_ZIP_REG_EX,
} from '../../../helpers/utils'
import { useHistory, useParams } from 'react-router-dom'
import {
  addPedigreeHeader,
  allCountryList,
  allStateList,
  deletePedigreeImage,
  getPedigreeHeader,
  resetState,
  updatePedigreeHeader,
} from '../../../store/actions'
import _ from 'lodash'
import { toast } from 'react-toastify'
import { UrlEncodeHook } from '../../../hooks'
import Swal from 'sweetalert2'

const HerdHeaderForm = (props) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { urlDecode } = UrlEncodeHook()
  let { id } = useParams()
  id = urlDecode(id)

  const { pedigreeHeaderForm, pedigreeHeaderFormLoading, user } = useSelector(
    (state) => ({
      pedigreeHeaderForm: state?.herdManagement?.pedigreeHeader?.form,
      pedigreeHeaderFormLoading:
        state?.herdManagement?.pedigreeHeader?.formLoading,
      user: state?.Login?.user,
    })
  )

  const { CountryList } = useSelector((state) => {
    return {
      CountryList: _.map(state?.Country?.CountryList, (c) => ({
        label: c?.country_name,
        value: c?.country_id,
      })),
    }
  })
  const { StateList } = useSelector((state) => {
    return {
      StateList: _.map(state?.State?.StateList, (c) => ({
        label: c?.state_code,
        value: c?.state_id,
      })),
    }
  })

  useEffect(() => {
    dispatch(allCountryList())
  }, [])
  useEffect(() => {
    if (id) {
      dispatch(getPedigreeHeader(id))
    }
  }, [id])

  const validationSchema = Yup.object({
    header_name: Yup.string()
      .trim()
      .required(`Header name ${validation.REQUIRED}`)
      .max(50, `Header name ${validation.MAX_CHAR_FUN(50)}`),
    logFile1: Yup.mixed().nullable(),
    logFile2: Yup.mixed().nullable(),
    logo1: Yup.mixed()
      .test('fileSize', 'Please select less then 256 KB image', (value) => {
        if (value?.size) {
          let fsize = value?.size
          fsize = Math.round(fsize / 1024)
          return fsize <= MAX_FILE_SIZE_5
        }
        return true
      })
      .test(
        'fileFormat',
        'Please select valid image',
        (value) => !value || SUPPORTED_FORMATS_IMG.includes(value.type)
      ),
    logo2: Yup.mixed()
      .test('fileSize', 'Please select less then 256 KB image', (value) => {
        if (value?.size) {
          let fsize = value?.size
          fsize = Math.round(fsize / 1024)
          return fsize <= MAX_FILE_SIZE_5
        }
        return true
      })
      .test(
        'fileFormat',
        'Please select valid image',
        (value) => !value || SUPPORTED_FORMATS_IMG.includes(value.type)
      ),
    first_name: Yup.string()
      .trim()
      .required(`First name ${validation.REQUIRED}`)
      // .matches(ONLY_CHAR_REGEX, `First name ${validation.INVALID}`)
      .max(50, `First name ${validation.MAX_CHAR_FUN(50)}`),
    last_name: Yup.string()
      .trim()
      .required(`Last name ${validation.REQUIRED}`)
      // .matches(ONLY_CHAR_REGEX, `Last name ${validation.INVALID}`)
      .max(50, `Last name ${validation.MAX_CHAR_FUN(50)}`),
    address_1: Yup.string()
      .trim()
      .required(`Address 1 ${validation.REQUIRED}`)
      .max(50, `Address 1 ${validation.MAX_CHAR_FUN(50)}`),
    address_2: Yup.string()
      .trim()
      .max(50, `Address 2 ${validation.MAX_CHAR_FUN(50)}`),
    country_id: Yup.string().trim().required(`Country ${validation.REQUIRED}`),
    state_id: Yup.string().trim().required(`State ${validation.REQUIRED}`),
    city: Yup.string()
      .trim()
      .required(`City ${validation.REQUIRED}`)
      .max(50, `City ${validation.MAX_CHAR_FUN(50)}`)
      .matches(ONLY_CHAR_REGEX, `City ${validation.ONLY_CHARACTER}`),
    zipcode: Yup.string()
      .when('country_id', {
        is: (value) => (Number(value) && Number(value) === 1 ? true : false),
        then: () =>
          Yup.string()
            .required(`Zip code ${validation.REQUIRED}`)
            .matches(US_ZIP_REG_EX, `Zip code ${validation.INVALID}`),
      })
      .when('country_id', {
        is: (value) => (Number(value) && Number(value) === 2 ? true : false),
        then: () =>
          Yup.string()
            .required(`Zip code ${validation.REQUIRED}`)
            .matches(CA_ZIP_REG_EX, `Zip code ${validation.INVALID}`),
      })
      .trim()
      .required(`Zip Code ${validation.REQUIRED}`)
      .matches(ONLY_ALLOW_NUMBER_AND_CHAR, `Zip code ${validation.INVALID}`)
      .max(50, `Zip Code ${validation.MAX_CHAR_FUN(50)}`),
    phone: Yup.string()
      .trim()
      // .required(`Phone ${validation.REQUIRED}`)
      .nullable()
      .notRequired()
      // .matches(ONLY_NUMBER_WITH_DASH, `Phone number ${validation.INVALID}`)
      .test(
        'phone',
        `Phone number ${validation.MUST_BE_10_DIGITS}`,
        (value) => {
          return value?.replace(/[^0-9]/g, '')
            ? value.replace(/[^0-9]/g, '').length === 10
            : true
        }
      ),
    email: Yup.string()
      .trim()
      .email(`Email ${validation.INVALID}`)
      .matches(EMAIL_REGEX, `Email ${validation.INVALID}`),
    signature: Yup.string()
      .trim()
      .nullable()
      .max(30, `Signature ${validation.MAX_CHAR_FUN(30)}`),
    font_type: Yup.string().when('signature', {
      is: (value) => {
        return Boolean(value)
      },
      then: () =>
        Yup.string().required(`Signature font ${validation.REQUIRED}`),
      otherwise: () =>
        Yup.string()
          .notRequired()
          .transform(() => null),
    }),
  })

  const addApi = (body) => {
    return new Promise((resolve, reject) => {
      dispatch(
        addPedigreeHeader(body, (err, data) => {
          if (err) {
            reject(err)
          } else {
            resolve(data)
          }
        })
      )
    })
  }
  const updateApi = (body) => {
    return new Promise((resolve, reject) => {
      dispatch(
        updatePedigreeHeader(body, (err, data) => {
          if (err) {
            reject(err)
          } else {
            resolve(data)
          }
        })
      )
    })
  }

  const deleteImageApi = (body) => {
    return new Promise((resolve, reject) => {
      dispatch(
        deletePedigreeImage(body, (err, data) => {
          if (err) {
            reject(err)
          } else {
            resolve(data)
          }
        })
      )
    })
  }
  const onSubmit = async (values, formProps) => {
    let value = validationSchema.cast(values)
    formProps.setStatus(true)
    try {
      const formData = new FormData()
      formData.append('user_id', user?.id)
      formData.append('header_name', value.header_name)
      formData.append('first_name', value.first_name)
      formData.append('last_name', value.last_name)
      formData.append('address_1', value.address_1)
      formData.append('address_2', value.address_2)
      formData.append('country_id', value.country_id)
      formData.append('state_id', value.state_id)
      formData.append('city', value.city)
      formData.append('zipcode', value.zipcode)
      formData.append('phone', value.phone?.replace(/-/gm, ''))
      formData.append('email', value?.email || '')
      formData.append('signature', value?.signature || '')
      formData.append('font_type', value?.font_type || '')
      if (value?.logFile1) {
        formData.append('logo_1', value.logFile1)
      }
      if (value?.logFile2) {
        formData.append('logo_2', value.logFile2)
      }

      if (id) {
        // updateApi
        const data = {
          id: id,
          body: formData,
        }
        const res = await updateApi(data)
        if (res?.data) {
          toast.success(res?.data)
          setTimeout(() => {
            history.push('/herd-management/pedigree-headers')
          }, 1500)
        }
      } else {
        const res = await addApi(formData)
        if (res?.data) {
          toast.success(res?.data)
          setTimeout(() => {
            history.push('/herd-management/pedigree-headers')
          }, 1500)
        }
      }
    } catch (err) {
      formProps.setStatus(false)
      if (Array.isArray(err)) {
        toast.error(_.get(_.first(err), 'msg'))
      } else if (err) {
        toast.error(err)
      }
    }
  }

  const onLogoDelete = (logo) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      width: '380px',
      showCancelButton: true,
      confirmButtonColor: '#062575',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          // if(logo.type)
          const body = {}
          body[logo.logo_type] = 1
          const data = {
            params: {
              id: logo.id,
            },
            body: body,
          }
          const res = await deleteImageApi(data)
          if (res?.data) {
            toast.success(res.data)
            dispatch(getPedigreeHeader(id))
          }
        } catch (err) {
          if (Array.isArray(err)) {
            toast.error(_.get(_.first(err), 'msg'))
          } else if (err) {
            toast.error(err)
          }
        }
      }
    })
  }
  return (
    <Card className='layout'>
      <Row>
        <CardHeader>
          <Row>
            <Breadcrumb title={`${id ? 'Edit Header' : 'Add Header'}`} />
          </Row>
        </CardHeader>
        <CardBody>
          {!pedigreeHeaderFormLoading ? (
            <Col lg='12'>
              <Formik
                initialValues={pedigreeHeaderForm}
                onSubmit={onSubmit}
                enableReinitialize
                validationSchema={validationSchema}
              >
                {({ status, values, errors }) => {
                  return (
                    <Form className='needs-validation'>
                      <Row>
                        <Col md={12}>
                          <div className='mb-3'>
                            <Label className='form-label' htmlFor='header_name'>
                              Header Name *
                            </Label>
                            <Field
                              name='header_name'
                              placeholder='Header Name'
                              type='text'
                              id='header_name'
                              component={TextField}
                            />
                          </div>
                        </Col>
                        <Col md={6}>
                          <div className='mb-3'>
                            <Label className='form-label me-2' htmlFor='logo_1'>
                              Logo 1
                            </Label>
                            <Field
                              name='logo1'
                              inputName='logFile1'
                              previewImg='logo_1'
                              type='file'
                              accept='image/png, image/gif, image/jpeg'
                              component={FileField}
                              className='form-control-file'
                              cropModal
                              useCrop
                              cropRectangle
                              aspect={1 / 1}
                            />

                            <small className='text-muted fs-14px'>
                              ( Note: Allowed format (png, gif, jpeg, jpg )
                              image size limit: 4MB)
                            </small>
                            {pedigreeHeaderForm?.logo_1 ? (
                              <Col>
                                <i
                                  className='bx bx-trash text-danger fs-4 mt-2'
                                  id='deletetooltip-1'
                                  role='button'
                                  title='Delete'
                                  onClick={() =>
                                    onLogoDelete({
                                      logo_type: 'logo_1',
                                      id: id,
                                    })
                                  }
                                />
                              </Col>
                            ) : null}
                          </div>
                        </Col>
                        <Col md={6}>
                          <div className='mb-3'>
                            <Label className='form-label me-2' htmlFor='logo_2'>
                              Logo 2
                            </Label>
                            <Field
                              name='logo2'
                              inputName='logFile2'
                              previewImg='logo_2'
                              type='file'
                              accept='image/png, image/gif, image/jpeg'
                              component={FileField}
                              className='form-control-file'
                              cropModal
                              useCrop
                              cropRectangle
                              aspect={1 / 1}
                            />
                            <small className='text-muted fs-14px'>
                              ( Note: Allowed format (png, gif, jpeg, jpg )
                              image size limit: 4MB)
                            </small>
                            {pedigreeHeaderForm?.logo_2 ? (
                              <Col>
                                <i
                                  className='bx bx-trash text-danger fs-4 mt-2'
                                  id='deletetooltip-1'
                                  role='button'
                                  title='Delete'
                                  onClick={() =>
                                    onLogoDelete({
                                      logo_type: 'logo_2',
                                      id: id,
                                    })
                                  }
                                />
                              </Col>
                            ) : null}
                          </div>
                        </Col>

                        <Col md={6}>
                          <div className='mb-3'>
                            <Label className='form-label' htmlFor='first_name'>
                              First Name *
                            </Label>
                            <Field
                              name='first_name'
                              placeholder='First Name'
                              type='text'
                              id='first_name'
                              component={TextField}
                            />
                          </div>
                        </Col>
                        <Col md={6}>
                          <div className='mb-3'>
                            <Label className='form-label' htmlFor='last_name'>
                              Last Name *
                            </Label>
                            <Field
                              name='last_name'
                              placeholder='Last Name'
                              type='text'
                              id='last_name'
                              component={TextField}
                            />
                          </div>
                        </Col>
                        <Col md={12}>
                          <div className='mb-3'>
                            <Label className='form-label' htmlFor='address_1'>
                              Address 1 *
                            </Label>
                            <Field
                              name='address_1'
                              placeholder='Address 1'
                              type='text'
                              id='address_1'
                              component={TextField}
                            />
                          </div>
                        </Col>
                        <Col md={12}>
                          <div className='mb-3'>
                            <Label className='form-label' htmlFor='address_2'>
                              Address 2
                            </Label>
                            <Field
                              name='address_2'
                              placeholder='Address 2'
                              type='text'
                              id='address_2'
                              component={TextField}
                            />
                          </div>
                        </Col>
                        <Col md={4}>
                          <div className='mb-3'>
                            <Label className='form-label' htmlFor='country_id'>
                              Country *
                            </Label>
                            <Field
                              name='country_id'
                              placeholder='Country'
                              type='select'
                              id='country_id'
                              resetvalue={['state_id', 'city', 'zipcode']}
                              option={[
                                {
                                  label: 'Select Country',
                                  value: '',
                                },
                              ].concat(CountryList)}
                              onlyShowOption
                              component={SelectField}
                            />
                          </div>
                        </Col>
                        <Col md={4}>
                          <div className='mb-3'>
                            <Label className='form-label' htmlFor='state_id'>
                              State *
                            </Label>
                            <Field
                              name='state_id'
                              placeholder='Country'
                              type='select'
                              id='state_id'
                              onlyShowOption
                              resetvalue={['city', 'zipcode']}
                              option={[
                                {
                                  label: 'Select State',
                                  value: '',
                                },
                              ].concat(StateList)}
                              component={SelectField}
                            />
                          </div>
                        </Col>
                        <GetStateComponent />

                        <Col md={4}>
                          <div className='mb-3'>
                            <Label className='form-label' htmlFor='city'>
                              City *
                            </Label>
                            <Field
                              name='city'
                              placeholder='City'
                              resetvalue={['zipcode']}
                              type='text'
                              id='city'
                              component={TextField}
                            />
                          </div>
                        </Col>
                        <Col md={4}>
                          <div className='mb-3'>
                            <Label className='form-label' htmlFor='zipcode'>
                              Zipcode *
                            </Label>
                            <Field
                              name='zipcode'
                              placeholder='Zipcode '
                              type='text'
                              id='zipcode'
                              component={TextField}
                            />
                          </div>
                        </Col>
                        <Col md={4}>
                          <div className='mb-3'>
                            <Label className='form-label' htmlFor='phone'>
                              Phone
                            </Label>
                            <Field
                              name='phone'
                              placeholder='Phone'
                              type='mask'
                              id='phone'
                              component={TextField}
                            />
                          </div>
                        </Col>
                        <Col md={12}>
                          <div className='mb-3'>
                            <Label className='form-label' htmlFor='email'>
                              Email
                            </Label>
                            <Field
                              name='email'
                              placeholder='Email'
                              type='email'
                              id='email'
                              component={TextField}
                            />
                          </div>
                        </Col>
                        <Col md={6}>
                          <div className='mb-3'>
                            <Label className='form-label' htmlFor='signature'>
                              Signature
                            </Label>
                            <Field
                              name='signature'
                              placeholder='Signature'
                              type='text'
                              id='signature'
                              component={TextField}
                            />
                          </div>
                        </Col>
                        <Col md={6}>
                          <div className='mb-3'>
                            <Label className='form-label' htmlFor='font_type'>
                              Select Font
                            </Label>
                            <Field
                              name='font_type'
                              id='font_type'
                              type='select'
                              option={[
                                {
                                  label: 'Arial',
                                  value: 'arial',
                                },
                                {
                                  label: 'Roboto',
                                  value: 'roboto',
                                },
                                {
                                  label: 'Open Sans',
                                  value: 'open-sans',
                                },
                              ]}
                              component={SelectField}
                            />
                          </div>
                        </Col>
                        <Col md={12} className='text-center mt-5 mb-5'>
                          <Button type='submit' disabled={status} title='Submit'>
                            SUBMIT
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                  )
                }}
              </Formik>
            </Col>
          ) : (
            <Col md={12} className='text-center'>
              <Spinner />
            </Col>
          )}
        </CardBody>
      </Row>
    </Card>
  )
}

HerdHeaderForm.propTypes = {}

export default HerdHeaderForm

const GetStateComponent = () => {
  const dispatch = useDispatch()
  const { values } = useFormikContext()

  useEffect(() => {
    if (values?.country_id) {
      const params = {
        country_id: values?.country_id,
        _order: 'asc',
        _sort: 'state_code',
      }
      dispatch(allStateList(params))
    } else {
      dispatch(resetState())
    }
  }, [values?.country_id])

  return null
}
