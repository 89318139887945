import React from 'react';
import { Row, Col } from 'reactstrap';

export const TopBox = () => {
  return (
    <React.Fragment>
      <Row>
        <div className="col-md-12 mb-7">
          <div className="card shadow-only-hover1">
            <div className="card-body event-card-body">
              <div className="col-lg-12 col-xl-12 event-box">
                <Row>
                  <Col xs={12} className="box-head text-left mb-3">
                    {' '}
                    <i className="fa fa-circle icon-dot"></i> For fast entry in
                    events, update entries here before clicking to participate
                    in an event.{' '}
                  </Col>
                  <Col xs={12} className="box-head text-left mb-3">
                    <i className="fa fa-circle icon-dot"></i> Only exhibitor &
                    class can be changed within the event participation screen.{' '}
                  </Col>
                  <Col xs={12} className="box-head text-left">
                    <i className="fa fa-circle icon-dot"></i> Only 25 animals
                    can be kept in EASY ENTRY, additional manual entries can be
                    made within the event participation screen.
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </div>
      </Row>
    </React.Fragment>
  );
};
