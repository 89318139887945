import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
// Redux
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

// actions
import { loginUser, apiError, socialLogin } from '../../../store/actions';

import { Loginform } from './Components/loginform';

const Login = (props) => {
  const history = useHistory();
  // handleValidSubmit
  const handleValidSubmit = (event, values) => {
    console.log(values);
    props.loginUser(values, props.history);
  };

  const signIn = (res, type) => {
    const { socialLogin } = props;
    if (type === 'google' && res) {
      const postData = {
        name: res.profileObj.name,
        email: res.profileObj.email,
        token: res.tokenObj.access_token,
        idToken: res.tokenId,
      };
      socialLogin(postData, props.history, type);
    } else if (type === 'facebook' && res) {
      const postData = {
        name: res.name,
        email: res.email,
        token: res.accessToken,
        idToken: res.tokenId,
      };
      socialLogin(postData, props.history, type);
    }
  };

  useEffect(() => {
    document.body.className = 'authentication-bg';
    return function cleanup() {
      document.body.className = '';
    };
  });
  useEffect(() => {
    if (localStorage.getItem('accessToken')) {
      history.push('/auth-events');
    }
  }, []);

  return (
    <React.Fragment>
      <Loginform />
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { error } = state.Login;
  return { error };
};

export default withRouter(
  connect(mapStateToProps, { loginUser, apiError, socialLogin })(Login)
);

Login.propTypes = {
  error: PropTypes.any,
  history: PropTypes.object,
  loginUser: PropTypes.func,
  socialLogin: PropTypes.func,
};
