import { put, takeEvery } from 'redux-saga/effects'

import { ALL_ANIMAL_LISTING_SUCCESS, ALL_ANIMAL_LISTING } from './actionType'

import { toast } from 'react-toastify'
import { get } from '../../helpers/api_helper'
import _ from 'lodash'

function* animalListing({ payload }) {
  try {
    let res = []
    if (payload?.breed_category_id) {
      res = yield get(
        `/api/v1/breed-setup/class/list?breed_category_id=${
          payload?.breed_category_id || ''
        }`
      )
      let finalData = res?.data || []
      if (Array.isArray(finalData)) {
        // finalData = _.orderBy(
        //   finalData,
        //   [(user) => user.breed_name.toLowerCase()],
        //   ['asc']
        // )
        finalData = _.orderBy(
          _.filter(finalData, (p) => p.is_published === 1),
          [(user) => user.breed_name.toLowerCase()],
          ['asc']
        )
      }
      res = finalData
    }

    yield put({
      type: ALL_ANIMAL_LISTING_SUCCESS,
      payload: res,
    })
  } catch (err) {
    if (err?.response?.data) {
      toast.error(err?.response?.data)
    }
  }
}
function* AnimalListingSaga() {
  yield takeEvery(ALL_ANIMAL_LISTING, animalListing)
}

export default AnimalListingSaga
