import {
  GET_ALL_ADITIONAL_PRODUCT,
  SET_ADITIONAL_PRODUCT_LOADING,
  GET_ALL_ADDITIONAL_PRODUCT_SUCCESS,
  ADITIONAL_PRODUCT_INCREMENT,
  ADITIONAL_PRODUCT_DECREMENT,
  SAVE_ADITIONAL_PRODUCT
} from './actionTypes';

export const getAllAdditionalProduct = (event_id) => {
  return {
    type: GET_ALL_ADITIONAL_PRODUCT,
    payload: event_id,
  };
};

export const getAdditionalProducts = (products) => ({
  type: GET_ALL_ADDITIONAL_PRODUCT_SUCCESS,
  payload: products,
});

export const setLoadingAdditionalProduct = () => {
  return {
    type: SET_ADITIONAL_PRODUCT_LOADING,
  };
};

export const aditionalIncrement = (data) => {

  return {
    type: ADITIONAL_PRODUCT_INCREMENT,
    payload:{
      product_id:data.product_id,
      unit:data.unit,
    },
  };
};

export const aditionalDecrement = (data) => {
  return {
    type: ADITIONAL_PRODUCT_DECREMENT,
    payload:{
      product_id:data.product_id,
      unit:data.unit,
    },
  };
};


export const saveAdditionalProduct = (product, history) => {
  return{
  type: SAVE_ADITIONAL_PRODUCT,
  payload: {product, history},
}}