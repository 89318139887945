export const REQUIRED = 'field is required'
export const INVALID = 'is invalid field'
export const INVALID_DATA = 'Invalid data'
export const STRONG_PASSWORD =
  'Minimum 8 and maximum 15 characters, at least one uppercase letter, one lowercase letter, one number and one special character'
export const ONLY_CHARACTER = 'field allow only characters'
export const ONLY_CHARACTER_AND_HYPHEN =
  'field allow only characters and hyphen'
export const MAX_CHAR = 'must be of minimum 1 and maximum 50 character length'
export const MUST_BE_10_DIGITS = ' must be 10 digits'
export const URL_NOT_ALLOWED = 'field url not allowed'
export const MAX_CHAR_FUN = (number) =>
  `must be of maximum ${number} character length`
export const POSITIVE_NUMBER_ALLOWED = 'Only positive allowed'
export const NUMBER_INVALID = 'Please enter number value only'
export const MAX_NUMBER = (number) => `must be less than or equal to ${number}`
export const MIN_NUMBER = (number) =>
  `must be greater than or equal to ${number}`
export const GREATER_THAN = (field) => `must be greater than ${field} field`
export const LESS_THAN = (field) => `must be less than ${field} field`
export const ONLY_NUMERIC_ALLOW = 'Numeric values only allowed (Without decimal points)'
export const LESS_THAN_SHORT_MESSAGE_CHAR = (n) => `This field should not exceed ${n} characters.`
export const LESS_THAN_SHORT_MESSAGE_NUM = (n) => `This field should not exceed ${n}.`