import {
  SET_SHOW_ALERT,
  SET_MESSAGE,
  SET_MESSAGE_TITLE,
  SET_HANDLE_CLICK,
  SET_SHOW_BUTTON,
} from './actionTypes'

export const setShowAlert = (value) => ({
  type: SET_SHOW_ALERT,
  payload: value,
})

export const setMsg = (value) => ({
  type: SET_MESSAGE,
  payload: value,
})

export const setMessageTitle = (value) => ({
  type: SET_MESSAGE_TITLE,
  payload: value,
})

export const setHandleClick = (value) => ({
  type: SET_HANDLE_CLICK,
  payload: value,
})

export const setShowBtn = (value) => ({
  type: SET_SHOW_BUTTON,
  payload: value,
})
