import React, { useState, useEffect } from 'react'

// import { Elements } from '@stripe/react-stripe-js';
import { Col, Container, Row } from 'reactstrap'

import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'
import CheckoutForm from './Component/checkoutForm'
import { post } from '../../helpers/api_helper'
import { useLocation } from 'react-router-dom'

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE)

const Checkout = () => {
  const location = useLocation()
  const query = new URLSearchParams(location.search)

  const payment_intent = query.get('payment_intent')

  const [clientSecret, setClientSecret] = useState('')

  // const [loading, setLoading] = useState(false)

  useEffect(() => {
    post(`/api/v1/shows/user-sponsor/create-payment-intent`).then((res) => {
      setClientSecret(res.client_secret)
    })
  }, [payment_intent])

  const appearance = {
    theme: 'stripe',
  }
  const options = {
    clientSecret,
    appearance,
    hidePostalCode: true,
    country: false,
  }

  return (
    <React.Fragment>
      <div className="page-content bg-gray-101 min-h-85">
        <section id="about" class="section1 bg-gray-101 pages event-list">
          <Container fluid={true} className="auto-fit">
            <Row>
              <Col lg={12} className="text-center">
                <div className="page-title-box justify-content-between">
                  <h2 className="top-title mb-4">
                    <span>PROCESS PAYMENT</span>
                  </h2>
                </div>
              </Col>
            </Row>
            {clientSecret && (
              <Elements options={options} stripe={stripePromise}>
                <CheckoutForm isDisabled={clientSecret} />
              </Elements>
            )}
          </Container>
        </section>
      </div>
    </React.Fragment>
  )
}

export default Checkout
