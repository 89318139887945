import React from 'react';
import { useHistory } from 'react-router-dom';

const UserLogout = () => {
  const history = useHistory();

  window.localStorage.clear();
  history.push('/events');

  return <></>;
};
export default UserLogout;
