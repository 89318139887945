import {
  ADD_MORE_MEDIA,
  ADD_MY_ANIMALS,
  ALL_MEDIA_LIST,
  DELETE_ANIMAL,
  DELETE_MEDIA,
  LISTING_MY_ANIMALS,
  SAVE_MEDIA,
  UPDATE_MEDIA,
  UPDATE_MY_ANIMALS,
} from './actionType'

//
export const addMoreMedia = (data) => ({
  type: ADD_MORE_MEDIA,
  payload: data,
})

export const deleteMedia = (data) => ({
  type: DELETE_MEDIA,
  payload: data,
})

export const addAnimal = (data) => {
  return {
    type: ADD_MY_ANIMALS,
    payload: data,
  }
}
export const updateAnimal = (data) => {
  return {
    type: UPDATE_MY_ANIMALS,
    payload: data,
  }
}
export const listingAnimal = (data) => {
  return {
    type: LISTING_MY_ANIMALS,
    payload: data,
  }
}

export const AnimalDelete = (data) => {
  return {
    type: DELETE_ANIMAL,
    payload: data,
  }
}

export const saveAnimaMedia = (data) => {
  return {
    type: SAVE_MEDIA,
    payload: data,
  }
}


export const updateAnimaMedia = (data) => {
  return {
    type: UPDATE_MEDIA,
    payload: data,
  }
}

export const animalMediaListing = (data) => {
  return {
    type: ALL_MEDIA_LIST,
    payload: data,
  }
}
