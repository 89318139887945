import { put, takeEvery } from 'redux-saga/effects'

import {
  GET_EASY_FIDES_PROFILE,
  GET_EASY_FIDES_PROFILE_SUCCESS,
} from './actionType'
import { get } from '../../../helpers/api_helper'
import { toast } from 'react-toastify'

function* EasyfidesProfile({ payload }) {
  try {
    const res = yield get(`/api/v1/profile/profile_details`)
    yield put({
      type: GET_EASY_FIDES_PROFILE_SUCCESS,
      payload: res?.data,
    })
  } catch (err) {
    if (err?.response?.data) {
      toast.error(err?.response?.data)
    }
  }
}
function* EasyfidesProfileSaga() {
  yield takeEvery(GET_EASY_FIDES_PROFILE, EasyfidesProfile)
}

export default EasyfidesProfileSaga
