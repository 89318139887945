import { put, takeEvery } from 'redux-saga/effects'

import {
  GET_ALL_PREMIER_REPORT_EVENT_DETAIL,
  GET_ALL_PREMIER_REPORT_EVENT_LIST,
  GET_ALL_PREMIER_REPORT_EVENT_DETAIL_SUCCESS,
  GET_ALL_PREMIER_REPORT_EVENT_LIST_SUCCESS,
  GET_ALL_PREMIER_REPORT_SHOW_LIST,
  GET_ALL_PREMIER_REPORT_SHOW_LIST_SUCCESS,
  GET_ALL_PREMIER_REPORT_BREED_LIST,
  GET_ALL_PREMIER_REPORT_BREED_LIST_SUCCESS,
  GET_ALL_PREMIER_REPORT_SHOW_FINALIZED,
  GET_ALL_PREMIER_REPORT_SHOW_FINALIZED_STATUS_SUCCESS,
  GET_ALL_PREMIER_REPORT_SHOW_FINALIZED_STATUS,
  GET_ALL_PREMIER_REPORT_DETAIL_BY_BREED,
  GET_ALL_PREMIER_REPORT_DETAIL_BY_BREED_SUCCESS,
} from './actionType'

import { toast } from 'react-toastify'
import { get, post } from '../../helpers/api_helper'
import _ from 'lodash'

function* getAllPremierEventListSagas({ payload }) {
  const { params, body } = payload

  try {
    const res = yield post('/api/v1/events/premier-event-list/all', body, {
      params,
    })

    let TotalPage = Math.ceil(res.totalRecords / params._limit) || 1

    const data = {
      data: res?.data || [],
      totalPages: TotalPage,
      page: params._page,
      totalRecords: res.totalRecords,
    }
    yield put({
      type: GET_ALL_PREMIER_REPORT_EVENT_LIST_SUCCESS,
      payload: data,
    })
  } catch (err) {
    if (err?.response?.data) {
      toast.error(err?.response?.data)
    }
  }
}
function* getAllPremierEventDetailSagas({ payload }) {
  const { eventId } = payload

  try {
    const res = yield get(`/api/v1/events/secretary/one/${eventId}`)

    yield put({
      type: GET_ALL_PREMIER_REPORT_EVENT_DETAIL_SUCCESS,
      payload: res.data || {},
    })
  } catch (err) {
    if (err?.response?.data) {
      toast.error(err?.response?.data)
    }
  }
}
function* getAllPremierShowListSagas({ payload }) {
  const { eventId } = payload

  try {
    const res = yield post(`/api/v1/events/premier-event-list/show/${eventId}`)

    yield put({
      type: GET_ALL_PREMIER_REPORT_SHOW_LIST_SUCCESS,
      payload: res.data || {},
    })
  } catch (err) {
    if (err?.response?.data) {
      toast.error(err?.response?.data)
    }
  }
}

function* getAllPremierReportBreedListSagas({ payload }) {
  const { params } = payload

  try {
    const res = yield get(
      `/api/v1/ctrl-sheet/premier-exh/${params?.show_id || ''}/breed-fur-list`,
      { params: _.omit(params, ['show_id']) }
    )

    yield put({
      type: GET_ALL_PREMIER_REPORT_BREED_LIST_SUCCESS,
      payload: _.filter(res.data, (o) => o.record_type === 'breed') || [],
    })
  } catch (err) {
    if (err?.response?.data) {
      toast.error(err?.response?.data)
    }
  }
}
function* getAllPremierReportShowFinalizedStatusSagas({ payload }) {
  const { params } = payload

  try {
    const res = yield get(`/api/v1/reports/premier-exh/finalized-report`, {
      params,
    })

    yield put({
      type: GET_ALL_PREMIER_REPORT_SHOW_FINALIZED_STATUS_SUCCESS,
      payload: _.first(res.data) || {},
    })
  } catch (err) {
    if (err?.response?.data) {
      toast.error(err?.response?.data)
    }
  }
}

function* getAllPremierReportShowFinalizedSagas({ payload }) {
  const { params } = payload

  try {
    const res = yield get(`/api/v1/reports/premier-exh/finalized-report`, {
      params,
    })
    yield put({
      type: GET_ALL_PREMIER_REPORT_SHOW_FINALIZED_STATUS_SUCCESS,
      payload: _.first(res.data) || {},
    })
  } catch (err) {
    if (err?.response?.data) {
      toast.error(err?.response?.data)
    }
  }
}
function* getAllPremierReportDetailByBreedSagas({ payload }) {
  const { params } = payload

  try {
    const res = yield get(`/api/v1/reports/premier-exh/details-by-breed-list`, {
      params,
    })
    yield put({
      type: GET_ALL_PREMIER_REPORT_DETAIL_BY_BREED_SUCCESS,
      payload: res.data || {},
    })
  } catch (err) {
    if (err?.response?.data) {
      toast.error(err?.response?.data)
    }
  }
}
function* StatesSagas() {
  yield takeEvery(
    GET_ALL_PREMIER_REPORT_EVENT_LIST,
    getAllPremierEventListSagas
  )
  yield takeEvery(
    GET_ALL_PREMIER_REPORT_EVENT_DETAIL,
    getAllPremierEventDetailSagas
  )
  yield takeEvery(
    GET_ALL_PREMIER_REPORT_BREED_LIST,
    getAllPremierReportBreedListSagas
  )
  yield takeEvery(
    GET_ALL_PREMIER_REPORT_SHOW_FINALIZED_STATUS,
    getAllPremierReportShowFinalizedStatusSagas
  )
  yield takeEvery(
    GET_ALL_PREMIER_REPORT_SHOW_FINALIZED,
    getAllPremierReportShowFinalizedSagas
  )
  yield takeEvery(
    GET_ALL_PREMIER_REPORT_DETAIL_BY_BREED,
    getAllPremierReportDetailByBreedSagas
  )
  yield takeEvery(GET_ALL_PREMIER_REPORT_SHOW_LIST, getAllPremierShowListSagas)
}

export default StatesSagas
