import 'flatpickr/dist/themes/material_blue.css'
import React, { useEffect } from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import { Col, Row } from 'reactstrap'
import { Link } from 'react-router-dom'
import SponsorSanctionEventBox from './Component/SponsorSanction'
import { useDispatch, useSelector } from 'react-redux'
import { getAllSponsorList } from '../../store/actions'
import ReactPaginate from 'react-paginate'
import ShoppingCartSanction from './Component/shoppingCart'
// import { getAllSponsorList } from '../../store/sponsorEvent/action'

const LIMIT = process.env.REACT_APP_LIMIT || 10
const SponsorSanction = (props) => {
  const sponsorSanction = useSelector((state) => state.SponsorSanction)

  const { allSponsorList, page, totalPages, totalRecords, loading } =
    sponsorSanction

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getAllSponsorList(1, LIMIT))
  }, [])
  const paginationClick = (p) => {
    let nextPage = p.selected + 1

    dispatch(getAllSponsorList(nextPage, LIMIT))
  }

  return (
    <React.Fragment>
      <section className="section bg-gray-101 pages event-list mt-12">
        <div className="auto-fit container-fluid">
          <div className="row mb-6">
            <div className="col-md-10 col-lg-10 col-xs-10 text-center">
              <h2 className="top-title">
                <span>SPONSOR SANCTION</span>
              </h2>
            </div>

            <div className="col-md-2 col-lg-2 col-xs-2 text-center">
              <ShoppingCartSanction />
            </div>
          </div>
          {allSponsorList.length ? (
            allSponsorList.map((sponsor, i) => {
              return (
                <React.Fragment key={i}>
                  <SponsorSanctionEventBox sponsor={sponsor} />
                </React.Fragment>
              )
            })
          ) : loading ? (
            <div className="text-center">
              <p style={{ fontSize: 24 }}>Loading...</p>
            </div>
          ) : null}
          {totalRecords > LIMIT && (
            <Col lg={12}>
              <div data-test="datatable-pagination sponsor-page">
                <div className="dataTables_paginate">
                  <ReactPaginate
                    breakLabel="..."
                    breakClassName="page-item"
                    breakLinkClassName="page-link"
                    nextLabel="Next"
                    pageCount={totalPages}
                    pageRangeDisplayed={4}
                    marginPagesDisplayed={1}
                    previousLabel="Previous"
                    renderOnZeroPageCount={null}
                    containerClassName="pagination mt-6 mb-2 justify-content-center"
                    pageLinkClassName="page-link page-link"
                    pageClassName="page-item"
                    activeClassName="active"
                    nextLinkClassName="page-link"
                    previousClassName="page-item"
                    previousLinkClassName="page-link"
                    nextClassName="page-item"
                    onPageChange={paginationClick}
                    forcePage={page - 1}
                  />
                </div>
              </div>
            </Col>
          )}
        </div>
      </section>
    </React.Fragment>
  )
}
export default SponsorSanction
