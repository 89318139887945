import React, { useState, useEffect } from 'react'

// import { Elements } from '@stripe/react-stripe-js';
import { Col, Container, Input, Modal, Row } from 'reactstrap'

import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'
import CheckoutForm from './CheckoutForm'
import { get, post } from '../../helpers/api_helper'
import { useHistory, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { shopCartPrice } from '../../helpers/utils'
// Image
import checkImg from '../../assets/images/users/check.png'
import { UrlEncodeHook } from '../../hooks'

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE)

const Checkout = () => {
  const { urlDecode } = UrlEncodeHook()

  let user = JSON.parse(localStorage.getItem('authUser'))
  const history = useHistory()
  const location = useLocation()
  const query = new URLSearchParams(location.search)
  let event_id = query.get('event_id')
  event_id = urlDecode(event_id)
  const payment_intent = query.get('payment_intent')
  const event_type = query.get('event_type')
  const [is_offline, set_offline] = useState(false)
  const [modal_center, setmodal_center] = useState(false)
  const [username, setUsername] = useState(null)

  const shoppingCart = useSelector((state) => state.shopping_cart)
  const { shopping_product, eventList } = shoppingCart
  const { final_price } = eventList

  let total = 0
  if (shopping_product && shopping_product.length > 0) {
    total = shopCartPrice(shopping_product)
  }

  const [clientSecret, setClientSecret] = useState('')
  const [loading, setLoading] = useState(false)
  function removeBodyCss() {
    document.body.classList.add('no_padding')
  }
  total = total + Number(final_price) || 0

  useEffect(() => {
    get(`/api/v1/profile/profile_details`).then((data) => {
      setUsername(data?.data?.last_name + ' ' + data?.data?.first_name)
    })
    setLoading(true)
    if (payment_intent) {
      post(`api/v1/payment/get-total?in_cart=1&event_id=${event_id}`).then(
        (res) => {
          if (total === res.total_final_price) {
            if (total > 0) {
              let data = {
                paymentIntent: {
                  id: payment_intent,
                },
              }
              post(
                `api/v1/payment/payment-success?in_cart=1&event_id=${event_id}&event_type=${event_type}`,
                data
              ).then((res) => {
                setLoading(false)
                setmodal_center(true)
                set_offline(true)
                setTimeout(() => {
                  history.push('/auth-events')
                }, 6000)
              })
            } else {
              setLoading(false)

              history.push('/auth-events')
            }
          }
        }
      )
    } else {
      post(`api/v1/payment/get-total?in_cart=1&event_id=${event_id}`).then(
        (res) => {
          if (total !== res.total_final_price) {
            // return <Redirect to='/shopping_cart' />
            history.push({
              pathname: '/shopping_cart',
              state: {
                hasAdditionalProduct: true,
                hasShoppingCart: true,
                showAdditional: true,
              },
            })
            setLoading(false)
          } else {
            post(
              `api/v1/payment/create-payment-intent?in_cart=1&event_id=${event_id}`
            ).then((res) => {
              setLoading(false)
              setClientSecret(res.clientSecret)
            })
          }
        }
      )
    }
  }, [payment_intent, total])

  const appearance = {
    theme: 'stripe',
  }
  const options = {
    clientSecret,
    appearance,
    hidePostalCode: true,
    country: false,
  }

  return (
    <React.Fragment>
      <div className='page-content bg-gray-101 min-h-85'>
        <section id='about' class='section1 bg-gray-101 pages event-list'>
          <Container fluid={true} className='auto-fit'>
            <Row>
              <Col lg={12} className='text-center'>
                <div className='page-title-box justify-content-between'>
                  <h2 className='top-title mb-4'>
                    <span>PROCESS PAYMENT</span>
                  </h2>
                </div>
              </Col>
              {loading && (
                <Col lg={12} className='text-center'>
                  <div className=''>
                    <h4 className='top-title mb-4'>
                      <span>Please wait....</span>
                    </h4>
                  </div>
                </Col>
              )}
            </Row>
            {clientSecret && (
              <Elements options={options} stripe={stripePromise}>
                <CheckoutForm
                  user_name={username || ''}
                  isDisabled={clientSecret}
                />
              </Elements>
            )}
          </Container>

          {is_offline ? (
            <Modal
              className='sucess_modal'
              isOpen={modal_center}
              toggle={() => {
                // contact_center_modal();
              }}
              centered={true}
            >
              <div className='modal-header'>
                <h4>&nbsp;</h4>
                <button
                  type='button'
                  onClick={() => {
                    setmodal_center(false)
                  }}
                  className='close close-btn'
                  data-dismiss='modal'
                  aria-label='Close'
                >
                  <span aria-hidden='true'>&times;</span>
                </button>
              </div>
              <div className='modal-body'>
                <Row className='mb-2 text-center pay-modal'>
                  <Col lg={12}>
                    <h4>PAYMENT SUCCESSFUL</h4>
                    <p className='m-0 '>
                      <img src={checkImg} alt='sucess' className='p-r-3' />
                    </p>
                    <p className='m-0 '>Dear, {username || ''}</p>
                    <p className='m-0 '>
                      Thank you for participating in the event.
                    </p>
                    <p>The check-in sheet has been sent to your e-mail.</p>
                  </Col>
                </Row>
              </div>
            </Modal>
          ) : (
            <Modal
              className='sucess_modal'
              isOpen={modal_center}
              toggle={() => {
                // contact_center_modal();
              }}
              centered={true}
            >
              <div className='modal-header'>
                <h4>&nbsp;</h4>
                <button
                  type='button'
                  onClick={() => {
                    setmodal_center(false)
                  }}
                  className='close close-btn'
                  data-dismiss='modal'
                  aria-label='Close'
                >
                  <span aria-hidden='true'>&times;</span>
                </button>
              </div>
              <div className='modal-body'>
                <Row className='mb-2 text-center pay-modal'>
                  <Col lg={12}>
                    <h4>PAYMENT SUCCESSFUL</h4>
                    <p className='m-0 '>
                      <img src={checkImg} alt='sucess' className='p-r-3' />
                    </p>
                    <p className='m-0 '>Dear, {username}</p>
                    <p className='m-0 '>
                      Thank you for participating in the event.
                    </p>
                    <p>The check-in sheet has been sent to your e-mail.</p>
                  </Col>
                </Row>
              </div>
            </Modal>
          )}
        </section>
      </div>
    </React.Fragment>
  )
}

export default Checkout
