import $ from 'jquery'
import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Card, CardBody, Col, Container, Row } from 'reactstrap'
import { get, POST, post } from '../../../helpers/api_helper'
import { getDate, getTime } from '../../../helpers/date_helper'
import ParticipateEasyEntryListAdd from './AddList'
import { v4 as uuidv4 } from 'uuid'
import Swal from 'sweetalert2'
import { UrlEncodeHook } from '../../../hooks'

const ParticipateManualEntry = (props) => {
  const history = useHistory()
  const { urlDecode, urlEncode } = UrlEncodeHook()

  const [isAllSelected, setisAllSelected] = useState(false)
  const [event, setEvent] = useState({})

  const [manualList, setManualList] = useState([
    {
      id: uuidv4(),
      in_cart: '',
      event_id: '',
      show_id: [],
      show_type: '',
      user_id: '',
      user_name: '',
      ear_no: '',
      breed_category_id: '',
      breed_id: '',
      class_id: '',
      variety_id: '',
      group_id: '',
      has_fur: '',
      fur_data_validation: false,
      fur_data: [],
      fur_type_id: [],
      fur_id: [],
      displayFur: true,
      fur_default_data: [],
      fur_color: '',
      group_seq: '',
      variety_seq: '',
      isSelected: 0,
      isSame: false,
      sameIndex: null,
    },
  ])
  const [listValidation, setListValidation] = useState([
    {
      id: '',
      show_id: '',
      show_type: '',
      user_id: '',
      ear_no: '',
      breed_category_id: '',
      breed_id: '',
      class_id: '',
      variety_id: '',
      group_id: '',
      has_fur: '',
      fur_type_id: '',
      fur_id: '',
      group_seq: '',
      variety_seq: '',
      fur_color: '',
    },
  ])
  const [showData, setShowData] = useState([])
  const [showList, setShowList] = useState([])
  const [exhibitorList, setExhibitorList] = useState([])

  const location = useLocation()
  const query = new URLSearchParams(location.search)
  let event_id = query.get('event_id')
  event_id = urlDecode(event_id)
  const has_products = query.get('has_products')
  const [showToast, setShowToast] = useState(false)
  const [newCount, setCount] = useState(0)
  const [checkSameBreed, setCheckSameBreed] = useState(true)
  const dispatch = useDispatch()

  const { additionalInfo } = (location && location.state) || {}

  // get All
  useEffect(() => {
    const body = {
      event_id_list: [event_id],
    }
    POST(`/api/v1/events/multiple?published=1&active=1&deadlines=1`, body).then(
      (res) => {
        setEvent(res.data[0])
      }
    )
    const getShow = async () => {
      await getAll()
    }
    getShow()
  }, [])

  const getAll = async () => {
    let result
    const res = await get(`/api/v1/shows/all?event_id=${event_id}`)
    if (res) {
      setShowData(res.data)
      result = await res.data.map((data) => {
        return {
          show_id: data.show_id,
          show_type: data.show_type,
        }
      })
      let finalData = _.uniqBy(result, function (e) {
        return e.show_type
      })
      setShowList(finalData)
    }
  }
  useEffect(() => {
    get(`/api/v1/profile/exhibitor_list`).then((res) => {
      if (res) {
        let sortList = _.sortBy(res?.data || [], ['last_name'], ['asc'])

        setExhibitorList(sortList)
      }
    })
  }, [])

  const onAddFormRow = () => {
    setCheckSameBreed(true)
    setManualList([
      ...manualList,
      {
        id: uuidv4(),
        in_cart: '',
        event_id: '',
        show_id: [],
        show_type: '',
        user_id: '',
        user_name: '',
        ear_no: '',
        breed_category_id: '',
        breed_id: '',
        class_id: '',
        variety_id: '',
        group_id: '',
        has_fur: '',
        fur_data: [],
        fur_type_id: [],
        fur_data_validation: false,
        fur_id: [],
        group_seq: '',
        variety_seq: '',
        fur_color: '',
        isSelected: 0,
        isSame: false,
        displayFur: true,
        fur_default_data: [],
        sameIndex: null,
      },
    ])
    // setListValidation([
    //   ...listValidation,
    //   {
    //     id: '',
    //     show_id: '',
    //     show_type: '',
    //     user_id: '',
    //     ear_no: '',
    //     breed_category_id: '',
    //     breed_id: '',
    //     class_id: '',
    //     variety_id: '',
    //     group_id: '',
    //     has_fur: '',
    //     fur_type_id: '',
    //     fur_id: '',
    //     group_seq: '',
    //     variety_seq: '',
    //     fur_color: '',
    //   },
    // ])
  }

  const handleRemove = (index) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      width: '380px',
      showCancelButton: true,
      confirmButtonColor: '#062575',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        let list = [...manualList]
        list.splice(index, 1)

        setManualList(list)
      }
    })
  }

  let count = 0

  /**
   *
   * @param {index} index
   * @param {for html value} e
   * @param {react select} type
   * @param {react select element name} n
   */
  const handleChange = async (index, e, type, n, breedList) => {
    // console.log('first');
    let list = [...manualList]
    if (type) {
      let value = e.map((data) => data.value)

      list[index][n] = value
      if (n === 'show_type') {
        list[index]['show_id'] = []
        list[index]['ear_no'] = ''
        list[index]['breed_category_id'] = ''
        list[index]['breed_id'] = ''
        list[index]['group_seq'] = ''
        list[index]['user_id'] = ''
        list[index]['variety_seq'] = ''
        list[index]['variety_id'] = ''
        list[index]['class_id'] = ''
        list[index]['group_id'] = ''
        list[index]['has_fur'] = ''
        list[index]['fur_data'] = []
        list[index]['fur_type_id'] = []
        list[index]['fur_color'] = ''
        list[index]['isSelected'] = 0
      }
      if (n === 'show_id') {
        list[index]['breed_category_id'] = ''
        list[index]['breed_id'] = ''
        list[index]['group_seq'] = ''
        list[index]['variety_seq'] = ''
        list[index]['variety_id'] = ''
        list[index]['class_id'] = ''
        list[index]['group_id'] = ''
        list[index]['has_fur'] = ''
        list[index]['fur_data'] = []
        list[index]['fur_type_id'] = []
        list[index]['fur_color'] = ''
        list[index]['isSelected'] = 0
      }
      if (n === 'fur_type_id') {
        list[index]['fur_color'] = ''
        list[index]['isSelected'] = 0
      }
    } else {
      const { name, value } = e.target
      if (name === 'ear_no') {
        setShowToast(false)
        if (value.length < 50) {
          list[index]['isSelected'] = 0
          list[index]['ear_no'] = value.toUpperCase()
        }
        if (value.length === 49 && !showToast) {
          setShowToast(true)
          setCount(1)
        }
        if (showToast && newCount === 1) {
          toast.error('Max Ear. No Should be 50 Character')
        }
      }

      if (name === 'show_type') {
        list[index]['show_type'] = value
        list[index]['user_id'] = ''
        list[index]['ear_no'] = ''
        list[index]['show_id'] = []
        list[index]['breed_category_id'] = ''
        list[index]['breed_id'] = ''
        list[index]['group_seq'] = ''
        list[index]['variety_seq'] = ''
        list[index]['variety_id'] = ''
        list[index]['class_id'] = ''
        list[index]['group_id'] = ''
        list[index]['has_fur'] = ''
        list[index]['fur_data'] = []
        list[index]['fur_type_id'] = []
        list[index]['fur_color'] = ''
        list[index]['fur_data_validation'] = ''
        list[index]['isSelected'] = 0
      }

      if (name === 'user_id') {
        let findUser = exhibitorList.find((item) => item.id === Number(value))
        list[index]['user_id'] = Number(value) ? Number(value) : ''
        list[index]['isSelected'] = 0
        list[index]['user_name'] = findUser
          ? `${findUser.last_name} ${findUser.first_name} `
          : ''
      }
      if (name === 'breed_category_id') {
        list[index]['breed_category_id'] = Number(value)
        list[index]['variety_id'] = ''
        list[index]['class_id'] = ''
        list[index]['group_id'] = ''
        list[index]['breed_id'] = ''
        list[index]['has_fur'] = ''
        list[index]['isSelected'] = 0
      }
      if (name === 'breed_id') {
        let breed = breedList.find((s) => s.breed_id === Number(value))
        list[index]['breed_id'] = value ? Number(value) : ''
        list[index]['group_seq'] = breed?.group_seq
          ? Number(breed?.group_seq)
          : ''
        list[index]['variety_seq'] = breed?.variety_seq
          ? Number(breed?.variety_seq)
          : ''

        list[index]['variety_id'] = ''
        list[index]['class_id'] = ''
        list[index]['group_id'] = ''
        list[index]['fur_type_id'] = []
        list[index]['fur_id'] = []
        list[index]['fur_data'] = []
        list[index]['has_fur'] = ''
        list[index]['isSelected'] = 0
      }
      if (name === 'group_id') {
        list[index]['group_id'] = value ? Number(value) : ''
        list[index]['class_id'] = ''
        list[index]['variety_id'] = ''
        list[index]['fur_type_id'] = []
        list[index]['fur_id'] = []
        list[index]['fur_data'] = []
        list[index]['has_fur'] = ''
        list[index]['isSelected'] = 0
      }
      if (name === 'variety_id') {
        list[index]['variety_id'] = value ? Number(value) : ''
        list[index]['class_id'] = ''
        list[index]['fur_type_id'] = []
        list[index]['fur_id'] = []
        list[index]['fur_data'] = []
        list[index]['has_fur'] = ''
        list[index]['isSelected'] = 0
      }
      if (name === 'class_id') {
        list[index]['class_id'] = value ? Number(value) : ''
        list[index]['isSelected'] = 0
      }
      if (name === 'has_fur') {
        list[index]['has_fur'] = value ? Number(value) : ''
        list[index]['isSelected'] = 0

        //has_fur

        if (Number(value)) {
          if (
            list[index].fur_default_data.length === 1 &&
            list[index].fur_default_data[0].fur_list.length === 1
          ) {
            list[index]['fur_type_id'] = [
              list[index].fur_default_data[0].fur_type_id,
            ]
            list[index]['fur_color'] =
              list[index].fur_default_data[0].fur_list[0].fur_id
            list[index]['isSelected'] = 0
          }
        } else {
          list[index]['fur_data'] = []
          list[index]['fur_type_id'] = []
          list[index]['fur_color'] = ''
          list[index]['isSelected'] = 0
        }
      }
      if (name === 'fur_color') {
        list[index]['fur_color'] = value ? Number(value) : ''
        list[index]['isSelected'] = 0
      }
      if (name === 'isSelected') {
        // debugger
        if (!Boolean(Number(value))) {
          $('*').addClass('changeCursor')
          let validate = list[index]

          let errorObj = {
            id: validate?.id || '',
          }
          if (validate?.show_id.length === 0) {
            errorObj[`show_id`] = 'Please select show'
          }
          if (!validate.ear_no) {
            errorObj[`ear_no`] = 'Ear no. field is required'
          } else {
            let checkErNum = hasErNo(
              validate.ear_no,
              validate.user_id,
              validate.show_id,
              manualList.filter((s) => s.id !== validate.id)
            )
            if (checkErNum) {
              errorObj[`ear_no`] = 'Duplicate Ear no. found for same user.'
            }
          }

          if (!validate.user_id) {
            errorObj[`user_id`] = 'Please select exhibitor'
          }
          if (!validate.show_type) {
            errorObj.show_type = 'Please select show type'
          }

          if (!validate.breed_category_id) {
            errorObj['breed_category_id'] = 'Please select category'
          }

          if (!validate.breed_id) {
            errorObj['breed_id'] = 'Please select breed '
          }
          if (!validate.class_id) {
            errorObj[`class_id`] = 'Please select class '
          }

          if (validate.group_seq) {
            if (!validate.group_id) {
              errorObj[`group_id`] = 'Please select group'
            }
          }
          if (validate.variety_seq) {
            if (!validate.variety_id) {
              errorObj['variety_id'] = 'Please select Variety'
            }
          }

          if (validate.fur_data_validation) {
            if (!validate.has_fur && validate.has_fur !== 0) {
              // if (!validate[index].has_fur) {

              errorObj[`has_fur`] = 'Please select fur'
            }
            if (validate.has_fur && !validate.fur_type_id.length) {
              errorObj['fur_type_id'] = 'Please select fur type'
            }
            if (validate.has_fur && !validate.fur_color) {
              errorObj[`fur_color`] = 'Please select fur color'
            }
          }

          if (
            !_.size(
              _.filter(
                _.map([errorObj], (err) => _.omit(err, ['id'])),
                function (o) {
                  return _.size(o)
                }
              )
            )
          ) {
            const allData = _.flatMap([validate], (ele) => {
              return ele.show_id.map((show) => ({
                in_cart: 1,
                id: ele?.id,
                event_id: event_id,
                show_id: show,
                show_type: ele.show_type,
                user_id: ele.user_id,
                ear_no: ele.ear_no,
                breed_category_id: ele.breed_category_id,
                breed_id: ele.breed_id,
                class_id: ele.class_id,
                variety_id: ele.variety_seq ? ele.variety_id : '',
                group_id: ele.group_seq ? ele.group_id : '',
                has_fur: ele.has_fur,
                fur_data: ele.fur_type_id.map((s) => {
                  return {
                    fur_type_id: s,
                    fur_id: ele.fur_color,
                  }
                }),
                group_seq: ele.group_seq,
                variety_seq: ele.variety_seq,
              }))
            })

            // try {
            const urls = allData.map((obj) => {
              return {
                url: post(`/api/v1/participation/validate_participation`, obj),
                id: obj.id,
              }
            })
            let allSettled = await Promise.allSettled(_.map(urls, 'url'))

            let settledErrors = allSettled.map((obj, index) => {
              let selectedError = urls[index]

              if (obj?.status === 'rejected') {
                return {
                  id: selectedError?.id,
                  ear_no: 'Ear no. already exits',
                  err: true,
                }
              }

              return {
                id: selectedError?.id,
                err: false,
              }
            })

            let allHasError = _.filter(settledErrors, (o) => o.err)
            if (Array.isArray(allHasError) && allHasError.length) {
              $('*').removeClass('changeCursor')
              setListValidation(allHasError)
            } else {
              $('*').removeClass('changeCursor')
              list[index]['isSelected'] = 1
              setListValidation([])
            }
            // setManualList(AllSetCheckBox)
          } else {
            $('*').removeClass('changeCursor')
            setListValidation([errorObj])
          }
        } else {
          $('*').removeClass('changeCursor')
          setListValidation([])
          list[index]['isSelected'] = 0
        }
      }
    }

    setManualList(list)
    // setListValidation(validationList)
  }
  // console.log(listValidation);
  const handleSelectAll = async (e) => {
    if (e.target.value === 'false') {
      $('*').addClass('changeCursor')
      const error = manualList.map((list, index) => {
        let errorObj = {
          id: list?.id || '',
        }
        if (list?.show_id.length === 0) {
          errorObj[`show_id`] = 'Please select show'
          errorObj[`err`] = true
        }
        if (!list.ear_no) {
          errorObj[`ear_no`] = 'Ear no. field is required'
          errorObj[`err`] = true
        } else {
          let checkErNum = hasErNo(
            list.ear_no,
            list.user_id,
            list.show_id,
            manualList.filter((s) => s.id !== list.id)
          )
          if (checkErNum) {
            errorObj[`ear_no`] = 'Duplicate Ear no. found for same user.'
            errorObj[`err`] = true
          }
        }

        if (!list.user_id) {
          errorObj[`user_id`] = 'Please select exhibitor'
          errorObj[`err`] = true
        }
        if (!list.show_type) {
          errorObj.show_type = 'Please select show type'
          errorObj[`err`] = true
        }

        if (!list.breed_category_id) {
          errorObj['breed_category_id'] = 'Please select category'
          errorObj[`err`] = true
        }

        if (!list.breed_id) {
          errorObj['breed_id'] = 'Please select breed '
          errorObj[`err`] = true
        }
        if (!list.class_id) {
          errorObj[`class_id`] = 'Please select class '
          errorObj[`err`] = true
        }

        if (list.group_seq) {
          if (!list.group_id) {
            errorObj[`group_id`] = 'Please select group'
            errorObj[`err`] = true
          }
        }
        if (list.variety_seq) {
          if (!list.variety_id) {
            errorObj['variety_id'] = 'Please select Variety'
            errorObj[`err`] = true
          }
        }

        if (list.fur_data_validation) {
          if (!list.has_fur && list.has_fur !== 0) {
            errorObj[`has_fur`] = 'Please select fur'
            errorObj[`err`] = true
          }
          if (list.has_fur && !list.fur_type_id.length) {
            errorObj['fur_type_id'] = 'Please select fur type'
            errorObj[`err`] = true
          }
          if (list.has_fur && !list.fur_color) {
            errorObj[`fur_color`] = 'Please select fur color'
            errorObj[`err`] = true
          }
        }

        return errorObj
      })
     

      setListValidation(error)

      if (
        !_.size(
          _.filter(
            _.map(error, (err) => _.omit(err, ['id'])),
            function (o) {
              return _.size(o)
            }
          )
        )
      ) {
        const allData = _.flatMap(manualList, (ele) => {
          return ele.show_id.map((show) => ({
            in_cart: 1,
            id: ele?.id,
            event_id: event_id,
            show_id: show,
            show_type: ele.show_type,
            user_id: ele.user_id,
            ear_no: ele.ear_no,
            breed_category_id: ele.breed_category_id,
            breed_id: ele.breed_id,
            class_id: ele.class_id,
            variety_id: ele.variety_seq ? ele.variety_id : '',
            group_id: ele.group_seq ? ele.group_id : '',
            has_fur: ele.has_fur,
            fur_data: ele.fur_type_id.map((s) => {
              return {
                fur_type_id: s,
                fur_id: ele.fur_color,
              }
            }),
            group_seq: ele.group_seq,
            variety_seq: ele.variety_seq,
          }))
        })

        // try {
        const urls = allData.map((obj) => {
          return {
            url: post(`/api/v1/participation/validate_participation`, obj),
            id: obj.id,
          }
        })
        let allSettled = await Promise.allSettled(_.map(urls, 'url'))

        let settledErrors = allSettled.map((obj, index) => {
          let selectedError = urls[index]

          if (obj?.status === 'rejected') {
            return {
              id: selectedError?.id,
              ear_no: 'Ear no. already exits',
              err: true,
            }
          }

          return {
            id: selectedError?.id,
            err: false,
          }

          // let checkFind = allSettled.find(f=> f.)
        })
        const AllSetCheckBox = manualList.map((obj) => {
          const hasError = settledErrors.filter((err) => err?.id === obj.id)
          const singleErr = hasError.find((e) => e?.err)
          if (singleErr?.err) {
            return {
              ...obj,
              isSelected: 0,
            }
          }
          return {
            ...obj,
            isSelected: 1,
          }
        })
        setListValidation(_.filter(settledErrors, (o) => o.err))
        setManualList(AllSetCheckBox)
      } else {
        const AllSetCheckBox = manualList.map((obj) => {
          const hasError = error.filter((err) => err?.id === obj.id)
          const singleErr = hasError.find((e) => e?.err)
          if (singleErr?.err) {
            return {
              ...obj,
              isSelected: 0,
            }
          }
          return {
            ...obj,
            isSelected: 1,
          }
        })
        setManualList(AllSetCheckBox)
      }
      $('*').removeClass('changeCursor')
    } else {
      let data = manualList.map((s) => {
        if (s['isSelected']) {
          s['isSelected'] = 0
        }
        return s
      })
      setManualList(data)
    }
  }
  const sameBreed = () => {
    let list = [...manualList]

    let lastIndex = list[list.length - 1]

    list.push({
      in_cart: 1,
      id: uuidv4(),
      event_id: event_id,
      show_id: lastIndex.show_id,
      show_type: lastIndex.show_type,
      user_id: lastIndex.user_id,
      user_name: lastIndex.user_name,
      ear_no: '',
      breed_category_id: lastIndex.breed_category_id,
      breed_id: lastIndex.breed_id,
      class_id: lastIndex.class_id,
      variety_id: lastIndex.variety_seq ? lastIndex.variety_id : '',
      group_id: lastIndex.group_seq ? lastIndex.group_id : '',
      has_fur: '',
      fur_data: [],
      fur_type_id: [],
      fur_id: [],
      fur_color: '',
      group_seq: lastIndex.group_seq,
      variety_seq: lastIndex.variety_seq,
      fur_data_validation: lastIndex.fur_data_validation,
      isSelected: 0,
      isSame: true,
      displayFur: lastIndex.displayFur,
      fur_default_data: lastIndex.fur_default_data,
      sameIndex: lastIndex.sameIndex ? lastIndex.sameIndex : list.length - 1,
    })

    // list = JSON.parse(JSON.stringify(list))
    // console.log(
    //   '🚀 ~ file: ParticipateManualEntry.js:826 ~ sameBreed ~ list:',
    //   list
    // )
    setManualList(list)
    // setListValidation([
    //   ...listValidation,
    //   {
    //     id: uuidv4(),
    //     show_id: '',
    //     show_type: '',
    //     user_id: '',
    //     ear_no: '',
    //     breed_category_id: '',
    //     breed_id: '',
    //     class_id: '',
    //     variety_id: '',
    //     group_id: '',
    //     has_fur: '',
    //     fur_type_id: '',
    //     fur_id: '',
    //     group_seq: '',
    //     variety_seq: '',
    //     fur_color: '',
    //   },
    // ])
  }

  const handlePreview = async () => {
    // toast.dismiss()

    let list = [...manualList]

    let data = list.find((s) => s.isSelected === 1)
    if (!data) {
      toast.error('Please select at least one record.', { toastId: 1 })
      return
    }

    let validationList = [...listValidation]

    let valid = true
    // for (let index = 0; index < manualList.length; index++) {
    //   let ele = manualList[index]

    //   if (Number(ele.isSelected)) {
    //     // console.log('first444');
    //     validationList[index].id = ele.id || uuidv4()
    //     if (list[index].show_id.length === 0) {
    //       validationList[index].show_id = 'Please select show'
    //       valid = false
    //     } else {
    //       validationList[index].show_id = ''
    //     }
    //     if (!list[index].ear_no) {
    //       validationList[index].ear_no = 'Ear no. field is required'
    //       valid = false
    //     } else {
    //       let checkErNum = hasErNo(
    //         list[index].ear_no,
    //         list[index].user_id,
    //         list[index].show_id,
    //         manualList.filter((s) => s.id !== list[index].id)
    //       )
    //       if (checkErNum) {
    //         valid = false
    //         validationList[index].ear_no =
    //           'Duplicate Ear no. found for same user.'
    //       } else {
    //         validationList[index].ear_no = ''
    //       }
    //     }
    //     if (!list[index].user_id) {
    //       validationList[index].user_id = 'Please select exhibitor'
    //       valid = false
    //     } else {
    //       validationList[index].user_id = ''
    //     }
    //     if (!list[index].show_type) {
    //       validationList[index].show_type = 'Please select show type'
    //       valid = false
    //     } else {
    //       validationList[index].show_type = ''
    //     }

    //     if (!list[index].breed_category_id) {
    //       validationList[index].breed_category_id = 'Please select category'
    //       valid = false
    //     } else {
    //       validationList[index].breed_category_id = ''
    //     }

    //     if (!list[index].breed_id) {
    //       validationList[index].breed_id = 'Please select breed '
    //       valid = false
    //     } else {
    //       validationList[index].breed_id = ''
    //     }
    //     if (!list[index].class_id) {
    //       validationList[index].class_id = 'Please select class '
    //       valid = false
    //     } else {
    //       validationList[index].class_id = ''
    //     }
    //     if (list[index].group_seq && list[index].variety_seq) {
    //       if (!list[index].group_id) {
    //         validationList[index].group_id = 'Please select group'
    //         valid = false
    //       } else {
    //         validationList[index].group_id = ''
    //       }
    //       if (!list[index].variety_id) {
    //         validationList[index].variety_id = 'Please select Variety'
    //         valid = false
    //       } else {
    //         validationList[index].variety_id = ''
    //       }
    //     }
    //     if (!list[index].group_seq && list[index].variety_seq) {
    //       if (!list[index].variety_id) {
    //         validationList[index].variety_id = 'Please select Variety'
    //         valid = false
    //       } else {
    //         validationList[index].variety_id = ''
    //       }
    //     }
    //     if (list[index].group_seq && !list[index].variety_seq) {
    //       if (!list[index].group_id) {
    //         validationList[index].group_id = 'Please select group'
    //         valid = false
    //       } else {
    //         validationList[index].group_id = ''
    //       }
    //     }
    //     if (!list[index].group_seq && !list[index].variety_seq) {
    //       validationList[index].variety_id = ''
    //       validationList[index].group_id = ''
    //     }

    //     if (list[index].fur_data_validation) {
    //       if (!list[index].has_fur && list[index].has_fur !== 0) {
    //         // if (!list[index].has_fur) {
    //         valid = false
    //         validationList[index].has_fur = 'Please select fur'
    //       } else {
    //         validationList[index].has_fur = ''
    //       }
    //       if (list[index].has_fur && !list[index].fur_type_id.length) {
    //         valid = false
    //         validationList[index].fur_type_id = 'Please select fur type'
    //       } else {
    //         validationList[index].fur_type_id = ''
    //       }
    //       if (list[index].has_fur && !list[index].fur_color) {
    //         valid = false
    //         validationList[index].fur_color = 'Please select fur color'
    //       } else {
    //         validationList[index].fur_color = ''
    //       }
    //     }
    //   }
    // }

    if (valid) {
      let productAddPromise = new Promise((resolve, reject) => {
        let newData = []
        let validationList = []
        for (let index = 0; index < manualList.length; index++) {
          let ele = manualList[index]

          for (let j = 0; j < ele.show_id.length; j++) {
            const show = ele.show_id[j]
            let id = uuidv4()
            newData.push({
              id,
              in_cart: 1,
              event_id: event_id,
              show_id: show,
              show_type: ele.show_type,
              user_id: ele.user_id,
              user_name: ele.user_name,
              ear_no: ele.ear_no,
              breed_category_id: ele.breed_category_id,
              breed_id: ele.breed_id,
              class_id: ele.class_id,
              variety_id: ele.variety_seq ? ele.variety_id : '',
              group_id: ele.group_seq ? ele.group_id : '',
              has_fur: ele.has_fur,
              fur_type_id: ele.fur_type_id,
              fur_id: ele.fur_id,
              fur_color: ele.fur_color,
              isSelected: ele.isSelected,
              fur_default_data: ele.fur_default_data,
              fur_data: ele.has_fur
                ? ele.fur_type_id.map((s) => {
                    return {
                      fur_type_id: Number(s),
                      fur_id: Number(ele.fur_color),
                    }
                  })
                : [],
              group_seq: ele.group_seq,
              variety_seq: ele.variety_seq,
              isDisable: true,
              previewScreen: true,
            })
            validationList.push({
              id,
              show_id: '',
              show_type: '',
              user_id: '',
              ear_no: '',
              breed_category_id: '',
              breed_id: '',
              class_id: '',
              variety_id: '',
              group_id: '',
              has_fur: '',
              fur_type_id: '',
              fur_id: '',
              group_seq: '',
              variety_seq: '',
              fur_color: '',
            })
          }
        }
        let finalResult = {
          newData,
          validationList,
        }
        resolve(finalResult)
      })

      // let ValidationAddPromise = new Promise((resolve, reject) => {
      //   let validationList = []
      //   for (let index = 0; index < manualList.length; index++) {
      //     let ele = manualList[index]

      //     for (let j = 0; j < ele.show_id.length; j++) {
      //       validationList.push({
      //         id: ele.id,
      //         show_id: '',
      //         show_type: '',
      //         user_id: '',
      //         ear_no: '',
      //         breed_category_id: '',
      //         breed_id: '',
      //         class_id: '',
      //         variety_id: '',
      //         group_id: '',
      //         has_fur: '',
      //         fur_type_id: '',
      //         fur_id: '',
      //         group_seq: '',
      //         variety_seq: '',
      //         fur_color: '',
      //       })
      //     }
      //   }
      //   resolve(validationList)
      // })
      const manualListData = await productAddPromise

      // const validationDataList = await ValidationAddPromise

      let successPromise = new Promise((resolve, reject) => {
        let finalData = manualListData.newData.filter((s) => s.isSelected === 1)

        finalData = _.sortBy(
          finalData,
          ['show_type', 'user_name'],
          ['asc', 'asc']
        )

        dispatch({
          type: 'ADD_ALL_PREVIEW',
          payload: finalData,
        })
        dispatch({
          type: 'ADD_VALIDATION',
          payload: manualListData.validationList,
        })

        resolve(1)
      })
      const success = await successPromise
      if (success) {
        history.push({
          pathname: '/participate_manual_entry_preview',
          search: `?event_id=${urlEncode(
            event_id
          )}&has_products=${has_products}`,
          state: {
            additionalInfo: additionalInfo,
          },
        })
      }
    } else {
      setListValidation(listValidation)
      setManualList(list)
    }
  }
  const hasFurData = async (index, hasFur) => {
    let list = [...manualList]

    if (hasFur && hasFur.length) {
      list[index].fur_data_validation = true
      list[index].displayFur = true
      list[index].fur_default_data = hasFur
      if (hasFur.length === 1 && hasFur[0].fur_list.length === 1) {
        list[index].displayFur = false
      }
    } else {
      list[index].fur_data_validation = false
      list[index].has_fur = ''
      list[index].fur_data = []
      list[index].fur_type_id = []
      list[index].fur_id = []
      list[index].fur_default_data = []
      list[index].displayFur = true
    }

    setManualList(list)
  }

  useEffect(() => {
    const checkSame = manualList[manualList.length - 1]

    let validation = true
    if (!checkSame.breed_category_id) validation = false
    if (!checkSame.breed_id) validation = false
    if (!checkSame.show_type) validation = false
    if (!checkSame.user_id) validation = false
    if (!checkSame.show_id.length) validation = false
    if (!checkSame.ear_no) validation = false
    if (!checkSame.breed_id) validation = false
    if (!checkSame.group_seq && checkSame.group_id) validation = false
    if (!checkSame.variety_seq && checkSame.variety_id) validation = false
    if (validation) setCheckSameBreed(false)
  }, [manualList])

  let displayEnd = true
  if (getDate(event?.start_date_time) === getDate(event?.end_date_time)) {
    displayEnd = false
  }

  let selectAll = false
  let checkAll = manualList.every((s) => s.isSelected === 1)

  if (checkAll) {
    selectAll = true
  }

  return (
    <React.Fragment>
      <div className='page-content bg-gray-101'>
        <section className='section1 bg-gray-101 pages event-list'>
          <Container fluid={true} className='auto-fit'>
            <Row>
              <Col lg={12} className='text-center'>
                <div className='page-title-box justify-content-between'>
                  <h2 className='top-title mb-4'>
                    <span>PARTICIPATE : MANUAL ENTRY</span>
                  </h2>
                </div>
              </Col>
              <Col lg={12} className='mb-7 event-list'>
                <Card className='shadow-only-hover'>
                  <CardBody className='event-card-body'>
                    <Col lg={12} className='event-box'>
                      <div className='row'>
                        <div className='col-lg-4 col-xl-3 box-head'>
                          <div className='row'>
                            <div className='col-lg-5 col-xl-5 mb-4 mt-4'>
                              Event Date:{' '}
                            </div>
                            <div className='col-lg-7 col-xl-7 pl-0'>
                              <p className='highlight'>
                                {event?.start_date_time &&
                                  getDate(event.start_date_time)}
                                <br />

                                {displayEnd && 'To'}
                                {displayEnd && <br />}
                                {displayEnd && getDate(event?.end_date_time)}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className='col-lg-5 col-xl-3 box-head mt-3'>
                          {event?.event_name}{' '}
                        </div>
                        <div className='col-lg-3 col-xl-3 box-head mt-3'>
                          {event?.address_1}
                          {event?.address_1 ? ', ' : ''}
                          <br />
                          {event?.address_2}
                          {event?.address_2 ? ', ' : ''}
                          {event?.city}
                          {event?.city ? ', ' : ''}
                          {event?.state_name}
                          {event?.state_name ? ', ' : ''}
                          {event?.zip_code}
                          {event?.zip_code ? ', ' : ''}
                          {event?.country_name}
                        </div>
                        <div className='col-lg-6 col-xl-3 box-head'>
                          <div className='row'>
                            <div className='col-lg-4 col-xl-4 mt-4'>
                              Deadline:
                            </div>
                            {event?.deadlines?.map((item, index) => {
                              if (
                                item?.deadline_status === 'upcoming' &&
                                count === 0
                              ) {
                                count++

                                return (
                                  <div className='col-lg-8 col-xl-8 view-dline mt-2'>
                                    <span className='deadline'>
                                      <div>
                                        {getDate(item?.date_time)}
                                        <br />
                                        {`${getTime(item?.date_time)} CST`}
                                      </div>
                                    </span>
                                    <div className='input-group-append'>
                                      <span className='input-group-text'>
                                        {index + 1}st
                                      </span>
                                    </div>
                                  </div>
                                )
                              }
                            })}
                          </div>
                        </div>
                      </div>
                    </Col>
                  </CardBody>
                </Card>
              </Col>

              <div className='col-md-12 mb-7'>
                <div className='card shadow-only-hover'>
                  <div className='card-body event-card-body'>
                    <Col lg={12} className='mt-0'>
                      {/* <hr /> */}
                      <Col lg={12} className='manual-fill-box fs-20'>
                        Event Type: {event?.event_type}
                      </Col>
                      <Col lg={12} className='mt-4 manual-empty-box fs-20'>
                        Fill in the details of the animals that you want to{' '}
                        <span>PARTICIPATE </span>with.
                      </Col>
                      <br />
                      {/* JSON.parse(JSON.stringify(manualList)) */}
                      {manualList.map((manual, index) => {
                        return (
                          <ParticipateEasyEntryListAdd
                            key={index}
                            index={index}
                            showData={showData}
                            showList={showList}
                            list={manual}
                            exhibitorList={exhibitorList}
                            handleChange={handleChange}
                            handleRemove={handleRemove}
                            validationList={listValidation}
                            hasFurData={hasFurData}
                          />
                        )
                      })}
                    </Col>

                    <hr />
                    <Col lg={12} className='text-center mt-5 mb-3 easy-box'>
                      <Row>
                        <Col lg={9}>
                          <button
                            type='button'
                            className='btn btn-primary btn-back res-mb-manual'
                            onClick={() => sameBreed(true)}
                            disabled={checkSameBreed}
                          >
                            + SAME EXHIBITOR / BREED
                          </button>
                          <button
                            type='button'
                            className='btn btn-primary  res-mb-manual'
                            onClick={() => onAddFormRow()}
                          >
                            + NEW EXHIBITOR / BREED
                          </button>
                          <button
                            type='button'
                            className='btn btn-primary btn-easy res-mb-manual'
                            onClick={() => {
                              handlePreview()
                            }}
                          >
                            PREVIEW
                          </button>
                        </Col>
                        <Col lg={3}>
                          <input
                            className='form-check-input mr-2 pr-2'
                            type='checkbox'
                            id='check'
                            onClick={(e) => {
                              handleSelectAll(e)
                            }}
                            checked={selectAll}
                            value={selectAll}
                          />
                          <label className='form-check-label'>
                            {' '}
                            Choose to select all ENTRIES
                          </label>
                        </Col>
                      </Row>
                    </Col>
                  </div>
                </div>
              </div>
            </Row>
          </Container>
        </section>
      </div>
    </React.Fragment>
  )
}
export default ParticipateManualEntry

const hasErNo = (er_no, user_id, show_ids, list) => {
  let find = list.find((s) => {
    let intersection = _.intersection(show_ids, s.show_id)

    if (s.user_id === user_id && s.ear_no === er_no && intersection.length) {
      return s
    }
    return undefined
  })

  return Boolean(find)
}
