import _ from 'lodash'
import {
  BREED_HIERARCHY_DETAIL_SUCCESS,
  BREED_HIERARCHY_RESET,
  BREED_LIST_SUCCESS,
  GET_ONE_PEDIGREE_DETAIL_SUCCESS,
  PEDIGREE_LIST,
  PEDIGREE_LIST_SUCCESS,
  RABBIT_LIST_SUCCESS,
  SHOW_CLOSE_PEDIGREE_MODAL,
} from './actionTypes'

const RabbitForm = {
  ear_no: '',
  animal_name: '',
  add_new_breed: false,
  breed_id: '',
  breed_name: '',
  add_new_group: false,
  group_id: '',
  group_name: '',
  add_new_variety: false,
  variety_id: '',
  variety_name: '',
  variety_seq: '',
  group_seq: '',
  weight: '',
  registration_number: '',
  date_of_birth: '',
  grand_championship: '',
  winnings: '',
  gender: '',
  text: '',
  father_id: '',
  mother_id: '',
  disabled: true,
  parents: [],
  color_name: '',
}
const Form = [
  {
    ...RabbitForm,
    text: 'New Pedigree',
    generation: 1,
    disabled: false,

    parents: [
      {
        ...RabbitForm,
        gender: 'M',
        text: 'Sire',
        generation: 2,
        add_from_existing_pedigree: false,
        pedigree_id: '',

        parents: [
          {
            ...RabbitForm,
            gender: 'M',
            text: 'Sire',
            generation: 3,
            add_from_existing_pedigree: false,
            pedigree_id: '',

            parents: [
              {
                ...RabbitForm,
                generation: 4,
                gender: 'M',
                text: 'Sire',
                add_from_existing_pedigree: false,
                pedigree_id: '',
              },
              {
                ...RabbitForm,
                generation: 4,
                gender: 'F',
                text: 'Dam',
                add_from_existing_pedigree: false,
                pedigree_id: '',
              },
            ],
          },
          {
            ...RabbitForm,
            gender: 'F',
            text: 'Dam',
            generation: 3,
            add_from_existing_pedigree: false,
            pedigree_id: '',

            parents: [
              {
                ...RabbitForm,
                gender: 'M',
                text: 'Sire',
                generation: 4,
                add_from_existing_pedigree: false,
                pedigree_id: '',
              },
              {
                ...RabbitForm,

                gender: 'F',
                text: 'Dam',
                generation: 4,
                add_from_existing_pedigree: false,
                pedigree_id: '',
              },
            ],
          },
        ],
      },
      {
        ...RabbitForm,
        gender: 'F',
        text: 'Dam',
        generation: 2,
        add_from_existing_pedigree: false,
        pedigree_id: '',

        parents: [
          {
            ...RabbitForm,
            gender: 'M',
            text: 'Sire',
            generation: 3,
            add_from_existing_pedigree: false,
            pedigree_id: '',

            parents: [
              {
                ...RabbitForm,
                generation: 4,
                gender: 'M',
                text: 'Sire',
                add_from_existing_pedigree: false,
                pedigree_id: '',
              },
              {
                ...RabbitForm,
                generation: 4,
                gender: 'F',
                text: 'Dam',
                add_from_existing_pedigree: false,
                pedigree_id: '',
              },
            ],
          },
          {
            ...RabbitForm,
            gender: 'F',
            text: 'Dam',
            generation: 3,
            add_from_existing_pedigree: false,
            pedigree_id: '',

            parents: [
              {
                ...RabbitForm,
                gender: 'M',
                text: 'Sire',
                generation: 4,
                add_from_existing_pedigree: false,
                pedigree_id: '',
              },
              {
                ...RabbitForm,
                gender: 'F',
                text: 'Dam',
                generation: 4,
                add_from_existing_pedigree: false,
                pedigree_id: '',
              },
            ],
          },
        ],
      },
    ],
  },
]

const initialState = {
  pedigreeModal: {
    showModal: false,
    modalType: '',
    ModalContent: {
      childRabbit: {},
      form: {},
    },

    loading: true,
  },
  pedigreeForm: {
    form: {
      pedigree_id: '',
      pedigree_text: '',
      is_agree: false,
      rabbit_data: Form,
    },
    configureData: {
      breed_list: [],
      group_list: [],
      variety_list: [],
    },
    loading: true,
  },
  pedigree: {
    pedigreeList: [],
    loading: true,
  },
  rabbit: {
    rabbitList: [],
    loading: true,
  },
}

const pedigreeHeaderReducer = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case SHOW_CLOSE_PEDIGREE_MODAL:
      return {
        ...state,
        pedigreeModal: {
          ...state.pedigreeModal,
          showModal: payload.showModal,
          modalType: payload.modalType,
          ModalContent: {
            ...state.ModalContent,
            childRabbit: payload.ModalContent.childRabbit,
            form: payload.ModalContent.form,
          },
          loading: false,
        },
      }
    case BREED_LIST_SUCCESS:
      return {
        ...state,
        configureData: {
          ...state.configureData,
          breed_list: payload,
        },
      }
    case BREED_HIERARCHY_DETAIL_SUCCESS:
      return {
        ...state,
        configureData: {
          ...state.configureData,
          group_list: payload?.group_list || [],
          variety_list: payload?.variety_list || [],
        },
      }

    case BREED_HIERARCHY_RESET:
      return {
        ...state,
        configureData: {
          ...state.configureData,
          group_list: [],
          variety_list: [],
        },
      }

    case GET_ONE_PEDIGREE_DETAIL_SUCCESS:
      const rabbit_data = _.map(
        _.filter(
          payload.rabbit_data,
          (q) => Number(q.rabbit_id) === Number(payload.rabbit_id)
        ),
        (level1) => {
          const fatherData = _.find(
            payload.rabbit_data,
            (q) => q.rabbit_id === level1.father_id
          )
          const motherData = _.find(
            payload.rabbit_data,
            (q) => q.rabbit_id === level1.mother_id
          )
          return {
            ...RabbitForm,
            ...level1,
            animal_name: level1?.rabbit_name,
            text: 'New Pedigree',
            generation: 1,
            disabled: false,
            add_new_breed: level1?.rabbit_id ? true : false,
            add_new_group: level1?.rabbit_id ? true : false,
            add_new_variety: level1?.rabbit_id ? true : false,
            // rabbit_gender
            is_agree: level1?.is_agree ? true : false,
            gender: level1?.rabbit_gender,
            img: payload.full_image,

            date_of_birth: new Date(level1.date_of_birth).toISOString(),
            parents: [
              {
                ...RabbitForm,
                ...fatherData,
                gender: 'M',
                text: 'Sire',
                generation: 2,
                add_from_existing_pedigree: false,
                disabled: !level1.rabbit_id,
                animal_name: fatherData?.rabbit_name,
                add_new_breed: fatherData?.rabbit_id ? true : false,
                add_new_group: fatherData?.rabbit_id ? true : false,
                add_new_variety: fatherData?.rabbit_id ? true : false,
              },
              {
                ...RabbitForm,
                ...motherData,
                generation: 2,
                gender: 'F',
                text: 'Dam',
                add_from_existing_pedigree: false,
                disabled: !level1.rabbit_id,
                animal_name: motherData?.rabbit_name,

                add_new_breed: motherData?.rabbit_id ? true : false,
                add_new_group: motherData?.rabbit_id ? true : false,
                add_new_variety: motherData?.rabbit_id ? true : false,
              },
            ].map((level2) => {
              const fatherData = _.find(
                payload.rabbit_data,
                (q) => q.rabbit_id === level2.father_id
              )
              const motherData = _.find(
                payload.rabbit_data,
                (q) => q.rabbit_id === level2.mother_id
              )

              return {
                ...level2,
                animal_name: level2?.rabbit_name,
                add_new_breed: level2?.rabbit_id ? true : false,
                add_new_group: level2?.rabbit_id ? true : false,
                add_new_variety: level2?.rabbit_id ? true : false,
                parents: [
                  {
                    ...RabbitForm,
                    ...fatherData,
                    gender: 'M',
                    text: 'Sire',
                    generation: 3,
                    add_from_existing_pedigree: false,
                    disabled: !level2.rabbit_id,
                    animal_name: fatherData?.rabbit_name,
                    add_new_breed: fatherData?.rabbit_id ? true : false,
                    add_new_group: fatherData?.rabbit_id ? true : false,
                    add_new_variety: fatherData?.rabbit_id ? true : false,
                  },
                  {
                    ...RabbitForm,
                    ...motherData,
                    generation: 3,
                    gender: 'F',
                    text: 'Dam',
                    add_from_existing_pedigree: false,
                    disabled: !level2.rabbit_id,
                    animal_name: motherData?.rabbit_name,
                    add_new_breed: motherData?.rabbit_id ? true : false,
                    add_new_group: motherData?.rabbit_id ? true : false,
                    add_new_variety: motherData?.rabbit_id ? true : false,
                  },
                ].map((level3) => {
                  const fatherData = _.find(
                    payload.rabbit_data,
                    (q) => q.rabbit_id === level3.father_id
                  )
                  const motherData = _.find(
                    payload.rabbit_data,
                    (q) => q.rabbit_id === level3.mother_id
                  )
                  return {
                    ...level3,
                    animal_name: level3?.rabbit_name,
                    add_new_breed: level3?.rabbit_id ? true : false,
                    add_new_group: level3?.rabbit_id ? true : false,
                    add_new_variety: level3?.rabbit_id ? true : false,
                    parents: [
                      {
                        ...RabbitForm,
                        ...fatherData,
                        gender: 'M',
                        text: 'Sire',
                        generation: 4,
                        add_from_existing_pedigree: false,
                        disabled: !level3.rabbit_id,

                        animal_name: fatherData?.rabbit_name,
                        add_new_breed: fatherData?.rabbit_id ? true : false,
                        add_new_group: fatherData?.rabbit_id ? true : false,
                        add_new_variety: fatherData?.rabbit_id ? true : false,
                      },
                      {
                        ...RabbitForm,
                        ...motherData,
                        generation: 4,
                        gender: 'F',
                        text: 'Dam',
                        add_from_existing_pedigree: false,
                        disabled: !level3.rabbit_id,
                        animal_name: motherData?.rabbit_name,
                        add_new_breed: motherData?.rabbit_id ? true : false,
                        add_new_group: motherData?.rabbit_id ? true : false,
                        add_new_variety: motherData?.rabbit_id ? true : false,
                      },
                    ],
                  }
                }),
              }
            }),
          }
        }
      )
      return {
        ...state,
        pedigreeForm: {
          ...state.pedigreeForm,
          form: {
            ...state.pedigreeForm.form,
            ..._.omit(payload, ['rabbit_data']),
            rabbit_data: rabbit_data,
          },
          // form
        },
      }
    case PEDIGREE_LIST:
      return {
        ...state,
        pedigree: {
          pedigreeList: [],
          loading: true,
        },
        pedigreeForm: {
          form: {
            pedigree_id: '',
            pedigree_text: '',
            is_agree: false,
            rabbit_data: Form,
          },
          configureData: {
            breed_list: [],
            group_list: [],
            variety_list: [],
          },
          loading: true,
        },
      }
    case PEDIGREE_LIST_SUCCESS:
      return {
        ...state,
        pedigree: {
          pedigreeList: _.map(payload, (p) => {
            const rabbit = _.first(p.rabbit_data)
            return {
              ...p,
              label: `${rabbit.rabbit_name || ''} > ${rabbit?.ear_no || ''} > ${
                rabbit?.breed_name || ''
              }  ${rabbit?.group_name ? `> ${rabbit?.group_name}` : ''} ${
                rabbit?.variety_name ? `> ${rabbit?.variety_name}` : ''
              } `,
              value: p.pedigree_id,
              gender: rabbit.rabbit_gender,
            }
          }),
          loading: false,
        },
      }
    case RABBIT_LIST_SUCCESS:
      return {
        ...state,
        rabbit: {
          rabbitList: _.map(payload, (rabbit) => {
            return {
              ...rabbit,
              label: `${rabbit.animal_name || ''} > ${rabbit?.ear_no || ''} > ${
                rabbit?.breed_name || ''
              }  ${rabbit?.group_name ? `> ${rabbit?.group_name}` : ''} ${
                rabbit?.variety_name ? `> ${rabbit?.variety_name}` : ''
              } `,
              value: rabbit.rabbit_id,
            }
          }),
          loading: false,
        },
      }
    default:
      return state
  }
}
export default pedigreeHeaderReducer
