import { call, delay, put, takeEvery } from 'redux-saga/effects'

import {
  GET_ALL_BREED_LIST,
  GET_ALL_BREED_LIST_SUCCESS,
  ADD_BREED_CONFIGURE,
  GET_BREED_CONFIGURE_DATA,
  GET_BREED_CONFIGURE_DATA_SUCCESS,
} from './actionTypes'
import { toast } from 'react-toastify'
import { get, post } from '../../../helpers/api_helper'
import { getConfigureBreedList } from './actions'

function* getAllBreedList() {
  try {
    const res = yield get(`/api/v1/breed/all?published=1`)

    yield put({
      type: GET_ALL_BREED_LIST_SUCCESS,
      payload: res?.data || [],
    })
    if (res) {
      yield put(getConfigureBreedList())
    }
  } catch (err) {
    if (err?.response?.data) {
      toast.error(err?.response?.data)
    }
  }
}

function* addConfigureBreedList({ payload, callback }) {
  try {
    const res = yield post(
      `/api/v1/herd/config-user-breed/create`,
      payload.data
    )
    if (payload.callback) payload.callback(null, res)
    yield put(getConfigureBreedList())
  } catch (err) {
    if (err?.response?.data) {
      toast.error(err?.response?.data)
    }
  }
}
function* getConfigureBreedListSagas({ payload }) {
  try {
    yield delay(1000)
    const res = yield get(`/api/v1/herd/config-user-breed/all`, payload?.data)

    yield put({
      type: GET_BREED_CONFIGURE_DATA_SUCCESS,
      payload: res.data,
    })
  } catch (err) {
    if (err?.response?.data) {
      toast.error(err?.response?.data)
    }
  }
}
function* easyEntrySaga() {
  yield takeEvery(GET_ALL_BREED_LIST, getAllBreedList)
  yield takeEvery(ADD_BREED_CONFIGURE, addConfigureBreedList)
  yield takeEvery(GET_BREED_CONFIGURE_DATA, getConfigureBreedListSagas)
}

export default easyEntrySaga
