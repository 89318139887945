import {
  GET_ALL_PREMIER_REPORT_BREED_LIST,
  GET_ALL_PREMIER_REPORT_BREED_LIST_SUCCESS,
  GET_ALL_PREMIER_REPORT_EVENT_DETAIL,
  GET_ALL_PREMIER_REPORT_EVENT_DETAIL_SUCCESS,
  GET_ALL_PREMIER_REPORT_EVENT_LIST,
  GET_ALL_PREMIER_REPORT_EVENT_LIST_SUCCESS,
  GET_ALL_PREMIER_REPORT_SHOW_LIST,
  GET_ALL_PREMIER_REPORT_SHOW_LIST_SUCCESS,
  GET_ALL_PREMIER_REPORT_SHOW_FINALIZED_SUCCESS,
  GET_ALL_PREMIER_REPORT_SHOW_FINALIZED_STATUS_SUCCESS,
  GET_ALL_PREMIER_REPORT_DETAIL_BY_BREED,
  GET_ALL_PREMIER_REPORT_DETAIL_BY_BREED_SUCCESS,
} from './actionType'

//
const INIT_STATE = {
  premierReportEventList: [],
  eventDetailData: {
    eventDetail: {},
    loading: true,
  },
  showListData: {
    showList: [],
    loading: true,
  },
  breedListData: {
    breedList: [],
    loading: true,
  },
  finalizedData: {
    finalizedStatus: {},
    finalizedList: [],
  },
  detailByBreed: [],
  totalPages: 0,
  totalRecords: 0,
  page: 1,
  loading: true,
}

const PremierReport = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ALL_PREMIER_REPORT_EVENT_LIST:
      return {
        ...state,
        loading: true,
      }
    case GET_ALL_PREMIER_REPORT_EVENT_DETAIL:
      return {
        ...state,
        eventDetailData: {
          ...state.eventDetailData,
          eventDetail: {},
          loading: true,
        },
      }
    case GET_ALL_PREMIER_REPORT_EVENT_DETAIL_SUCCESS:
      return {
        ...state,
        eventDetailData: {
          ...state.eventDetailData,
          eventDetail: action.payload,
          loading: false,
        },
      }
    case GET_ALL_PREMIER_REPORT_SHOW_LIST:
      return {
        ...state,
        showListData: {
          ...state.showListData,
          loading: true,
        },
      }
    case GET_ALL_PREMIER_REPORT_SHOW_LIST_SUCCESS:
      return {
        ...state,
        showListData: {
          ...state.showListData,
          showList: action.payload,
          loading: false,
        },
      }

    case GET_ALL_PREMIER_REPORT_EVENT_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        premierReportEventList: action?.payload?.data || [],
        totalPages: action?.payload?.totalPages || 0,
        totalRecords: action?.payload?.totalRecords || 0,
        page: action?.payload?.page || 1,
      }
    case GET_ALL_PREMIER_REPORT_BREED_LIST:
      return {
        ...state,
        breedListData: {
          ...state.breedListData,
          loading: true,
        },
      }
    case GET_ALL_PREMIER_REPORT_BREED_LIST_SUCCESS:
      return {
        ...state,
        breedListData: {
          ...state.breedListData,
          breedList: action?.payload,
          loading: false,
        },
      }
    case GET_ALL_PREMIER_REPORT_SHOW_FINALIZED_STATUS_SUCCESS:
      return {
        ...state,
        finalizedData: {
          ...state.finalizedData,
          finalizedStatus: action?.payload,
        },
      }
    case GET_ALL_PREMIER_REPORT_SHOW_FINALIZED_SUCCESS:
      return {
        ...state,
        finalizedData: {
          ...state.finalizedData,
          finalizedList: action?.payload,
        },
      }
    case GET_ALL_PREMIER_REPORT_DETAIL_BY_BREED_SUCCESS:
      return {
        ...state,
        detailByBreed: action?.payload,
      }
    default:
      return state
  }
}

export default PremierReport
