import {
  GET_ALL_CART_EVENT_SUCCESS,
  GET_LIST_SHOPPING_CART,
  REMOVE_SHOPPING_CART,
  SET_CART_LOADING,
} from './actionTypes'

//
const INIT_STATE = {
  shopping_product: [],
  eventList: {
    cart_data: [],
    event_details: {},
    final_price: 0,
  },
  loading: true,
  error: {},
}

const shoppingCart = (state = INIT_STATE, action) => {
  const { type, payload } = action

  switch (type) {
    case SET_CART_LOADING:
      return {
        ...state,
        loading: true,
      }
    case GET_LIST_SHOPPING_CART:
      return {
        ...state,
        shopping_product: payload || [],
        loading: false,
      }
    case GET_ALL_CART_EVENT_SUCCESS:
      return {
        ...state,
        eventList: payload,
        loading: false,
      }
    case REMOVE_SHOPPING_CART:
      return {
        ...state,
        loading: false,
        shopping_product: [],
      }
    default:
      return state
  }
}

export default shoppingCart
