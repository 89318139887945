import {
  LOGIN_USER,
  LOGIN_SUCCESS,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  API_ERROR,
  SOCIAL_LOGIN,
  PREMIER_USER_DETAIL,
  PREMIER_USER_DETAIL_SUCCESS,
  LOAD_USER,
} from './actionTypes'

export const loginUser = (user, history, redirect) => {
  return {
    type: LOGIN_USER,
    payload: { user, history, redirect },
  }
}

export const loginSuccess = (user) => {
  return {
    type: LOGIN_SUCCESS,
    payload: user,
  }
}

export const logoutUser = (history) => {
  return {
    type: LOGOUT_USER,
    payload: { history },
  }
}

export const logoutUserSuccess = () => {
  return {
    type: LOGOUT_USER_SUCCESS,
    payload: {},
  }
}

export const apiError = (error) => {
  return {
    type: API_ERROR,
    payload: error,
  }
}

export const socialLogin = (data, history, type) => {
  return {
    type: SOCIAL_LOGIN,
    payload: { data, history, type },
  }
}

export const premierUsers = (data, callback) => {
  return {
    type: PREMIER_USER_DETAIL,
    payload: { data, callback },
  }
}
export const premierUsersSuccess = (data) => {
  return {
    type: PREMIER_USER_DETAIL_SUCCESS,
    payload: data,
  }
}

export const loadUser = () => {
  return {
    type: LOAD_USER,
    payload: null,
  }
}