import {
  TRADITIONAL_PLAN_PAYMENT_INTENT_SUCCESS,
  TRADITIONAL_PLAN_PAYMENT_INTENT_ACTION,
  TRADITIONAL_RENEW_PLAN_PAYMENT_ACTION,
} from './actionType'

export const TraditionalPlanPaymentCreateIntentAction = (params, history) => {
  return {
    type: TRADITIONAL_PLAN_PAYMENT_INTENT_ACTION,
    payload: { params: params, history },
  }
}

//
export const TraditionalRenewPayment = (data) => {
  return {
    type: TRADITIONAL_RENEW_PLAN_PAYMENT_ACTION,
    payload: data,
  }
}
