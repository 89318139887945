import { NEXT_PAGE, HERD_MANAGEMENT_PAGINATION_DATA } from './actionType'

export const paginationData = (data) => {
  return {
    type: HERD_MANAGEMENT_PAGINATION_DATA,
    payload: data,
  }
}

export const nextPage = (data) => {
  return {
    type: NEXT_PAGE,
    payload: data,
  }
}
