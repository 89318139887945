import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Col, Row, Table } from 'reactstrap'
import Swal from 'sweetalert2'
import { get, post } from '../../../helpers/api_helper'
import {
  MANUAL_ENTRY_CHECK_IN_SHEET,
  MANUAL_ENTRY_CHECK_IN_SHEET_LOG,
} from '../../../helpers/url_helper'
import { DeadlineHook, PdfToUrlHook } from '../../../hooks'
import { getAllYourParticipationByShow } from '../../../store/actions'
import $ from 'jquery'
import { first } from 'lodash'
// import { getAllYourParticipationByShow } from '../../store/action'
import { UrlEncodeHook } from '../../../hooks'

const ParticipateUser = (props) => {
  const { event } = props

  const dispatch = useDispatch()
  const history = useHistory()
  const { sendBase64 } = PdfToUrlHook()
  const YourParticipation = useSelector((state) => state.YourParticipation)

  const { allUserAnimal } = YourParticipation
  let { eventId, showId } = useParams()
  const { urlDecode, urlEncode } = UrlEncodeHook()
  eventId = urlDecode(eventId)
  showId = urlDecode(showId)

  const { deadLineType } = DeadlineHook({
    eventId,
  })

  useEffect(() => {
    let data = {
      show_id: showId,
    }
    dispatch(getAllYourParticipationByShow(data))
  }, [])

  const checkInSheet = async (data) => {
    $('*').addClass('changeCursor')
    try {
      const res = await get(
        `${MANUAL_ENTRY_CHECK_IN_SHEET}?event_id=${data?.eventId}&user_id=${
          data?.userId
        }&show_id=${data?.showId}&send_email=''&role=user&is_coop_no_req=${
          Boolean(event?.is_coop_no_req) ? 1 : ''
        }`
      )
      sendBase64(res.data, 'Check-in-sheet')
      $('*').removeClass('changeCursor')
    } catch (err) {
      $('*').removeClass('changeCursor')
      if (err.response.data) {
        toast.error(err.response.data)
      }
    }
  }

  const checkInSheetManual = async (data) => {
    $('*').addClass('changeCursor')
    try {
      const res = await get(
        `${MANUAL_ENTRY_CHECK_IN_SHEET_LOG}?event_id=${data?.eventId}&user_id=${
          data?.userId
        }&show_id=${data?.showId}&send_email=''&role=user&is_coop_no_req=${
          Boolean(event?.is_coop_no_req) ? 1 : ''
        }`
      )
      sendBase64(res.data, 'Check-in-sheet-with-log')
      $('*').removeClass('changeCursor')
    } catch (err) {
      $('*').removeClass('changeCursor')
      if (err.response.data) {
        toast.error(err.response.data)
      }
    }
  }
  const deleteAnimal = async (data) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      width: '380px',
      showCancelButton: true,
      confirmButtonColor: '#062575',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const res = await delAnimal(data)
          let showData = {
            show_id: showId,
          }
          dispatch(getAllYourParticipationByShow(showData))
          toast.success(res.data)
          // setTimeout(() => {
          history.push('/your_participation')
          // }, 1000)
        } catch (err) {
          if (err.response.data) {
            toast.error(err.response.data)
          }
        }
      }
    })
  }
  let deadline = deadLineType()

  let deleteDisabled = useMemo(() => {
    let isDisable = false
    if (deadline.deadlineType === 3 && deadline.first_deadline) {
      isDisable = true
    }
    if (deadline.deadlineType === 2 && deadline.first_deadline) {
      isDisable = true
    }
    if (deadline.deadlineType === 1 && deadline.first_deadline) {
      isDisable = true
    }

    return isDisable
  }, [deadline])
  let editDisable = useMemo(() => {
    let isDisable = false
    if (deadline.deadlineType === 3 && deadline.third_deadline) {
      isDisable = true
    }
    if (deadline.deadlineType === 2 && deadline.second_deadline) {
      isDisable = true
    }
    if (deadline.deadlineType === 1 && deadline.first_deadline) {
      isDisable = true
    }
    return isDisable
  }, [deadline])

  return (
    <div>
      {' '}
      {allUserAnimal.length
        ? allUserAnimal.map((animal) => {
            return (
              <React.Fragment key={animal.user_id}>
                <Col lg={12}>
                  <div className="text-center mt-5 middle-box">
                    <Row>
                      <div className="col-lg-4 col-sm-12 text-left user-name res-m-box">
                        User Name: {animal.user_name}
                      </div>
                      <div  className="col-lg-4 col-sm-6 text-right check-btn res-m-box">
                        <Link
                          onClick={() =>
                            checkInSheet({
                              eventId,
                              userId: animal.user_id,
                              showId: showId,
                            })
                          }
                          title="Check-in Sheet"
                        >
                          CHECK-IN SHEET
                        </Link>
                      </div>
                      <div className="col-lg-4 col-sm-6 text-right log-btn res-m-box">
                        <Link
                          onClick={() =>
                            checkInSheetManual({
                              eventId,
                              userId: animal.user_id,
                              showId: showId,
                            })
                          }
                          title="Check-in Sheet with Log"
                        >
                          CHECK-IN SHEET WITH LOG
                        </Link>
                      </div>
                    </Row>
                  </div>
                </Col>
                <Col lg={12}>
                  <div className="table-responsive text-center mt-8">
                    <Table className="table mb-0 cust-tab">
                      <thead className="table-color">
                        <tr>
                          <th>Ear No.</th>
                          <th>Breed</th>
                          <th>Group / Variety Details</th>
                          <th>Class</th>
                          <th>Fur</th>
                          <th>Fur Details</th>
                          <th>Fur Color</th>
                          {deadline ? <th>Action</th> : null}
                        </tr>
                      </thead>
                      <tbody>
                        {animal.animals.length ? (
                          animal.animals.map((breed) => {
                            let hasFur = false
                            let breedColor = {}
                            if (
                              Array.isArray(breed?.fur_data) &&
                              breed?.fur_data.length
                            ) {
                              hasFur = true
                              breedColor = first(breed?.fur_data)
                            }
                            return (
                              <tr>
                                <td>{breed.ear_no}</td>
                                <td>{breed.breed_name}</td>
                                <td>
                                  {' '}
                                  {breed?.group_seq && breed.group_name}
                                  {breed?.group_seq &&
                                    breed?.group_name &&
                                    breed?.variety_seq &&
                                    breed?.variety_name &&
                                    ' > '}
                                  {breed?.variety_seq &&
                                    `${breed.variety_name}`}
                                </td>
                                <td>{breed?.class_name}</td>
                                <td>{hasFur ? 'Yes' : 'No'}</td>
                                <td>
                                  {/* {hasFur &&
                                    breed?.fur_data.map((fur) => {
                                      return `${fur.fur_type_name} / ${fur.fur_name}`
                                    })} */}
                                  {hasFur ? (
                                    <Table className="table mt-2 mb-0">
                                      <tbody>
                                        {breed.fur_data.map((fur) => {
                                          return (
                                            <tr className="text-view">
                                              {fur.fur_type_name}
                                            </tr>
                                          )
                                        })}
                                      </tbody>
                                    </Table>
                                  ) : null}
                                </td>
                                <td>
                                  {hasFur ? (
                                    <Table className="table mt-2 mb-0">
                                      <tbody>
                                        <tr className="text-view">
                                          {breedColor?.fur_name}
                                        </tr>
                                        {/* {entry.fur_data.map((fur) => {
                                        return <tr>{fur.fur_name}</tr>
                                      })} */}
                                      </tbody>
                                    </Table>
                                  ) : null}
                                </td>
                                {deadline ? (
                                  <td>
                                    <span
                                      className={`${
                                        editDisable ? 'not-allow' : ''
                                      }`}
                                    >
                                      <Link
                                        style={{ 'pointer-events': 'none' }}
                                        to={`/your_participation/${urlEncode(
                                          eventId
                                        )}/${urlEncode(
                                          showId
                                        )}/update/${urlEncode(
                                          breed.participant_id
                                        )}`}
                                        className={`${
                                          editDisable ? 'not-allow pe-none' : ''
                                        }`}
                                      >
                                        <img
                                          src={`${process.env.PUBLIC_URL}/assets/img/icons/update.png`}
                                          alt="update"
                                          className={`mb-2 p-r-3  ${
                                            editDisable
                                              ? 'pe-none not-allow'
                                              : 'pe-auto'
                                          }`}
                                          title="Update"
                                        />
                                      </Link>
                                    </span>

                                    <span
                                      className={`${
                                        deleteDisabled ? 'not-allow' : ''
                                      }`}
                                    >
                                      <img
                                        src={`${process.env.PUBLIC_URL}/assets/img/icons/delete.png`}
                                        alt="delete"
                                        role="button"
                                        onClick={() =>
                                          deleteAnimal({
                                            participant_id_list: [
                                              breed.participant_id,
                                            ],
                                          })
                                        }
                                        className={`mb-2 p-r-3  ${
                                          deleteDisabled
                                            ? 'pe-none not-allow'
                                            : 'pe-auto'
                                        }`}
                                        title="Delete"
                                      />
                                    </span>
                                  </td>
                                ) : null}
                              </tr>
                            )
                          })
                        ) : (
                          <tr>
                            <td colSpan={10}>No breed found</td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </div>
                </Col>
              </React.Fragment>
            )
          })
        : null}
    </div>
  )
}

export default ParticipateUser
const delAnimal = async (data) => {
  const res = await post(
    `/api/v1/participation/secretary/manual/entry-list-delete`,
    data
  )
  return res
}
