import {
  SET_SHOW_ALERT,SET_MESSAGE,SET_MESSAGE_TITLE,SET_HANDLE_CLICK,SET_SHOW_BUTTON
} from "./actionTypes"

const initialState = {
  showAlert:false,
  msg:'',
  handleClicked:()=>{},
  title:'',
  showBtn:false
}

const successpopup = (state = initialState, action) => {
  switch (action.type) {
    case SET_SHOW_ALERT:
      state = { ...state ,showAlert: action.payload}
      break
      case SET_MESSAGE_TITLE:
        state = { ...state ,title: action.payload}
      break
      case SET_MESSAGE:
      state = { ...state ,msg: action.payload}
      break
      case SET_HANDLE_CLICK:
      state = { ...state ,handleClicked: action.payload}
      break
      case SET_SHOW_BUTTON:
      state = { ...state ,showBtn: action.payload}
      break
    default:
      state = { ...state }
      break
  }
  return state
}
export default successpopup;
