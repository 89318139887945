import { MILES_LIST_SUCCESS } from './actionType'

//

const INIT_STATE = {
  MileListing: [],
  loading: true,
  error: {},
}

const EasyFidesReducer = (state = INIT_STATE, action) => {
  const { type, payload } = action
  switch (type) {
    case MILES_LIST_SUCCESS:
      return {
        ...state,
        MileListing: payload,
        loading: false,
      }
    default:
      return state
  }
}

export default EasyFidesReducer
