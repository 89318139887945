import React from 'react'
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap'

const CModal = (props) => {
  const { showModal, OnInTouchModal, showNotPayModal, onCloseModal } = props

  return (
    <Modal isOpen={showModal} centered={true}>
      <ModalBody style={{ background: '#fff' }}>
        <h4 className='fw-normal'>Please contact to show secretary for the changes.</h4>
      </ModalBody>
      <ModalFooter style={{ background: '#fff', textAlign:'center' }}>
        <Button color="primary" onClick={OnInTouchModal} title="Contact">
          CONTACT
        </Button>
        <Button color="danger" onClick={onCloseModal} title="Close">
          CLOSE
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default CModal
