import React, { useEffect } from "react";
import { Row, Col, Container, Label, Input } from "reactstrap";
import { Link, useLocation } from "react-router-dom";

import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE);

const PlanCheckout = () => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const clientSecret = query.get("client_secret");

  useEffect(() => {
    window.history.forward();
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener("popstate", function (event) {
      window.history.pushState(null, document.title, window.location.href);
    });
  }, []);

  const appearance = {
    theme: "stripe",
  };
  const options = {
    clientSecret,
    appearance,
    hidePostalCode: true,
    country: false,
  };
  return (
    <React.Fragment>
      <div className="page-content bg-gray-101">
        <section id="about" class="section1 bg-gray-101 pages event-list">
          <Container fluid={true} className="auto-fit">
            <Row>
              <Col lg={12} className="mt-5 text-center">
                <h2 className="top-title-plan mb-4">
                  <span>Payment Details</span>
                </h2>
              </Col>
            </Row>

            <Row>
              <Col xl={12} className="plan-structure mt-5 mb-4">
                {clientSecret && (
                  <Elements options={options} stripe={stripePromise}>
                    <CheckoutForm isDisabled={clientSecret} />
                  </Elements>
                )}
              </Col>
            </Row>
          </Container>
        </section>
      </div>
    </React.Fragment>
  );
};

export default PlanCheckout;
