import {
  ADD_MORE_MEDIA_EQUIPMENT,
  ALL_MEDIA_LIST_SUCCESS_EQUIPMENT,
  DELETE_MEDIA_SUCCESS_EQUIPMENT,
  LISTING_SUCCESS_EQUIPMENT,
} from './actionType'

//
export const equipmentObj = {
  document_id: '',
  display_order: '',
  document_file: '',
  new_document_file: '',
  document_title: '',
}
const INIT_STATE = {
  EquipmentMedia: [equipmentObj],
  EquipmentList: [],
  loading: true,
  error: {},
}

const EasyFiedsAnimalReducer = (state = INIT_STATE, action) => {
  const { type, payload } = action
  switch (type) {
    case ALL_MEDIA_LIST_SUCCESS_EQUIPMENT:
      return {
        ...state,
        EquipmentMedia:
          Array.isArray(payload) && payload.length
            ? payload
            : [
                {
                  document_id: '',
                  display_order: '',
                  document_file: '',
                  new_document_file: '',
                  document_title: '',
                },
              ],
        loading: false,
      }
    case ADD_MORE_MEDIA_EQUIPMENT:
      return {
        ...state,
        EquipmentMedia: [
          ...state.EquipmentMedia,
          [
            {
              document_id: '',
              display_order: '',
              document_file: '',
              new_document_file: '',
              document_title: '',
            },
          ],
        ],
        loading: false,
      }
    case LISTING_SUCCESS_EQUIPMENT:
      return {
        ...state,
        EquipmentList: payload,
        loading: false,
      }
    case DELETE_MEDIA_SUCCESS_EQUIPMENT:
      let finalData = state.EquipmentMedia.filter((q, i) => i !== payload.index)
      return {
        ...state,
        EquipmentMedia: finalData.length
          ? finalData
          : [
              {
                document_id: '',
                display_order: '',
                document_file: '',
                new_document_file: '',
                document_title: '',
              },
            ],
        loading: false,
      }
    default:
      return state
  }
}

export default EasyFiedsAnimalReducer
