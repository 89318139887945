import React, { useState } from 'react'
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap'
import InputMask from 'react-input-mask'
import * as validation from '../../helpers/validation'
import * as Util from '../../helpers/utils'
import { toast } from 'react-toastify'
import { post } from '../../helpers/api_helper'
import GRecaptcha from '../../components/GRecaptcha'

const Contact = (props) => {
  const [first_name, setFirst_name] = useState('')
  const [last_name, setLast_name] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [message, setMessage] = useState('')
  const [error, setError] = useState({})
  const [GRC, setGRC] = useState(null)

  const onSubmit = async () => {
    const token = await GRC.current.executeAsync()

    let validationFlag = false
    let finalNumber = phone.replace(/[^0-9]/g, '')
    let err = {}
    if (!first_name.trim()) {
      validationFlag = true
      err.first_name = `First name ${validation.REQUIRED}`
    }
    if (first_name.trim()) {
      if (!Util.ONLY_CHAR_REGEX.test(first_name.trim())) {
        validationFlag = true
        err.first_name = `First name ${validation.ONLY_CHARACTER_AND_HYPHEN}`
      } else if (first_name.trim().length > 50) {
        validationFlag = true
        err.first_name = `First name ${validation.MAX_CHAR_FUN(50)}`
      }
    }
    if (!finalNumber.trim()) {
      validationFlag = true
      err.phone = `Phone number ${validation.REQUIRED}`
    }
    if (finalNumber.trim()) {
      // .matches(ONLY_NUMBER_REGEX, `Phone number ${validation.INVALID}`)
      if (!Util.ONLY_NUMBER_REGEX.test(finalNumber.trim())) {
        validationFlag = true
        err.phone = `Phone number ${validation.INVALID}`
      } else if (finalNumber.trim().length !== 10) {
        validationFlag = true
        err.phone = `Phone number ${validation.MUST_BE_10_DIGITS}`
      }
    }

    if (!last_name.trim()) {
      validationFlag = true
      err.last_name = `Last name ${validation.REQUIRED}`
    }
    if (last_name.trim()) {
      if (!Util.ONLY_CHAR_REGEX.test(last_name.trim())) {
        validationFlag = true
        err.last_name = `Last name ${validation.ONLY_CHARACTER_AND_HYPHEN}`
      } else if (last_name.trim().length >= 50) {
        validationFlag = true
        err.last_name = `Last name ${validation.MAX_CHAR_FUN(50)}`
      }
    }
    if (!email.trim()) {
      validationFlag = true
      err.email = `Email ${validation.REQUIRED}`
    }
    if (email.trim()) {
      if (!Util.EMAIL_REGEX.test(email.trim())) {
        validationFlag = true
        err.email = `Email ${validation.INVALID}`
      }
    }
    if (!message.trim()) {
      validationFlag = true
      err.message = `Message ${validation.REQUIRED}`
    }
    if (message.trim()) {
      if (message.trim().length >= 1000) {
        validationFlag = true
        err.message = `Message ${validation.MAX_CHAR_FUN(1000)}`
      }
    }
    setError(err)

    if (!validationFlag && token) {
      document.getElementById('submit-btn').setAttribute('disabled', true)
      let obj = {
        first_name: first_name,
        last_name: last_name,
        email: email,
        phone: finalNumber,
        text_description: message,
      }
      try {
        const res = await post(`/api/v1/easyfied/contact-us`, obj)

        if (res) {
          setFirst_name('')
          setLast_name('')
          setEmail('')
          setPhone('')
          setMessage('')
          document.getElementById('submit-btn').removeAttribute('disabled')
          toast.success('Email has been sent successfully')
        }
      } catch (err) {
        document.getElementById('submit-btn').removeAttribute('disabled')
        if (err?.response.data) {
          toast.error(err?.response.data)
        }
      }
    }
  }
  return (
    <section
      className='section bg-gray-101 pages event-list mt-12'
      style={{ minHeight: '100vh' }}
    >
      <Container fluid>
        <div className='row mb-6'>
          <Col md={12} sm={12} xs={12}>
            <h2 className='top-title'>
              <span>Contact Us</span>
            </h2>
          </Col>
        </div>

        <Row>
          <Col
            md={12}
            xs={12}
            sm={12}
            className='d-flex1 justify-content-center form-cont-label'
          >
            <Row>
              <Col md={12} xs={12} sm={12}>
                <Card>
                  <CardBody>
                    <div className='p-6'>
                      <Row>
                        <Col md={12} sm={12} xs={12} className='m-auto'>
                          <Row>
                            <Col md={6} sm={6}>
                              <FormGroup className='mb-2'>
                                <Label htmlFor='name'>First Name *</Label>
                                <Input
                                  type='text'
                                  name='first_name'
                                  id='first_name'
                                  placeholder='First Name'
                                  value={first_name}
                                  onChange={(e) =>
                                    setFirst_name(e.target.value)
                                  }
                                />
                                {error?.first_name ? (
                                  <div className='text-danger mt-1'>
                                    {error?.first_name}
                                  </div>
                                ) : null}
                              </FormGroup>
                            </Col>
                            <Col md={6} sm={6}>
                              <FormGroup className='mb-2'>
                                <Label htmlFor='name'>Last Name *</Label>
                                <Input
                                  type='text'
                                  name='last_name'
                                  id='last_name'
                                  placeholder='Last Name'
                                  value={last_name}
                                  onChange={(e) => setLast_name(e.target.value)}
                                />
                                {error?.last_name ? (
                                  <div className='text-danger mt-1'>
                                    {error?.last_name}
                                  </div>
                                ) : null}
                              </FormGroup>
                            </Col>
                            <Col md={6} sm={6}>
                              <FormGroup className='mb-2'>
                                <Label htmlFor='email'>Email *</Label>
                                <Input
                                  type='email'
                                  name='email'
                                  id='email'
                                  placeholder='Email'
                                  value={email}
                                  onChange={(e) => setEmail(e.target.value)}
                                />
                                {error?.email ? (
                                  <div className='text-danger mt-1'>
                                    {error?.email}
                                  </div>
                                ) : null}
                              </FormGroup>
                            </Col>
                            <Col md={6} sm={6}>
                              <FormGroup className='mb-2'>
                                <Label htmlFor='email'>Phone *</Label>
                                <InputMask
                                  placeholder='000-000-0000'
                                  mask='999-999-9999'
                                  className='form-control'
                                  value={phone}
                                  onChange={(e) => setPhone(e.target.value)}
                                />
                                {/* <Input
                                  type='text'
                                  name='phone'
                                  id='phone'
                                  placeholder='Phone (000) 000-0000 *'
                                  value={phone}
                                  onChange={(e) => setPhone(e.target.value)}
                                /> */}
                                {error?.phone ? (
                                  <div className='text-danger mt-1'>
                                    {error?.phone}
                                  </div>
                                ) : null}
                              </FormGroup>
                            </Col>
                            <Col md={12} sm={12}>
                              <FormGroup className='my-3'>
                                <Label
                                  htmlFor='Description'
                                  className='d-block'
                                >
                                  Message *
                                </Label>
                                <Input
                                  id='Description'
                                  name='Description'
                                  placeholder='Message'
                                  type='textarea'
                                  rows='4'
                                  value={message}
                                  onChange={(e) => setMessage(e.target.value)}
                                />
                                <div className='text-muted'>{`(Note: Do not use <, >, ', ")`}</div>
                                {error?.message ? (
                                  <div className='text-danger mt-1'>
                                    {error?.message}
                                  </div>
                                ) : null}
                              </FormGroup>
                            </Col>
                            <Col md='3' sm='6' xs={'12'} className=' mt-4 mb-4'>
                              <Button
                                type='submit'
                                name='btnSubmit'
                                className='w-lg waves-effect waves-light btn btn-success'
                                title='Submit'
                                id='submit-btn'
                                onClick={onSubmit}
                              >
                                SUBMIT
                              </Button>
                            </Col>
                          </Row>
                        </Col>
                        <GRecaptcha setGRC={setGRC} />
                      </Row>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default Contact
