export const nav = [
  {
    name: 'Rabbits & Cavies',
    url: '/easyfides',
  },
  {
    name: 'Equipment & Services',
    url: '/easyfides/equipment',
  },
  {
    name: 'Registrars & Judges',
    url: '/easyfides/judges',
  },
  {
    name: 'Contact Us',
    url: '/easyfides/contact',
  },
]

// Profile Menu

export const profileMenu = [
  {
    name: 'My Profile',
    url: '/easyfides/profile',
    icon: <i className='fa fa-user-circle me-2'></i>,
    abbr: 'my-profile',
    show_menu: true,
    always_show: true,
  },
  {
    name: 'My Animal Listings',
    url: '/easyfides/profile/my_animal',
    icon: <i className='fa fa-list me-2'></i>,
    abbr: 'my_animal',
    show_menu: true,
    always_show: false,
    menu_abbr: 'm_animal_listing',
  },
  {
    name: 'My Animal Listings Create',
    url: '/easyfides/profile/my_animal/create',
    icon: null,
    abbr: 'my_animal_create',
    always_show: true,
    show_menu: false,
  },
  {
    name: 'My Animal Listings Update',
    url: '/easyfides/profile/my_animal/update/:ID',
    icon: null,
    abbr: 'my_animal_update',
    always_show: true,
    show_menu: false,
  },
  {
    name: 'My Animal Listings Image',
    url: '/easyfides/profile/my_animal/animal_image/:ID',
    icon: null,
    abbr: 'my_animal_image',
    always_show: true,
    show_menu: false,
  },
  {
    name: 'Inquiry',
    url: '/easyfides/profile/my_animal/inquiry',
    icon: null,
    abbr: 'my_animal_inquiry',
    always_show: true,
    show_menu: false,
  },
  {
    name: 'Service as a Judge or Registrar',
    url: '/easyfides/profile/judge',
    icon: <i className='fa fa-user me-2'></i>,
    abbr: 'judge-registrar',
    show_menu: true,
    always_show: false,
    menu_abbr: 'm_service_judge',
  },
  {
    name: 'Service as a Judge or Registrar Create',
    url: '/easyfides/profile/judge/create',
    icon: null,
    abbr: 'judge_create',
    show_menu: false,
    always_show: true,
  },
  {
    name: 'Service as a Judge or Registrar Update',
    url: '/easyfides/profile/judge/update/:ID',
    icon: null,
    abbr: 'judge_update',
    show_menu: false,
    always_show: true,
  },
  {
    name: 'Judge Inquiry',
    url: '/easyfides/profile/judge/inquiry',
    icon: null,
    abbr: 'judge_inquiry',
    show_menu: false,
    always_show: true,
  },
  {
    name: 'Selling new or used equipment (Non Vendor)',
    url: '/easyfides/profile/equipments',
    icon: <i className='fa fa-credit-card me-2'></i>,
    abbr: 'equipment',
    show_menu: true,
    always_show: false,
    menu_abbr: 'm_selling_equipments',
  },
  {
    name: 'Selling new or used equipment (Non Vendor) Create',
    url: '/easyfides/profile/equipments/create',
    icon: null,
    abbr: 'equipment_create',
    show_menu: false,
    always_show: true,
  },
  {
    name: 'Equipment List',
    url: '/easyfides/profile/equipments/inquiry',
    icon: null,
    abbr: 'equipments_inquiry',
    show_menu: false,
    always_show: true,
  },
  {
    name: 'Selling new or used equipment (Non Vendor) Update',
    url: '/easyfides/profile/equipments/update/:ID',
    icon: null,
    abbr: 'equipment_update',
    show_menu: false,
    always_show: true,
  },
  {
    name: 'Equipment Image',
    url: '/easyfides/profile/equipments/equipment_image/:ID',
    icon: null,
    abbr: 'equipment_image',
    show_menu: false,
    always_show: true,
  },
  // {
  //   name: 'Inquiry',
  //   url: '/easyfides/profile/inquiry',
  //   icon: <i className='fa fa-envelope me-2'></i>,
  //   abbr: 'inquiry',
  //   show_menu: true,
  //   always_show: true,
  // },
]
