import { put, takeEvery } from 'redux-saga/effects'

import {
  ANCESTRAL_ARCHIVE_LIST,
  ANCESTRAL_ARCHIVE_DETAIL,
  ANCESTRAL_ARCHIVE_UPDATE,
  ANCESTRAL_ARCHIVE_DETAIL_SUCCESS,
  ANCESTRAL_ARCHIVE_LIST_SUCCESS,
} from './actionTypes'
import { toast } from 'react-toastify'
import { PATCH, post } from '../../../helpers/api_helper'
import { paginationData } from '../Pagination/action'

function* updateRabbitSagas({ payload }) {
  try {
    const res = yield PATCH(
      `/api/v1/herd/pedigree/update-rabbit/${payload.data.params.rabbit_id}`,
      payload.data.data
    )

    if (payload.callback) payload.callback(null, res)
  } catch (err) {
    if (err?.response?.data) {
      toast.error(err?.response?.data)
    }
    if (payload.callback) payload.callback(err, null)
  }
}

function* ancestorDetailSagas({ payload }) {
  try {
    const res = yield post(`/api/v1/herd/pedigree/all`, null, {
      params: payload?.params,
    })
    yield put({
      type: ANCESTRAL_ARCHIVE_DETAIL_SUCCESS,
      payload: res?.data || [],
    })
  } catch (err) {
    if (err?.response?.data) {
      toast.error(err?.response?.data)
    }
  }
}

function* ancestorListSagas({ payload }) {
  try {
    const res = yield post(
      `/api/v1/herd/pedigree/anchestrial-animals/all`,
      null,
      {
        params: payload?.params,
      }
    )
    yield put({
      type: ANCESTRAL_ARCHIVE_LIST_SUCCESS,
      payload: res?.data || [],
    })
    if (Number(payload?.params?._limit || 0) > 1) {
      // Pagination Section
      const limit = payload?.params?._limit || 10
      const TotalPage = Math.ceil(res?.totalRecords / limit) || 1
      const paginationObj = {
        page: payload?.params?._page || 1,
        totalPage: TotalPage || 1,
        totalRecords: res?.totalRecords || 0,
      }
      yield put(paginationData(paginationObj))
    }
  } catch (err) {
    if (Number(payload?.params?._limit || 0) > 1) {
      const paginationObj = {
        page: 1,
        totalPage: 1,
        totalRecords: 1,
      }
      yield put(paginationData(paginationObj))
    }
    if (err?.response?.data) {
      toast.error(err?.response?.data)
    }
  }
}
function* pedigreeSagas() {
  yield takeEvery(ANCESTRAL_ARCHIVE_UPDATE, updateRabbitSagas)
  yield takeEvery(ANCESTRAL_ARCHIVE_DETAIL, ancestorDetailSagas)
  yield takeEvery(ANCESTRAL_ARCHIVE_LIST, ancestorListSagas)
}

export default pedigreeSagas
