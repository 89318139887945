import moment from 'moment'
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import {
  Button,
  Col,
  Table,
  Modal,
  ModalBody,
  ModalFooter,
  Row,
} from 'reactstrap'
import viewImg from '../../../assets/images/users/view.png'

import { getDateWithFormat } from '../../../helpers/date_helper'
import { getAllParticipatedEvent } from '../../../store/actions'
import { differenceInDays, differenceInMonths } from 'date-fns'
import ReactPaginate from 'react-paginate'
import classNames from 'classnames'
import { UrlEncodeHook } from '../../../hooks'
import { get, post } from '../../../helpers/api_helper'
import _ from 'lodash'

const LIMIT = process.env.REACT_APP_LIMIT || 10

const ParticipatedEvent = (props) => {
  const { urlEncode } = UrlEncodeHook()

  const dispatch = useDispatch()
  const [modal, setModal] = useState(false)
  const [modalData, setModalData] = useState({})
  const [eventData, setEventData] = useState(null)
  const [eventLodaing, setEventLodaing] = useState(false)
  const [reportUserList, setReportUserList] = useState([])
  const [reportLoading, setReportLoading] = useState(false)
  //  event={event}
  const { event, isSearch, interval } = props
  // const [page, setPage] = useState(1)
  const YourParticipation = useSelector((state) => state.YourParticipation)

  const { allParticipationEvent, loading, page, totalPages, totalRecords } =
    YourParticipation

  useEffect(() => {
    let pagination = {
      page: 1,
      limit: LIMIT,
    }
    let data = {
      interval: Number(interval),
    }
    if (event) {
      data = {
        ...data,
        event: Number(event),
      }
    }

    dispatch(getAllParticipatedEvent({ pagination, body: data }))
  }, [isSearch, interval])

  useEffect(() => {
    setEventLodaing(true)
    if (modal && modalData?.event_id) {
      get(
        `/api/v1/events/sponsor/all-shows-by-event?event_id=${
          modalData?.event_id || ''
        }`
      ).then((res) => {
        if (res && Array.isArray(res.data)) {
          let obj = _.first(res.data)
          setEventLodaing(false)
          setEventData(obj)
        }
        // setEvents(res.data[0])
      })
    }
  }, [modal, modalData?.event_id])
  useEffect(() => {
    if ((modal, modalData?.show_id)) {
      setReportLoading(true)
      get(`/api/v1/subuser`)
        .then((data) => {
          let user = localStorage.getItem('authUser')
            ? JSON.parse(localStorage.getItem('authUser'))
            : null

          let finalUser = []
          if (user) {
            finalUser.push(user?.id)
          }
          if (Array.isArray(data?.data) && data?.data.length) {
            let userList = data?.data.map((x) => x.id)

            finalUser = [...finalUser, ...userList]
            finalUser = _.uniq(finalUser)
          }
          let bodyObj = {
            report_abbr: '',
            report_type: 'general',
            user_id: finalUser,
          }
          post(
            `/api/v1/reports/report-generation-data?show_id=${
              modalData?.show_id || ''
            }`,

            bodyObj
          )
            .then((res) => {
              if (Array.isArray(res)) {
                let reportArr = res.filter(
                  (x) =>
                    x.report_abbr === 'e_leg' ||
                    x.report_abbr === 'official_exh'
                )

                let reportList = _.chain(reportArr)
                  .groupBy('user_id')
                  .map((value, key) => {
                    let user_name = value.find((x) => x.user_id === key)
                    return {
                      user_id: key,
                      user_full_name: user_name?.user_full_name || '',
                      reportList: _.orderBy(value, ['report_abbr'], ['asc']),
                    }
                  })
                  .value()
                reportList = _.orderBy(reportList, ['user_full_name'], ['asc'])
                setReportUserList(reportList)

                setReportLoading(false)
              } else {
                setReportUserList([])
                setReportLoading(false)
              }
            })
            .catch((err) => {
              console.log(err)
              setReportUserList([])
              setReportLoading(false)
            })

          // else {
          //   setReportLoading(false)
          //   setReportUserList([])
          //   setReportLoading(false)
          // }
        })
        .catch((error) => {
          console.log(error)
          setReportUserList([])
          setReportLoading(false)
        })
    }
  }, [modal, modalData?.show_id])

  const paginationClick = (p) => {
    let nextPage = p.selected + 1
    let pagination = {
      page: nextPage,
      limit: LIMIT,
    }
    let data = {
      interval: Number(interval),
    }
    if (event) {
      data = {
        ...data,
        event: Number(event),
      }
    }
    dispatch(getAllParticipatedEvent({ pagination, body: data }))
  }

  const showModal = (participate) => {
    setModal(!modal)

    setModalData(participate)
  }
  let diffDay = 0
  if (eventData) {
    diffDay = differenceInDays(
      new Date(eventData.end_date_time),
      new Date(eventData.start_date_time)
    )
  }
  const showDetail = useMemo(() => {
    let show = {}
    if (eventData) {
      show = eventData.show_list.find((s) => {
        return s.show_id === Number(modalData.show_id)
      })
    }
    return show
  }, [eventData, modalData.showId])

  const postFixShow = `${
    showDetail?.show_category === 'speciality' ? '/ SP' : ''
  }`
  return (
    <div>
      <Col lg={12}>
        <div className='table-responsive text-center mt-3'>
          <Table className='table cust-tab'>
            <thead className='table-color'>
              <tr>
                <th>Event Date</th>
                <th>Event Name</th>
                <th>Show Type</th>
                <th>Show Name</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {!loading && allParticipationEvent.length ? (
                allParticipationEvent.map((participate, index) => {
                  let diffDay = differenceInDays(
                    new Date(participate.end_date_time),
                    new Date(participate.start_date_time)
                  )
                  const postFixShow = `${
                    participate.show_category === 'speciality' ? '/ SP' : ''
                  }`

                  return (
                    <tr key={index}>
                      <td>
                        {/* {moment(getDateWithFormat(participate.start_date_time))} */}
                        {diffDay <= 1 ? (
                          moment(
                            getDateWithFormat(participate.start_date_time)
                          ).format('MMM Do YYYY')
                        ) : (
                          <>
                            {moment(
                              getDateWithFormat(participate.start_date_time)
                            ).format('MMM Do YYYY')}
                            -
                            <br />
                            {moment(
                              getDateWithFormat(participate.end_date_time)
                            ).format('MMM Do YYYY')}
                          </>
                        )}
                      </td>
                      <td>
                        <span
                          className={classNames({
                            'text-danger': participate.event_is_deactivated,
                          })}
                        >
                          {participate.event_name}{' '}
                          {participate.event_is_deactivated
                            ? '- (event deleted)'
                            : null}
                        </span>
                      </td>
                      <td className='text-capitalize'>
                        <span
                          className={classNames({
                            'text-danger': participate.show_is_deactivated,
                          })}
                        >
                          {participate.show_type}
                          {participate.show_is_deactivated
                            ? '- (show deleted)'
                            : null}
                        </span>
                      </td>
                      <td>
                        <span
                          className={classNames({
                            'text-danger': participate.show_is_deactivated,
                          })}
                        >
                          {participate.show_char}({' '}
                          {moment(
                            getDateWithFormat(participate.show_date)
                          ).format('MMM Do YYYY')}
                          ) {postFixShow}
                          {participate.show_is_deactivated
                            ? '- (show deleted)'
                            : null}
                        </span>
                      </td>
                      <td>
                        {/* {} */}
                        {participate?.plan_type_event === 'traditional' ||
                        participate.event_is_deactivated ||
                        participate.show_is_deactivated ? (
                          '-'
                        ) : (
                          <React.Fragment>
                            <Link
                              to={`/your_participation/${urlEncode(
                                participate.event_id
                              )}/${urlEncode(participate.show_id)}`}
                            >
                              {' '}
                              <img
                                src={viewImg}
                                alt='View'
                                className='p-r-3 mb-2'
                                title='View Details'
                              />
                            </Link>
                            {Boolean(participate?.start_date_time) ? (
                              <Button
                                className='pbtn-view mb-2'
                                onClick={() => showModal(participate)}
                                title='Report'
                              >
                                REPORT
                              </Button>
                            ) : null}
                          </React.Fragment>
                        )}

                        {/* <Link to="/">
                          {' '}
                          <img
                            src={reportImg}
                            alt="View"
                            className="p-r-3"
                            title="Reports"
                          />
                        </Link> */}
                      </td>
                    </tr>
                  )
                })
              ) : loading ? (
                <tr>
                  <td colSpan={10} className='text-center'>
                    Loading...
                  </td>
                </tr>
              ) : (
                <tr>
                  <td colSpan={10}>No participate found</td>
                </tr>
              )}
            </tbody>
          </Table>
          <Modal
            isOpen={modal}
            toggle={showModal}
            className='bg-white'
            size={'xl'}
          >
            <ModalBody>
              <Row>
                <Col lg={12}>
                  <div className='table-responsive text-center'>
                    <Table className='table mb-0 cust-tab'>
                      <thead className='table-color'>
                        <tr>
                          <th>Event Date</th>
                          <th>Event Name</th>
                          <th>Show Type</th>
                          <th>Show Name</th>
                        </tr>
                      </thead>
                      <tbody>
                        {!eventLodaing && eventData ? (
                          <tr>
                            <td>
                              {diffDay <= 1 ? (
                                moment(
                                  getDateWithFormat(eventData?.start_date_time)
                                ).format('MMM Do YYYY')
                              ) : (
                                <>
                                  {moment(
                                    getDateWithFormat(
                                      eventData?.start_date_time
                                    )
                                  ).format('MMM Do YYYY')}
                                  -
                                  <br />
                                  {moment(
                                    getDateWithFormat(eventData?.end_date_time)
                                  ).format('MMM Do YYYY')}
                                </>
                              )}
                            </td>
                            <td>{eventData?.event_name || '-'}</td>
                            <td className='text-capitalize'>
                              {showDetail?.show_type || '-'}
                            </td>
                            <td>
                              {showDetail?.show_char || '-'} {postFixShow}
                            </td>
                          </tr>
                        ) : !eventLodaing && !eventData ? (
                          <tr>
                            <td colSpan={10}>Please Connect Admin</td>
                          </tr>
                        ) : (
                          <tr>
                            <td colSpan={10} className='text-center'>
                              Loading...
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </div>
                </Col>
              </Row>
              {/* Report By user */}
              <Row>
                <Col lg={12}>
                  <div className='table-responsive text-center'>
                    <Table className='table mb-0 cust-tab'>
                      <thead className='table-color'>
                        <tr>
                          <th>Name</th>
                          <th>Report</th>
                        </tr>
                      </thead>
                      <tbody>
                        {!reportLoading &&
                        Array.isArray(reportUserList) &&
                        reportUserList.length ? (
                          reportUserList.map((user, index) => {
                            const differenceBetweenMonths = differenceInMonths(
                              new Date(
                                new Date().setDate(new Date().getDate() - 1)
                              ),
                              new Date(
                                moment(
                                  getDateWithFormat(showDetail?.show_date || '')
                                ).format('YYYY-MM-DD')
                              )
                            )

                            return (
                              <tr key={index}>
                                <td>
                                  {user?.user_full_name || 'User Deleted'}
                                </td>
                                <td>
                                  {Array.isArray(user.reportList) &&
                                  user.reportList.length
                                    ? user.reportList.map((report) => {
                                        return (
                                          <div>
                                            {report?.file_link &&
                                            differenceBetweenMonths < 12 ? (
                                              <>
                                                <Button
                                                  className='btn btn-info my-2 px-2 py-2'
                                                  onClick={() => {
                                                    if (report?.file_link) {
                                                      window.open(
                                                        report?.file_link,
                                                        '_blank'
                                                      )
                                                    }
                                                  }}
                                                >
                                                  View -
                                                  {report.report_abbr ===
                                                  'e_leg'
                                                    ? '( E-Leg Report )'
                                                    : null}
                                                  {report.report_abbr ===
                                                  'official_exh'
                                                    ? '( Official Exhibitor Show Report )'
                                                    : null}
                                                </Button>
                                              </>
                                            ) : (
                                              '-'
                                            )}
                                          </div>
                                        )
                                      })
                                    : '-'}
                                </td>
                              </tr>
                            )
                          })
                        ) : !reportLoading && !reportUserList.length ? (
                          <tr>
                            <td colSpan={10}>No user report found</td>
                          </tr>
                        ) : (
                          <tr>
                            <td colSpan={10} className='text-center'>
                              Loading...
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </div>
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter>
              <Button
                color='danger'
                classname='fs-16px'
                onClick={showModal}
                title='Close'
              >
                CLOSE
              </Button>
            </ModalFooter>
          </Modal>
        </div>
      </Col>
      {totalRecords > LIMIT && (
        <Col lg={12}>
          <div data-test='datatable-pagination sponsor-page'>
            <div className='dataTables_paginate'>
              <ReactPaginate
                breakLabel='...'
                breakClassName='page-item'
                breakLinkClassName='page-link'
                nextLabel='Next'
                pageCount={totalPages}
                pageRangeDisplayed={4}
                marginPagesDisplayed={1}
                previousLabel='Previous'
                renderOnZeroPageCount={null}
                containerClassName='pagination mt-6 mb-2 justify-content-center'
                pageLinkClassName='page-link page-link'
                pageClassName='page-item'
                activeClassName='active'
                nextLinkClassName='page-link'
                previousClassName='page-item'
                previousLinkClassName='page-link'
                nextClassName='page-item'
                onPageChange={paginationClick}
                forcePage={page - 1}
              />
            </div>
          </div>
        </Col>
      )}
    </div>
  )
}

export default ParticipatedEvent