import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { get } from '../../helpers/api_helper'
const Deadline = (props) => {
  const { eventId } = props
  const [deadlineData, setDeadlineData] = useState([])

  useEffect(() => {
    get(`api/v1/events/${eventId}/deadline`)
      .then((res) => {
        setDeadlineData(res.data)
      })
      .catch((error) => {
        console.log(error)
      })
  }, [eventId])

  const deadLineType = () => {
    let deadlineObj = {
      deadlineType: deadlineData.length,
      first_deadline: false,
      second_deadline: false,
      third_deadline: false,
    }
    // if (deadlineType === 1) {
    // if (deadLineTypes === 3) {
    deadlineData.forEach((date, i) => {
      if (i === 0 && date.deadline_status === 'expired') {
        deadlineObj = {
          ...deadlineObj,
          first_deadline: true,
        }
      }
      if (i === 1 && date.deadline_status === 'expired') {
        deadlineObj = {
          ...deadlineObj,
          second_deadline: true,
        }
      }
      if (i === 2 && date.deadline_status === 'expired') {
        deadlineObj = {
          ...deadlineObj,
          third_deadline: true,
        }
      }
    })
    // }
    // }

    return deadlineObj
  }

  return { deadLineType }
}
Deadline.prototype = {
  deadlineType: PropTypes.number.isRequired,
}
export default Deadline
