import React, { useEffect, useState } from 'react'
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
} from 'reactstrap'

import ImageGallery from 'react-image-gallery'
import 'react-image-gallery/styles/css/image-gallery.css'
import { Link,  useParams } from 'react-router-dom'
import { UrlEncodeHook } from '../../hooks'
import { get } from '../../helpers/api_helper'
import _ from 'lodash'

const EquipmentReadMore = () => {
  const { urlDecode, urlEncode } = UrlEncodeHook()
  let { ID } = useParams()
  ID = urlDecode(ID)
  const [detail, setdetail] = useState({})
  const [Images, setImages] = useState([])
  const [document, setDocument] = useState([])
 
  useEffect(() => {
    if (ID) {
      get(
        `/api/v1/easyfied/equipment-product/one/${ID}?with_owner_details=1`
      ).then((res) => {
        let finalData = res.data
        setdetail(finalData)
      })
      get(
        `/api/v1/easyfied/equipment-product-doc/all?equipment_product_id=${ID}&doc_type=img`
      ).then((res) => {
        let finalData = res.data
        finalData = _.orderBy(finalData, 'display_order')
        finalData = Array.isArray(finalData)
          ? finalData.map((x) => ({
              original: x.document_file,
              thumbnail: x.document_file,
              description: x.document_title,
            }))
          : []
        setImages(finalData)
      })
      get(
        `/api/v1/easyfied/equipment-product-doc/all?equipment_product_id=${ID}&doc_type=doc`
      ).then((res) => {
        let finalData = res.data
        finalData = _.orderBy(finalData, 'display_order')

        setDocument(finalData)
      })
    }
  }, [ID])
  return (
    <section className='section bg-gray-101 pages event-list mt-12'>
      <Container fluid>
        <div class='row mb-6'>
          <div class='col-md-8 col-lg-8 col-xs-8 text-center'>
            <h2 className='top-title'>
              <span>Equipment & Services</span>
            </h2>
          </div>
          <div class='col-md-4 col-lg-4 col-xs-4 text-end '>
            {/* <h2 className='top-title'> */}
            <Link to='/easyfides/equipment'>
              <Button>Back</Button>
            </Link>
            {/* </h2> */}
          </div>
        </div>

        <Col xl={12} className='tab-structure res-tab-str'>
          <div className='mb-5 equi-card'>
            <Card>
              <CardHeader className='res-card-header'>
                <Row>
                  <Col md='9' sm='9' xs={'12'} className='text-left'>
                    <h4>
                      {' '}
                      {detail?.category_name || ''} -{' '}
                      {detail?.product_name || ''}
                    </h4>
                  </Col>
                  <Col md='3' sm='3' xs={'12'} className='text-right'>
                    <h4>Price : ${detail?.amount}</h4>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <div className='col-md-12 euip-box'>
                  <Row>
                  {Array.isArray(Images) && Images.length ? (
                    <Col
                      md='6'
                      sm='12'
                      xs={'12'}
                      className=' mt-3 mb-5 text-center mx-image-auto'
                    >
                      {' '}
                        <ImageGallery items={Images} />
                    </Col>
                     ) : null}
                    <Col md='12' sm='12' xs={'12'} className='mb-4 euip-box'>
                      <h4 className='mb-4'>{detail?.business_name || ''}</h4>
                      <p>{detail?.text_description || ''}</p>
                      <Col md='12' sm='12' xs={'12'} className='mb-4 euip-box'>
                        {Array.isArray(document) && document.length ? (
                          <>
                            <hr />
                            <h4 className='mb-2'>Document List </h4> 
                          </>
                        ) : null}

                        {Array.isArray(document) && document.length
                          ? document.map((doc) => {
                              return (
                                <h5>
                                  <a
                                    href={doc.document_file}
                                    alt={doc.document_title}
                                    target='_blank'
                                    rel='noreferrer'
                                  >
                                    {' '}
                                    {doc.document_title}{' '}
                                  </a>
                                </h5>
                              )
                            })
                          : null}
                      </Col>

                      <p className='mb-0 mt-5 text-center social-btn'>
                        <Link
                          to={`/easyfides/equipment/contact/${urlEncode(ID)}`}
                        >
                          <Button
                            size='md'
                            color='secondary'
                            title='Send Us A Message'
                            className='mb-3'
                          >
                            SEND US A MESSAGE
                          </Button>
                        </Link>
                        {detail?.social_link ? (
                          <Button
                            size='md'
                            color='secondary'
                            title='Social Media Links / URLs'
                            className='sponsor-read-more mb-3'
                            onClick={() =>
                              window.open(detail?.social_link, '_blank')
                            }
                          >
                            LINKS / URL
                          </Button>
                        ) : null}
                      </p>
                    </Col>
                  </Row>
                </div>
              </CardBody>
            </Card>
          </div>
        </Col>
      </Container>
    </section>
  )
}

export default EquipmentReadMore
