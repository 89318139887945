import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { Col, Spinner, Table } from 'reactstrap'
import moment from 'moment'
import { getDateWithFormat } from '../../../helpers/date_helper'

import { useParams } from 'react-router-dom'
import { differenceInDays } from 'date-fns'
import _ from 'lodash'
import { get } from '../../../helpers/api_helper'
import { UrlEncodeHook } from '../../../hooks'

const EventDetail = (props) => {
  const { event, loading } = props
  let { showId } = useParams()
  const { urlDecode } = UrlEncodeHook()
  showId = urlDecode(showId)

  let diffDay = 0
  if (event) {
    diffDay = differenceInDays(
      new Date(event.end_date_time),
      new Date(event.start_date_time)
    )
  }
  const showDetail = useMemo(() => {
    let show = {}
    if (event) {
      show = event.show_list.find((s) => {
        return s.show_id === Number(showId)
      })
    }
    return show
  }, [event])

  const postFixShow = `${
    showDetail.show_category === 'speciality' ? '/ SP' : ''
  }`

  return (
    <Col lg={12}>
      <div className="table-responsive text-center">
        <Table className="table mb-0 cust-tab">
          <thead className="table-color">
            <tr>
              <th>Event Date</th>
              <th>Event Name</th>
              <th>Show Type</th>
              <th>Show Name</th>
            </tr>
          </thead>
          <tbody>
            {!loading && event ? (
              <tr>
                <td>
                  {diffDay <= 1 ? (
                    moment(getDateWithFormat(event?.start_date_time)).format(
                      'MMM Do YYYY'
                    )
                  ) : (
                    <>
                      {moment(getDateWithFormat(event?.start_date_time)).format(
                        'MMM Do YYYY'
                      )}
                      -
                      <br />
                      {moment(getDateWithFormat(event?.end_date_time)).format(
                        'MMM Do YYYY'
                      )}
                    </>
                  )}
                </td>
                <td>{event?.event_name || '-'}</td>
                <td className="text-capitalize">
                  {showDetail?.show_type || '-'}
                </td>
                <td>
                  {showDetail?.show_char || '-'} {postFixShow}
                </td>
              </tr>
            ) : !loading && !event ? (
              <tr>
                <td colSpan={10}>Please Connect Admin</td>
              </tr>
            ) : (
              <tr>
                <td colSpan={10} className="text-center">
                  Loading...
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
    </Col>
  )
}

EventDetail.propTypes = {}

export default EventDetail
