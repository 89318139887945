import React, { useEffect } from 'react'

const Index = (props) => {
  // close the window
  // useEffect(() => {
  //   window.close()
  // }, [])
  return (
    <div
      className='d-flex justify-content-center align-items-center'
      style={{ height: '100vh' }}>
      {' '}
      <h2>Only one tab is allowed at a time</h2>
    </div>
  )
}

Index.propTypes = {}

export default Index
