import {
  SHOW_CLOSE_PEDIGREE_MODAL,
  BREED_LIST,
  BREED_HIERARCHY_DETAIL,
  ADD_RABBIT,
  GET_ONE_PEDIGREE_DETAIL,
  UPDATE_PEDIGREE_DETAIL,
  BREED_HIERARCHY_RESET,
  UPDATE_RABBIT,
  PEDIGREE_LIST,
  RABBIT_LIST,
  DELETE_PEDIGREE,
  GENERATE_PEDIGREE_PDF,
} from './actionTypes'

export const showAndClosePedigreeModal = (data) => {
  return {
    type: SHOW_CLOSE_PEDIGREE_MODAL,
    payload: data,
  }
}

export const getAllBreedList = (data) => {
  return {
    type: BREED_LIST,
    payload: data,
  }
}
export const getBreedHierarchy = (data) => {
  return {
    type: BREED_HIERARCHY_DETAIL,
    payload: data,
  }
}
export const resetBreedHierarchy = (data) => {
  return {
    type: BREED_HIERARCHY_RESET,
    payload: data,
  }
}

export const addRabbit = (data, callback) => {
  return {
    type: ADD_RABBIT,
    payload: { data, callback },
  }
}
export const updateRabbit = (data, callback) => {
  return {
    type: UPDATE_RABBIT,
    payload: { data, callback },
  }
}

export const getOnePedigreeDetail = (data, callback) => {
  return {
    type: GET_ONE_PEDIGREE_DETAIL,
    payload: { data, callback },
  }
}

export const updatePedigreeUpdate = (data, callback) => {
  return {
    type: UPDATE_PEDIGREE_DETAIL,
    payload: { data, callback },
  }
}

export const pedigreeList = (data) => {
  return {
    type: PEDIGREE_LIST,
    payload: data,
  }
}
export const rabbitList = (data) => {
  return {
    type: RABBIT_LIST,
    payload: data,
  }
}

export const deletePedigree = (data, callback) => {
  return {
    type: DELETE_PEDIGREE,
    payload: { data, callback },
  }
}

export const pedigreePdf = (data, callback) => {
  return {
    type: GENERATE_PEDIGREE_PDF,
    payload: { data, callback },
  }
}
