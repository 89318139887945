import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { Table } from 'react-super-responsive-table'
import { toast } from 'react-toastify'
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  InputGroup,
  Row,
} from 'reactstrap'
import { POST } from '../../helpers/api_helper'
import { getDate, getTime } from '../../helpers/date_helper'
import { fixed, hasContinueBTN, totalPrice } from '../../helpers/utils'
import {
  aditionalDecrement,
  aditionalIncrement,
  getAllAdditionalProduct,
} from '../../store/actions'
import _ from 'lodash'

// import { saveAdditionalProduct } from '../../store/additionalProducts/saga';

const AdditionalProduct = (props) => {
  const { events, hasShoppingCart } =
    (props.location && props.location.state) || {}
  const location = useLocation()
  const query = new URLSearchParams(location.search)

  const skip = query.get('skip')
  // let  continues = false
  const dispatch = useDispatch()
  const history = useHistory()
  const product = useSelector((state) => state.additional_product)
  const [continues, setContinues] = useState(false)
  const { additional_products, loading } = product

  let event = JSON.parse(localStorage.getItem('events'))
  let user = JSON.parse(localStorage.getItem('authUser'))
  var deadlineArray = event?.deadlines
  let deadline = _.first(deadlineArray)

  useEffect(() => {
    dispatch(getAllAdditionalProduct(event?.event_id))
  }, [])
  const saveProduct = () => {
    let product = additional_products.map((product) => {
      return {
        in_cart: 1,
        user_id: user.id,
        unit_price: product.amount,
        units: product.unit,
        event_id: product.event_id,
        product_id: product.product_id,
      }
    })
    product = product.filter((item) => item.units > 0)
    setContinues(true)
    POST(`api/v1/additional-product`, product)
      .then((res) => {
        toast.success(res.data, { autoClose: 1500 })
        setTimeout(() => {
          history.push({
            pathname: '/shopping_cart',
            state: {
              events,
              hasShoppingCart: true,
              showAdditional: true,
            },
          })
        }, 2000)
      })
      .catch((err) => {
        setContinues(false)
        let { status } = err.response
        if (status === 409) {
          // toast.error('You can only add one product in add to cart')
          toast.error(
            'Something is not right! either you have a pending shopping cart to process or the event you wish to participate may be restricted by the event secretary. If you have any questions please get in touch with the event secretary.'
          )
          setTimeout(() => {
            history.push({
              pathname: `/shopping_cart`,
              state: {
                hasAdditionalProduct: true,
                hasShoppingCart: true,
                showAdditional: true,
              },
            })
          }, 1500)
        }
      })
  }
  let count = 0

  let price = totalPrice(additional_products)
  let isDisabled = hasContinueBTN(additional_products)
  let displayEnd = true
  if (getDate(event?.start_date_time) === getDate(event?.end_date_time)) {
    displayEnd = false
  }
  return (
    <React.Fragment>
      <div className="page-content bg-gray-101">
        <section className="section1 bg-gray-101 pages events-list">
          <Container fluid={true} className="auto-fit">
            <Row>
              <Col lg={12} className="text-center">
                <div className="justify-content-between">
                  <h2 className="top-title mb-4">
                    {' '}
                    <span>ADDITIONAL PRODUCTS</span>
                  </h2>
                </div>
              </Col>
            </Row>

            <Row>
              <Col lg={12} className="mb-7 event-list">
                <Card className="shadow-only-hover">
                  <CardBody className="event-card-body">
                    <Col lg={12} className="event-box">
                      <div className="row">
                        <div className="col-lg-4 col-xl-3 box-head">
                          <div className="row">
                            <div className="col-lg-5 col-xl-5 mb-4 mt-4">
                              Event Date:{' '}
                            </div>
                            <div className="col-lg-7 col-xl-7 pl-0">
                              <p className="highlight">
                                {getDate(event.start_date_time)}
                                <br />

                                {displayEnd && 'To'}
                                {displayEnd && <br />}
                                {displayEnd && getDate(event?.end_date_time)}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-5 col-xl-3 box-head mt-3">
                          {event.event_name}{' '}
                        </div>
                        <div className="col-lg-3 col-xl-3 box-head mt-3">
                          {event?.address_1}
                          {event?.address_1 ? ', ' : ''}
                          <br />
                          {event?.address_2}
                          {event?.address_2 ? ', ' : ''}
                          {event?.city}
                          {event?.city ? ', ' : ''}
                          {event?.state_name}
                          {event?.zip_code}
                          {event?.zip_code ? ', ' : ''}
                          {event?.country_name}
                        </div>
                        <div className="col-lg-6 col-xl-3 box-head">
                          <div className="row">
                            <div className="col-lg-4 col-xl-4 mt-4">
                              Deadline:
                            </div>
                            {event.deadlines?.map((item, index) => {
                              if (
                                item.deadline_status == 'upcoming' &&
                                count === 0
                              ) {
                                count++

                                return (
                                  <div className="col-lg-8 col-xl-8 view-dline mt-2">
                                    <span className="deadline">
                                      <div>
                                        {getDate(item.date_time)}
                                        <br />
                                        {`${getTime(item.date_time)} CST`}
                                      </div>
                                    </span>
                                    <div className="input-group-append">
                                      <span className="input-group-text">
                                        {index + 1}st
                                      </span>
                                    </div>
                                  </div>
                                )
                              }
                            })}
                          </div>
                        </div>
                      </div>
                    </Col>
                  </CardBody>
                </Card>
              </Col>

              <Col lg={12}>
                <div className="table-responsive text-center">
                  <Table className="table mb-0 cust-tab">
                    <thead className="table-color">
                      <tr>
                        <th>No.</th>
                        <th>Additional Product</th>
                        <th style={{ textAlign: 'right' }}>Price</th>
                        <th>Units </th>
                        <th style={{ textAlign: 'right' }}>Final Price</th>
                      </tr>
                    </thead>
                    <tbody>
                      {!loading &&
                      additional_products &&
                      additional_products.length > 0 ? (
                        additional_products.map((product, i) => {
                          let amount = product.amount
                          let totalWithUnit =
                            product?.unit && product?.amount
                              ? product.unit * product?.amount
                              : '0.00'
                          return (
                            <tr key={i}>
                              <td>{i + 1}</td>
                              <td className="break">{product.product_name}</td>
                              <td style={{ textAlign: 'right' }}>
                                ${fixed(amount, 2, 2)}
                              </td>
                              <td>
                                <Form>
                                  <InputGroup className="count-num">
                                    <div
                                      className="input-group-append"
                                      onClick={() => {
                                        dispatch(
                                          aditionalDecrement({
                                            product_id: product.product_id,
                                            unit:
                                              product.unit > 0
                                                ? product.unit - 1
                                                : 0,
                                          })
                                        )
                                      }}
                                    >
                                      <Button
                                        type="button"
                                        disabled={
                                          product?.unit > 0 ? false : true
                                        }
                                      >
                                        <i className="mdi mdi-minus" />
                                      </Button>
                                    </div>
                                    <input
                                      type="number"
                                      className="form-control add-input"
                                      value={product.unit ? product.unit : 0}
                                      placeholder="number"
                                      readOnly
                                    />
                                    <div
                                      className="input-group-append"
                                      onClick={() => {
                                        dispatch(
                                          aditionalIncrement({
                                            product_id: product.product_id,
                                            unit:
                                              product.unit > 0
                                                ? product.unit + 1
                                                : 1,
                                          })
                                        )
                                      }}
                                    >
                                      <Button type="button">
                                        <i className="mdi mdi-plus" />
                                      </Button>
                                    </div>
                                  </InputGroup>
                                </Form>
                              </td>
                              <td style={{ textAlign: 'right' }}>
                                ${fixed(totalWithUnit, 2, 2)}
                              </td>
                            </tr>
                          )
                        })
                      ) : loading ? (
                        <tr>
                          <td colSpan="6">Loading ...</td>{' '}
                        </tr>
                      ) : !additional_products ||
                        additional_products.length === 0 ? (
                        <tr>
                          <td colSpan={6}>No additional product found</td>
                        </tr>
                      ) : null}

                      <tr className="total-box">
                        <td colSpan={4}>TOTAL</td>
                        <td style={{ textAlign: 'right' }}>
                          ${fixed(price, 2, 2)}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </Col>

              <Col lg={12} className="text-center mt-5">
                {/* <Link to='/auth-events'> */}
                <button
                  type="button"
                  className="btn btn-primary btn-back w-15 res-w-f res-mar"
                  onClick={() => {
                    skip
                      ? history.push({
                          pathname: '/shopping_cart',
                          state: {
                            event,
                            hasShoppingCart: hasShoppingCart,
                            showAdditional: false,
                          },
                        })
                      : history.push({
                          pathname: '/auth-events',
                        })
                  }}
                >
                  {skip ? 'SKIP' : 'BACK'}
                </button>
                {/* </Link> */}

                <button
                  type="button"
                  className="btn btn-primary btn-easy w-15 res-w-f"
                  onClick={saveProduct}
                  disabled={
                    continues ||
                    isDisabled ||
                    deadline?.deadline_status === 'expired'
                  }
                >
                  CONTINUE
                </button>
              </Col>
            </Row>
          </Container>
        </section>
      </div>
    </React.Fragment>
  )
}

export default AdditionalProduct
