export const GET_ALL_YOUR_PARTICIPATION_EVENT_DATES =
  'GET_ALL_YOUR_PARTICIPATION_EVENT_DATES'
export const GET_ALL_YOUR_PARTICIPATION_EVENT =
  'GET_ALL_YOUR_PARTICIPATION_EVENT'
export const GET_ALL_YOUR_PARTICIPATION_EVENT_SUCCESS =
  'GET_ALL_YOUR_PARTICIPATION_EVENT_SUCCESS'
export const TOTAL_PAGE_YOUR_PARTICIPATION = 'TOTAL_PAGE_YOUR_PARTICIPATION'
export const GET_ALL_YOUR_PARTICIPATION_ADDITIONAL_PRODUCT =
  'GET_ALL_YOUR_PARTICIPATION_ADDITIONAL_PRODUCT'
export const GET_ALL_YOUR_PARTICIPATION_ADDITIONAL_PRODUCT_SUCCESS =
  'GET_ALL_YOUR_PARTICIPATION_ADDITIONAL_PRODUCT_SUCCESS'
export const GET_ALL_YOUR_PARTICIPATION_ANIMAL_LIST =
  'GET_ALL_YOUR_PARTICIPATION_ANIMAL_LIST'
export const GET_ALL_YOUR_PARTICIPATION_ANIMAL_LIST_SUCCESS =
  'GET_ALL_YOUR_PARTICIPATION_ANIMAL_LIST_SUCCESS'
export const ADD_YOUR_PARTICIPATION_ANIMAL_LIST_SUCCESS =
  'ADD_YOUR_PARTICIPATION_ANIMAL_LIST_SUCCESS'
export const ADD_YOUR_PARTICIPATION_TO_SHOPPING_CART =
  'ADD_YOUR_PARTICIPATION_TO_SHOPPING_CART'
