import { put, takeEvery } from 'redux-saga/effects'

import { ALL_STATE_LIST, ALL_STATE_LIST_SUCCESS } from './actionType'

import { toast } from 'react-toastify'
import { get } from '../../helpers/api_helper'

function* StateList({ payload }) {
  let url = `/api/v1/address/state/all?_order=asc&_sort=state_code`
  if (payload?.country_id) {
    url = `/api/v1/address/state/all?country_id=${payload?.country_id}&_order=asc&_sort=state_code`
  }

  try {
    let res = []
    res = yield get(url)
    let finalData = res?.data || []
    // if (finalData) {
    //   finalData = _.orderBy(
    //     finalData,
    //     [(_state) => _state.state_code.toLowerCase()],
    //     ['asc']
    //   )
    // }
    res = finalData

    yield put({
      type: ALL_STATE_LIST_SUCCESS,
      payload: res,
    })
  } catch (err) {
    if (err?.response?.data) {
      toast.error(err?.response?.data)
    }
  }
}
function* StatesSagas() {
  yield takeEvery(ALL_STATE_LIST, StateList)
}

export default StatesSagas
