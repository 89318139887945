import { CURRENT_PAGE } from '../sponsorEvent/actionType'
import {
  GET_ALL_YOUR_PARTICIPATION_EVENT_SUCCESS,
  TOTAL_PAGE_YOUR_PARTICIPATION,
  GET_ALL_YOUR_PARTICIPATION_ADDITIONAL_PRODUCT_SUCCESS,
  GET_ALL_YOUR_PARTICIPATION_ANIMAL_LIST_SUCCESS,
  ADD_YOUR_PARTICIPATION_ANIMAL_LIST_SUCCESS,
  ADD_YOUR_PARTICIPATION_TO_SHOPPING_CART,
} from './actionType'

const initialState = {
  error: '',
  loading: true,
  allParticipationEvent: [],
  allAdditionProduct: [],
  allUserAnimal: [],
  previewAnimal: [],
  shoppingData: {},
  totalPages: 1,
  totalRecords: 1,
  page: 1,
}

const YourParticipationReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_YOUR_PARTICIPATION_EVENT_SUCCESS:
      // console.log('action.payload', action.payload)
      return {
        ...state,
        allParticipationEvent: action?.payload?.data || [],
        totalRecords: action?.payload?.totalRecords || 1,
        loading: false,
      }
    case TOTAL_PAGE_YOUR_PARTICIPATION:
      return {
        ...state,
        totalPages: action.payload,
      }
    case GET_ALL_YOUR_PARTICIPATION_ADDITIONAL_PRODUCT_SUCCESS:
      return {
        ...state,
        allAdditionProduct: action?.payload.data || [],
        totalRecords: action?.payload?.totalRecords || 1,
      }
    case GET_ALL_YOUR_PARTICIPATION_ANIMAL_LIST_SUCCESS:
      return {
        ...state,
        allUserAnimal: action?.payload,
      }
    case ADD_YOUR_PARTICIPATION_ANIMAL_LIST_SUCCESS:
      return {
        ...state,
        previewAnimal: action?.payload,
      }
    case ADD_YOUR_PARTICIPATION_TO_SHOPPING_CART:
      return {
        ...state,
        shoppingData: action?.payload,
      }
    case CURRENT_PAGE:
      return {
        ...state,
        page: action.payload,
      }
    default:
      return state
  }
}
export default YourParticipationReducer
