import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Col, Spinner, Table } from 'reactstrap'
import { useParams } from 'react-router-dom'
import _ from 'lodash'
import { differenceInDays } from 'date-fns'
import { getDateWithFormat } from '../../../helpers/date_helper'
import moment from 'moment'
import { get } from '../../../helpers/api_helper'
import { UrlEncodeHook } from '../../../hooks'

const ParticipationEvent = (props) => {
  let { eventId, showId } = useParams()
  const { urlDecode } = UrlEncodeHook()
  eventId = urlDecode(eventId)

  const [event, setEvent] = useState()
  useEffect(() => {
    get(`/api/v1/events/sponsor/all-shows-by-event?event_id=${eventId}`).then(
      (res) => {
        if (res && Array.isArray(res.data)) {
          let obj = _.first(res.data)

          setEvent(obj)
        }
        // setEvents(res.data[0])
      }
    )
  }, [])

  let diffDay = 0
  if (event) {
    diffDay = differenceInDays(
      new Date(event.end_date_time),
      new Date(event.start_date_time)
    )
  }
  return (
    <Col lg={12}>
      <div className="table-responsive text-center">
        <Table className="table mb-0 cust-tab participate-str">
          <thead className="table-color">
            <tr>
              <th>Event Date</th>
              <th>Event Name</th>
              <th>Event Type</th>
              <th>Location</th>
            </tr>
          </thead>
          <tbody>
            {event ? (
              <tr>
                <td>
                  {diffDay <= 1 ? (
                    moment(getDateWithFormat(event.start_date_time)).format(
                      'MMM Do YYYY'
                    )
                  ) : (
                    <>
                      {moment(getDateWithFormat(event.start_date_time)).format(
                        'MMM Do YYYY'
                      )}
                      -
                      <br />
                      {moment(getDateWithFormat(event.end_date_time)).format(
                        'MMM Do YYYY'
                      )}
                    </>
                  )}
                </td>
                <td>{event.event_name}</td>
                <td className="text-capitalize">{event.event_type}</td>
                <td>
                  {event?.address_1}
                  {event?.address_1 ? ', ' : ''}
                  <br />
                  {event?.address_2}
                  {event?.address_2 ? ', ' : ''}
                  {event?.city}
                  {event?.city ? ', ' : ''}
                  {event?.state_name}
                  {event?.state_name ? ', ' : ''}
                  {event?.zip_code}
                  {event?.zip_code ? ', ' : ''}
                  {event?.country_name}
                </td>
              </tr>
            ) : (
              <tr>
                <td colSpan={10} className="text-center">
                  Loading...
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
    </Col>
  )
}

ParticipationEvent.propTypes = {}

export default ParticipationEvent
