import _ from 'lodash'
import { useCallback } from 'react'
import { useSelector } from 'react-redux'

const FinalBreedList = () => {
  const { participationList } = useSelector((state) => {
    return {
      participationList:
        state?.LiveEvent?.participationData?.participationList || [],
    }
  })
  /*******************************************************************/
  //////////////////////////{Class}///////////////////////////////////
  /******************************************************************/
  const hasClass = useCallback(
    (classes) => {
      const result = _.filter(
        _.map(classes, (c) => {
          const _class = _.find(participationList, (p) =>
            _.isMatch(p, c?.judging_data)
          )
          if (_class) {
            return {
              ...c,
            }
          }
          return null
        }),
        (o) => o
      )
      return result
    },
    [participationList]
  )

  /*******************************************************************/
  //////////////////////////{Fur List}/////////////////////////////////
  /******************************************************************/
  const hasFurList = useCallback(
    (furList) => {
      const result = _.filter(
        _.map(furList, (f) => {
          const _furList = _.find(participationList, (p) => {
            return _.isMatch(p, f?.judging_data)
          })

          if (_furList) {
            return {
              ...f,
            }
          }
          return null
        }),
        (o) => o
      )
      return result
    },
    [participationList]
  )

  /*******************************************************************/
  //////////////////////////{Fur Type List}/////////////////////////////////
  /******************************************************************/
  const hasFurTypeList = useCallback(
    (furTypeList) => {
      const result = _.filter(
        _.map(furTypeList, (f) => {
          const _furTypeList = _.find(participationList, (p) =>
            _.isMatch(p, f?.judging_data)
          )
          if (_furTypeList) {
            return {
              ...f,
              furList: hasFurList(f.furList),
            }
          }
          return null
        }),
        (o) => o
      )
      return result
    },
    [hasFurList, participationList]
  )

  /*******************************************************************/
  //////////////////////////{Variety}/////////////////////////////////
  /******************************************************************/
  const hasVariety = useCallback(
    (variety) => {
      const result = _.filter(
        _.map(variety, (v) => {
          const _variety = _.find(participationList, (p) =>
            _.isMatch(p, v?.judging_data)
          )

          if (_variety) {
            return {
              ...v,
              classList: hasClass(v.classList),
            }
          }
          return null
        }),
        (o) => o
      )

      return result
    },

    [hasClass, participationList]
  )

  /*******************************************************************/
  //////////////////////////{Group}///////////////////////////////////
  /******************************************************************/
  const hasGroup = useCallback(
    (group) => {
      const result = _.filter(
        _.map(group, (g) => {
          const _group = _.find(participationList, (p) =>
            _.isMatch(p, g?.judging_data)
          )
          if (_group) {
            if (g.has_variety) {
              return {
                ...g,
                varietyList: hasVariety(g?.varietyList),
              }
            }
            return {
              ...g,
              classList: hasClass(g.classList),
            }
          }

          return null
        }),
        (o) => o
      )
      return result
    },
    [hasClass, hasVariety, participationList]
  )
  const breed = useCallback(
    (breed) => {
      let obj = {
        ...breed,
      }
      if (breed?.breed_id && _.has(breed, 'groupList')) {
        obj = {
          ...obj,
          groupList: hasGroup(breed.groupList),
        }
      }
      if (breed?.breed_id && _.has(breed, 'varietyList')) {
        obj = {
          ...obj,
          varietyList: hasVariety(breed.varietyList),
        }
      }
      if (breed?.breed_id && _.has(breed, 'classList')) {
        obj = {
          ...obj,
          classList: hasClass(breed.classList),
        }
      }

      if (
        (_.has(breed, 'breed_id') || _.has(breed, 'fur_type_id')) &&
        _.has(breed, 'furTypeList')
      ) {
        obj = {
          ...obj,
          furTypeList: hasFurTypeList(breed.furTypeList),
        }
      }
      if (
        (_.has(breed, 'breed_id') || _.has(breed, 'fur_type_id')) &&
        _.has(breed, 'furList')
      ) {
        obj = {
          ...obj,
          furList: hasFurList(breed.furList),
        }
      }

      return obj
    },
    [hasClass, hasFurList, hasFurTypeList, hasGroup, hasVariety]
  )
  return {
    breedDetail: breed,
  }
}

export default FinalBreedList
