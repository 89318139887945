import classNames from 'classnames'
import React from 'react'
import { Link } from 'react-router-dom'
import { Card, Col, Container, Row } from 'reactstrap'
import ProfileCover from '../../easyfieds/Profile/ProfileCover/ProfileCover'
import { profileMenu } from '../../common/data/EasyFiedsNav'

const HerdManagement = () => {
  return (
    <React.Fragment>
      {/* <section id='profile' className='section1 pages '>
        <Container fluid={true} className='auto-fit'>
          <Row>
            <Col md='3'>
              <Card className='mb-4 new-card'>
                <div className='card-header text-black'>
                  <h6 className='my-2'>
                    My Account{' '}
                    <span className='pull-right'>
                      <Link
                        to=''
                        rel='noopener noreferrer'
                        className='text-green'
                      >
                        Logout
                      </Link>
                    </span>
                    <div className='clearfix'></div>
                  </h6>
                </div>
                <div className='list-group list-group-flush left-bar'></div>
                {Array.isArray(profileMenu) && profileMenu.length
                  ? profileMenu
                      .filter((q) => q.show_menu)
                      .map((menu, i) => {
                        return (
                          <Link
                            to={`${menu?.url}`}
                            className={classNames(
                              'list-group-item list-group-item-action d-flex justify-content-between py-3',
                              {
                                active: menu.active,
                              }
                            )}
                            key={i}
                          >
                            <div>
                              {menu?.icon}
                              <span>{menu?.name || ''} </span>
                            </div>
                            <div>
                              <i className='fa fa-chevron-right'></i>
                            </div>
                          </Link>
                        )
                      })
                  : null}
              </Card>
            </Col>
          </Row>
        </Container>
      </section> */}
    </React.Fragment>
  )
}

export default HerdManagement
