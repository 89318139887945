import { toast } from 'react-toastify'
import { post } from '../../helpers/api_helper'
import {
  paymentIntentSuccess,
  paymentSuccessData,
  purchasedPremierPlanListSuccess,
} from './actions'
import { put, takeEvery } from 'redux-saga/effects'
import {
  PAYMENT_CREATE_INTENT,
  PAYMENT_SUCCESS,
  PURCHASED_PREMIER_LIST,
} from './actionTypes'
import { setShowModal } from '../actions'

function* paymentIntent(data) {
  const payload = data.payload
  try {
    const res = yield post(
      '/api/v1/premier-exhibitor/create-payment-intent-plan',
      payload
    )
    yield put(paymentIntentSuccess(res))
  } catch (err) {
    if (err?.response?.data) {
      toast.error(err?.response?.data)
    }
  }
}
function* purchasedPremierList(data) {
  const { body, params } = data.payload
  try {
    const res = yield post(
      '/api/v1/premier-exhibitor/purchased-plans/multiple',
      body,
      params
    )

    let TotalPage = Math.ceil(res.totalRecords / params._limit) || 1

    const data = {
      data: res?.data || [],
      totalPages: TotalPage,
      page: params._page,
      totalRecords: res.totalRecords,
    }

    yield put(purchasedPremierPlanListSuccess(data))
  } catch (err) {
    if (err?.response?.data) {
      toast.error(err?.response?.data)
    }
  }
}
function* paymentSuccess(data) {
  const { body } = data.payload
  try {
    const res = yield post('/api/v1/premier-exhibitor/payment-success', body)

    if (res) {
      yield put(
        setShowModal({
          showModal: true,
          modalContent: res,
        })
      )
    }
  } catch (err) {
    yield put(
      setShowModal({
        showModal: true,
        modalContent: err?.response?.data
          ? err?.response?.data
          : 'Payment has been failed please connect with Admin',
      })
    )
  }
}

function* PremierMembership() {
  yield takeEvery(PAYMENT_CREATE_INTENT, paymentIntent)
  yield takeEvery(PAYMENT_SUCCESS, paymentSuccess)
  yield takeEvery(PURCHASED_PREMIER_LIST, purchasedPremierList)
}

export default PremierMembership
