import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { Col, Container, Modal, Row } from 'reactstrap'
import checkImg from '../../assets/images/users/check.png'
import { post } from '../../helpers/api_helper'

export default function PaymentProcess() {
  const location = useLocation()
  const history = useHistory()

  const query = new URLSearchParams(location.search)
  const paymentIntentId = query.get('payment_intent')
  const clientSecret = query.get('payment_intent_client_secret')

  let info = localStorage.getItem('purchaseInfo')
  if (info) info = JSON.parse(info)

  const [modal, setModal] = useState(false)

  useEffect(() => {
    window.history.forward()

    window.history.pushState(null, document.title, window.location.href)
    window.addEventListener('popstate', function (event) {
      window.history.pushState(null, document.title, window.location.href)
    })

    if (paymentIntentId && clientSecret) {
      const data = {
        payment_intent_id: paymentIntentId,
        client_secret: clientSecret,
      }
      post(`api/v1/plan-purchase/process-payment`, data)
        .then((res) => {
          setModal(true)

          setTimeout(() => {
            localStorage.removeItem('purchaseInfo')
            history.push('/login')
          }, 6000)
        })
        .catch((error) => {
          console.log(
            '🚀 ~ file: PaymentProcess.js ~ line 44 ~ useEffect ~ error',
            error.response
          )
          // toast.error('Something went wrong, please try again later. ');
          history.push('/login')
        })
    } else {
      history.push('/login')
    }
  }, [])

  return (
    <React.Fragment>
      <div className="page-content bg-gray-101 min-h-85">
        <section id="about" class="section1 bg-gray-101 pages event-list">
          <Container fluid={true} className="auto-fit">
            <Row>
              <Col lg={12} className="text-center">
                <div className="page-title-box justify-content-between">
                  <h2 className="top-title mb-4">
                    <span>PROCESS PAYMENT</span>
                  </h2>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </div>

      <Modal
        className="sucess_modal"
        isOpen={modal}
        toggle={() => {
          // contact_center_modal();
        }}
        centered={true}
      >
        <div className="modal-header">
          <h4>&nbsp;</h4>
          <button
            type="button"
            onClick={() => {
              setModal(false)
            }}
            className="close close-btn"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <Row className="mb-2 text-center pay-modal">
            <Col lg={12}>
              <h4>PAYMENT SUCCESSFUL</h4>
              <p className="m-0 ">
                <img src={checkImg} alt="sucess" className="p-r-3" />
              </p>
              <p className="m-0 ">
                Dear, {info?.first_name || ''} {info?.last_name || ''}
              </p>
              <p>
               Email has been successfully sent to your registered email.
              </p>
            </Col>
          </Row>
        </div>
      </Modal>
    </React.Fragment>
  )
}
