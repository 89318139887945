import React, { useEffect, useMemo, useState } from 'react'

import { Card, Col, Container, FormGroup, Input, Row } from 'reactstrap'
import moment from 'moment'
import BreedList from '../BreedList/BreedList'
import {
  getAllLiveEventList,
  getAllLiveEventShowList,
  resetActiveTabs,
} from '../../../../store/actions'
import { useDispatch, useSelector } from 'react-redux'
import { getDateWithFormat } from '../../../../helpers/date_helper'
import _ from 'lodash'

const PlacingList = () => {
  const dispatch = useDispatch()
  const { eventList, showList, reloadData } = useSelector((state) => ({
    eventList: state?.LiveEvent?.eventData?.eventList || [],
    eventLoading: state?.LiveEvent?.eventData?.loading,
    showList: state?.LiveEvent?.showData?.showList,
    showEventLoading: state?.LiveEvent?.showData?.loading,
    reloadData: state?.LiveEvent?.reloadData,
  }))
  const [showId, setShowId] = useState()
  const [eventId, setEventId] = useState()
  useEffect(() => {
    dispatch(getAllLiveEventList())
  }, [reloadData])

  useEffect(() => {
    if (eventId) {
      const data = {
        eventId: eventId,
      }
      dispatch(getAllLiveEventShowList(data))
    }
  }, [eventId, reloadData])

  useMemo(() => {
    if (eventId && _.size(eventList)) {
      const findEvent = _.find(eventList, (e) => e.event_id === Number(eventId))
      setEventId(findEvent?.event_id || '')
    } else {
      setEventId('')
    }
  }, [eventId, eventList])

  useMemo(() => {
    if (showId && _.size(showList)) {
      const findShow = _.find(showList, (e) => e.show_id === Number(showId))
      setShowId(findShow?.show_id || '')
    } else {
      setShowId('')
    }
  }, [showId, showList])

  return (
    <div className='page-content bg-gray-101 min-h-85'>
      <section id='about' className='section1 pages'>
        <Container fluid>
          <Row>
            <Col sm='12' md='12' lg='12'>
              <div className='page-title-box justify-content-between'>
                <h2 className='top-title mb-4'>Live Shows</h2>
              </div>
            </Col>
          </Row>
          <Col md='12'>
            <div className='text-center text-note mb-3'>
              The show results are still pending finalization, and all
              placements are subject to change. The secretary may adjust the
              placements based on any new information received.
            </div>
          </Col>

          <Card body className=''>
            <Row>
              <Col md='12'>
                <FormGroup className='mb-3 mt-3'>
                  <Input
                    type='select'
                    onChange={(e) => {
                      setEventId(e.target.value)
                      setShowId('')
                      dispatch(resetActiveTabs())
                    }}
                    className='text-capitalize'
                  >
                    <option value={''}>Select Event</option>
                    {eventList.map((event) => {
                      const date =
                        moment(
                          moment(
                            getDateWithFormat(event?.end_date_time)
                          ).format('YYYY-MM-DD')
                        ).diff(
                          moment(
                            getDateWithFormat(event?.start_date_time)
                          ).format('YYYY-MM-DD'),
                          'days'
                        ) < 1
                          ? moment(
                              getDateWithFormat(event?.start_date_time)
                            ).format('MMM Do YYYY')
                          : `${moment(
                              getDateWithFormat(event?.start_date_time)
                            ).format('MMM Do YYYY')} -
                            ${moment(
                              getDateWithFormat(event?.end_date_time)
                            ).format('MMM Do YYYY')}`
                      return (
                        <option value={event?.event_id} key={event?.event_id}>
                          {event.event_name} -{'  '}({` ${date} `})
                        </option>
                      )
                    })}
                  </Input>
                </FormGroup>
              </Col>
              {eventId ? (
                <>
                  <Col md='12'>
                    <FormGroup className='mb-3 mt-3'>
                      <Input
                        type='select'
                        className='text-capitalize form-select'
                        onChange={(e) => {
                          setShowId(e.target.value)
                          dispatch(resetActiveTabs())
                        }}
                        value={showId}
                      >
                        <option value={''}>Select Show</option>
                        {showList.map((show) => {
                          return (
                            <option value={show?.show_id} key={show?.show_id}>
                              {show.show_type} {show.show_char} (
                              {moment(
                                getDateWithFormat(show?.show_date || '')
                              ).format(' MMM Do YYYY ')}
                              )
                            </option>
                          )
                        })}
                      </Input>
                    </FormGroup>
                  </Col>
                  {!showId ? (
                    <Col md='12' className='text-center mt-2'>
                      <h5>Please select show</h5>
                    </Col>
                  ) : (
                    <BreedList eventId={eventId} showId={showId} />
                  )}
                </>
              ) : (
                <Col md='12' className='text-center mt-2'>
                  <h5>Please select event</h5>
                </Col>
              )}
            </Row>
          </Card>
        </Container>
      </section>
    </div>
  )
}

export default PlacingList
