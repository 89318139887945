import {
  ADD_MORE_MEDIA,
  ALL_MEDIA_LIST_SUCCESS,
  DELETE_MEDIA,
  DELETE_MEDIA_SUCCESS,
  LISTING_MY_ANIMALS_SUCCESS,
} from './actionType'

//
export const animalObj = {
  document_id: '',
  display_order: '',
  document_file: '',
  new_document_file: '',
  image_title: '',
}
const INIT_STATE = {
  animalList: [animalObj],
  MyAnimalList: [],
  loading: true,
  error: {},
}

const EasyFiedsAnimalReducer = (state = INIT_STATE, action) => {
  const { type, payload } = action
  switch (type) {
    case ALL_MEDIA_LIST_SUCCESS:
      return {
        ...state,
        animalList:
          Array.isArray(payload) && payload.length
            ? payload
            : [
                {
                  document_id: '',
                  display_order: '',
                  document_file: '',
                  new_document_file: '',
                  image_title: '',
                },
              ],
        loading: false,
      }
    case ADD_MORE_MEDIA:
      return {
        ...state,
        animalList: [
          ...state.animalList,
          [
            {
              document_id: '',
              display_order: '',
              document_file: '',
              new_document_file: '',
              image_title: '',
            },
          ],
        ],
        loading: false,
      }
    case LISTING_MY_ANIMALS_SUCCESS:
      return {
        ...state,
        MyAnimalList: payload,
        loading: false,
      }
    case DELETE_MEDIA_SUCCESS:
      let finalData = state.animalList.filter((q, i) => i !== payload.index)

      return {
        ...state,
        animalList: finalData.length
          ? finalData
          : [
              {
                document_id: '',
                display_order: '',
                document_file: '',
                new_document_file: '',
                image_title: '',
              },
            ],
        loading: false,
      }
    default:
      return state
  }
}

export default EasyFiedsAnimalReducer
