import 'flatpickr/dist/themes/material_blue.css'
import React, { useEffect, useState } from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import { Row, Col, Spinner } from 'reactstrap'
import { useHistory, useParams } from 'react-router-dom'
import {
  ADD_SANCTION_NUMBER_CART,
  GET_SANCTION_SHOW_EVENT,
} from '../../helpers/url_helper'
import { get, post } from '../../helpers/api_helper'
import _ from 'lodash'
import { getDate } from '../../helpers/date_helper'
import SponsorShow from './Component/SponsorShow'
import { toast } from 'react-toastify'
import $ from 'jquery'
import ShoppingCartSanction from './Component/shoppingCart'
import { useDispatch } from 'react-redux'
import { sanctionShoppingCart } from '../../store/actions'
import { UrlEncodeHook } from '../../hooks'

const SponsorSanctionView = (props) => {
  let { id } = useParams()
  const { urlDecode } = UrlEncodeHook()
  id = urlDecode(id)
  const dispatch = useDispatch()
  const history = useHistory()
  const [eventDetail, setEventDetail] = useState({})
  const [clubType, setClubType] = useState('national')
  const [loading, setLoading] = useState(true)
  const [showClubPrice, setShowClubPrice] = useState([])
  //
  useEffect(() => {
    let fun = async () => {
      const res = await get(`${GET_SANCTION_SHOW_EVENT}?event_id=${id}`)

      if (res && Array.isArray(res.data)) {
        let event = _.first(res.data)
        setEventDetail(event)
      }

      return true
    }
    fun().then((res) => {
      setLoading(false)
    })
  }, [])

  //
  useEffect(() => {
    if (clubType && eventDetail?.show_list) {
      let fun = async () => {
        let club_type = clubType
        let event_type = eventDetail.event_type

        let showIds = []
        if (Array.isArray(eventDetail?.show_list)) {
          showIds = eventDetail.show_list.map(({ show_id }) => show_id)
        }

        let showObj = {
          show_id_list: showIds,
        }

        let res = await post(
          `/api/v1/events/sponsor/costing-sponsor?club_type=${club_type}&event_type=${event_type}`,
          showObj
        )
        if (res && Array.isArray(res.data)) {
          setShowClubPrice(res.data)
        } else {
          setShowClubPrice([])
        }
      }
      fun()
    }
  }, [clubType, eventDetail.show_list])

  let displayEnd = true
  if (
    getDate(eventDetail?.start_date_time) ===
    getDate(eventDetail?.end_date_time)
  ) {
    displayEnd = false
  }
  const addTOCart = async (data) => {
    try {
      $('*').addClass('changeCursor')
      const res = await post(ADD_SANCTION_NUMBER_CART, data)
      toast.success(res.data)
      dispatch(sanctionShoppingCart())
      setTimeout(() => {
        $('*').removeClass('changeCursor')
      }, 1000)
    } catch (err) {
      toast.error(err.response.data)
      setTimeout(() => {
        $('*').removeClass('changeCursor')
      }, 1000)
    }
  }
  return (
    <React.Fragment>
      <section className="section bg-gray-101 pages event-list mt-12">
        <div className="auto-fit container-fluid">
          <div className="row mb-6">
            <div className="col-md-10 col-lg-10 col-xs-10 text-center">
              <h2 className="top-title">
                <span>SPONSOR SANCTION</span>
              </h2>
            </div>

            <div className="col-md-2 col-lg-2 col-xs-2 text-center">
              <ShoppingCartSanction />
            </div>
          </div>
          <div className="col-md-12 ">
            {loading ? (
              <div className="col-md-12 text-center ">
                <p style={{ fontSize: 24 }}>Loading...</p>
              </div>
            ) : (
              <div className="col-md-12 ">
                <div className="card shadow-only-hover">
                  <div className="card-body event-card-body">
                    <div className="col-lg-12 col-xl-12 event-box">
                      <div className="row">
                        <div className="col-lg-4 col-xl-3 box-head">
                          <div className="row">
                            <div className="col-lg-5 col-xl-5 mb-4 mt-3">
                              Event Date :
                            </div>
                            <div className="col-lg-7 col-xl-7 mb-4 pl-0">
                              <div className="highlight">
                                {getDate(eventDetail?.start_date_time)}
                                <br />

                                {displayEnd && 'To'}
                                {displayEnd && <br />}
                                {displayEnd &&
                                  getDate(eventDetail?.end_date_time)}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="col-lg-6 col-xl-6 box-head mt-3"
                          style={{ textAlign: 'center' }}
                        >
                          {eventDetail.event_name}
                        </div>

                        <div className="col-lg-3 col-xl-3 box-head">
                          <div className="mb-4 mt-2 p-0 text-center">
                            {eventDetail.address_1}
                            {eventDetail.address_1 ? ', ' : ''}
                            <br />
                            {eventDetail.address_2}
                            {eventDetail.address_2 ? ', ' : ''}
                            {eventDetail.city}
                            {eventDetail.city ? ', ' : ''}
                            {eventDetail.state_name}
                            {eventDetail.state_name ? ', ' : ''}
                            {eventDetail?.zip_code}
                            {eventDetail?.zip_code ? ', ' : ''}
                            {eventDetail?.country_name || 'USA'}
                            {/* Iona, <br /> Michigan */}
                          </div>
                        </div>
                        <Row>
                          <hr />
                          <div className="col-lg-12 col-xl-12 box-btn mt-2">
                            <div className="col-lg-12 col-xl-12 mb-3 mt-6">
                              <Row
                                onChange={(e) => setClubType(e.target.value)}
                              >
                                <Col lg={3} className="mb-3">
                                  <div className="mb-3 mt-3 agree-check">
                                    <input
                                      className="form-check-input mr-2"
                                      type="radio"
                                      name="clubType"
                                      id="national"
                                      checked={clubType === 'national'}
                                      value="national"
                                    />
                                    <label
                                      className="form-check-label p-r-3"
                                      htmlFor="national"
                                    >
                                      National Club
                                    </label>
                                  </div>
                                </Col>
                                {eventDetail.event_type === 'non-national' ? (
                                  <>
                                    <Col lg={3} className="mb-3">
                                      <div className="mb-3 mt-3 agree-check">
                                        <input
                                          className="form-check-input mr-2"
                                          type="radio"
                                          name="clubType"
                                          id="state"
                                          checked={clubType === 'state'}
                                          value="state"
                                        />
                                        <label
                                          className="form-check-label p-r-3"
                                          htmlFor="state"
                                        >
                                          State Club
                                        </label>
                                      </div>
                                    </Col>
                                    <Col lg={3} className="mb-3">
                                      <div className="mb-3 mt-3 agree-check">
                                        <input
                                          className="form-check-input mr-2"
                                          type="radio"
                                          name="clubType"
                                          id="other"
                                          value="other"
                                          checked={clubType === 'other'}
                                        />
                                        <label
                                          className="form-check-label p-r-3"
                                          htmlFor="other"
                                        >
                                          Other
                                        </label>
                                      </div>
                                    </Col>
                                  </>
                                ) : null}
                              </Row>
                            </div>
                          </div>
                        </Row>
                        {eventDetail?.show_list &&
                        eventDetail?.show_list.length > 0 ? (
                          eventDetail?.show_list
                            .filter((x) => x.show_details === 'sponsor')
                            .map((show, i) => {
                              return (
                                <React.Fragment key={i}>
                                  <SponsorShow
                                    clubType={clubType}
                                    setClubType={setClubType}
                                    show={show}
                                    showClubPrice={showClubPrice}
                                    addTOCart={addTOCart}
                                  />
                                </React.Fragment>
                              )
                            })
                        ) : (
                          <div className="col-lg-12 col-xl-12 box-btn mt-2">
                            <div className="col-lg-12 col-xl-12 mb-3 text-center no-show">
                              No show found
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
    </React.Fragment>
  )
}
export default SponsorSanctionView
