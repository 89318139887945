import { put, takeEvery } from 'redux-saga/effects'

import {
  BREED_LIST,
  BREED_LIST_SUCCESS,
  BREED_HIERARCHY_DETAIL,
  BREED_HIERARCHY_DETAIL_SUCCESS,
  ADD_RABBIT,
  GET_ONE_PEDIGREE_DETAIL,
  GET_ONE_PEDIGREE_DETAIL_SUCCESS,
  UPDATE_PEDIGREE_DETAIL,
  UPDATE_RABBIT,
  PEDIGREE_LIST,
  PEDIGREE_LIST_SUCCESS,
  RABBIT_LIST,
  RABBIT_LIST_SUCCESS,
  DELETE_PEDIGREE,
  GENERATE_PEDIGREE_PDF,
} from './actionTypes'
import { toast } from 'react-toastify'
import { del, get, PATCH, POST, post } from '../../../helpers/api_helper'
import { paginationData } from '../Pagination/action'
import _ from 'lodash'

function* getAllBreedListSagas({ payload }) {
  try {
    const res = yield get(`/api/v1/breed/all`, {
      params: payload.params,
    })
    const breedRes = yield get(`/api/v1/herd/config-user-breed/all`)

    const finalRes = _.filter(
      _.map(_.flatten(_.map(breedRes.data, (b) => b.breed_list)), (b) => {
        const findBreed = _.find(res.data, (_b) => _b.breed_id === b.breed_id)
        if (findBreed) {
          return {
            ...findBreed,
            label: findBreed.breed_name,
            value: findBreed.breed_id,
          }
        }
        return null
      }),
      (f) => f
    )
    yield put({
      type: BREED_LIST_SUCCESS,
      payload: finalRes || [],
    })
  } catch (err) {
    if (err?.response?.data) {
      toast.error(err?.response?.data)
    }
  }
}

function* getBreedHierarchySagas({ payload }) {
  try {
    const res = yield post(
      `/api/v1/breed-setup/classification/breed-hierarchy`,
      payload.body,
      {
        params: payload.params,
      }
    )
    const finalData = {
      group_list: _.map(
        _.unionBy(_.get(_.first(res?.data), 'groupList'), (g) => g.group_id),
        (o) => {
          return {
            ...o,
            label: o.group_name,
            value: o.group_id,
          }
        }
      ),
      variety_list: _.map(
        _.unionBy(
          _.get(_.first(res?.data), 'varietyList'),
          (g) => g.variety_id
        ),
        (o) => {
          return {
            ...o,
            label: o.variety_name,
            value: o.variety_id,
          }
        }
      ),
    }

    yield put({
      type: BREED_HIERARCHY_DETAIL_SUCCESS,
      payload: finalData,
    })
  } catch (err) {
    if (err?.response?.data) {
      toast.error(err?.response?.data)
    }
  }
}

function* addRabbitSagas({ payload }) {
  try {
    const res = yield POST(`/api/v1/herd/pedigree/create-rabbit`, payload.data)

    if (payload.callback) payload.callback(null, res)
  } catch (err) {
    // if (err?.response?.data) {
    //   toast.error(err?.response?.data)
    // }
    if (payload.callback) payload.callback(err, null)
  }
}
function* updateRabbitSagas({ payload }) {
  try {
    const res = yield PATCH(
      `/api/v1/herd/pedigree/update-rabbit/${payload.data.params.rabbit_id}`,
      payload.data.data
    )

    if (payload.callback) payload.callback(null, res)
  } catch (err) {
    // if (err?.response?.data) {
    //   toast.error(err?.response?.data)
    // }
    if (payload.callback) payload.callback(err, null)
  }
}

function* updatePedigreeUpdateSagas({ payload }) {
  try {
    const res = yield PATCH(
      `/api/v1/herd/pedigree/update-pedigree/${payload.data.params.id}`,
      payload.data.data
    )
    if (payload.callback) payload.callback(null, res)
  } catch (err) {
    if (err?.response?.data) {
      toast.error(err?.response?.data)
    }
    if (payload.callback) payload.callback(err, null)
  }
}

function* getOnePedigreeDetailSagas({ payload }) {
  try {
    const res = yield get(`/api/v1/herd/pedigree/one/${payload.data.params.id}`)

    yield put({
      type: GET_ONE_PEDIGREE_DETAIL_SUCCESS,
      payload: res?.data,
    })

    if (payload.callback) payload.callback(null, res)
  } catch (err) {
    if (err?.response?.data) {
      toast.error(err?.response?.data)
    }
    if (payload.callback) payload.callback(err, null)
  }
}

function* pedigreeListSagas({ payload }) {
  try {
    const res = yield post(`/api/v1/herd/pedigree/all`, payload?.data, {
      params: payload?.params,
    })
    yield put({
      type: PEDIGREE_LIST_SUCCESS,
      payload: res?.data || [],
    })
    if (Number(payload?.params?._limit || 0) > 1) {
      // Pagination Section
      const limit = payload?.params?._limit || 10
      const TotalPage = Math.ceil(res?.totalRecords / limit) || 1
      const paginationObj = {
        page: payload?.params?._page || 1,
        totalPage: TotalPage || 1,
        totalRecords: res?.totalRecords || 1,
      }
      yield put(paginationData(paginationObj))
    }
  } catch (err) {
    if (Number(payload?.params?._limit || 0) > 1) {
      const paginationObj = {
        page: 1,
        totalPage: 1,
        totalRecords: 1,
      }
      yield put(paginationData(paginationObj))
    }
    if (err?.response?.data) {
      toast.error(err?.response?.data)
    }
  }
}

function* rabbitListSagas({ payload }) {
  try {
    const res = yield post(`/api/v1/herd/pedigree/rabbit/all`, null, {
      params: payload?.params,
    })
    yield put({
      type: RABBIT_LIST_SUCCESS,
      payload: res?.data || [],
    })
    if (Number(payload?.params?._limit || 0) > 1) {
      // Pagination Section
      const limit = payload?.params?._limit || 10
      const TotalPage = Math.ceil(res?.totalRecords / limit) || 1
      const paginationObj = {
        page: payload?.params?._page || 1,
        totalPage: TotalPage || 1,
        totalRecords: res?.totalRecords || 1,
      }
      yield put(paginationData(paginationObj))
    }
  } catch (err) {
    if (Number(payload?.params?._limit || 0) > 1) {
      const paginationObj = {
        page: 1,
        totalPage: 1,
        totalRecords: 1,
      }
      yield put(paginationData(paginationObj))
    }
    if (err?.response?.data) {
      toast.error(err?.response?.data)
    }
  }
}

function* deletePedigreeSagas({ payload }) {
  try {
    const res = yield del(
      `/api/v1/herd/pedigree/delete/${payload.data.params.id}`,
      payload.data.data
    )
    if (payload.callback) payload.callback(null, res)
  } catch (err) {
    if (err?.response?.data) {
      toast.error(err?.response?.data)
    }
    if (payload.callback) payload.callback(err, null)
  }
}
function* pedigreePdfSagas({ payload }) {
  try {
    const res = yield post(
      `/api/v1/herd/pedigree/print-pdf/${payload.data.params.id}`,
      payload.data.data
    )
    if (payload.callback) payload.callback(null, res)
  } catch (err) {
    if (err?.response?.data) {
      toast.error(err?.response?.data)
    }
    if (payload.callback) payload.callback(err, null)
  }
}
function* pedigreeSagas() {
  yield takeEvery(BREED_LIST, getAllBreedListSagas)
  yield takeEvery(BREED_HIERARCHY_DETAIL, getBreedHierarchySagas)
  yield takeEvery(ADD_RABBIT, addRabbitSagas)
  yield takeEvery(GET_ONE_PEDIGREE_DETAIL, getOnePedigreeDetailSagas)
  yield takeEvery(UPDATE_PEDIGREE_DETAIL, updatePedigreeUpdateSagas)
  yield takeEvery(UPDATE_RABBIT, updateRabbitSagas)
  yield takeEvery(PEDIGREE_LIST, pedigreeListSagas)
  yield takeEvery(RABBIT_LIST, rabbitListSagas)
  yield takeEvery(DELETE_PEDIGREE, deletePedigreeSagas)
  yield takeEvery(GENERATE_PEDIGREE_PDF, pedigreePdfSagas)
}

export default pedigreeSagas
