import ReactPaginate from 'react-paginate'
import { useDispatch, useSelector } from 'react-redux'
import { pageChange } from '../../../store/actions'

const Pagination = (props) => {
  const dispatch = useDispatch()

  //
  const {
    page,
    perPage,
    totalPage,
    totalRecords,
    loading: PageLoading,
  } = useSelector((state) => state.pagination)

  // const paginationClick = (nextPage) => {
  //   const { selected } = nextPage
  //   const finalPage = (Number(selected) || 0) + 1
  //   dispatch(pageChange(finalPage))
  // }

  return !PageLoading && totalRecords > perPage ? (
    <ReactPaginate
      {...props}
      breakLabel='...'
      breakClassName='page-item'
      breakLinkClassName='page-link'
      nextLabel='>'
      pageCount={totalPage}
      pageRangeDisplayed={4}
      marginPagesDisplayed={1}
      previousLabel='<'
      renderOnZeroPageCount={null}
      containerClassName='pagination mtb-4'
      pageLinkClassName='page-link page-link'
      pageClassName='page-item'
      activeClassName='active'
      nextLinkClassName='page-link'
      previousClassName='page-item'
      previousLinkClassName='page-link'
      nextClassName='page-item'
      onPageChange={props.paginationClick}
      forcePage={page - 1}
    />
  ) : null
}

export default Pagination
