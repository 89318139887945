export const GET_ALL_PREMIER_REPORT_EVENT_LIST =
  'GET_ALL_PREMIER_REPORT_EVENT_LIST'
export const GET_ALL_PREMIER_REPORT_EVENT_LIST_SUCCESS =
  'GET_ALL_PREMIER_REPORT_EVENT_LIST_SUCCESS'
export const GET_ALL_PREMIER_REPORT_EVENT_DETAIL =
  'GET_ALL_PREMIER_REPORT_DETAIL_LIST'
export const GET_ALL_PREMIER_REPORT_EVENT_DETAIL_SUCCESS =
  'GET_ALL_PREMIER_REPORT_EVENT_DETAIL_SUCCESS'
export const GET_ALL_PREMIER_REPORT_SHOW_LIST =
  'GET_ALL_PREMIER_REPORT_SHOW_LIST'
export const GET_ALL_PREMIER_REPORT_SHOW_LIST_SUCCESS =
  'GET_ALL_PREMIER_REPORT_SHOW_LIST_SUCCESS'
export const GET_ALL_PREMIER_REPORT_BREED_LIST =
  'GET_ALL_PREMIER_REPORT_BREED_LIST'
export const GET_ALL_PREMIER_REPORT_BREED_LIST_SUCCESS =
  'GET_ALL_PREMIER_REPORT_BREED_LIST_SUCCESS'

export const GET_ALL_PREMIER_REPORT_SHOW_FINALIZED =
  'GET_ALL_PREMIER_REPORT_SHOW_FINALIZED'
export const GET_ALL_PREMIER_REPORT_SHOW_FINALIZED_SUCCESS =
  'GET_ALL_PREMIER_REPORT_SHOW_FINALIZED_SUCCESS'

export const GET_ALL_PREMIER_REPORT_SHOW_FINALIZED_STATUS =
  'GET_ALL_PREMIER_REPORT_SHOW_FINALIZED_STATUS'
export const GET_ALL_PREMIER_REPORT_SHOW_FINALIZED_STATUS_SUCCESS =
  'GET_ALL_PREMIER_REPORT_SHOW_FINALIZED_STATUS_SUCCESS'

export const GET_ALL_PREMIER_REPORT_DETAIL_BY_BREED =
  'GET_ALL_PREMIER_REPORT_DETAIL_BY_BREED'
export const GET_ALL_PREMIER_REPORT_DETAIL_BY_BREED_SUCCESS =
  'GET_ALL_PREMIER_REPORT_DETAIL_BY_BREED_SUCCESS'
