import _ from 'lodash'
import {
  ANCESTRAL_ARCHIVE_DETAIL,
  ANCESTRAL_ARCHIVE_DETAIL_SUCCESS,
  ANCESTRAL_ARCHIVE_LIST,
  ANCESTRAL_ARCHIVE_LIST_SUCCESS,
  ANCESTRAL_ARCHIVE_UPDATE,
} from './actionTypes'

const RabbitForm = {
  ear_no: '',
  animal_name: '',
  add_new_breed: false,
  breed_id: '',
  breed_name: '',
  add_new_group: false,
  group_id: '',
  group_name: '',
  add_new_variety: false,
  variety_id: '',
  variety_name: '',
  variety_seq: '',
  group_seq: '',
  weight: '',
  registration_number: '',
  date_of_birth: '',
  grand_championship: '',
  winnings: '',
  gender: '',
  text: '',
  father_id: '',
  mother_id: '',
  disabled: true,
  parents: [],
  color_name: '',
}
const initialState = {
  AncestralArchive: {
    list: [],
    loading: true,
  },
  form: RabbitForm,
  formLoading: false,
}

const AncestralArchiveReducer = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case ANCESTRAL_ARCHIVE_LIST:
      return {
        ...state,
        AncestralArchive: {
          ...state.AncestralArchive,
          loading: true,
        },
        form: RabbitForm,
      }
    case ANCESTRAL_ARCHIVE_LIST_SUCCESS:
      return {
        ...state,
        AncestralArchive: {
          ...state.AncestralArchive,
          list: payload,
          loading: false,
        },
      }
    case ANCESTRAL_ARCHIVE_DETAIL:
      return {
        ...state,
        form: _.pick(payload, Object.keys(RabbitForm)),
        formLoading: true,
      }
    // case GET_PEDIGREE_HEADER_SUCCESS:
    //   return {
    //     ...state,
    //     form: _.pick(payload, Object.keys(RabbitForm)),
    //     formLoading: false,
    //   }
    default:
      return state
  }
}
export default AncestralArchiveReducer
