import React, { useState } from 'react'

import { Modal, Row, Col } from 'reactstrap'
import { toast } from 'react-toastify'
import { get, post, put, del } from '../../../../helpers/api_helper'
import { useHistory } from 'react-router-dom'

function removeBodyCss() {
  document.body.classList.add('no_padding')
}

let experss =
  /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/g
const emailRegex = new RegExp(experss)

// const emailRegex = new RegExp('^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$')
const alphabetRegex = /^[a-zA-Z]+$/

function IsAlphaNumeric(e) {
  var val = e.target.value
  if (!val.match(/^[a-zA-Z-,*]+$/)) {
    e.preventDefault()
    return false
  } else {
    return true
  }
}
export const GetInTouchModal = (props) => {
  const history = useHistory()
  //const [modal_center, setmodal_center] = useState(false);
  const [inputs, setInputs] = useState({})
  const [errors, setErrors] = useState({
    fname: null,
    lname: null,
    email: null,
    vemail: null,
    vfname: null,
    vlname: null,
  })

  const handleChange = (event) => {
    let name = event.target.name
    let value = event.target.value
    if (name == 'phone') {
      let newvalue = value.replace(/-/gm, '') //value.split("-").join("");
      //alert(newvalue);
      value = `${newvalue.substring(0, 3)}-${newvalue.substring(
        3,
        6
      )}-${newvalue.substring(6, newvalue.length)}`
      value = value.replace(/^-|-$/g, '')
    }
    if (name == 'fname') {
      let IsAlNumeric = IsAlphaNumeric(event)
      if (IsAlNumeric === true || value == '') {
        setInputs((values) => ({
          ...values,
          [name]: value,
        }))
      }
    } else if (name == 'lname') {
      let IsAlNumeric = IsAlphaNumeric(event)
      if (IsAlNumeric === true || value == '') {
        value.substring(0, 2)
        setInputs((values) => ({
          ...values,
          [name]: value,
        }))
      }
    } else {
      setInputs((values) => ({ ...values, [name]: value }))
    }
  }
  const handleGetInTouch = (e) => {
    e.preventDefault()
    let err = {}
    err.email = !inputs.email ? true : null
    err.vemail = inputs.email && !emailRegex.test(inputs.email) ? true : null
    err.fname = !inputs.fname ? true : null
    err.lname = !inputs.lname ? true : null
    err.vfname = inputs.fname && !alphabetRegex.test(inputs.fname) ? true : null
    err.vlname = inputs.lname && !alphabetRegex.test(inputs.lname) ? true : null
    //err.message = !inputs.message ? true : null;
    setErrors(err)
    if (!Object.values(err).includes(true)) {
      document.getElementById('btn-get-in-touch').disabled = true
      post(`api/v1/events/${props.event_id}/getInTouch`, {
        first_name: inputs.fname,
        last_name: inputs.lname,
        email: inputs.email,
        message: inputs.message ? inputs.message : '',
      })
        .then((res) => {
          
          props.setmodal_center(false)
          setInputs({})
          props.setShowSuccessPopup(true)
          props.setShowAlert(true)
          if (res) {
            toast.success(res)
          }
          props.setShowBtn(false)
          props.showNotPayModal()
          setTimeout(() => {
            // document.getElementById('btn-get-in-touch').disabled = false
            props.setShowSuccessPopup(false)
            props.setShowAlert(false)
            // props.setMsg('')
            history.push('/your_participation')
          }, 1000)
        })
        .catch((error) => {
          document.getElementById('btn-get-in-touch').disabled = false
          if (error.response.status === 422) {
            error.response.data.map((item) => {
              toast.error(item.msg)
            })
          }
          console.log(error)
        })
    }
  }

  function contact_center_modal() {
    props.setmodal_center(!props.modal_center)
    removeBodyCss()
  }

  return (
    <Modal
      isOpen={props.modal_center}
      toggle={() => {
        contact_center_modal()
      }}
      centered={true}
    >
      <div className="modal-header" style={{ background: '#fff' }}>
        <h4 className="modal-title mt-0">Get in touch</h4>
        <button
          type="button"
          onClick={() => {
            setInputs({})
            setErrors({})
            props.setmodal_center(false)
          }}
          className="close close-btn"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body" style={{ background: '#fff' }}>
        <form onSubmit={handleGetInTouch}>
          <Row className="mb-2">
            <Col lg={12}>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group mb-4">
                    <input
                      id="fname"
                      type="text"
                      name="fname"
                      placeholder="First Name *"
                      class="form-control"
                      onChange={handleChange}
                      value={inputs.fname || ''}
                      autoFocus={true}
                      maxlength="50"
                    />
                    {errors.fname && (
                      <p style={{ color: 'red' }}>First Name is required</p>
                    )}
                    {errors.vfname && (
                      <p style={{ color: 'red' }}>
                        First name must be alphabetic.
                      </p>
                    )}
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group mb-4">
                    <input
                      id="lname"
                      type="text"
                      name="lname"
                      placeholder="Last Name *"
                      class="form-control"
                      onChange={handleChange}
                      value={inputs.lname}
                      maxlength="50"
                    />
                    {errors.lname && (
                      <p style={{ color: 'red' }}>Last Name is required</p>
                    )}
                    {errors.vlname && (
                      <p style={{ color: 'red' }}>
                        Last name must be alphabetic.
                      </p>
                    )}
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="form-group mb-4">
                    <input
                      id="email"
                      type="email"
                      name="email"
                      placeholder="Email *"
                      class="form-control"
                      onChange={handleChange}
                      value={inputs.email}
                    />
                    {errors.email && (
                      <p style={{ color: 'red' }}>Email address is required</p>
                    )}
                    {errors.vemail && (
                      <p style={{ color: 'red' }}>Invalid email address.</p>
                    )}
                  </div>
                </div>
              </div>
            </Col>
            <div class="col-md-12">
              <div class="form-group mb-2">
                <textarea
                  class="form-control"
                  id="contact-message"
                  name="message"
                  rows="7"
                  placeholder="Message..."
                  data-constraints="@Required"
                  onChange={handleChange}
                >
                  {inputs.message}
                </textarea>
                {errors.message && (
                  <p style={{ color: 'red' }}>Message field is required</p>
                )}
              </div>
            </div>

            <div class="col-md-12 pt-3 text-center">
              <button
                class="btn btn-primary"
                type="submit"
                id="btn-get-in-touch"
                name="send"
                // onClick={handleGetInTouch}
              >
                SUBMIT
              </button>
            </div>
          </Row>
        </form>
      </div>
    </Modal>
  )
}
