import { GET_ALL_EVENT } from './actionTypes'

// export const setApiError = err => ({
//           type: API_ERROR,
//           payload: err,
// });

// export const setAllEvents = events => ({
//           type: GET_ALL_EVENT_SUCCESS,
//           payload: events,
// });

// export const setTotalPage = total => ({
//           type: TOTAL_PAGE,
//           payload: total,
// });

export const getAllEvent = (pageNo, perPage, data) => ({
  type: GET_ALL_EVENT,
  payload: { pageNo, perPage, data },
})
