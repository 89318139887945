import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router'
import { TraditionalRenewPayment } from '../../store/actions'
import { useDispatch, useSelector } from 'react-redux'
import { Col, Modal, Row } from 'reactstrap'
import checkImg from '../../assets/images/users/check.png'

const RedirectComponent = (props) => {
  const history = useHistory()
  const location = useLocation()
  const dispatch = useDispatch()
  const { renewPaymentData, renewLoading } = useSelector(
    (state) => state.TraditionalPayment
  )
  const [showModal, setShowModal] = useState(false)
  const [Message, setMessage] = useState('')

  const query = new URLSearchParams(location.search)
  const payment_intent = query.get('payment_intent')

  useEffect(() => {
    if (payment_intent) {
      let data = {
        payment_intent_id: payment_intent,
      }
      dispatch(TraditionalRenewPayment(data))
    }
  }, [payment_intent])

  //
  useEffect(() => {
    if (renewPaymentData?.message) {
      localStorage.clear()
      setMessage(renewPaymentData?.message)
      setShowModal(true)
    }
    if (typeof renewPaymentData?.success === 'boolean') {
      setTimeout(() => {
        history.push('/login')
      }, 3000)
    }
  }, [renewPaymentData?.message, renewPaymentData?.success])
  return (
    <div
      style={{
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {renewLoading ? <h2>Loading...</h2> : null}

      <Modal
        className="sucess_modal"
        isOpen={showModal}
        toggle={() => {
          // contact_center_modal();
        }}
        centered={true}
      >
       
        <div className="modal-body">
          <Row className="mb-2 text-center pay-modal">
            <Col lg={12}>
              <h4 style={{color:'#112233', paddingTop:'20px'}}>{Message}</h4>
              <p className="m-0 ">
                <img src={checkImg} alt="success" className="p-r-3" />
              </p>

              <p className="m-0 ">Thank you for renew your plan.</p>
            </Col>
          </Row>
        </div>
      </Modal>
    </div>
  )
}

export default RedirectComponent
