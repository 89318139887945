import { put, takeEvery } from 'redux-saga/effects'

import { ALL_CLASS_TYPE_LIST, ALL_CLASS_TYPE_LIST_SUCCESS } from './actionType'

import { toast } from 'react-toastify'
import { get } from '../../helpers/api_helper'
import _ from 'lodash'

function* classTypesList() {
  try {
    let res = []
    res = yield get(`/api/v1/admin/class/list`)
    let finalData = res?.data || []
    if (finalData) {
      finalData = _.orderBy(
        finalData,
        [(_class) => _class.class_name.toLowerCase()],
        ['asc']
      )
    }
    res = finalData

    yield put({
      type: ALL_CLASS_TYPE_LIST_SUCCESS,
      payload: res,
    })
  } catch (err) {
    if (err?.response?.data) {
      toast.error(err?.response?.data)
    }
  }
}
function* ClassTypesSagas() {
  yield takeEvery(ALL_CLASS_TYPE_LIST, classTypesList)
}

export default ClassTypesSagas
