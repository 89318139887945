import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { useLocation } from 'react-router'
import { Button, Col, FormGroup, Input, Label } from 'reactstrap'
import { toast } from 'react-toastify'
const PaymentForm = ({ submitBtn = true, ...props }) => {
  const { clientSecret, returnUrl } = props
  const stripe = useStripe()
  const elements = useElements()
  const location = useLocation()
  const [isLoading, setIsLoading] = useState(false)
  const [isAccept, setIsAccept] = useState(false)

  useEffect(() => {
    window.history.forward()
    window.history.pushState(null, document.title, window.location.href)
    window.addEventListener('popstate', function (event) {
      window.history.pushState(null, document.title, window.location.href)
    })
    // debugger
    setIsLoading(true)
    setIsAccept(false)
    if (!stripe) {
      setIsLoading(false)
      return
    }

    if (!clientSecret) {
      setIsLoading(false)
      return
    }

    setTimeout(() => {
      setIsLoading(false)
    }, 3000)
  }, [clientSecret, stripe])

  //
  const onSubmit = async (e) => {
    e.preventDefault()

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return
    }

    setIsLoading(true)
    // debugger
    stripe
      .confirmPayment({
        elements,
        confirmParams: {
          // Make sure to change this to your payment completion page
          //
          // process.env.REACT_APP_URL
          // ${process.env.REACT_APP_URL}
          // http://localhost:3002/
          return_url: returnUrl,
        },
      })
      .then((res) => {
        if (res.error) {
          if (
            res.error.code !== 'incomplete_number' &&
            res.error.code !== 'incomplete_expiry' &&
            res.error.code !== 'invalid_expiry_month_past' &&
            res.error.code !== 'incomplete_cvc'
          ) {
            toast.error(res?.error?.message || 'invalid card details')
          } else if (res?.error?.message) {
            toast.error(res?.error?.message)
          }
          setIsLoading(false)
        }
      })
      .catch((err) => {
        if (
          err.code !== 'incomplete_number' ||
          err.code !== 'incomplete_expiry' ||
          err.code !== 'invalid_expiry_month_past' ||
          err.code !== 'incomplete_cvc' ||
          err.type !== 'fetch_error'
        ) {
          if (err.type === 'fetch_error') {
            toast.error(
              'You are currently offline. Please connect to the internet and try again!'
            )
          } else {
            toast.error(err.message || 'invalid card details')
          }
        }
        // history.push('/plan_shopping_cart');
        setIsLoading(false)
      })

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    // if (error.type === 'card_error' || error.type === 'validation_error') {
    //   setMessage(error.message);
    // } else {
    //   setMessage('An unexpected error occured.');
    // }
  }
  return (
    <form id='payment-form' onSubmit={(e) => onSubmit(e)}>
      <PaymentElement />
      {props?.children}
      <Col lg={12}>
        <div className='mt-4 text-center'>
          <Button
            type='submit'
            color='success'
            disabled={!stripe || isLoading || !submitBtn}
            className='w-lg waves-effect waves-light'
          >
            PAY
          </Button>
        </div>
      </Col>
    </form>
  )
}

PaymentForm.propTypes = {}

export default PaymentForm