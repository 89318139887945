import React, { useState } from 'react';
import { Modal } from 'reactstrap';
import CloseIcon from '@mui/icons-material/Close';
import { useEffect } from 'react';
import { post } from '../../helpers/api_helper';
import * as url from '../../helpers/url_helper';
import { config } from '../../store/shoppingCart/saga';
import { useHistory } from 'react-router-dom';

export default function ResendVerifyModal({
  active,
  uniqueString,
  resetFields,
  redirectLink,
}) {
  const history = useHistory();
  const [modal_center, setmodal_center] = useState(false);
  const [modal_center1, setmodal_center1] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (active === 0) {
      setmodal_center(true);
    }
  }, []);

  function removeBodyCss() {
    document.body.classList.add('no_padding');
  }
  function tog_center() {
    setmodal_center(!modal_center);
    removeBodyCss();
  }
  function tog_center1() {
    tog_center();
    setmodal_center1(!modal_center1);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
    removeBodyCss();
    history.push(redirectLink);
    resetFields();
    localStorage.removeItem('purchaseInfo');
  }

  const onResendVerify = (e) => {
    setLoading(true);
    const body = JSON.stringify({ unique_string: uniqueString });
    post(url.RESEND_VERIFICATION_EMAIL, body, config).then((res) => {
      if (res) {
        tog_center1();
      }
    });
  };
  return (
    <>
      <Modal
        isOpen={modal_center}
        toggle={() => {
          tog_center();
        }}
        centered={modal_center}
        className="exist-modal-view agree_modal"
      >
        <div className="modal-header">
          <div
            aria-label="close"
            onClick={() => {
              setmodal_center(false);
            }}
          >
            <CloseIcon
              style={{
                background: 'red',
                position: 'absolute',
                top: 10,
                right: 10,
                color: '#fff',
                padding: 2,
                height: 30,
                width: 30,
                cursor: 'pointer',
              }}
            />
          </div>
        </div>
        <div className="modal-body">
          <p className="para">
            Your Email already exists, but not Registered. Click below to
            recieve a new registration link
          </p>
          <button
            className="btn btn-primary btn-easy"
            style={{ background: 'green', color: '#fff' }}
            type="button"
            onClick={(e) => {
              onResendVerify(e);
            }}
            data-toggle="modal"
            data-target=".bs-example-modal-center"
            disabled={loading}
          >
            CLICK HERE
          </button>
        </div>
      </Modal>

      {/* ====== confirmation Modal ====== */}
      <Modal
        isOpen={modal_center1}
        toggle={() => {
          tog_center1();
        }}
        centered={true}
        className="exist-modal-view agree_modal"
      >
        <div className="modal-header">
          <div
            aria-label="close"
            onClick={() => {
              setmodal_center1(false);
            }}
          >
            <CloseIcon
              style={{
                background: 'red',
                position: 'absolute',
                top: 10,
                right: 10,
                color: '#fff',
                padding: 2,
                height: 30,
                width: 30,
              }}
            />
          </div>
        </div>
        <div className="modal-body">
          <p className="para">
            Email verify link has been successfully sent to your registered
            Email.
          </p>
        </div>
      </Modal>
    </>
  );
}
