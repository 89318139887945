const PdfToUrl = () => {
  const sendBase64 = async (base64, fileName) => {
    if (base64) {
      let pdfBlob = await fetch(base64)

      pdfBlob = await pdfBlob.blob()
      let pdfUrl = URL.createObjectURL(pdfBlob)
      // window.open(pdfUrl, '_blank')
      const link = document.createElement('a')
      link.href = pdfUrl
      link.download = `${fileName}.pdf`
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }
  }
  return { sendBase64 }
}

export default PdfToUrl
