import React, { useEffect, useMemo, useState } from 'react'
import { Row, Col, Container, Spinner } from 'reactstrap'
import { Table } from 'react-super-responsive-table'
import { Link, useHistory, useParams } from 'react-router-dom'
import { get } from '../../../helpers/api_helper'
import _ from 'lodash'
import { useSelector } from 'react-redux'
import { UrlEncodeHook } from '../../../hooks'

const ShoppingCart = (props) => {
  let { eventId, participateId, showId } = useParams()
  const { urlDecode, urlEncode } = UrlEncodeHook()
  eventId = urlDecode(eventId)
  showId = urlDecode(showId)

  const history = useHistory()
  const YourParticipation = useSelector((state) => state.YourParticipation)
  const { shoppingData } = YourParticipation
  const [error, setError] = useState('')
  const [value, setValue] = useState('')
  const [event, setEvent] = useState()

  useEffect(() => {
    get(`/api/v1/events/sponsor/all-shows-by-event?event_id=${eventId}`).then(
      (res) => {
        if (res && Array.isArray(res.data)) {
          let obj = _.first(res.data)

          setEvent(obj)
        }
      }
    )
  }, [])
  const showDetail = useMemo(() => {
    let show = {}
    if (event) {
      show = event.show_list.find((s) => {
        return s.show_id === Number(showId)
      })
    }
    return show
  }, [event])

  const paymentData = () => {
    let validation = false
    let err = ''
    if (!value) {
      err = 'Please accept terms and conditions'
    } else {
      err = ''
    }
    if (err) {
      validation = true
      setError(err)
    } else {
      setError(err)
    }
    if (!validation) {
      history.push(
        `/your_participation/${urlEncode(eventId)}/${urlEncode(showId)}/participate_checkout`
      )
    }
  }
  return (
    <React.Fragment>
      <div className="page-content bg-gray-101">
        <section id="about" class="section1 bg-gray-101 pages event-list">
          <Container fluid={true} className="auto-fit">
            <Row>
              <Col lg={12} className="text-center">
                <div className="page-title-box justify-content-between">
                  <h2 className="top-title mb-4">
                    <span>Shopping Cart</span>
                  </h2>
                </div>
              </Col>
            </Row>

            <Row>
              <div className="plan-structure1 mt-2">
                <Col lg={12}>
                  <div className="mb-5 table-responsive">
                    <Table className="table mb-0 cust-tab">
                      <thead className="table-color">
                        <tr>
                          <th>NO.</th>
                          <th>Event Name</th>
                          <th>Units</th>
                          <th style={{ textAlign: 'right' }}>Final Price</th>
                        </tr>
                      </thead>
                      <tbody>
                        {event ? (
                          <tr>
                            <td>1</td>
                            <td>{event?.event_name}</td>
                            <td>
                              Show {showDetail?.show_char} : 1 x 1 ( Animal )
                            </td>
                            <td style={{ textAlign: 'right' }}>
                              $
                              {shoppingData?.final_cost
                                ? shoppingData?.final_cost.toFixed(2)
                                : '0.00'}
                            </td>
                          </tr>
                        ) : (
                          <tr>
                            <td colSpan={10} className="text-center">
                              Loading...
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </div>
                </Col>
                {/* <Col lg={12} className="mt-3">
                    <Row>
                        <Col lg={8}>
                            <div className="grand-box">
                                <h4>TOTAL</h4>
                            </div>
                        </Col>
                        <Col lg={4}>
                            <div className="grand-box">
                                <h4>$7.00</h4>
                            </div>
                        </Col>
                    </Row>
                </Col> */}

                <Col lg={12} className="mt-4">
                  <Row>
                    <Col lg={8}>
                      <div className="grand-box-view">
                        <h4>GRAND TOTAL</h4>
                      </div>
                    </Col>
                    <Col lg={4}>
                      <div className="grand-box-view">
                        <h4>
                          $
                          {shoppingData?.final_cost
                            ? shoppingData?.final_cost.toFixed(2)
                            : '0.00'}
                        </h4>
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col lg={12} className="mt-4">
                  <Row>
                    <Col lg={8}>
                      <div className="grand-box">
                        <h4>PREVIOUS AMOUNT PAID</h4>
                      </div>
                    </Col>
                    <Col lg={4}>
                      <div className="grand-box">
                        <h4>
                          $
                          {shoppingData?.previous_amt_paid
                            ? Number(shoppingData?.previous_amt_paid).toFixed(2)
                            : '0.00'}
                        </h4>
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col lg={12}>
                  <div className="card shadow-only-hover mt-8 shop-card text-center">
                    <div className="card-body">
                      <h4 className="mb-3">
                        NOTE - ALL ENTRIES ARE FINAL AND NON-REFUNDABLE.
                      </h4>
                      <p>
                        You as the exhibitor understands and is agreeing to the
                        fact that your entry is FINAL and NON-REFUNDABLE for any
                        reason. It is at the discrection of the host club if
                        they will refund any part of or all monies, but
                        Easy2Show will not guarantee that the show might/will
                        and Easy2Show does not refund any monies/fees.
                      </p>

                      <p>
                        If the show cancels or postpones, for any reason, your
                        paid entry is still active. It can be modified for the
                        furture show date within the same animal "category". To
                        make changes: "login" as a user, goto "your
                        participation", search for the Show/Event and click
                        "update" next to the animal you want to update. You need
                        to update each animal in each Event. Changing an
                        animal's information in Event A will NOT automatically
                        change it in Event B.
                      </p>

                      <p>
                        Any changes after the show deadline is at the discretion
                        of the show committee. Easy2Show does NOT guarantee that
                        any change requested will be granted by the show
                        committee.
                      </p>

                      <Col xl={12}>
                        <div className="mt-3 agree-check text-left">
                          <input
                            className="form-check-input mr-2"
                            type="checkbox"
                            id="check"
                            value=""
                            onChange={(e) => {
                              setValue((prev) => !prev)
                            }}
                          />
                          <label
                            className={'form-check-label p-r-3 mt-1'}
                            htmlFor="check"
                          >
                            {' '}
                            ACCEPT
                          </label>
                          {error && <div className="text-danger">{error}</div>}
                        </div>
                      </Col>
                    </div>
                  </div>
                </Col>
                <Col lg={12} className="text-center mt-5">
                  <Link to="/events">
                    <button type="button" className="btn btn-primary">
                      CONTINUE SHOPPING
                    </button>
                  </Link>

                  <button
                    type="button"
                    className="btn btn-primary btn-easy"
                    onClick={() => paymentData()}
                  >
                    PAY / CHECKOUT
                  </button>
                </Col>
              </div>
            </Row>
          </Container>
        </section>
      </div>
    </React.Fragment>
  )
}

export default ShoppingCart
