import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { width } from '@mui/system';
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <div
          aria-label="close"
          onClick={onClose}
         
        >
          <CloseIcon  style={{
            background: 'red',
            position: 'absolute',
            top: 10,
            right: 10,
            color: '#fff',
            padding: 2,
            height: 30,
            width: 30,
            cursor: 'pointer',
          }} />
        </div>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function SuccessPopup(props) {
  const handleClickOpen = () => {
    props.setShowAlert(true);
  };
  const handleClose = () => {
    props.setShowAlert(false);
    props.setMsg('');
  };

  return (
    <div>
      {/* <Button variant="outlined" onClick={handleClickOpen}>
        Open dialog
      </Button> */}
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={props.showAlert}
      >
        <div
          style={{
            textAlign: 'center',
            width: '420px',
            background: 'rgb(50 187 41)',
            color: '#fff',
            padding: '10px',
          }}
        >
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={handleClose}
          ></BootstrapDialogTitle>
          <DialogContent style={{ marginTop: '10px' }}>
            <h4 style={{ color: '#fff' }}>{props.title ? props.title : ''}</h4>
            <div
              style={{
                border: '5px solid',
                height: '50px',
                width: '50px',
                paddingTop: '5px',
                borderRadius: '50px',
                marginLeft: 'auto',
                marginRight: 'auto',
                marginBottom: '21px',
                marginTop: '20px',
              }}
            >
              <DoneOutlineIcon />
            </div>
            <Typography gutterBottom>{props.msg}</Typography>
            {props.showBtn && (
              <button
                style={{ background: 'green', color: '#fff' }}
                onClick={props.handleClicked}
              >
                CLICK HERE
              </button>
            )}
          </DialogContent>
        </div>
      </BootstrapDialog>
    </div>
  );
}
