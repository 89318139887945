import $ from 'jquery'
import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Card, CardBody, Col, Container, Row } from 'reactstrap'
import { get, POST, post } from '../../../helpers/api_helper'
import { getDate, getTime } from '../../../helpers/date_helper'
import ParticipateEasyEntryList from './list'
import Popup from './popup'
import { Tooltip } from 'react-tippy'
import { v4 as uuidv4 } from 'uuid'
import Swal from 'sweetalert2'
import { UrlEncodeHook } from '../../../hooks'

const ParticipateEasyEntry = (props) => {
  const history = useHistory()

  const dispatch = useDispatch()
  const location = useLocation()
  const query = new URLSearchParams(location.search)
  let event_id = query.get('event_id')
  const { urlDecode, urlEncode } = UrlEncodeHook()
  event_id = urlDecode(event_id)
  const has_products = query.get('has_products')
  const [showList, setShowList] = useState([])
  const [showData, setShowData] = useState([])
  const [showModal, setShowModal] = useState(false)
  const [exhibitorList, setExhibitorList] = useState([])
  const [listValidation, setListValidation] = useState([
    {
      id: '',
      show_id: '',
      show_type: '',
      user_id: '',
      ear_no: '',
      breed_category_id: '',
      breed_id: '',
      class_id: '',
      variety_id: '',
      group_id: '',
      has_fur: '',
      fur_type_id: '',
      fur_id: '',
      group_seq: '',
      variety_seq: '',
      fur_color: '',
    },
  ])

  const [List, setList] = useState([])

  const [showToast, setShowToast] = useState(false)
  const [newCount, setCount] = useState(0)

  const [isAllSelected, setisAllSelected] = useState()

  const { additionalInfo } = (props.location && props.location.state) || {}

  const [event, setEvent] = useState()
  let count = 0

  // new Code

  useEffect(() => {
    const body = {
      event_id_list: [event_id],
    }
    POST(`/api/v1/events/multiple?published=1&active=1&deadlines=1`, body).then(
      (res) => {
        setEvent(res.data[0])
      }
    )
    if (exhibitorList.length) {
      const getAllEntry = async () => {
        await getAll()
      }
      getAllEntry()
    }
  }, [exhibitorList])
  useEffect(() => {
    get(`/api/v1/profile/exhibitor_list`).then((res) => {
      if (res) {
        let sortList = _.sortBy(res?.data || [], ['first_name'], ['asc'])

        setExhibitorList(sortList)
      }
    })
  }, [])

  const getAll = async () => {
    let result
    const res = await get(`/api/v1/shows/all?event_id=${event_id}`)
    if (res) {
      setShowData(res.data)
      result = await res.data.map((data) => {
        return {
          show_id: data.show_id,
          show_type: data.show_type,
        }
      })
      let finalData = _.uniqBy(result, function (e) {
        return e.show_type
      })
      setShowList(finalData)
    }
    const myPromise = new Promise(async (resolve, reject) => {
      const result = await post(
        `/api/v1/easy_entry/sorted-list?event_id=${event_id}`
      )

      const finalResult = await result.filter((s) => s.show_list !== null)
      if (!finalResult.length) {
        setShowModal(true)
      }

      resolve(finalResult)
    })

    const allEasyEntryList = await myPromise
    const finalPromise = new Promise(async (resolve, reject) => {
      const result = await allEasyEntryList.map((res) => {
        let findUser = exhibitorList.find(
          (item) => item.id === Number(res.user_id)
        )

        return {
          id: uuidv4(),
          in_cart: 1,
          event_id,
          show_id: [],
          easy_entry_id: res.easy_entry_id,
          show_type: res.show_type,
          user_id: res.user_id,
          user_name: `${findUser.first_name} ${findUser.last_name}`,
          ear_no: res.ear_no,
          breed_category_id: res.breed_category_id,
          breed_id: res.breed_id,
          class_id: res.class_id,
          variety_id: res.breed_data?.variety_seq ? res.variety_id : '',
          group_id: res.breed_data?.group_seq ? res.group_id : '',
          has_fur: '',
          fur_data_validation: false,
          fur_data: [],
          fur_type_id: [],
          fur_id: [],
          fur_color: '',
          group_seq: res.breed_data?.group_seq,
          variety_seq: res.breed_data?.variety_seq,
          isSelected: 0,
          easyDisable: true,

          showList: res.show_list
            ? res.show_list.map((s) => {
                const postFixShow = `${
                  s.show_category === 'speciality' ? '/ SP' : ''
                }`

                return {
                  value: s.show_id,
                  label: (
                    <>
                      <span>
                        {' '}
                        {s.show_char} ({getDate(s.show_date)}) {postFixShow}
                      </span>
                      <Tooltip
                        // options
                        title={s.char_description}
                        position='bottom'
                        arrow={true}
                        distance={20}
                        trigger='mouseenter'
                      >
                        <i
                          className='fa fa-info-circle ml-2 '
                          // style={{ cursor: 'pointer' }}
                          aria-hidden='true'
                        ></i>
                      </Tooltip>
                    </>
                  ),
                }
              })
            : [],
          type: 2, // 1 = Manual Entry 2= easy entry
        }
      })

      resolve(result)
    })
    let finalData = await finalPromise
    // let finalData1 = finalData.map(async (easyPart) => {
    //   let groupVarietyData = {}
    //   let breedData = await get(
    //     `api/v1/breed/all?published=1&breed_category_id=${easyPart.breed_category_id}`,
    //   )

    //   if (breedData?.data.length && easyPart.breed_id) {
    //     let data = breedData.data.find(
    //       (res) => Number(res.breed_id) === Number(easyPart.breed_id),
    //     )
    //     groupVarietyData = data
    //   }
    //   console.log(groupVarietyData)

    //   return {
    //     ...easyPart,
    //   }
    // })

    let validationPromise = await new Promise((resolve, reject) => {
      let validation = []
      for (let i = 0; i < finalData.length; i++) {
        validation.push({
          id: finalData[i].id,
          show_id: '',
          show_type: '',
          user_id: '',
          ear_no: '',
          breed_category_id: '',
          breed_id: '',
          class_id: '',
          variety_id: '',
          group_id: '',
          has_fur: '',
          fur_type_id: '',
          fur_id: '',
          group_seq: '',
          variety_seq: '',
          fur_color: '',
        })
        resolve(validation)
      }
    })
    const validation = await validationPromise
    setListValidation(validation)
    setList(finalData)
  }
  const handleChange = async (i, e, type, n, breedList, easyDisable) => {
    let list = [...List]
    let validationList = [...listValidation]
    if (type) {
      // if (e.length > 0) {
      let value = e.map((data) => data.value)
      list[i][n] = value
      // }

      // debugger
      if (n === 'show_id') {
        if (!easyDisable) {
          // list[i][n] = e.value
          list[i]['breed_category_id'] = ''
          list[i]['breed_id'] = ''
          list[i]['group_seq'] = ''
          list[i]['variety_seq'] = ''
          list[i]['variety_id'] = ''
          list[i]['class_id'] = ''
          list[i]['group_id'] = ''
          list[i]['has_fur'] = ''
          list[i]['fur_data'] = []
          list[i]['fur_type_id'] = []
          list[i]['fur_color'] = ''
          list[i]['fur_data_validation'] = ''
          list[i]['isSelected'] = 0
        } else {
          list[i]['has_fur'] = ''
          list[i]['fur_data_validation'] = ''
          list[i]['fur_data'] = []
          list[i]['fur_type_id'] = []
          list[i]['fur_color'] = ''
          list[i]['isSelected'] = 0
        }
      }
      if (n === 'fur_type_id') {
        list[i]['fur_color'] = ''
      }
    } else {
      const { name, value } = e.target
      if (name === 'ear_no') {
        setShowToast(false)
        if (value.length < 50) {
          list[i]['isSelected'] = 0
          list[i]['ear_no'] = value.toUpperCase()
        }
        if (value.length === 49 && !showToast) {
          setShowToast(true)
          setCount(1)
        }
        if (showToast && newCount === 1) {
          toast.error('Max Ear. No Should be 50 Character')
        }
      }

      if (name === 'show_type') {
        list[i]['show_type'] = value
        list[i]['user_id'] = ''
        list[i]['ear_no'] = ''
        list[i]['show_id'] = []
        list[i]['breed_category_id'] = ''
        list[i]['breed_id'] = ''
        list[i]['group_seq'] = ''
        list[i]['variety_seq'] = ''
        list[i]['variety_id'] = ''
        list[i]['class_id'] = ''
        list[i]['group_id'] = ''
        list[i]['has_fur'] = ''
        list[i]['fur_data'] = []
        list[i]['fur_type_id'] = []
        list[i]['fur_color'] = ''
        list[i]['fur_data_validation'] = ''
        list[i]['isSelected'] = 0
      }

      if (name === 'user_id') {
        let findUser = exhibitorList.find((item) => item.id === Number(value))
        list[i]['user_id'] = Number(value) ? Number(value) : ''
        list[i]['isSelected'] = 0
        list[i]['user_name'] = findUser
          ? `${findUser.first_name} ${findUser.last_name}`
          : ''
      }
      if (name === 'breed_category_id') {
        list[i]['breed_category_id'] = Number(value)
        list[i]['variety_id'] = ''
        list[i]['class_id'] = ''
        list[i]['group_id'] = ''
        list[i]['breed_id'] = ''
        list[i]['has_fur'] = ''
        list[i]['fur_data_validation'] = ''
      }
      if (name === 'breed_id') {
        let breed = breedList.find((s) => s.breed_id === Number(value))
        list[i]['breed_id'] = value ? Number(value) : ''
        list[i]['group_seq'] = breed?.group_seq ? Number(breed?.group_seq) : ''
        list[i]['variety_seq'] = breed?.variety_seq
          ? Number(breed?.variety_seq)
          : ''

        list[i]['variety_id'] = ''
        list[i]['class_id'] = ''
        list[i]['group_id'] = ''
        list[i]['has_fur'] = ''
        list[i]['fur_data_validation'] = ''

        list[i]['fur_type_id'] = []
        list[i]['fur_id'] = []
        list[i]['fur_data'] = []
        list[i]['has_fur'] = ''
      }
      if (name === 'group_id') {
        list[i]['group_id'] = value ? Number(value) : ''
        list[i]['class_id'] = ''
        list[i]['variety_id'] = ''
        list[i]['fur_type_id'] = []
        list[i]['fur_id'] = []
        list[i]['fur_data'] = []
        list[i]['has_fur'] = ''
      }
      if (name === 'variety_id') {
        list[i]['variety_id'] = value ? Number(value) : ''
        list[i]['class_id'] = ''
        list[i]['fur_type_id'] = []
        list[i]['fur_id'] = []
        list[i]['fur_data'] = []
        list[i]['has_fur'] = ''
      }
      if (name === 'class_id') {
        list[i]['class_id'] = value ? Number(value) : ''
      }
      if (name === 'has_fur') {
        list[i]['has_fur'] = value ? Number(value) : ''
        //has_fur

        if (Number(value)) {
          if (
            list[i].fur_default_data.length === 1 &&
            list[i].fur_default_data[0].fur_list.length === 1
          ) {
            list[i]['fur_type_id'] = [list[i].fur_default_data[0].fur_type_id]
            list[i]['fur_color'] =
              list[i].fur_default_data[0].fur_list[0].fur_id
          }
        } else {
          list[i]['fur_data'] = []
          list[i]['fur_type_id'] = []
          list[i]['fur_color'] = ''
        }
      }
      if (name === 'fur_color') {
        list[i]['fur_color'] = value ? Number(value) : ''
      }
      if (name === 'isSelected') {
        if (!Boolean(Number(value))) {
          $('*').addClass('changeCursor')
          let validate = list[i]

          let errorObj = {
            id: validate?.id || '',
          }
          if (validate?.show_id.length === 0) {
            errorObj[`show_id`] = 'Please select show'
          }
          if (!validate.ear_no) {
            errorObj[`ear_no`] = 'Ear no. field is required'
          } else {
            let checkErNum = hasErNo(
              validate.ear_no,
              validate.user_id,
              validate.show_id,
              List.filter((s) => s.id !== validate.id)
            )
            if (checkErNum) {
              errorObj[`ear_no`] = 'Duplicate Ear no. found for same user.'
            }
          }

          if (!validate.user_id) {
            errorObj[`user_id`] = 'Please select exhibitor'
          }
          if (!validate.show_type) {
            errorObj.show_type = 'Please select show type'
          }

          if (!validate.breed_category_id) {
            errorObj['breed_category_id'] = 'Please select category'
          }

          if (!validate.breed_id) {
            errorObj['breed_id'] = 'Please select breed '
          }
          if (!validate.class_id) {
            errorObj[`class_id`] = 'Please select class '
          }

          if (validate.group_seq) {
            if (!validate.group_id) {
              errorObj[`group_id`] = 'Please select group'
            }
          }
          if (validate.variety_seq) {
            if (!validate.variety_id) {
              errorObj['variety_id'] = 'Please select Variety'
            }
          }

          if (validate.fur_data_validation) {
            if (!validate.has_fur && validate.has_fur !== 0) {
              errorObj[`has_fur`] = 'Please select fur'
            }
            if (validate.has_fur && !validate.fur_type_id.length) {
              errorObj['fur_type_id'] = 'Please select fur type'
            }
            if (validate.has_fur && !validate.fur_color) {
              errorObj[`fur_color`] = 'Please select fur color'
            }
          }

          setListValidation([errorObj])

          if (
            !_.size(
              _.filter(
                _.map([errorObj], (err) => _.omit(err, ['id'])),
                function (o) {
                  return _.size(o)
                }
              )
            )
          ) {
            const allData = _.flatMap([validate], (ele) => {
              return ele.show_id.map((show) => ({
                in_cart: 1,
                id: ele?.id,
                event_id: event_id,
                show_id: show,
                show_type: ele.show_type,
                user_id: ele.user_id,
                ear_no: ele.ear_no,
                breed_category_id: ele.breed_category_id,
                breed_id: ele.breed_id,
                class_id: ele.class_id,
                variety_id: ele.variety_seq ? ele.variety_id : '',
                group_id: ele.group_seq ? ele.group_id : '',
                has_fur: ele.has_fur,
                fur_data: ele.fur_type_id.map((s) => {
                  return {
                    fur_type_id: s,
                    fur_id: ele.fur_color,
                  }
                }),
                group_seq: ele.group_seq,
                variety_seq: ele.variety_seq,
              }))
            })

            // try {
            const urls = allData.map((obj) => {
              return {
                url: post(`/api/v1/participation/validate_participation`, obj),
                id: obj.id,
              }
            })
            let allSettled = await Promise.allSettled(_.map(urls, 'url'))

            let settledErrors = allSettled.map((obj, index) => {
              let selectedError = urls[index]

              if (obj?.status === 'rejected') {
                return {
                  id: selectedError?.id,
                  ear_no: 'Ear no. already exits',
                  err: true,
                }
              }

              return {
                id: selectedError?.id,
                err: false,
              }
            })

            let allHasError = _.filter(settledErrors, (o) => o.err)
            if (Array.isArray(allHasError) && allHasError.length) {
              $('*').removeClass('changeCursor')
              setListValidation(allHasError)
            } else {
              $('*').removeClass('changeCursor')
              list[i]['isSelected'] = 1
              setListValidation([])
            }
          } else {
            $('*').removeClass('changeCursor')
            setListValidation([errorObj])
          }
        } else {
          $('*').removeClass('changeCursor')
          setListValidation([])
          list[i]['isSelected'] = 0
        }
      }
    }

    setList(list)
  }
  const handleRemove = (index) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      width: '380px',
      showCancelButton: true,
      confirmButtonColor: '#062575',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        let list = [...List]
        list.splice(index, 1)

        setList(list)
      }
    })
  }

  const hasFurData = async (i, hasFur) => {
    let list = [...List]
    if (hasFur && hasFur.length) {
      list[i].fur_data_validation = true
      list[i].displayFur = true
      list[i].fur_default_data = hasFur
      if (hasFur.length === 1 && hasFur[0].fur_list.length === 1) {
        list[i].displayFur = false
      }
    } else {
      list[i].fur_data_validation = false
      list[i].has_fur = ''
      list[i].fur_data = []
      list[i].fur_type_id = []
      list[i].fur_id = []
      list[i].fur_default_data = []
      list[i].displayFur = true
    }
    setList(list)
  }
  const handleSelectAll = async (e) => {
    if (e.target.value === 'false') {
      $('*').addClass('changeCursor')
      const error = List.map((list, index) => {
        let errorObj = {
          id: list?.id || '',
        }
        if (list?.show_id.length === 0) {
          errorObj[`show_id`] = 'Please select show'
          errorObj[`err`] = true
        }
        if (!list.ear_no) {
          errorObj[`ear_no`] = 'Ear no. field is required'
          errorObj[`err`] = true
        } else {
          let checkErNum = hasErNo(
            list.ear_no,
            list.user_id,
            list.show_id,
            List.filter((s) => s.id !== list.id)
          )
          if (checkErNum) {
            errorObj[`ear_no`] = 'Duplicate Ear no. found for same user.'
            errorObj[`err`] = true
          }
        }

        if (!list.user_id) {
          errorObj[`user_id`] = 'Please select exhibitor'
          errorObj[`err`] = true
        }
        if (!list.show_type) {
          errorObj.show_type = 'Please select show type'
          errorObj[`err`] = true
        }

        if (!list.breed_category_id) {
          errorObj['breed_category_id'] = 'Please select category'
          errorObj[`err`] = true
        }

        if (!list.breed_id) {
          errorObj['breed_id'] = 'Please select breed '
          errorObj[`err`] = true
        }
        if (!list.class_id) {
          errorObj[`class_id`] = 'Please select class '
          errorObj[`err`] = true
        }

        if (list.group_seq) {
          if (!list.group_id) {
            errorObj[`group_id`] = 'Please select group'
            errorObj[`err`] = true
          }
        }
        if (list.variety_seq) {
          if (!list.variety_id) {
            errorObj['variety_id'] = 'Please select Variety'
            errorObj[`err`] = true
          }
        }

        if (list.fur_data_validation) {
          if (!list.has_fur && list.has_fur !== 0) {
            errorObj[`has_fur`] = 'Please select fur'
            errorObj[`err`] = true
          }
          if (list.has_fur && !list.fur_type_id.length) {
            errorObj['fur_type_id'] = 'Please select fur type'
            errorObj[`err`] = true
          }
          if (list.has_fur && !list.fur_color) {
            errorObj[`fur_color`] = 'Please select fur color'
            errorObj[`err`] = true
          }
        }

        return errorObj
      })

      setListValidation(error)

      if (
        !_.size(
          _.filter(
            _.map(error, (err) => _.omit(err, ['id'])),
            function (o) {
              return _.size(o)
            }
          )
        )
      ) {
        const allData = _.flatMap(List, (ele) => {
          return ele.show_id.map((show) => ({
            in_cart: 1,
            id: ele?.id,
            event_id: event_id,
            show_id: show,
            show_type: ele.show_type,
            user_id: ele.user_id,
            ear_no: ele.ear_no,
            breed_category_id: ele.breed_category_id,
            breed_id: ele.breed_id,
            class_id: ele.class_id,
            variety_id: ele.variety_seq ? ele.variety_id : '',
            group_id: ele.group_seq ? ele.group_id : '',
            has_fur: ele.has_fur,
            fur_data: ele.fur_type_id.map((s) => {
              return {
                fur_type_id: s,
                fur_id: ele.fur_color,
              }
            }),
            group_seq: ele.group_seq,
            variety_seq: ele.variety_seq,
          }))
        })

        // try {
        const urls = allData.map((obj) => {
          return {
            url: post(`/api/v1/participation/validate_participation`, obj),
            id: obj.id,
          }
        })
        let allSettled = await Promise.allSettled(_.map(urls, 'url'))

        let settledErrors = allSettled.map((obj, index) => {
          let selectedError = urls[index]

          if (obj?.status === 'rejected') {
            return {
              id: selectedError?.id,
              ear_no: 'Ear no. already exits',
              err: true,
            }
          }

          return {
            id: selectedError?.id,
            err: false,
          }

          // let checkFind = allSettled.find(f=> f.)
        })
        const AllSetCheckBox = List.map((obj) => {
          const hasError = settledErrors.filter((err) => err?.id === obj.id)
          const singleErr = hasError.find((e) => e?.err)
          if (singleErr?.err) {
            return {
              ...obj,
              isSelected: 0,
            }
          }
          return {
            ...obj,
            isSelected: 1,
          }
        })
        setListValidation(_.filter(settledErrors, (o) => o.err))
        setList(AllSetCheckBox)
      } else {
        const AllSetCheckBox = List.map((obj) => {
          const hasError = error.filter((err) => err?.id === obj.id)
          const singleErr = hasError.find((e) => e?.err)
          if (singleErr?.err) {
            return {
              ...obj,
              isSelected: 0,
            }
          }
          return {
            ...obj,
            isSelected: 1,
          }
        })
        setList(AllSetCheckBox)
      }
      $('*').removeClass('changeCursor')
    } else {
      let data = List.map((s) => {
        if (s['isSelected']) {
          s['isSelected'] = 0
        }
        return s
      })
      setList(data)
    }
    // if (e.target.value === 'false') {
    //   $('*').addClass('changeCursor')
    //   let count = 0
    //   let list = [...List]
    //   let validationList = [...listValidation]

    //   for (let i = 0; i < List.length; i++) {
    //     let ele = List[i]
    //     let valid = true
    //     validationList[i].id = ele.id
    //     if (list[i].show_id.length === 0) {
    //       validationList[i].show_id = 'Please select show'
    //       valid = false
    //     } else {
    //       validationList[i].show_id = ''
    //     }
    //     if (!list[i].ear_no) {
    //       validationList[i].ear_no = 'Ear no. field is required'
    //       valid = false
    //     } else {
    //       let checkErNum = hasErNo(
    //         list[i].ear_no,
    //         list[i].user_id,
    //         list[i].show_id,
    //         List.filter((s) => s.id !== list[i].id)
    //       )

    //       if (checkErNum) {
    //         valid = false

    //         validationList[i].ear_no = 'Duplicate Ear no. found for same user.'
    //       } else {
    //         validationList[i].ear_no = ''
    //       }
    //     }
    //     if (!list[i].user_id) {
    //       validationList[i].user_id = 'Please select exhibitor'
    //       valid = false
    //     } else {
    //       validationList[i].user_id = ''
    //     }
    //     if (!list[i].show_type) {
    //       validationList[i].show_type = 'Please select show type'
    //       valid = false
    //     } else {
    //       validationList[i].show_type = ''
    //     }

    //     if (!list[i].breed_category_id) {
    //       validationList[i].breed_category_id = 'Please select category'
    //       valid = false
    //     } else {
    //       validationList[i].breed_category_id = ''
    //     }

    //     if (!list[i].breed_id) {
    //       validationList[i].breed_id = 'Please select breed '
    //       valid = false
    //     } else {
    //       validationList[i].breed_id = ''
    //     }
    //     if (!list[i].class_id) {
    //       validationList[i].class_id = 'Please select class '
    //       valid = false
    //     } else {
    //       validationList[i].class_id = ''
    //     }
    //     if (list[i].group_seq && list[i].variety_seq) {
    //       if (!list[i].group_id) {
    //         validationList[i].group_id = 'Please select group'
    //         valid = false
    //       } else {
    //         validationList[i].group_id = ''
    //       }
    //       if (!list[i].variety_id) {
    //         validationList[i].variety_id = 'Please select Variety'
    //         valid = false
    //       } else {
    //         validationList[i].variety_id = ''
    //       }
    //     }
    //     if (!list[i].group_seq && list[i].variety_seq) {
    //       if (!list[i].variety_id) {
    //         validationList[i].variety_id = 'Please select Variety'
    //         valid = false
    //       } else {
    //         validationList[i].variety_id = ''
    //       }
    //     }
    //     if (list[i].group_seq && !list[i].variety_seq) {
    //       if (!list[i].group_id) {
    //         validationList[i].group_id = 'Please select group'
    //         valid = false
    //       } else {
    //         validationList[i].group_id = ''
    //       }
    //     }
    //     if (!list[i].group_seq && !list[i].variety_seq) {
    //       validationList[i].variety_id = ''
    //       validationList[i].group_id = ''
    //     }

    //     if (list[i].fur_data_validation) {
    //       if (!list[i].has_fur && list[i].has_fur !== 0) {
    //         valid = false
    //         validationList[i].has_fur = 'Please select fur'
    //       } else {
    //         validationList[i].has_fur = ''
    //       }
    //       if (list[i].has_fur && !list[i].fur_type_id.length) {
    //         valid = false
    //         validationList[i].fur_type_id = 'Please select fur type'
    //       } else {
    //         validationList[i].fur_type_id = ''
    //       }
    //       if (list[i].has_fur && !list[i].fur_color) {
    //         valid = false
    //         validationList[i].fur_color = 'Please select fur color'
    //       } else {
    //         validationList[i].fur_color = ''
    //       }
    //     }

    //     // all validation for all checkbox checked

    //     if (valid) {
    //       let newObj = []

    //       for (let j = 0; j < ele.show_id.length; j++) {
    //         const show = ele.show_id[j]
    //         newObj.push({
    //           in_cart: 1,
    //           event_id: event_id,
    //           show_id: show,
    //           show_type: ele.show_type,
    //           user_id: ele.user_id,
    //           ear_no: ele.ear_no,
    //           breed_category_id: ele.breed_category_id,
    //           breed_id: ele.breed_id,
    //           class_id: ele.class_id,
    //           variety_id: ele.variety_seq ? ele.variety_id : '',
    //           group_id: ele.group_seq ? ele.group_id : '',
    //           has_fur: ele.has_fur,
    //           fur_data: ele.fur_type_id.map((s) => {
    //             return {
    //               fur_type_id: s,
    //               fur_id: ele.fur_color,
    //             }
    //           }),
    //           group_seq: ele.group_seq,
    //           variety_seq: ele.variety_seq,
    //         })
    //       }
    //       let validationFlag = true
    //       for (let j = 0; j < newObj.length; j++) {
    //         validationFlag = validationFlag
    //         try {
    //           const res = await post(
    //             `/api/v1/participation/validate_participation`,
    //             newObj[j]
    //           )

    //           // list[i]['isSelected'] = Number(1)
    //           if (res) {
    //             count = count + 1
    //           }
    //         } catch (err) {
    //           validationFlag = validationFlag && false
    //           count = count - 1
    //           // list[i]['isSelected'] = 0
    //           err.response.data.forEach((res) => {
    //             valid = false
    //             validationList[i].ear_no = 'Ear no. already exits'
    //           })
    //         }
    //         if (validationFlag) {
    //           list[i]['isSelected'] = 1
    //         } else {
    //           list[i]['isSelected'] = 0
    //         }
    //       }

    //       setListValidation([...listValidation, validationList])

    //       setList(list)
    //     } else {
    //       setListValidation([...listValidation, validationList])
    //       setList(list)
    //     }
    //   }
    //   $('*').removeClass('changeCursor')
    //   if (count === List.length) {
    //     setisAllSelected(true)
    //   }
    // } else {
    //   let list = [...List]
    //   let data = list.map((s) => {
    //     if (s['isSelected']) {
    //       s['isSelected'] = 0
    //     }
    //     return s
    //   })
    //   setList(data)
    //   setisAllSelected(false)
    // }
  }
  const onAddFormRow = () => {
    setList([
      ...List,
      {
        id: uuidv4(),
        in_cart: 1,
        event_id,
        show_id: [],
        showList: [],
        easy_entry_id: '',
        show_type: '',
        user_id: '',
        user_name: '',
        ear_no: '',
        breed_category_id: '',
        breed_id: '',
        class_id: '',
        variety_id: '',
        group_id: '',
        has_fur: '',
        fur_data_validation: false,
        fur_data: [],
        displayFur: true,
        fur_default_data: [],
        fur_type_id: [],
        fur_id: [],
        fur_color: '',
        group_seq: '',
        variety_seq: '',
        isSelected: 0,
        easyDisable: false,
        type: 1, // 1 = Manual Entry 2= easy entry
      },
    ])
    setListValidation([
      ...listValidation,
      {
        id: '',
        show_id: '',
        show_type: '',
        user_id: '',
        ear_no: '',
        breed_category_id: '',
        breed_id: '',
        class_id: '',
        variety_id: '',
        group_id: '',
        has_fur: '',
        fur_type_id: '',
        fur_id: '',
        group_seq: '',
        variety_seq: '',
        fur_color: '',
      },
    ])
  }
  const handlePreview = async () => {
    // toast.dismiss()
    let list = [...List]

    let data = list.find((s) => s.isSelected === 1)
    if (!data) {
      toast.error('Please select at least one record.', { toastId: 1 })
      return
    }

    let valid = true

    setListValidation(listValidation)
    setList(list)
    if (valid) {
      let ParticipateList = []
      let validationDataList = []

      for (let ind = 0; ind < List.length; ind++) {
        let ele = List[ind]
        for (let j = 0; j < ele.show_id.length; j++) {
          const show = ele.show_id[j]
          let id = uuidv4()
          let obj = {}
          obj.id = id
          obj.in_cart = 1
          obj.event_id = event_id
          obj.show_id = show
          obj.showList = ele.showList
          obj.user_name = ele.user_name
          obj.show_type = ele.show_type
          obj.user_id = ele.user_id
          obj.ear_no = ele.ear_no
          obj.breed_category_id = ele.breed_category_id
          obj.breed_id = ele.breed_id
          obj.class_id = ele.class_id
          obj.variety_id = ele.variety_id
          obj.group_id = ele.group_id
          obj.has_fur = ele.has_fur
          obj.fur_type_id = ele.fur_type_id
          obj.fur_id = ele.fur_id
          obj.fur_color = ele.fur_color
          obj.isSelected = ele.isSelected
          obj.fur_data = ele.has_fur
            ? ele.fur_type_id.map((s) => {
                return {
                  fur_type_id: Number(s),
                  fur_id: Number(ele.fur_color),
                }
              })
            : []
          if (ele.group_seq) {
            obj.group_seq = ele.group_seq
          }
          if (ele.variety_seq) {
            obj.variety_seq = ele.variety_seq
          }
          obj.isDisable = true

          obj.easyDisable = ele.type === 2 ? true : false
          obj.previewScreen = true

          ParticipateList.push(obj)
          validationDataList.push({
            id,
            show_id: '',
            show_type: '',
            user_id: '',
            ear_no: '',
            breed_category_id: '',
            breed_id: '',
            class_id: '',
            variety_id: '',
            group_id: '',
            has_fur: '',
            fur_type_id: '',
            fur_id: '',
            group_seq: '',
            variety_seq: '',
            fur_color: '',
          })
        }
      }

      let successPromise = new Promise((resolve, reject) => {
        let finalData = ParticipateList.filter((s) => s.isSelected === 1)

        finalData = _.sortBy(
          finalData,
          ['show_type', 'user_name'],
          ['asc', 'asc']
        )

        dispatch({
          type: 'ADD_ALL_PREVIEW',
          payload: finalData,
        })
        dispatch({
          type: 'ADD_VALIDATION',
          payload: validationDataList,
        })

        resolve(1)
      })
      const success = await successPromise
      if (success) {
        history.push({
          pathname: '/participate_easy_entry_preview',
          search: `?event_id=${urlEncode(
            event_id
          )}&has_products=${has_products}`,
          state: {
            additionalInfo: additionalInfo,
          },
        })
      }
    } else {
      setListValidation(listValidation)
      setList(list)
    }
  }

  let handleclick = () => {
    history.push({
      pathname: `/participate_manual_entry`,
      search: `?event_id=${urlEncode(event_id)}&has_products=${has_products}`,
      state: {
        additionalInfo: additionalInfo,
      },
    })
  }

  let displayEnd = true
  if (getDate(event?.start_date_time) === getDate(event?.end_date_time)) {
    displayEnd = false
  }
  let selectAll = false
  let checkAll = List.every((s) => s.isSelected === 1)

  if (checkAll) {
    selectAll = true
  }
  return (
    <React.Fragment>
      <div className='page-content bg-gray-101'>
        <section className='section1 bg-gray-101 pages event-list'>
          <Container fluid={true} className='auto-fit'>
            <Row>
              <Col lg={12} className='text-center'>
                <div className='page-title-box justify-content-between'>
                  <h2 className='top-title mb-4'>
                    <span>PARTICIPATE : EASY ENTRY</span>
                  </h2>
                </div>
              </Col>

              <Col lg={12} className='mb-7 event-list'>
                <Card className='shadow-only-hover'>
                  <CardBody className='event-card-body'>
                    <Col lg={12} className='event-box'>
                      <div className='row'>
                        <div className='col-lg-4 col-xl-3 box-head'>
                          <div className='row'>
                            <div className='col-lg-5 col-xl-5 mb-4 mt-4'>
                              Event Date:{' '}
                            </div>
                            <div className='col-lg-7 col-xl-7 pl-0'>
                              <p className='highlight'>
                                {event?.start_date_time &&
                                  getDate(event.start_date_time)}
                                <br />

                                {displayEnd && 'To'}
                                {displayEnd && <br />}
                                {displayEnd && getDate(event?.end_date_time)}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className='col-lg-5 col-xl-3 box-head mt-3'>
                          {event?.event_name}{' '}
                        </div>
                        <div className='col-lg-3 col-xl-3 box-head mt-3'>
                          {event?.address_1}
                          {event?.address_1 ? ', ' : ''}
                          <br />
                          {event?.address_2}
                          {event?.address_2 ? ', ' : ''}
                          {event?.city}
                          {event?.city ? ', ' : ''}
                          {event?.state_name}
                          {event?.state_name ? ', ' : ''}
                          {event?.zip_code}
                          {event?.zip_code ? ', ' : ''}
                          {event?.country_name}
                        </div>
                        <div className='col-lg-6 col-xl-3 box-head'>
                          <div className='row'>
                            <div className='col-lg-4 col-xl-4 mt-4'>
                              Deadline:
                            </div>
                            {event?.deadlines?.map((item, i) => {
                              if (
                                item?.deadline_status === 'upcoming' &&
                                count === 0
                              ) {
                                count++

                                return (
                                  <div className='col-lg-8 col-xl-8 view-dline mt-2'>
                                    <span className='deadline'>
                                      <div>
                                        {getDate(item?.date_time)}
                                        <br />
                                        {`${getTime(item?.date_time)} CST`}
                                      </div>
                                    </span>
                                    <div className='input-group-append'>
                                      <span className='input-group-text'>
                                        1st
                                      </span>
                                    </div>
                                  </div>
                                )
                              }
                            })}
                          </div>
                        </div>
                      </div>
                    </Col>
                  </CardBody>
                </Card>
              </Col>

              <div className='col-md-12 mb-7'>
                <div className='card shadow-only-hover'>
                  <div className='card-body event-card-body'>
                    <Col lg={12} className='mt-0'>
                      {/* <hr /> */}
                      <Col lg={12} className='manual-fill-box fs-20'>
                        Event Type: {additionalInfo?.event_type}
                      </Col>
                      <Col lg={12} className='mt-4 manual-empty-box fs-20'>
                        Fill in the details of the animals that you want to{' '}
                        <span>PARTICIPATE </span>with.
                      </Col>
                      {List.map((participate, i) => (
                        <ParticipateEasyEntryList
                          key={i}
                          index={i}
                          showData={showData}
                          showList={showList}
                          list={participate}
                          exhibitorList={exhibitorList}
                          handleChange={handleChange}
                          handleRemove={handleRemove}
                          validationList={listValidation}
                          hasFurData={hasFurData}
                        />
                      ))}
                    </Col>

                    <hr />
                    <Col lg={12} className='text-center mt-5 mb-3 easy-box'>
                      <Row>
                        <Col lg={4}>
                          <p className='text-black mt-1'>
                            ( More than 25 entries won’t be added to your Rabbit
                            Bank )
                          </p>
                        </Col>
                        <Col lg={4}>
                          <button
                            type='button'
                            className='btn btn-primary mb-3'
                            onClick={() => onAddFormRow()}
                          >
                            + ADD MORE
                          </button>
                          <button
                            type='button'
                            className='btn btn-primary btn-easy mb-3'
                            onClick={() => {
                              handlePreview()
                            }}
                          >
                            PREVIEW
                          </button>
                        </Col>
                        <Col lg={4}>
                          <input
                            className='form-check-input mr-2 pr-2'
                            type='checkbox'
                            id='check'
                            onClick={(e) => {
                              handleSelectAll(e)
                            }}
                            checked={selectAll}
                            value={selectAll}
                          />
                          <label className='form-check-label'>
                            {' '}
                            Choose to select all ENTRIES
                          </label>
                        </Col>
                      </Row>
                    </Col>
                  </div>
                </div>
              </div>
            </Row>
          </Container>
        </section>
        {showModal === true && <Popup handleClicked={handleclick} />}
        {/* {ShowPopUp === true && <Popup handleClicked={handleclick} />} */}
      </div>
    </React.Fragment>
  )
}
export default ParticipateEasyEntry

const hasErNo = (er_no, user_id, show_ids, list) => {
  let find = list.find((s) => {
    let intersection = _.intersection(show_ids, s.show_id)

    if (s.user_id === user_id && s.ear_no === er_no && intersection.length) {
      return s
    }
    return undefined
  })

  return Boolean(find)
}
