import { put, takeEvery } from 'redux-saga/effects'
import {
  GET_ALL_ADITIONAL_PRODUCT,
  SET_LOADING_ADDITIONAL_PRODUCT,
  SET_ADITIONAL_PRODUCT_ERROR,
  SAVE_ADITIONAL_PRODUCT,
} from './actionTypes'
import { get, post } from '../../helpers/api_helper'
import { getAdditionalProducts } from './actions'

import { toast } from 'react-toastify'
//
function* getAllAdditionalProduct({ payload }) {
  try {
    yield put({
      type: SET_LOADING_ADDITIONAL_PRODUCT,
    })
    const response = yield get(
      `api/v1/events/${payload}/products/all?published=1`
    )

    yield put(getAdditionalProducts(response.data))
  } catch (error) {
    yield put({
      type: SET_ADITIONAL_PRODUCT_ERROR,
      payload: 'Error in additionalProduct()',
    })
  }
}

function* onSaveAdditionalProduct({ payload: { product, history } }) {
  try {
    const response = yield post(`api/v1/additional-product`, product)
    toast.success(response.data)

    setTimeout(() => {
      history.push('/shopping_cart')
    }, 1000)
  } catch (err) {
    let { status } = err.response
    if (status === 409) {
      // toast.error('You can only add one product in add to cart')
      toast.error(
        'Something is not right! either you have a pending shopping cart to process or the event you wish to participate may be restricted by the event secretary. If you have any questions please get in touch with the event secretary.'
      )
      setTimeout(() => {
        history.push({
          pathname: `/shopping_cart`,
          state: {
            hasAdditionalProduct: true,
            hasShoppingCart: true,
            showAdditional: true,
          },
        })
      }, 1500)
    }
    yield put({
      type: SET_ADITIONAL_PRODUCT_ERROR,
      payload: 'Error in additionalProduct()',
    })
  }
}

function* getAdditionalProduct() {
  yield takeEvery(GET_ALL_ADITIONAL_PRODUCT, getAllAdditionalProduct)
  yield takeEvery(SAVE_ADITIONAL_PRODUCT, onSaveAdditionalProduct)
}

export default getAdditionalProduct
