import React from 'react';
import ContactUs from './Contact/ContactUs';
import { Link } from 'react-router-dom';

const Footer = (props) => {
  return (
    <React.Fragment>
      <footer className="footer-page">
        <div>
          <div className="row">
            <div className="col-md-5">
              <div className="text-left d-sm-block rights">
                © {new Date().getFullYear()} Easy2Show. All Rights Reserved.
              </div>
            </div>
            <div className="col-md-5">
              <div className="text-left d-sm-block rights">
                <Link
                  to={`${props?.authTerm ? '/user' : null}/terms_conditions`}
                  className="mb-3"
                >
                  Website Terms, Conditions & Privacy Policy
                </Link>{' '}
              </div>
            </div>
            <ContactUs />
          </div>
        </div>
      </footer>
    </React.Fragment>
  );
};

export default Footer;