import Hashids from 'hashids';
import _ from 'lodash';
const UrlEncode = () => {
  const hashids = new Hashids(
    /* salt: */ 'EasyToShow',
    /* minLength: */ 7,
    /* alphabet: */ 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz1234567890',
    /* these chars can't be next to one another: */ '?/&'
  );
  const urlEncode = (id) => {
    let ids = '';
    ids = hashids.encode(id);

    return ids;
  };
  const urlDecode = (hash) => {
    let ids = '';
    ids = hashids.decode(hash);
    ids = _.first(ids);
    return ids;
  };
  return {
    urlEncode,
    urlDecode,
  };
};

export default UrlEncode;
