import { put, takeEvery } from 'redux-saga/effects'

import {
  ALL_LISTING_ANIMAL_INQUIRY,
  ALL_LISTING_EQUIPMENT_INQUIRY,
  ALL_LISTING_INQUIRY_SUCCESS,
  ALL_LISTING_JUDGE_INQUIRY,
  SET_LOADING,
} from './actionType'
import { get } from '../../../helpers/api_helper'
import { toast } from 'react-toastify'
import { PaGINATION_DATA } from '../../pagination/actionTypes'

function* InquiryListingAnimalSaga({ payload }) {
  try {
    // if (Number(payload?._page) === 1) {
    // yield put({
    //   type: SET_LOADING,
    // })
    // }

    const res = yield get(
      `/api/v1/easyfied/animal-listing/user/contact-us/enquiry-list?_page=${
        payload?._page || ''
      }&_limit=${payload?._limit || ''}&_sort=${payload?._sort || ''}&_order=${
        payload?._order || ''
      }`
    )
    yield put({
      type: ALL_LISTING_INQUIRY_SUCCESS,
      payload: res?.data || [],
    })
    if (payload?._limit) {
      const limit = payload._limit || 10
      const TotalPage = Math.ceil(res.totalRecords / limit) || 1
      const paginationObj = {
        page: payload._page,
        totalPage: TotalPage,
        totalRecords: res?.totalRecords,
      }
      yield put({
        type: PaGINATION_DATA,
        payload: paginationObj,
      })
    }
  } catch (err) {
    if (err?.response?.data) {
      toast.error(err?.response?.data)
    }
  }
}
function* InquiryListingJudgeSaga({ payload }) {
  try {
    // if (Number(payload?._page) === 1) {
    // yield put({
    //   type: SET_LOADING,
    // })
    // }

    const res = yield get(
      `/api/v1/admin/easyfied/sales-services-judge/contact-us/enquiry-list-owner?_page=${
        payload?._page || ''
      }&_limit=${payload?._limit || ''}&_sort=${payload?._sort || ''}&_order=${
        payload?._order || ''
      }`
    )
    yield put({
      type: ALL_LISTING_INQUIRY_SUCCESS,
      payload: res?.data || [],
    })
    if (payload?._limit) {
      const limit = payload._limit || 10
      const TotalPage = Math.ceil(res.totalRecords / limit) || 1
      const paginationObj = {
        page: payload._page,
        totalPage: TotalPage,
        totalRecords: res?.totalRecords,
      }
      yield put({
        type: PaGINATION_DATA,
        payload: paginationObj,
      })
    }
  } catch (err) {
    if (err?.response?.data) {
      toast.error(err?.response?.data)
    }
  }
}
function* InquiryListingEquipmentSaga({ payload }) {
  try {
    // if (Number(payload?._page) === 1) {
    // yield put({
    //   type: SET_LOADING,
    // })
    // }

    const res = yield get(
      `/api/v1/easyfied/equipment-product/contact-us/enquiry-list-owner?_page=${
        payload?._page || ''
      }&_limit=${payload?._limit || ''}&_sort=${payload?._sort || ''}&_order=${
        payload?._order || ''
      }`
    )
    yield put({
      type: ALL_LISTING_INQUIRY_SUCCESS,
      payload: res?.data || [],
    })
    if (payload?._limit) {
      const limit = payload._limit || 10
      const TotalPage = Math.ceil(res.totalRecords / limit) || 1
      const paginationObj = {
        page: payload._page,
        totalPage: TotalPage,
        totalRecords: res?.totalRecords,
      }
      yield put({
        type: PaGINATION_DATA,
        payload: paginationObj,
      })
    }
  } catch (err) {
    if (err?.response?.data) {
      toast.error(err?.response?.data)
    }
  }
}

function* EasyfidesInquirySaga() {
  yield takeEvery(ALL_LISTING_ANIMAL_INQUIRY, InquiryListingAnimalSaga)
  yield takeEvery(ALL_LISTING_JUDGE_INQUIRY, InquiryListingJudgeSaga)
  yield takeEvery(ALL_LISTING_EQUIPMENT_INQUIRY, InquiryListingEquipmentSaga)
}

export default EasyfidesInquirySaga
