import {
  ANCESTRAL_ARCHIVE_LIST,
  ANCESTRAL_ARCHIVE_DETAIL,
  ANCESTRAL_ARCHIVE_UPDATE,
} from './actionTypes'

export const ancestorList = (data) => {
  return {
    type: ANCESTRAL_ARCHIVE_LIST,
    payload: data,
  }
}

export const ancestorDetail = (data) => {
  return {
    type: ANCESTRAL_ARCHIVE_DETAIL,
    payload: data,
  }
}

export const ancestorUpdate = (data) => {
  return {
    type: ANCESTRAL_ARCHIVE_UPDATE,
    payload: data,
  }
}
