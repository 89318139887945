import {
  GET_ALL_ADDITIONAL_PRODUCT_SUCCESS,
  SET_ADITIONAL_PRODUCT_ERROR,
  SET_LOADING_ADDITIONAL_PRODUCT,
  ADITIONAL_PRODUCT_INCREMENT,
  ADITIONAL_PRODUCT_DECREMENT,
} from './actionTypes';

//
const INIT_STATE = {
  additional_products: [],
  loading: true,
  error: {},
};

const AdditionalProducts = (state = INIT_STATE, action) => {

  switch (action.type) {
    case SET_LOADING_ADDITIONAL_PRODUCT:
      return {
        ...state,
        
        loading: true,
      };
    case GET_ALL_ADDITIONAL_PRODUCT_SUCCESS:
      return {
        ...state,
        additional_products: action.payload,
        
        loading: false,
      };
    case ADITIONAL_PRODUCT_INCREMENT:
      return {
        ...state,
        additional_products: state.additional_products.map((item) =>
          item.product_id === action.payload.product_id
            ? { ...item, unit:  action.payload.unit ,  }
            : item,
        ),
      };

    case ADITIONAL_PRODUCT_DECREMENT:
        return {
          ...state,
          additional_products: state.additional_products.map((item) =>
            item.product_id === action.payload.product_id
              ? { ...item, unit:  action.payload.unit  }
              : item,
          ),
        };
    case SET_ADITIONAL_PRODUCT_ERROR:
      return {
        ...state,
        additional_products:[],
        error: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};

export default AdditionalProducts;
