import { useCallback, useState } from 'react'
import { Col } from 'reactstrap'
import Cropper from 'react-easy-crop'
import Slider from 'react-rangeslider'
import 'react-rangeslider/lib/index.css'

// import getCroppedImg, { dataURLtoFile } from 'src/helpers/cropImage'

import { Button } from '../Atoms'
import getCroppedImg, { dataURLtoFile } from '../../../helpers/cropImage'

const ImageCopper = ({
  selectedFiles,
  setCroppedImage,
  setFieldValue,
  croppedImage,
  inputName,
  cropRectangle,
  aspect = 4 / 3,
}) => {
  const [isSummit, setIsSummit] = useState(false)
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [rotation, setRotation] = useState(0)
  const [zoom, setZoom] = useState(1)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)

  const onCropComplete = useCallback((va, cropXYWidth) => {
    setCroppedAreaPixels(cropXYWidth)
  }, [])
  const showCroppedImage = useCallback(async () => {
    setIsSummit(true)
    try {
      const finalImage = await getCroppedImg(
        selectedFiles,
        croppedAreaPixels,
        rotation
      )

      // console.log('donee', { croppedImage })
      const date = new Date().getTime()

      const fileWithName = dataURLtoFile(
        finalImage,
        `${inputName || 'profile'}-${date}.png`
      )
      // setProfile(file)

      setIsSummit(false)
      setFieldValue(inputName, fileWithName)
      setCroppedImage(finalImage)
    } catch (e) {
      console.error(e)
    }
  }, [croppedAreaPixels, rotation])

  return (
    <>
      <div
        style={{
          position: 'relative',
          width: '100%',
          height: 200,
          background: '#333',
        }}
      >
        <Cropper
          image={selectedFiles}
          crop={crop}
          rotation={rotation}
          zoom={zoom}
          // cropSize={cropSize}
          cropShape={cropRectangle ? 'rect' : 'round'}
          showGrid
          aspect={cropRectangle ? aspect : 1 / 1}
          onCropChange={setCrop}
          onRotationChange={setRotation}
          onCropComplete={onCropComplete}
          onZoomChange={setZoom}
        />
      </div>
      <Col xl={12} className='h-100'>
        <Slider
          className='mt-4'
          value={zoom}
          min={1}
          max={3}
          step={0.1}
          aria-labelledby='Zoom'
          onChange={(value) => setZoom(value.toFixed(2))}
          // onChange={(e, zValue) => setZoom(zValue)}
        />
        <Button
          onClick={showCroppedImage}
          variant='contained'
          //   className='btn btn-primary w-lg waves-effect waves-light mt-4'
          color='primary'
          title=' SHOW RESULTS'
          type='button'
          loadingProps={{
            isLoading: true,
            isSubmitting: isSummit,
          }}
        />
      </Col>
      <Col md='12' className='mt-4'>
        {croppedImage ? (
          <img
            src={croppedImage}
            alt='preview-img'
            style={{
              width: cropRectangle ? 200 : 50,
              height: cropRectangle ? 150 : 50,
              borderRadius: cropRectangle ? null : '50%',
            }}
          />
        ) : null}
      </Col>
    </>
  )
}

export default ImageCopper
