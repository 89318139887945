export const GET_ALL_EXHIBITOR_LIST = 'GET_ALL_EXHIBITOR_LIST';
export const GET_ALL_EXHIBITOR_LIST_SUCCESS = 'GET_ALL_EVENT_SUCCESS';
export const GET_ALL_EXHIBITOR_LIST_FAIL = 'GET_ALL_EVENT_FAIL';
export const SET_EXHIBITOR_LOADING = 'SET_EVENT_LOADING';

export const GET_CATEGORY_LIST = 'GET_CATEGORY_LIST';
export const GET_CATEGORY_LIST_SUCCESS = 'GET_CATEGORY_LIST_SUCCESS';
export const GET_CATEGORY_LIST_FAIL = 'GET_CATEGORY_LIST_FAIL';
export const SET_CATEGORY_LOADING = 'SET_CATEGORY_LOADING';

export const GET_CATEGORY_LIST_BY_EVENT = 'GET_CATEGORY_LIST_BY_EVENT';
export const GET_CATEGORY_LIST_BY_EVENT_SUCCESS =
  'GET_CATEGORY_LIST_BY_EVENT_SUCCESS';
export const GET_CATEGORY_LIST_BY_EVENT_FAIL =
  'GET_CATEGORY_LIST_BY_EVENT_FAIL';
export const SET_CATEGORY_BY_EVENT_LOADING = 'SET_CATEGORY_BY_EVENT_LOADING';

export const GET_ENTRY_LIST = 'GET_ENTRY_LIST';
export const GET_ENTRY_LIST_SUCCESS = 'GET_ENTRY_LIST_SUCCESS';
export const GET_ENTRY_LIST_FAIL = 'GET_ENTRY_LIST_FAIL';
export const SET_ENTRY_LOADING = 'SET_ENTRY_LOADING';

export const API_ERROR = 'API_ERROR';
