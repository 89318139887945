import {
  ADD_MORE_MEDIA_EQUIPMENT,
  ADD_EQUIPMENT,
  ALL_MEDIA_LIST_EQUIPMENT,
  DELETE_EQUIPMENT,
  DELETE_MEDIA_EQUIPMENT,
  LISTING_EQUIPMENT,
  SAVE_MEDIA_EQUIPMENT,
  UPDATE_MEDIA_EQUIPMENT,
  UPDATE_EQUIPMENT,
  LISTING_EQUIPMENT_FRONT,
} from './actionType'

//
export const addMoreMediaEquipment = (data) => ({
  type: ADD_MORE_MEDIA_EQUIPMENT,
  payload: data,
})

export const deleteMediaEquipment = (data) => ({
  type: DELETE_MEDIA_EQUIPMENT,
  payload: data,
})

export const addEquipment = (data) => {
  return {
    type: ADD_EQUIPMENT,
    payload: data,
  }
}
export const updateEquipment = (data) => {
  return {
    type: UPDATE_EQUIPMENT,
    payload: data,
  }
}
export const listingEquipment = (data) => {
  return {
    type: LISTING_EQUIPMENT,
    payload: data,
  }
}
export const listingEquipmentFront = (data) => {
  return {
    type: LISTING_EQUIPMENT_FRONT,
    payload: data,
  }
}

export const EquipmentDelete = (data) => {
  return {
    type: DELETE_EQUIPMENT,
    payload: data,
  }
}

export const saveEquipmentMedia = (data) => {
  return {
    type: SAVE_MEDIA_EQUIPMENT,
    payload: data,
  }
}

export const updateEquipmentMedia = (data) => {
  return {
    type: UPDATE_MEDIA_EQUIPMENT,
    payload: data,
  }
}

export const EquipmentMediaListing = (data) => {
  return {
    type: ALL_MEDIA_LIST_EQUIPMENT,
    payload: data,
  }
}
