import {
  ADD_PEDIGREE_HEADER,
  DELETE_PEDIGREE_HEADER,
  DELETE_PEDIGREE_IMAGE,
  GET_ALL_PEDIGREE_HEADER,
  GET_ALL_PEDIGREE_HEADER_OPTIONS,
  GET_PEDIGREE_HEADER,
  UPDATE_PEDIGREE_HEADER,
} from './actionTypes'

export const getAllPedigreeHeaderList = (data) => {
  return {
    type: GET_ALL_PEDIGREE_HEADER,
    payload: data,
  }
}

export const addPedigreeHeader = (data, callback) => {
  return {
    type: ADD_PEDIGREE_HEADER,
    payload: { data, callback },
  }
}

export const getPedigreeHeader = (data) => {
  return {
    type: GET_PEDIGREE_HEADER,
    payload: data,
  }
}

export const updatePedigreeHeader = (data, callback) => {
  return {
    type: UPDATE_PEDIGREE_HEADER,
    payload: { data, callback },
  }
}
export const deletePedigreeHeader = (data, callback) => {
  return {
    type: DELETE_PEDIGREE_HEADER,
    payload: { data, callback },
  }
}

export const getAllPedigreeHeaderListForOption = (data) => {
  return {
    type: GET_ALL_PEDIGREE_HEADER_OPTIONS,
    payload: data,
  }
}

export const deletePedigreeImage = (data, callback) => {
  return {
    type: DELETE_PEDIGREE_IMAGE,
    payload: { data, callback },
  }
}
