import { put, takeEvery } from 'redux-saga/effects'

import { ALL_COUNTRY_LIST, ALL_COUNTRY_LIST_SUCCESS } from './actionType'

import { toast } from 'react-toastify'
import { get } from '../../helpers/api_helper'
import _ from 'lodash'

function* CountryList() {
  try {
    let res = []
    res = yield get(`/api/v1/address/country/all`)
    let finalData = res?.data || []
    if (finalData) {
      finalData = _.orderBy(
        finalData,
        [(_country) => _country.country_name.toLowerCase()],
        ['asc']
      )
    }
    res = finalData

    yield put({
      type: ALL_COUNTRY_LIST_SUCCESS,
      payload: res,
    })
  } catch (err) {
    if (err?.response?.data) {
      toast.error(err?.response?.data)
    }
  }
}
function* COuntrySagas() {
  yield takeEvery(ALL_COUNTRY_LIST, CountryList)
}

export default COuntrySagas
