import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js'
import React, { Fragment, useEffect, useState } from 'react'
import { Redirect, useHistory, useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Col, Input, Row } from 'reactstrap'

// import { getAllEventCart, getAllShopCartValue } from '../store/actions';

export default function CheckoutForm(props) {
  const stripe = useStripe()
  const elements = useElements()
  const location = useLocation()

  const query = new URLSearchParams(location.search)
  const clientSecret = query.get('client_secret')

  let info = localStorage.getItem('purchaseInfo')
  if (info) info = JSON.parse(info)

  const [isLoading, setIsLoading] = useState(false)
  const [isAccept, setIsAccept] = useState(false)

  useEffect(() => {
    window.history.forward()
    window.history.pushState(null, document.title, window.location.href)
    window.addEventListener('popstate', function (event) {
      window.history.pushState(null, document.title, window.location.href)
    })
    // debugger
    setIsLoading(true)
    setIsAccept(false)
    if (!stripe) {
      setIsLoading(false)
      return
    }

    if (!clientSecret) {
      setIsLoading(false)
      return
    }

    setTimeout(() => {
      setIsLoading(false)
    }, 3000)
  }, [clientSecret, stripe])

  if (!info) {
    return <Redirect to="/login" />
  }
  const handleSubmit = async (e) => {
    e.preventDefault()
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return
    }

    setIsLoading(true)
    // debugger
    await stripe
      .confirmPayment({
        elements,
        confirmParams: {
          // Make sure to change this to your payment completion page
          return_url: `${process.env.REACT_APP_URL}process-payment`,
        },
        // redirect: 'always',
      })
      .then((res) => {
        // console.log('🚀 ~ file: CheckoutForm.js ~ line 74 ~ .then ~ res', res);
        if (res.error) {
          setIsLoading(false)
          // history.push('/plan_shopping_cart');

          if (
            res.error.code !== 'incomplete_number' &&
            res.error.code !== 'incomplete_expiry' &&
            res.error.code !== 'invalid_expiry_month_past' &&
            res.error.code !== 'incomplete_cvc'
          ) {
            toast.error(res?.error?.message || 'invalid card details')
          } else if (res?.error?.message) {
            toast.error(res?.error?.message)
          }
        }

        console.log('res', res)
      })
      .catch((err) => {
        if (
          err.code !== 'incomplete_number' ||
          err.code !== 'incomplete_expiry' ||
          err.code !== 'invalid_expiry_month_past' ||
          err.code !== 'incomplete_cvc' ||
          err.type !== 'fetch_error'
        ) {
          console.log('first///...,', err.type)
          if (err.type === 'fetch_error') {
            toast.error(
              'You are currently offline. Please connect to the internet and try again!'
            )
          } else {
            toast.error(err.message || 'invalid card details')
          }
        }
        // history.push('/plan_shopping_cart');
        setIsLoading(false)
      })
    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    // if (error.type === 'card_error' || error.type === 'validation_error') {
    //   setMessage(error.message);
    // } else {
    //   setMessage('An unexpected error occured.');
    // }
  }

  let name = ''
  if (info) {
    name = info.first_name + ' ' + info.last_name
  }
  return (
    <Fragment>
      <form id="payment-form" onSubmit={(e) => handleSubmit(e)}>
        <Row>
          <Col lg="12">
            <Row>
              <Col xl={6}>
                <div className="mb-4">
                  <Input
                    type="text"
                    className=""
                    readOnly
                    name="exhibitor_name"
                    id="exhibitor_name"
                    placeholder="Exhibitor Name"
                    value={name}
                  />
                </div>
              </Col>

              <Col xl={6}>
                <div className="mb-4">
                  <Input
                    type="email"
                    className=""
                    name="email"
                    id="email"
                    readOnly
                    placeholder="Exhibitor Email Id "
                    value={`${info && info.email}`}
                  />
                </div>
              </Col>
              <PaymentElement id="payment-element" />
              {/* <CardElement /> */}
              {/* <Col lg={12}>
              <div className="mt-4 text-center">
                <Button
                  type="submit"
                  color="success"
                  disabled={!stripe || isLoading}
                  className="w-lg waves-effect waves-light"
                >
                  PAY
                </Button>
              </div>
            </Col> */}
            </Row>
          </Col>
        </Row>
        <Row className="final-pay mt-4 mx-1">
          <Col lg="6">
            <h4>Final Payment</h4>
          </Col>
          <Col lg="6" className="text-right">
            <span>${((info && info.totalAmount) || 0).toFixed(2)}</span>
          </Col>
        </Row>

        <Row>
          <Col lg="12">
            <div className="card mt-8 shop-card text-center">
              <div className="card-body">
                <h4 className="mb-3">
                  NOTE – ALL PURCHASES ARE FINAL AND NON-REFUNDABLE
                </h4>
                <p>
                  “You” as the show secretary understands and is agreeing to the
                  fact that your <strong>Easy2Show</strong> software usage
                  purchase is
                  <strong> NON-REFUNDABLE</strong> for any reason. You have the
                  ability to change the show date prior to the show date in
                  order to “reuse” the software usage fee you are now
                  purchasing. <strong>BUT</strong> if you choose to change the
                  show date and there are entries already made for that
                  Event/Shows it is the sole responsibility of you and “Your”
                  club to personally contact all entered exhibitors and notify
                  them of the change. If those entries/exhibitors have already
                  paid online for their entry, it is the <strong>SOLE</strong>{' '}
                  responsibility of you and your club to manage the money if the
                  Event/Show is cancelled for any reason since{' '}
                  <strong> Easy2Show</strong> does <strong>NOT</strong> issue
                  refunds for any reason. Your club <strong>MUST</strong>{' '}
                  identify and post in the “document” and “show information”
                  section within <strong>Easy2Show</strong> for each event, what
                  your club will do with any exhibitor/entry fees paid if an
                  Event/show is postponed, cancelled or changed in any way. If
                  you and your club decide to refund entry moneys for any reason
                  to any exhibitor you agree that any “fee” paid to{' '}
                  <strong>Easy2Show</strong> to process those online entries, is
                  not refundable to you, as the show secretary, your club as the
                  host club or any of your exhibitors. You as the show secretary
                  and your club further agree that any exhibitor that might
                  “dispute” credit card charges with
                  <strong> Easy2Show</strong> for their online entries for any
                  reason, that your club will ultimately refund{' '}
                  <strong>Easy2Show</strong> if <strong>Easy2Show</strong> has
                  to refund the exhibitor. Please see additional terms and
                  conditions for using <strong> Easy2Show</strong> located in
                  your secretary Dashboard in the information center.
                </p>

                <Col xl={12}>
                  <div className="mt-3 agree-check text-left">
                    <input
                      className="form-check-input mr-2"
                      type="checkbox"
                      id="check"
                      checked={isAccept}
                      onChange={(e) => setIsAccept(!isAccept)}
                    />
                    <label
                      className={'form-check-label p-r-3 mt-1'}
                      htmlFor="check"
                    >
                      ACCEPT
                    </label>
                  </div>
                </Col>
              </div>
            </div>
          </Col>
        </Row>

        <Col lg={12} className="text-center mt-5 mb-5">
          <button
            type="submit"
            className="btn btn-primary btn-easy"
            disabled={!stripe || isLoading || !isAccept}
          >
            PROCEED TO PAY
          </button>
        </Col>
      </form>
    </Fragment>
  )
}

/**

 */
