import { put, takeEvery } from 'redux-saga/effects'

import {
  ALL_LISTING_JUDGE,
  Add_JUDGE,
  DELETE_JUDGE,
  LISTING_JUDGE,
  LISTING_JUDGE_SUCCESS,
  UPDATE_JUDGE,
} from './actionType'
import { PATCH, del, get, patch, post } from '../../../helpers/api_helper'
import { toast } from 'react-toastify'
import { PaGINATION_DATA } from '../../pagination/actionTypes'

function* JudgeCreateSaga({ payload }) {
  try {
    const res = yield post(
      `/api/v1/admin/easyfied/sales-services-judge/create`,
      payload.data
    )
    if (res?.data) {
      toast.success(res?.data)
    }
    setTimeout(() => {
      payload.history.push('/easyfides/profile/judge')
    }, 1500)
  } catch (err) {
    if (err?.response?.data) {
      document.getElementById('submit-btn').removeAttribute('disabled')
      toast.error(err?.response?.data)
    }
  }
}
function* JudgeUpdateSaga({ payload }) {
  try {
    const res = yield PATCH(
      `/api/v1/admin/easyfied/sales-services-judge/update/${payload?.ID}`,
      payload?.data
    )
    if (res?.data) {
      toast.success(res?.data)
    }
    setTimeout(() => {
      payload.history.push('/easyfides/profile/judge')
    }, 1500)
  } catch (err) {
    if (err?.response?.data) {
      document.getElementById('submit-btn').removeAttribute('disabled')
      toast.error(err?.response?.data)
    }
  }
}
function* JudgeDeleteSaga({ payload }) {
  try {
    const res = yield del(
      `/api/v1/admin/easyfied/sales-services-judge/delete/${payload?.ID}`
    )
    if (res?.data) {
      toast.success(res?.data)
      payload.setReload(!payload.reload)
    }
  } catch (err) {
    if (err?.response?.data) {
      document.getElementById('submit-btn').removeAttribute('disabled')
      toast.error(err?.response?.data)
    }
  }
}
function* JudgeListingSaga({ payload }) {
  try {
    const res = yield get(
      `/api/v1/admin/easyfied/sales-services-judge/all?_page=${payload?._page}&_limit=${payload?._limit}&_sort=${payload?._sort}&_order=${payload?._order}`
    )
    yield put({
      type: LISTING_JUDGE_SUCCESS,
      payload: res?.data || [],
    })
    if (payload?._limit) {
      const limit = payload._limit || 10
      const TotalPage = Math.ceil(res.totalRecords / limit) || 1
      const paginationObj = {
        page: payload._page,
        totalPage: TotalPage,
        totalRecords: res?.totalRecords,
      }
      yield put({
        type: PaGINATION_DATA,
        payload: paginationObj,
      })
    }
  } catch (err) {
    if (err?.response?.data) {
      document.getElementById('submit-btn').removeAttribute('disabled')
      toast.error(err?.response?.data)
    }
  }
}
function* allJudgeListingSaga({ payload }) {
  try {
    const res = yield get(
      `/api/v1/admin/easyfied/sales-services-judge/all-listing?_page=${
        payload?._page
      }&_limit=${payload?._limit}&_sort=${payload?._sort}&_order=${
        payload?._order
      }&serve_as_registrar=${payload?.serve_as_registrar}&serve_as_judge=${
        payload?.serve_as_judge
      }&travel_radius_id=${payload?.travel_radius_id || ''}&state_id=${
        payload?.state_id || ''
      }&city=${payload?.city || ''}`
    )
    yield put({
      type: LISTING_JUDGE_SUCCESS,
      payload: res?.data || [],
    })
    if (payload?._limit) {
      const limit = payload._limit || 10
      const TotalPage = Math.ceil(res.totalRecords / limit) || 1
      const paginationObj = {
        page: payload._page,
        totalPage: TotalPage,
        totalRecords: res?.totalRecords,
      }
      yield put({
        type: PaGINATION_DATA,
        payload: paginationObj,
      })
    }
  } catch (err) {
    if (err?.response?.data) {
      document.getElementById('submit-btn').removeAttribute('disabled')
      toast.error(err?.response?.data)
    }
  }
}
function* EasyfidesJudgeSaga() {
  yield takeEvery(Add_JUDGE, JudgeCreateSaga)
  yield takeEvery(LISTING_JUDGE, JudgeListingSaga)
  yield takeEvery(ALL_LISTING_JUDGE, allJudgeListingSaga)
  yield takeEvery(UPDATE_JUDGE, JudgeUpdateSaga)
  yield takeEvery(DELETE_JUDGE, JudgeDeleteSaga)
}

export default EasyfidesJudgeSaga
