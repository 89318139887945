import React, { useEffect } from 'react'
import { Card, CardHeader, Spinner, Table } from '../Atoms'
import { CardBody, Col, Row } from 'reactstrap'
import Breadcrumb from '../../../common/Breadcrumb'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
  allCountryList,
  allStateList,
  deletePedigreeHeader,
  getAllPedigreeHeaderList,
} from '../../../store/actions'
import _ from 'lodash'
import { UrlEncodeHook } from '../../../hooks'
import Swal from 'sweetalert2'
import { toast } from 'react-toastify'
import Pagination from '../../../HerdManagement/Pagination/Pagination'
import { getDateWithFormat } from '../../../helpers/date_helper'
import moment from 'moment'

const HerdHeader = (props) => {
  const { urlEncode } = UrlEncodeHook()
  const { pedigreeHeaderList, pedigreeHeaderListLoading, page, LIMIT } =
    useSelector((state) => {
      return {
        pedigreeHeaderList:
          state?.herdManagement?.pedigreeHeader?.pedigreeHeader
            ?.pedigreeHeaderList || [],
        pedigreeHeaderListLoading:
          state?.herdManagement?.pedigreeHeader?.pedigreeHeader?.loading,
        page: state?.herdManagement?.pagination?.page || 1,
        LIMIT: state?.herdManagement?.pagination?.limit || 1,
      }
    })
  const { CountryList } = useSelector((state) => {
    return {
      CountryList: _.map(state?.Country?.CountryList, (c) => ({
        label: c?.country_name,
        value: c?.country_id,
      })),
    }
  })
  const { StateList } = useSelector((state) => {
    return {
      StateList: _.map(state?.State?.StateList, (c) => ({
        label: c?.state_code,
        value: c?.state_id,
      })),
    }
  })
  const dispatch = useDispatch()
  useEffect(() => {
    const data = {
      params: {
        _limit: LIMIT,
        _page: 1,
      },
    }
    dispatch(getAllPedigreeHeaderList(data))
    dispatch(allCountryList())
    dispatch(allStateList())
  }, [])

  const paginationClick = (nextPage) => {
    const { selected } = nextPage
    const finalPage = (Number(selected) || 0) + 1
    const data = {
      params: {
        _limit: LIMIT,
        _page: finalPage,
      },
    }
    dispatch(getAllPedigreeHeaderList(data))
  }

  const deleteApi = (body) => {
    return new Promise((resolve, reject) => {
      dispatch(
        deletePedigreeHeader(body, (err, data) => {
          if (err) {
            reject(err)
          } else {
            resolve(data)
          }
        })
      )
    })
  }
  const deleteHeader = async (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      width: '380px',
      showCancelButton: true,
      confirmButtonColor: '#062575',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const res = await deleteApi(id)

          if (res?.data) {
            toast.success(res?.data)
            let finalPage = page
            if (_.size(pedigreeHeaderList) === 1) {
              finalPage -= 1
            }
            const data = {
              params: {
                _limit: LIMIT,
                _page: finalPage,
              },
            }
            dispatch(getAllPedigreeHeaderList(data))
          }
        } catch (err) {
          if (Array.isArray(err)) {
            toast.error(_.get(_.first(err), 'msg'))
          } else if (err) {
            toast.error(err)
          }
        }
      }
    })
  }
  return (
    <Card className='layout'>
      <Row>
        <CardHeader>
          <Row>
            <Breadcrumb title='Pedigree Headers' />
          </Row>
        </CardHeader>
        <CardBody>
          <Col md='12' className='mb-3 text-end'>
            <Link
              to='/herd-management/pedigree-headers/create'
              className='btn btn-primary btn-sm'
              title='Create'
            >
              CREATE
            </Link>
          </Col>

          <Col md={12}>
            <Table className='table-responsive'>
              <thead>
                <tr>
                  <th className='w-1'>No.</th>
                  <th>Header Name</th>
                  <th>Breeder Full Name</th>
                  <th>Address</th>
                  <th>Created On</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {!pedigreeHeaderListLoading && _.size(pedigreeHeaderList) ? (
                  pedigreeHeaderList.map((header, i) => {
                    const country = _.find(
                      CountryList,
                      (c) => c.value === header.country_id
                    )
                    const state = _.find(
                      StateList,
                      (c) => c.value === header.state_id
                    )

                    return (
                      <tr key={header.headers_id}>
                        <td>{LIMIT * (page - 1) + i + 1}</td>
                        <td>{header?.header_name || ''}</td>
                        <td>
                          {_.join(
                            _.compact([header?.last_name, header?.first_name]),
                            ' '
                          ) || '-'}
                        </td>
                        <td>
                          {_.join(
                            _.compact([
                              header?.address_1,
                              header?.address_2,
                              header?.city,
                              state?.label,
                              country?.label,
                              header?.zipcode,
                            ]),
                            ', '
                          ) || '-'}
                        </td>
                        <td>
                          {moment(
                            getDateWithFormat(header?.created_on || '')
                          ).format('MMM Do YYYY LT')}
                        </td>
                        <td>
                          <span

                          // className={`${editDisable ? 'not-allow' : ''}`}
                          >
                            <Link
                              // style={{ 'pointer-events': 'none' }}
                              to={`/herd-management/pedigree-headers/update/${urlEncode(
                                header.headers_id
                              )}`}
                              // className={`${editDisable ? 'not-allow pe-none' : ''}`}
                            >
                              <img
                                src={`${process.env.PUBLIC_URL}/assets/img/icons/update.png`}
                                alt='update'
                                className={`mb-2 p-r-3 `}
                                title='Update'
                              />
                            </Link>
                          </span>
                          <span>
                            <img
                              src={`${process.env.PUBLIC_URL}/assets/img/icons/delete.png`}
                              alt='delete'
                              role='button'
                              onClick={() => deleteHeader(header.headers_id)}
                              className={`mb-2 p-r-3`}
                              title='Delete'
                            />
                          </span>
                        </td>
                      </tr>
                    )
                  })
                ) : pedigreeHeaderListLoading ? (
                  <tr>
                    <td colSpan={7} className='text-center'>
                      <Spinner size='sm' />
                    </td>
                  </tr>
                ) : (
                  <tr>
                    <td colSpan={7} className='text-center'>
                      No pedigree header found
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
            <Pagination paginationClick={paginationClick} />
          </Col>
        </CardBody>
      </Row>
    </Card>
  )
}

HerdHeader.propTypes = {}

export default HerdHeader
