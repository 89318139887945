import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import {
  Col,
  Container,
  Input,
  InputGroup,
  Row,
  Spinner,
  Table,
} from 'reactstrap'
import DatePicker from '../../../common/DatePicker'
import { UrlEncodeHook } from '../../../hooks'
import { differenceInDays } from 'date-fns'
import { getDateWithFormat } from '../../../helpers/date_helper'
import { useDispatch, useSelector } from 'react-redux'
import { getAllPremierReportEventList } from '../../../store/actions'
import ReactPaginate from 'react-paginate'

const LIMIT = process.env.REACT_APP_LIMIT
const PremierReport = () => {
  const { premierReportEventList, loading, totalPages, totalRecords, page } =
    useSelector((state) => state.PremierReport)
  const dispatch = useDispatch()
  const { urlEncode } = UrlEncodeHook()
  const [showFromDate, setShowFromDate] = useState()
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [event, setEvent] = useState('')

  useEffect(() => {
    if (event || endDate) {
      const data = {
        body: {
          event_type: 'national',
          event_name: event,
          end_date: endDate ? moment(endDate).format('YYYY-MM-DD') : '',
          start_date: startDate ? moment(startDate).format('YYYY-MM-DD') : '',
        },
        params: {
          _page: 1,
          _limit: LIMIT,
        },
      }
      dispatch(getAllPremierReportEventList(data))
    }
  }, [event, endDate])

  const paginationClick = (page) => {
    let nextPage = page.selected + 1
    if (event || endDate) {
      const data = {
        body: {
          event_type: 'national',
          event_name: event,
          end_date: endDate ? moment(endDate).format('YYYY-MM-DD') : '',
          start_date: startDate ? moment(startDate).format('YYYY-MM-DD') : '',
        },
        params: {
          _page: nextPage,
          _limit: LIMIT,
        },
      }
      dispatch(getAllPremierReportEventList(data))
    }
  }

  return (
    <div className='page-content bg-gray-101 min-h-85'>
      <section id='about' className='section1 pages'>
        <Container fluid={true} className='auto-fit'>
          <Row>
            <Col lg={10} className='text-center'>
              <div className='page-title-box justify-content-between'>
                <h2 className='top-title mb-4'>
                  <span>Premier Reports</span>
                </h2>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg='12'>
              <Row>
                <Col lg={3}>
                  <div className='mb-4 pre-date'>
                    <InputGroup>
                      <DatePicker
                        selected={showFromDate}
                        onChange={(date) => {
                          setShowFromDate(date)
                          setStartDate(null)
                          setEndDate(null)
                          setEvent('')
                        }}
                        placeholderText='Select Show From Date'
                        className='form-control'
                        dateFormat='MMM/yyyy'
                        showMonthYearPicker
                        disabled={event}
                      />
                      <i className='fa fa-calendar cal-icon'></i>
                    </InputGroup>
                  </div>
                </Col>
                <Col lg={3}>
                  <div className='mb-4 pre-date'>
                    <InputGroup>
                      <DatePicker
                        selected={startDate}
                        disabled={!showFromDate}
                        onChange={(date) => {
                          setStartDate(date)
                          setEndDate('')
                        }}
                        placeholderText='Search By Start Date'
                        className='form-control'
                        dateFormat='MMM do yyyy'
                        maxDate={moment(showFromDate).endOf('month').toDate()}
                        minDate={moment(showFromDate).toDate()}
                        // highlightDates={dateHighLight}
                        disabledKeyboardNavigation
                      />
                      <i className='fa fa-calendar cal-icon'></i>
                    </InputGroup>
                    {/* <div className='text-danger'>{error?.date}</div> */}
                  </div>
                </Col>
                <Col lg={3}>
                  <div className='mb-4'>
                    <InputGroup>
                      <DatePicker
                        selected={endDate}
                        onChange={(date) => {
                          setEndDate(date)
                        }}
                        placeholderText='Search By End Date'
                        className='form-control'
                        dateFormat='MMM do yyyy'
                        maxDate={moment(showFromDate).endOf('month').toDate()}
                        minDate={moment(startDate).add('1', 'days').toDate()}
                        disabled={!startDate}
                        disabledKeyboardNavigation
                        // highlightDates={dateHighLight}
                      />
                      <i className='fa fa-calendar cal-icon'></i>
                    </InputGroup>
                  </div>
                </Col>
                <Col lg={3}>
                  <Row className='d-flex flex-wrap align-items-center '>
                    <Col xl={2} lg={2} md='12' className='mb-4 text-center'>
                      <strong className='text-dark fs-4'>(OR)</strong>
                    </Col>
                    <Col xl={10} lg={10} md={12}>
                      <div className='mb-4'>
                        <Input
                          type='text'
                          placeholder='Search By Event Name'
                          disabled={showFromDate}
                          onChange={(e) => {
                            setEndDate(null)
                            setStartDate(null)
                            setShowFromDate(null)
                            setEvent(e.target.value)
                          }}
                        />
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            {endDate || event ? (
              <Col md='12'>
                <div className='table-responsive'>
                  <Table className='table mb-0 cust-tab'>
                    <thead className='table-color'>
                      <tr>
                        <th>No.</th>
                        <th>Event Date</th>
                        <th>Event Name</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {!loading &&
                      Array.isArray(premierReportEventList) &&
                      premierReportEventList.length ? (
                        premierReportEventList.map((event, i) => {
                          let diffDay = moment(
                            moment(
                              getDateWithFormat(event?.end_date_time)
                            ).format('YYYY-MM-DD')
                          ).diff(
                            moment(
                              getDateWithFormat(event?.start_date_time)
                            ).format('YYYY-MM-DD'),
                            'days'
                          )
                          // const diffDay = differenceInDays(
                          //   new Date(event.end_date_time),
                          //   new Date(event.start_date_time)
                          // )
                          return (
                            <tr key={event.event_id}>
                              <td>{(page - 1) * LIMIT + (i + 1)}</td>
                              <td>
                                {diffDay < 1 ? (
                                  moment(
                                    getDateWithFormat(event?.start_date_time)
                                  ).format('MMM Do YYYY')
                                ) : (
                                  <>
                                    {moment(
                                      getDateWithFormat(event?.start_date_time)
                                    ).format('MMM Do YYYY')}
                                    -
                                    <br />
                                    {moment(
                                      getDateWithFormat(event?.end_date_time)
                                    ).format('MMM Do YYYY')}
                                  </>
                                )}
                              </td>
                              <td>{event.event_name}</td>
                              <td>
                                <Link
                                  to={`/premier-report/${urlEncode(
                                    event.event_id
                                  )}`}
                                  className='btn btn-secondary btn-sm'
                                  title='View'
                                >
                                  VIEW
                                </Link>
                              </td>
                            </tr>
                          )
                        })
                      ) : loading ? (
                        <tr>
                          <td colSpan={10} className='text-center'>
                            <Spinner size='sm' />
                          </td>
                        </tr>
                      ) : Array.isArray(premierReportEventList) &&
                        !premierReportEventList.length ? (
                        <tr>
                          <td colSpan={10} className='text-center'>
                            No event found
                          </td>
                        </tr>
                      ) : null}
                    </tbody>
                  </Table>
                </div>
                {totalRecords > LIMIT ? (
                  <ReactPaginate
                    breakLabel='...'
                    breakClassName='page-item'
                    breakLinkClassName='page-link'
                    nextLabel='>'
                    pageCount={totalPages || 1}
                    pageRangeDisplayed={4}
                    marginPagesDisplayed={1}
                    previousLabel='<'
                    renderOnZeroPageCount={null}
                    containerClassName='pagination mtb-4'
                    pageLinkClassName='page-link page-link'
                    pageClassName='page-item'
                    activeClassName='active'
                    nextLinkClassName='page-link'
                    previousClassName='page-item'
                    previousLinkClassName='page-link'
                    nextClassName='page-item'
                    onPageChange={paginationClick}
                    forcePage={(Number(page) || 1) - 1}
                  />
                ) : null}
              </Col>
            ) : (
              <Row>
                <Col md='12' className='text-center mt-5'>
                  <h4> Please select Date or Event Name</h4>
                </Col>
              </Row>
            )}
          </Row>
        </Container>
      </section>
    </div>
  )
}

export default PremierReport
