import React, { useEffect } from 'react'
import {
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  SelectField,
  Table,
} from '../../Atoms'
import { Field, Form, Formik } from 'formik'
import { Col, FormGroup, Row } from 'reactstrap'
import {
  getAllPedigreeHeaderListForOption,
  pedigreePdf,
  showAndClosePedigreeModal,
} from '../../../../store/actions'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import _ from 'lodash'
import { PdfToUrlHook } from '../../../../hooks'

const PedigreePdf = () => {
  const { sendBase64 } = PdfToUrlHook()
  const { showModal, optionsList, selectedPedigree } = useSelector((state) => {
    return {
      showModal: state.herdManagement.Pedigree.pedigreeModal.showModal,
      selectedPedigree:
        state.herdManagement.Pedigree.pedigreeModal.ModalContent.form,
      optionsList:
        state.herdManagement.pedigreeHeader.pedigreeHeader.pedigreeHeaderList,
    }
  })
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getAllPedigreeHeaderListForOption())
  }, [])
  const pedigreePdfApi = (body) => {
    return new Promise((resolve, reject) => {
      dispatch(
        pedigreePdf(body, (err, data) => {
          if (err) {
            reject(err)
          } else {
            resolve(data)
          }
        })
      )
    })
  }
  const generatePdf = async (value, formProps) => {
    try {
      formProps.setStatus(true)
      const data = {
        params: {
          id: selectedPedigree.pedigree_id,
        },
        data: {
          header_id: value.header_id,
        },
      }
      const res = await pedigreePdfApi(data)
      sendBase64(res, 'pedigree')
      setTimeout(() => {
        formProps.setStatus(false)
      }, 1000)
    } catch (err) {
      formProps.setStatus(false)
      if (Array.isArray(err)) {
        toast.error(_.get(_.first(err), 'msg'))
      } else if (err.response.data) {
        toast.error(err.response.data)
      } else if (err) {
        toast.error(err)
      }
    }
  }
  return (
    <Modal isOpen={showModal} size='lg'>
      <ModalHeader
        toggle={() =>
          dispatch(
            showAndClosePedigreeModal({
              showModal: false,
              modalType: '',
              ModalContent: '',
            })
          )
        }
      >
        Pedigree Pdf
      </ModalHeader>
      <ModalBody>
        <Formik initialValues={{ header_id: '' }}>
          {({ values, status, setFieldValue, ...props }) => {
            return (
              <Form>
                <Row>
                  <Col md={12}>
                    <Field
                      name='header_id'
                      placeholder='Select Header *'
                      type='select'
                      id='header_id'
                      onlyShowOption
                      option={optionsList}
                      isSelect
                      hasOnChange
                      isClearable
                      onChange={(v) => {
                        setFieldValue('header_id', v?.value || '')
                      }}
                      component={SelectField}
                    />
                  </Col>
                  {values.header_id ? (
                    <Row>
                      <Col md='12' className='mt-4'>
                        <Table className='table-responsive'>
                          <thead>
                            <tr>
                              <th className='w-1'>No.</th>
                              <th>PDF Type</th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td className='w-1'>1</td>
                              <td>Vertical </td>
                              <td>
                                <Button
                                  size='sm'
                                  disabled={status}
                                  onClick={() => generatePdf(values, props)}
                                  title="Download Pdf"
                                >
                                  Download Pdf
                                </Button>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </Col>
                    </Row>
                  ) : null}
                  <Col md='12' className='text-center mt-4'>
                    <Button
                      size='sm'
                      color='warning'
                      onClick={() =>
                        dispatch(
                          showAndClosePedigreeModal({
                            showModal: false,
                            modalType: '',
                            ModalContent: '',
                          })
                        )
                      }
                    >
                      Close
                    </Button>
                  </Col>
                </Row>
              </Form>
            )
          }}
        </Formik>
      </ModalBody>
    </Modal>
  )
}

export default PedigreePdf
