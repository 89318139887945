import { Field, useFormikContext } from 'formik'
import { Col, Label, Row } from 'reactstrap'
import { TextField } from '../../Atoms'
const AnimalList = ({ breed, breedCategoryIndex }) => {
  const { values } = useFormikContext()

  return (
    <Row>
      <Col md='12' className='ms-4 my-2'>
        <Row className='checkbox-lg'>
          {Array.isArray(breed) && breed.length
            ? breed.map((_breed, index) => {
                return (
                  <Col md='6' sm='6' lg='4' key={index}>
                    <Field
                      name={`breed_list.${breedCategoryIndex}.breed.${index}.isSelected`}
                      id={`breed_list.${breedCategoryIndex}.breed.${index}.isSelected`}
                      type='checkbox'
                      className='form-check-input me-2'
                      component={TextField}
                      // eslint-disable-next-line no-undef
                      disabled={values.config_user_id}
                      labelComponent={
                        <Label
                          className='me-3'
                          htmlFor={`breed_list.${breedCategoryIndex}.breed.${index}.isSelected`}
                        >
                          {_breed?.breed_name}{' '}
                        </Label>
                      }
                    />
                  </Col>
                )
              })
            : null}
        </Row>
      </Col>
    </Row>
  )
}

export default AnimalList
