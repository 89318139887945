import { put, takeEvery } from 'redux-saga/effects'

import {
  ADD_PEDIGREE_HEADER,
  DELETE_PEDIGREE_HEADER,
  DELETE_PEDIGREE_IMAGE,
  GET_ALL_PEDIGREE_HEADER,
  GET_ALL_PEDIGREE_HEADER_OPTIONS,
  GET_ALL_PEDIGREE_HEADER_SUCCESS,
  GET_PEDIGREE_HEADER,
  GET_PEDIGREE_HEADER_SUCCESS,
  UPDATE_PEDIGREE_HEADER,
} from './actionTypes'
import { toast } from 'react-toastify'
import { del, get, PATCH, patch, post } from '../../../helpers/api_helper'
import { paginationData } from '../Pagination/action'
import _ from 'lodash'

function* getAllPedigreeListSagas({ payload }) {
  try {
    const res = yield post(`/api/v1/herd/headers/all`, null, {
      params: payload.params,
    })
    yield put({
      type: GET_ALL_PEDIGREE_HEADER_SUCCESS,
      payload: res?.data || [],
    })
    if (Number(payload?.params?._limit || 0) > 1) {
      // Pagination Section
      const limit = payload?.params?._limit || 10
      const TotalPage = Math.ceil(res?.totalRecords / limit) || 1
      const paginationObj = {
        page: payload?.params?._page || 1,
        totalPage: TotalPage || 1,
        totalRecords: res?.totalRecords || 1,
      }
      yield put(paginationData(paginationObj))
    }
  } catch (err) {
    if (Number(payload?.params?._limit || 0) > 1) {
      const paginationObj = {
        page: 1,
        totalPage: 1,
        totalRecords: 1,
      }
      yield put(paginationData(paginationObj))
    }
    if (err?.response?.data) {
      toast.error(err?.response?.data)
    }
  }
}
function* addPedigreeHeaderSaga({ payload, callback }) {
  try {
    const res = yield post(`/api/v1/herd/headers/create`, payload.data)
    if (payload.callback) payload.callback(null, res)
  } catch (err) {
    if (err?.response?.data) {
      toast.error(err?.response?.data)
    }
  }
}
function* getPedigreeHeaderSaga({ payload }) {
  try {
    const res = yield get(`/api/v1/herd/headers/one/${payload}`)
    yield put({
      type: GET_PEDIGREE_HEADER_SUCCESS,
      payload: res?.data || {},
    })
  } catch (err) {
    if (err?.response?.data) {
      toast.error(err?.response?.data)
    }
  }
}

function* updatePedigreeHeaderSaga({ payload }) {
  try {
    const res = yield PATCH(
      `/api/v1/herd/headers/update/${payload.data.id}`,
      payload.data.body
    )
    if (payload.callback) payload.callback(null, res)
  } catch (err) {
    if (err?.response?.data) {
      toast.error(err?.response?.data)
    }
  }
}
function* deletePedigreeHeaderSaga({ payload }) {
  try {
    const res = yield del(`/api/v1/herd/headers/delete/${payload.data}`)
    if (payload.callback) payload.callback(null, res)
  } catch (err) {
    if (err?.response?.data) {
      toast.error(err?.response?.data)
    }
  }
}

function* getAllPedigreeListOptionSagas({ payload }) {
  try {
    const res = yield post(`/api/v1/herd/headers/all`, null, {
      params: payload?.params,
    })
    yield put({
      type: GET_ALL_PEDIGREE_HEADER_SUCCESS,
      payload:
        _.map(res?.data, (o) => ({
          ...o,
          label: o.header_name,
          value: o.headers_id,
        })) || [],
    })
  } catch (err) {
    if (err?.response?.data) {
      toast.error(err?.response?.data)
    }
  }
}

function* deletePedigreeImageSaga({ payload }) {
  try {
    const res = yield PATCH(
      `/api/v1/herd/headers/update-logo/${payload.data.params.id}`,
      payload.data.body
    )
    if (payload.callback) payload.callback(null, res)
  } catch (err) {
    payload.callback(err, null)
  }
}
function* easyEntrySaga() {
  yield takeEvery(GET_ALL_PEDIGREE_HEADER, getAllPedigreeListSagas)
  yield takeEvery(
    GET_ALL_PEDIGREE_HEADER_OPTIONS,
    getAllPedigreeListOptionSagas
  )
  yield takeEvery(ADD_PEDIGREE_HEADER, addPedigreeHeaderSaga)
  yield takeEvery(UPDATE_PEDIGREE_HEADER, updatePedigreeHeaderSaga)
  yield takeEvery(DELETE_PEDIGREE_HEADER, deletePedigreeHeaderSaga)
  yield takeEvery(GET_PEDIGREE_HEADER, getPedigreeHeaderSaga)
  yield takeEvery(DELETE_PEDIGREE_IMAGE, deletePedigreeImageSaga)
}

export default easyEntrySaga
