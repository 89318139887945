import React from 'react'
import {
  Card,
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from 'reactstrap'
import classNames from 'classnames'
import moment from 'moment'
import ShowContent from '../ShowContent/ShowContent'
import { getDateWithFormat } from '../../../../../helpers/date_helper'

const AllShowList = ({ showList, selectedShow, setSelectedShow }) => {
  return (
    <Card body className='m-4'>
      <Nav
        tabs
        className='d-flex flex-nowrap overflow-x-auto overflow-y-hidden'
      >
        {Array.isArray(showList) && showList.length
          ? showList.map((show) => {
              return (
                <NavItem>
                  <NavLink
                    className={classNames('', {
                      active: selectedShow === show.show_id,
                    })}
                    style={{ width: '175px' }}
                    onClick={() => setSelectedShow(show.show_id)}
                  >
                    <div className='fs-6'>
                      {show?.show_char}-(
                      {show?.show_date
                        ? moment(getDateWithFormat(show?.show_date)).format(
                            'Do MMM YYYY'
                          )
                        : ''}
                      )
                    </div>
                  </NavLink>
                </NavItem>
              )
            })
          : null}
      </Nav>
      <TabContent activeTab={selectedShow}>
        {Array.isArray(showList) && showList.length
          ? showList.map((show) => {
              return (
                <TabPane tabId={show.show_id}>
                  <Row>
                    <Col sm='12'>
                      <ShowContent selectedShow={selectedShow} show={show} />
                    </Col>
                  </Row>
                </TabPane>
              )
            })
          : null}
      </TabContent>
    </Card>
  )
}
export default AllShowList
