import {
  GET_ALL_LIVE_EVENT_BREED_AS_FUR_DETAIL,
  GET_ALL_LIVE_EVENT_BREED_DETAIL,
  GET_ALL_LIVE_EVENT_BREED_FUR_LIST,
  GET_ALL_LIVE_EVENT_BREED_FUR_LIST_FOR_EXCEL,
  GET_ALL_LIVE_EVENT_LIST,
  GET_ALL_LIVE_EVENT_SHOW_LIST,
  LIVE_EVENT_BREED_TAB_CLOSE,
  LIVE_EVENT_BREED_TAB_OPEN,
  LIVE_EVENT_EXHIBITOR_LIST,
  LIVE_EVENT_EXHIBITOR_LIST_FOR_EXCEL,
  LIVE_EVENT_RELOAD,
  LIVE_EVENT_RESET_ACTIVE_TABS,
} from './actionTypes'

export const getAllLiveEventList = (data) => {
  return {
    type: GET_ALL_LIVE_EVENT_LIST,
    payload: data,
  }
}
export const getAllLiveEventShowList = (data) => {
  return {
    type: GET_ALL_LIVE_EVENT_SHOW_LIST,
    payload: data,
  }
}
export const getAllLiveEventBreedList = (data) => {
  return {
    type: GET_ALL_LIVE_EVENT_BREED_FUR_LIST,
    payload: data,
  }
}
export const getLiveEventBreedDetail = (data) => {
  return {
    type: GET_ALL_LIVE_EVENT_BREED_DETAIL,
    payload: data,
  }
}
export const getLiveEventBreedAsFurDetail = (data) => {
  return {
    type: GET_ALL_LIVE_EVENT_BREED_AS_FUR_DETAIL,
    payload: data,
  }
}

export const LiveEventBreedTabOpen = (obj) => {
  return {
    type: LIVE_EVENT_BREED_TAB_OPEN,
    payload: obj,
  }
}
export const LiveEventBreedTabClose = (obj) => {
  return {
    type: LIVE_EVENT_BREED_TAB_CLOSE,
    payload: obj,
  }
}

export const LiveEventExhibitorList = (obj) => {
  return {
    type: LIVE_EVENT_EXHIBITOR_LIST,
    payload: obj,
  }
}

export const LiveEventReload = () => {
  return {
    type: LIVE_EVENT_RELOAD,
  }
}

export const resetActiveTabs = () => {
  return {
    type: LIVE_EVENT_RESET_ACTIVE_TABS,
  }
}

export const getAllLiveEventBreedListForExcel = (data) => {
  return {
    type: GET_ALL_LIVE_EVENT_BREED_FUR_LIST_FOR_EXCEL,
    payload: data,
  }
}

export const LiveEventExhibitorListForExcel = (obj) => {
  return {
    type: LIVE_EVENT_EXHIBITOR_LIST_FOR_EXCEL,
    payload: obj,
  }
}
