import React, { useEffect, useState } from 'react'
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Input,
  Row,
  Spinner,
  Table,
} from 'reactstrap'
import Collapse from '@mui/material/Collapse'
import { Link } from 'react-router-dom'
import { allJudgeListing, allStateList, pageChange } from '../../store/actions'
import { useDispatch, useSelector } from 'react-redux'

import Pagination from '../Profile/Pagination/Pagination'
import { UrlEncodeHook } from '../../hooks'
import { get } from '../../helpers/api_helper'
import { toast } from 'react-toastify'
import _ from 'lodash'
import ImageModal from '../../components/ImageModal/ImageModal'

const JudgeListing = (props) => {
  const { urlEncode } = UrlEncodeHook()
  const dispatch = useDispatch()
  const [open, setOpen] = React.useState(null)

  const { page, perPage } = useSelector((state) => state.pagination)
  const { judgeListing, loading } = useSelector((state) => state.EasyFiedsJudge)

  const { StateList } = useSelector((state) => state.State)

  //
  const [serve_as_registrar, setServe_as_registrar] = useState('')
  const [serve_as_judge, setServe_as_judge] = useState('')
  const [travel_radius_id, setTravel_radius_id] = useState('')
  const [travelList, setTravelList] = useState([])
  const [serviceAs, setServiceAs] = useState('')
  const [modal, setModal] = useState(false)
  const [imageList, setImageList] = useState([])

  //
  const [state_id, setState_id] = useState('')
  const [city, setCity] = useState('')
  const [cityList, setCityList] = useState([])
  const [StatesList, setStatesList] = useState([])
  //
  useEffect(() => {
    dispatch(allStateList())
  }, [])
  useEffect(() => {
    let params = {
      _limit: perPage,
      _page: 1,
      _sort: 'ss.created_on',
      _order: 'DESC',
      serve_as_registrar: serve_as_registrar,
      serve_as_judge: serve_as_judge,
      travel_radius_id: travel_radius_id,
      state_id: state_id,
      city,
    }
    dispatch(allJudgeListing(params))
  }, [serve_as_registrar, serve_as_judge, travel_radius_id, city, state_id])

  useEffect(() => {
    let params = {
      _sort: 'ss.created_on',
      _order: 'ASC',
      serve_as_registrar: serve_as_registrar,
      serve_as_judge: serve_as_judge,
    }
    get(
      `/api/v1/admin/easyfied/sales-services-judge/all-listing?_sort=${params?._sort}&_order=${params?._order}&serve_as_registrar=${params?.serve_as_registrar}&serve_as_judge=${params?.serve_as_judge}`
    )
      .then((res) => {
        let finalRes = res.data
        finalRes = _.unionBy(finalRes, 'travel_radius_id')
        setTravelList(finalRes)
      })
      .catch((err) => {
        if (err?.responsive?.data) {
          toast.error(err?.responsive?.data)
        }
      })
  }, [serve_as_registrar, serve_as_judge])
  useEffect(() => {
    if (Array.isArray(StateList) && StateList.length) {
      let params = {
        _sort: 'ss.created_on',
        _order: 'ASC',
        serve_as_registrar: serve_as_registrar,
        serve_as_judge: serve_as_judge,
        travel_radius_id: travel_radius_id,
      }
      get(
        `/api/v1/admin/easyfied/sales-services-judge/all-listing?_sort=${params?._sort}&_order=${params?._order}&serve_as_registrar=${params?.serve_as_registrar}&serve_as_judge=${params?.serve_as_judge}&travel_radius_id=${params?.travel_radius_id}`
      )
        .then((res) => {
          let finalRes = res.data

          let stateData = _.intersectionBy(
            StateList,
            _.uniqBy(finalRes, 'state_id'),
            'state_id'
          )

          setStatesList(stateData)
        })
        .catch((err) => {
          if (err?.responsive?.data) {
            toast.error(err?.responsive?.data)
          }
        })
    }
  }, [serve_as_registrar, serve_as_judge, travel_radius_id, StateList])
  useEffect(() => {
    let params = {
      _sort: 'ss.created_on',
      _order: 'ASC',
      serve_as_registrar: serve_as_registrar,
      serve_as_judge: serve_as_judge,
      travel_radius_id: travel_radius_id,
      state_id,
    }
    get(
      `/api/v1/admin/easyfied/sales-services-judge/all-listing?_sort=${params?._sort}&_order=${params?._order}&serve_as_registrar=${params?.serve_as_registrar}&serve_as_judge=${params?.serve_as_judge}&travel_radius_id=${params?.travel_radius_id}&state_id=${params?.state_id}`
    )
      .then((res) => {
        const finalRes = res.data

        let cityData = _.map(_.uniqBy(finalRes, 'city'), (q) => ({
          city_name: q.city,
        }))

        setCityList(cityData)
      })
      .catch((err) => {
        if (err?.responsive?.data) {
          toast.error(err?.responsive?.data)
        }
      })
  }, [serve_as_registrar, serve_as_judge, travel_radius_id, state_id])

  const handleClick = (data) => {
    if (data === open) {
      setOpen(null)
    } else {
      setOpen(data)
    }
  }
  const showModal = async (file) => {
    let data = [
      {
        original: file,
        thumbnail: file,
      },
    ]
    setImageList(data)
    setModal(true)
  }
  const modalClose = () => {
    setModal(false)
    setImageList([])
  }
  const paginationClick = (nextPage) => {
    const { selected } = nextPage
    const finalPage = (Number(selected) || 0) + 1
    let params = {
      _limit: perPage,
      _page: finalPage,
      _sort: 'ss.created_on',
      _order: 'DESC',
      serve_as_registrar: serve_as_registrar,
      serve_as_judge: serve_as_judge,
      travel_radius_id: travel_radius_id,
    }
    dispatch(allJudgeListing(params))
  }

  return (
    <section
      className='section bg-gray-101 pages event-list mt-12'
      style={{ background: '#f5f8fb' }}
    >
      <Container fluid>
        <div class='row mb-6'>
          <div class='col-md-12 col-lg-12 col-xs-12 text-center'>
            <h2 className='top-title'>
              <span>Registrars & Judges</span>
            </h2>
          </div>
        </div>

        <Col lg='12' className='text-center mb-3 filter-line'>
          <p>
            Filters will only show drop down with options that have data listed.
          </p>
        </Col>

        <Card>
          <CardHeader>
            <Row className='mt-5'>
              <Col md='3' sm='6' xs={'12'} className='mb-4'>
                <select
                  name='travel'
                  className='form-control form-select frm-sel-pad'
                  value={serviceAs}
                  onChange={(e) => {
                    if (e.target.value === 'all') {
                      setServe_as_registrar(1)
                      setServe_as_judge(1)
                    }
                    if (e.target.value === 'judge') {
                      setServe_as_registrar('')
                      setServe_as_judge(1)
                    }
                    if (e.target.value === 'registrar') {
                      setServe_as_registrar(1)
                      setServe_as_judge('')
                    }
                    if (!e.target.value) {
                      setServe_as_registrar('')
                      setServe_as_judge('')
                    }
                    setServiceAs(e.target.value)
                    dispatch(pageChange(1))
                    setTravel_radius_id('')
                    setState_id('')
                    setCity('') 
                  }}
                >
                  <option value=''>Select Service</option>
                  {Array.isArray(ServiceAs) && ServiceAs.length
                    ? ServiceAs.map((service, i) => {
                        return (
                          <option value={service?.abbr} key={i}>
                            {service?.title}{' '}
                          </option>
                        )
                      })
                    : null}
                </select>
              </Col>

              <Col md='3' sm='6' xs={'12'} className='mb-4'>
                <select
                  name='travel'
                  className='form-control form-select frm-sel-pad'
                  value={travel_radius_id}
                  onChange={(e) => {
                    dispatch(pageChange(1))
                    setTravel_radius_id(e.target.value)
                    setState_id('')
                    setCity('') 
                    
                  }}
                >
                  <option value=''>Select Travel Radius ( Miles )</option>
                  {Array.isArray(travelList) && travelList.length
                    ? travelList.map((travel, i) => {
                        return (
                          <option value={travel?.travel_radius_id} key={i}>
                            {travel?.title || ''}{' '}
                          </option>
                        )
                      })
                    : null}
                </select>
              </Col>
              <Col md='3' sm='6' xs={'12'} className='mb-4'>
                <Input
                  id='exampleSelect'
                  className='form-select frm-sel-pad'
                  name='select'
                  type='select'
                  value={state_id}
                  onChange={(e) => {
                    dispatch(pageChange(1))
                    setState_id(e.target.value)
                    setCity('')

                  }}
                >
                  <option value={''}>Select State</option>
                  {Array.isArray(StatesList) && StatesList.length
                    ? StatesList.map((state, i) => {
                        return (
                          <option value={state?.state_id || ''} key={i}>
                            {' '}
                            {state?.state_code || ''}
                          </option>
                        )
                      })
                    : null}
                </Input>
              </Col>
              <Col md='3' sm='6' xs={'12'} className='mb-4'>
                <Input
                  id='exampleSelect'
                  className='form-select frm-sel-pad'
                  name='select'
                  type='select'
                  value={city}
                  onChange={(e) => {
                    dispatch(pageChange(1))
                    setCity(e.target.value)
                  }}
                >
                  <option value={''}>Select City</option>
                  {Array.isArray(cityList) && cityList.length
                    ? cityList.map((city, i) => {
                        return (
                          <option value={city?.city_name || ''} key={i}>
                            {' '}
                            {city?.city_name || ''}
                          </option>
                        )
                      })
                    : null}
                </Input>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <div className='col-md-12'>
              <Table responsive hover1 className='table cust-tab table'>
                <thead className='table-color'>
                  <tr>
                    <th>No.</th>
                    <th>Name</th>
                    <th>Image</th>
                    <th>Travel Radius (Miles) </th>
                    <th>City </th>
                    <th>State </th>
                    <th>Action </th>
                    <th>More Info </th>
                  </tr>
                </thead>
                <tbody>
                  {!loading &&
                  Array.isArray(judgeListing) &&
                  judgeListing.length ? (
                    judgeListing.map((judge, index) => {
                      return (
                        <>
                          <tr key={index}>
                            <td>{(page - 1) * perPage + (index + 1)}</td>
                            <td>{judge?.display_name}</td>
                            <td>
                              {judge?.image_file ? (
                                <img
                                  src={judge?.image_file}
                                  alt={judge?.display_name}
                                  width='100px'
                                  className='img-thumbnail'
                                  style={{ cursor: 'pointer' }}
                                  onClick={() => showModal(judge?.image_file)}
                                />
                              ) : (
                                <img
                                  src={'/assets/img/logo/logo.png'}
                                  alt={'logo'}
                                  width='100px'
                                  className='img-thumbnail'
                                />
                              )}
                            </td>
                            <td>{judge?.title || ''}</td>
                            <td>{judge?.city || '-'}</td>
                            <td>
                              <StateName ID={judge?.state_id} />
                            </td>
                            <td>
                              <Link
                                to={`/easyfides/judges/contact/${urlEncode(
                                  judge?.sales_service_id
                                )}`}
                              >
                                <Button
                                  size='sm'
                                  color='secondary'
                                  title='Contact'
                                >
                                  CONTACT
                                </Button>
                              </Link>
                            </td>
                            <td>
                              <i
                                class='fa fa-arrow-circle-down down-arrow'
                                onClick={() =>
                                  handleClick(judge?.sales_service_id)
                                }
                              ></i>
                            </td>
                          </tr>
                          {judge?.sales_service_id === open ? (
                            <tr>
                              <td colSpan={9}>
                                <Collapse
                                  in={
                                    judge?.sales_service_id === open
                                      ? true
                                      : false
                                  }
                                  timeout='auto'
                                  unmountOnExit
                                >
                                  <div className='disc-box text-left w-break'>
                                    {judge?.description || ''}
                                  </div>
                                </Collapse>
                              </td>
                            </tr>
                          ) : null}
                        </>
                      )
                    })
                  ) : loading ? (
                    <tr className='text-center'>
                      <td colSpan={10}>
                        <Spinner color='primary' />
                      </td>
                    </tr>
                  ) : (
                    <tr className='text-center'>
                      <td colSpan={10}>No registrars & judges found</td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
            <ImageModal
              modalClose={modalClose}
              modal={modal}
              imageList={imageList}
            />
            <Row>
              <Col lg={12} className='easy-page'>
                <Pagination paginationClick={paginationClick} />
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Container>
    </section>
  )
}

export default JudgeListing

const StateName = ({ ID }) => {
  const [stateData, setStateData] = useState('')

  useEffect(() => {
    get(`/api/v1/address/state/one/${ID}`).then((res) => {
      setStateData(res?.data)
    })
  }, [ID])

  return stateData?.state_name || '-'
}

const ServiceAs = [
  {
    title: 'Judge & Registrar',
    abbr: 'all',
  },
  {
    title: 'Judge',
    abbr: 'judge',
  },
  {
    title: 'Registrar',
    abbr: 'registrar',
  },
]
