import {
  CURRENT_PAGE,
  GET_ALL_SPONSOR_SUCCESS,
  SANCTION_SHOPPING_CART_SUCCESS,
  TOTAL_PAGE_SPONSOR,
} from './actionType'

const initialState = {
  error: '',
  loading: true,
  allSponsorList: [],
  shoppingCartList: [],
  shoppingCount: 0,
  totalPages: 0,
  totalRecords: 0,
  page: 1,
}

const SponsorSanction = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_SPONSOR_SUCCESS:
      // console.log('action.payload', action.payload)
      return {
        ...state,
        allSponsorList: action.payload.data,
        totalRecords: action.payload.totalRecords,
        loading: false,
      }
    case TOTAL_PAGE_SPONSOR:
      return {
        ...state,
        totalPages: action.payload,
      }
    case SANCTION_SHOPPING_CART_SUCCESS:
      return {
        ...state,
        shoppingCartList: action?.payload ? action?.payload.data : [],
        shoppingCount: action?.payload ? action.payload?.data.length : 0,
      }
    case CURRENT_PAGE:
      return {
        ...state,
        page: action.payload,
      }
    default:
      return state
  }
}
export default SponsorSanction
