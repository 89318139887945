import { GET_ALL_DISCOUNT_SUMMARY, GET_ALL_DISCOUNT_SUMMARY_SUCCESS } from "./actionType";


export const getSummaryDiscount = (event_id, history, state, historyData) => {
    return {
      type: GET_ALL_DISCOUNT_SUMMARY,
      payload: {event_id:event_id, history, state, historyData},
    };
  };
 export const getSummaryDiscounts = (discount) => ({
    type: GET_ALL_DISCOUNT_SUMMARY_SUCCESS,
    payload: discount,
  });