import React from 'react'

const ProfileCover = () => {
  return (
    <div
      className='min-h-350px parallax'
      style={{ background: '#333d5d' }}
    ></div>
  )
}

export default ProfileCover
