import { SET_CLOSE_MODAL, SET_SHOW_MODAL } from './actionTypes'

export const setShowModal = (data) => ({
  type: SET_SHOW_MODAL,
  payload: data,
})

export const setCloseModal = (data) => ({
  type: SET_CLOSE_MODAL,
  payload: data,
})
