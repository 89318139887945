import React from 'react'
import Switch from 'react-switch'

const Offsymbol = () => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        fontSize: 12,
        color: '#fff',
        paddingRight: 2,
      }}
    >
      {' '}
      No
    </div>
  )
}
const OnSymbol = () => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        fontSize: 12,
        color: '#fff',
        paddingRight: 2,
      }}
    >
      {' '}
      Yes
    </div>
  )
}
const ESwitch = ({ ...props }) => {
  const onChange = (e) => {
    if (Array.isArray(props.resetvalue)) {
      // eslint-disable-next-line no-restricted-syntax
      for (const name of props.resetvalue) {
        props.form.setFieldValue(name, '', false)
      }
    }
    if (props.resetform) {
      props.form.resetForm()
    }

    props.form.setFieldValue(props.field.name, !props.field.value)
  }
  return (
    <span>
      <Switch
        {...props.field}
        {...props}
        uncheckedIcon={<Offsymbol />}
        checkedIcon={<OnSymbol />}
        onColor='#00A310'
        onChange={props?.hasOnChange ? props.onChange : onChange}
        checked={props.field.value}
      />
    </span>
  )
}

export default ESwitch
