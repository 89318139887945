import classNames from 'classnames'
import React, { useEffect, useMemo, useState } from 'react'
import {
  Button,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  Spinner,
  TabContent,
  Table,
  TabPane,
} from 'reactstrap'
import AllShowList from './AllShowList/AllShowList'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import {
  getAllPremierReportEventDetail,
  getAllPremierReportShowList,
} from '../../../../store/actions'
import { UrlEncodeHook } from '../../../../hooks'
import { Link, useParams } from 'react-router-dom'
import { differenceInDays } from 'date-fns'
import { getDateWithFormat } from '../../../../helpers/date_helper'
import _ from 'lodash'

const ShowList = () => {
  const { eventDetail, loading: eventDetailLoading } = useSelector(
    (state) => state.PremierReport.eventDetailData
  )
  const { showList, loading: showLoading } = useSelector(
    (state) => state.PremierReport.showListData
  )
  const { urlDecode, urlEncode } = UrlEncodeHook()
  let { eventId } = useParams()
  eventId = urlDecode(eventId)
  const dispatch = useDispatch()
  const [showTabType, setShowTabType] = useState('open')
  const [selectedShow, setSelectedShow] = useState()

  useEffect(() => {
    if (eventId) {
      const data = {
        eventId: eventId,
      }
      dispatch(getAllPremierReportEventDetail(data))
      dispatch(getAllPremierReportShowList(data))
    }
  }, [eventId])

  const showTypeList = useMemo(() => {
    let finalShowList = []
    if (Array.isArray(showList) && showList.length) {
      finalShowList = _.map(_.groupBy(showList, 'show_type'), (values, key) => {
        return {
          show_type: _.capitalize(key),
          show_list: _.filter(
            _.orderBy(values, [(o) => _.lowerCase(o.show_char)], ['asc']),
            (o) => o.finalize_date
          ),
        }
      })
      finalShowList = _.filter(finalShowList, (o) => o.show_list.length)
      setShowTabType(_.get(_.first(finalShowList), 'show_type'))
    }

    return finalShowList
  }, [showList])

  useEffect(() => {
    if (Array.isArray(showTypeList) && showTabType) {
      setSelectedShow(
        _.get(
          _.first(
            _.get(
              _.find(showTypeList, (f) => f.show_type === showTabType),
              'show_list'
            )
          ),
          'show_id'
        )
      )
    }
  }, [showTypeList, showTabType])
  return (
    <div className='page-content bg-gray-101 min-h-85'>
      <section id='about' className='section1 pages'>
        <Container fluid={true} className='auto-fit'>
          <Row>
            <Col lg={11} className='text-center'>
              <div className='page-title-box justify-content-between'>
                <h2 className='top-title mb-4'>
                  <span>Show List</span>
                </h2>
              </div>
            </Col>
            <Col lg={1} className='text-center'>
              <Link to='/premier-report'>
                <Button color='dark' block className='w-100'>
                  BACK
                </Button>
              </Link>
            </Col>
          </Row>

          <Row>
            <Col lg={12}>
              <div className='table-responsive text-center mb-3'>
                <Table className='table mb-0 cust-tab'>
                  <thead className='table-color'>
                    <tr>
                      <th>Event Date</th>
                      <th>Event Name</th>
                      <th>Event Type</th>
                    </tr>
                  </thead>
                  <tbody>
                    {!eventDetailLoading && _.size(eventDetail) ? (
                      <tr>
                        <td>
                          {moment(
                            moment(
                              getDateWithFormat(
                                eventDetail?.end_date_time || ''
                              )
                            ).format('YYYY-MM-DD')
                          ).diff(
                            moment(
                              getDateWithFormat(
                                eventDetail?.start_date_time || ''
                              )
                            ).format('YYYY-MM-DD'),
                            'days'
                          ) < 1 ? (
                            moment(
                              getDateWithFormat(eventDetail?.start_date_time)
                            ).format('MMM Do YYYY')
                          ) : (
                            <>
                              {moment(
                                getDateWithFormat(eventDetail?.start_date_time)
                              ).format('MMM Do YYYY')}
                              -
                              <br />
                              {moment(
                                getDateWithFormat(eventDetail?.end_date_time)
                              ).format('MMM Do YYYY')}
                            </>
                          )}
                        </td>
                        <td>{eventDetail?.event_name}</td>
                        <td className='text-capitalize'>
                          {eventDetail?.event_type}
                        </td>
                      </tr>
                    ) : eventDetailLoading ? (
                      <tr>
                        <td className='text-center' colSpan={10}>
                          <Spinner size='sm' />
                        </td>
                      </tr>
                    ) : (
                      <tr>
                        <td className='text-center' colSpan={10}>
                          No event found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
            </Col>
          </Row>
          {!showLoading &&
          Array.isArray(showTypeList) &&
          showTypeList.length ? (
            <Col xl={12} className='tab-structure res-tab-str'>
              <div>
                <Nav tabs fill className=''>
                  {showTypeList.map((show) => {
                    return (
                      <NavItem
                        className={classNames('', {
                          'tab-heading-show-list':
                            show.show_type === showTabType,
                        })}
                      >
                        <NavLink
                          className={classNames('', {
                            'active tab-heading-show-list':
                              show.show_type === showTabType,
                          })}
                          onClick={() => setShowTabType(show.show_type)}
                        >
                          {show.show_type}
                        </NavLink>
                      </NavItem>
                    )
                  })}
                </Nav>
                <TabContent
                  activeTab={showTabType}
                  className='tab-heading-show-list'
                >
                  {showTypeList.map((show) => {
                    return (
                      <TabPane tabId={show.show_type}>
                        <Row>
                          <Col sm='12'>
                            <AllShowList
                              showList={show.show_list}
                              selectedShow={selectedShow}
                              setSelectedShow={setSelectedShow}
                            />
                          </Col>
                        </Row>
                      </TabPane>
                    )
                  })}
                </TabContent>
              </div>
            </Col>
          ) : showLoading ? (
            <Col xl={12} className='tab-structure res-tab-str text-center'>
              <Spinner size='sm' color='primary' />
            </Col>
          ) : showTypeList.length === 0 ? (
            <Col
              xl={12}
              className='tab-structure res-tab-str text-dark text-center fs-4 fw-bolder'
            >
              No show found
            </Col>
          ) : null}
        </Container>
      </section>
    </div>
  )
}

export default ShowList
