import React from 'react';
import { Container } from 'reactstrap';
import { Row, Col } from 'reactstrap';

const PricingView = () => {
  return (
    <React.Fragment>
      <div className="page-content mb-5">
        <Container fluid>
          <Row>
            <Col md="12" className="text-center mt-5 mb-5">
              {/* <h2 className="top-title text-center mb-5">
                <span className="text-cap">Get Started Today</span>
              </h2> */}
            </Col>
          </Row>
          <Row>
            <Col lg={4} md={6} sm={6}>
              <div className="pricingTable pre-exh">
                <div className="outer">
                  <div className="pricingTable-header">
                    <h3 className="heading">PREMIER EXHIBITOR</h3>
                  </div>
                  <div className="inner">
                    <div className="box">
                      <div className="pricing-content text-left">
                        <div className="cont-color">
                          <ul className='mb-2'>
                            <li>View Complete Show Reports *</li>
                            <li>View Live Class placements *</li>
                          </ul>
                        </div>
                        <ul className='mb-2'>
                          <li>View Upcoming Events</li>
                          <li>View Entry Count By Breed</li>
                          <li>Online Entry Portal</li>
                          <li>Easy Entry</li>
                          <li>Easyfieds</li>
                        </ul>
                      </div>
                      <div className="pricingTable-footer-price">
                        <Row>
                          <Col md={5} className="dis-price">
                            {' '}
                            <div class="deceased"></div>
                            <strong>$24/year</strong> <br />{' '}
                            <small>
                              *Plus 7% State Sales Tax & 3% Processing Fees
                            </small>
                          </Col>
                          <Col md={7} className="dis-price">

                          <p className='mt-0'><strong>Convention Special $20/year</strong> <br /> Until 11-6-2024</p>
                            <small>
                              *Plus 7% State Sales Tax & 3% Processing Fees
                            </small>{' '}
                          </Col>
                          <Col md={12} className="dis-price only-shows">
                            <small>*Only shows that use Easy2show</small>
                          </Col>
                        </Row>
                      </div>
                      <div className="pricingTable-notice">
                        <a color="primary" class="btn btn-primary mt-2 btn-pre-exh" title="Purchase" href="/">PURCHASE</a>
                        <p>Prices subject to change without notice.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={4} md={6} sm={6}>
              <div className="pricingTable herd-mgt">
                <div className="outer">
                  <div className="pricingTable-header">
                    <h3 className="heading">HERD MANAGEMENT</h3>
                  </div>
                  <div className="inner">
                    <div className="box">
                      <div className="pricing-content hert-mgt-list">
                        <ul>
                          <li>
                            <h3 className="pedi-mgt-head">
                              Pedigree Management
                            </h3>
                          </li>
                          <li>Currently Available with these features :</li>
                          <li>
                            <small>
                              <span className="text-red w-break">
                                Rabbits & Cavies included New
                                Breeds/Varieties/Crossbred as well as Imports 3
                                “users” at the same address
                              </span>{' '}{' '}
                               and more features to come
                            </small>
                          </li>
                          <li>
                            <h3>Herd Management</h3>
                          </li>
                          <li>
                            <small>
                              Pre buy now to get our special price and all
                              additional features to launch in the next several
                              months
                            </small>
                          </li>
                        </ul>
                      </div>
                      <div className="hght-5">
                        <div class="ribbon">
                          $100/year <br />
                          <small className='pt-2'>
                            *Plus 7% State Sales Tax & 3% Processing Fees
                          </small>
                        </div>
                      </div>
                      <div className="pricingTable-notice">
                        <p className='herd-mgt-msg'>The ability to purchase Herd Management will be available on 11-11-24</p>
                        {/* <a color="primary" class="btn btn-primary mt-4 btn-herd-mgt" title="Purchase" href="/">PURCHASE</a> */}
                        <p>Prices subject to change without notice.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={4} md={6} sm={6}>
              <div className="pricingTable pre_herd">
              <div class="ribbon-ex">EXCLUSIVE</div>
                <div className="outer">
                  <div className="pricingTable-header">
                    <h3 className="heading">
                      PREMIER EXHIBITOR <br /> <small>and</small> <br /> HERD
                      MANAGEMENT
                    </h3>
                  </div>
                  <div className="inner">
                    <div className="box">
                      <div className="pricing-content hert-mgt-list">
                        <ul className="mb-0">
                          <li>
                            For a limited time purchase{' '}
                            <h3 className="mt-3">
                              {' '}
                              PREMIER EXHIBITOR <br /> <small>
                                and
                              </small> <br /> HERD MANAGEMENT
                            </h3>{' '}
                            <small>
                              {' '}
                              For this special price and <br /> receive all
                              future features for this special price
                            </small>{' '}
                          </li>
                        </ul>
                      </div>

                      <div className="pricingTable-footer-price">
                        <Row>
                          <Col md={6} className="dis-price">
                            <img
                              className="logo-dark"
                              src={'/assets/img/horn.png'}
                              title=""
                              alt=""
                            />
                            <small>
                              *Plus 7% State Sales Tax & 3% Processing Fees
                            </small>
                          </Col>
                          <Col md={6} className="dis-price">
                          <p className='mt-4 w-break'><strong>Convention <br />  $70 <br /> Fees Special</strong></p>
                          </Col>
                        </Row>
                      </div>
                      <div className="pricingTable-notice">
                      <p className='pre-herd-msg'>Premier Exhibitor is available now, <strike>Herd Management</strike> will be available on 11-11-24. </p>
                      {/* <a color="primary" class="btn btn-primary mt-2 btn-pre-herd-mgt" title="Purchase" href="/">PURCHASE</a> */}
                        <p>Prices subject to change without notice.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default PricingView;