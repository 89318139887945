export const GET_ALL_PEDIGREE_HEADER = 'GET_ALL_PEDIGREE_HEADER'
export const GET_ALL_PEDIGREE_HEADER_OPTIONS = 'GET_ALL_PEDIGREE_HEADER_OPTIONS'
export const GET_ALL_PEDIGREE_HEADER_SUCCESS = 'GET_ALL_PEDIGREE_HEADER_SUCCESS'

export const ADD_PEDIGREE_HEADER = 'ADD_PEDIGREE_HEADER'
export const UPDATE_PEDIGREE_HEADER = 'UPDATE_PEDIGREE_HEADER'
export const DELETE_PEDIGREE_HEADER = 'DELETE_PEDIGREE_HEADER'
export const GET_PEDIGREE_HEADER = 'GET_PEDIGREE_HEADER'
export const GET_PEDIGREE_HEADER_SUCCESS = 'GET_PEDIGREE_HEADER_SUCCESS'

export const DELETE_PEDIGREE_IMAGE = 'DELETE_PEDIGREE_IMAGE'