import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { Registerform } from './Components/registerform';

const Register = (props) => {
  const history = useHistory();

  useEffect(() => {
    if (localStorage.getItem('accessToken')) {
      history.push('/auth-events');
    }
  }, []);
  return (
    <React.Fragment>
      <Registerform />
    </React.Fragment>
  );
};

export default Register;
