
import { ToastContainer } from 'react-toastify';
import styled from 'styled-components';

const EToast = (props) => <StyleToast  {...props} />


export default EToast

const StyleToast = styled(ToastContainer)`
`