import { put, takeEvery } from 'redux-saga/effects'

import {
  GET_ALL_VENDOR_CATEGORY,
  GET_ALL_VENDOR_CATEGORY_SUCCESS,
} from './actionType'
import { get } from '../../../helpers/api_helper'
import { toast } from 'react-toastify'

function* VendorCategoryListing() {
  try {
    const res = yield get(
      `/api/v1/category/admin/sponsor-vendor/all?_sort=category_name&_order=ASC`
    )

    yield put({
      type: GET_ALL_VENDOR_CATEGORY_SUCCESS,
      payload: res?.data || [],
    })
  } catch (err) {
    if (err?.response?.data) {
      document.getElementById('submit-btn').removeAttribute('disabled')
      toast.error(err?.response?.data)
    }
  }
}

function* AnimalSagas() {
  yield takeEvery(GET_ALL_VENDOR_CATEGORY, VendorCategoryListing)
}

export default AnimalSagas
